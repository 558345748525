import styled from "styled-components";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";

type Custom = {};

export const ReviewThirdPartyContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px 30px;
  justify-content: flex-start;

  .reviewaccess__main__wrap {
    background: #ffffff;
    border-radius: 18px;
    padding: 40px;
    width: 90%;

    .review__title {
      margin: 0;
      color: #001935;
      font-size: 18px;
    }
    .review__subtitle {
      margin: 10px 0 20px 0;
      color: #545f6b;
      font-size: 16px;
    }

    .access__cards {
      background: #fafafa;
      border: 1px solid #f2f2f2;
      border-radius: 12px;
      padding: 30px 15px;
      margin: 10px 0;
      cursor: pointer;

      .user__title {
        font-size: 17px;
        color: #00499b;
        margin: 0;
        border-bottom: 1.7px solid #00499b;
        text-transform: uppercase;
      }
      .pass__subtitle {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #828282;
        margin: 20px 0;
      }

      .pass__items {
        margin: 0;
        font-size: 14px;
        padding: 8px 15px;
        border-radius: 10px;
        color: #0c0c0c;
        border: 1px solid #0c0c0c11;
        font-weight: 600;
        margin: 8px 15px 8px 0;
      }
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  @media (max-width: 880px) {
    padding: 40px 15px;

    .manageaccess__main__wrap {
      padding: 40px 15px;
    }
  }

  @media (max-width: 570px) {
    .manageaccess__main__wrap {
      .access__cards {
        .footer__wrap {
          flex-direction: column;
          align-items: flex-end;
          .passes__wrap {
            width: 100%;
          }

          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
`;
