import { DashboardLayout, GoBack } from "components"
import { useLocation } from "react-router-dom"
import { FlexibleSection } from "../../../components/lib/Box/box.styles"
import { CustomForm } from "components"
import { AddMedicalContainer } from "./addMedicalHistory.styles"

const AddMedical = () => {
  const location = useLocation()
  const { state } = location

  const generalRecordsformDetails = [
    {
      name: "height",
      type: "text",
      width: "48%",
      label: "Your height",
    },
    {
      name: "weight",
      type: "text",
      width: "48%",
      label: "Your weight",
    },
    {
      name: "bloodType",
      type: "select",
      width: "48%",
      label: "Blood type",
      dataSet: ["O Negative", "O Positive"],
    },
    {
      name: "genotype",
      type: "select",
      width: "48%",
      label: "Your Genotype",
      dataSet: ["AS", "AC", "AA", "SS"],
    },
    {
      name: "disabilities",
      type: "select",
      width: "48%",
      label: "Disabilities",
      dataSet: ["Yes", "No"],
      break: "Disabilities & Allergies",
    },
    {
      name: "enterDisability",
      type: "text",
      width: "48%",
      label: "Disability",
    },
    {
      name: "allergies",
      type: "select",
      width: "48%",
      label: "Allergies",
      dataSet: ["Yes", "No"],
    },
    {
      name: "enterAllergy",
      type: "text",
      width: "48%",
      label: "Allergey",
    },
  ]
  const hospitalRecordsformDetails = [
    {
      name: "facility",
      type: "text",
      width: "100%",
      label: "Healthcare Facility",
    },
    {
      name: "ailment",
      type: "text",
      width: "48%",
      label: "Ailment",
    },
    {
      name: "symptoms",
      type: "text",
      width: "48%",
      label: "Symptoms Of Ailment",
    },
    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
    {
      name: "physician",
      type: "text",
      width: "100%",
      label: "Physician",
    },
  ]

  const submit = () => {}
  return (
    <DashboardLayout headerText="Medical History">
      <GoBack />

      <AddMedicalContainer>
        <FlexibleSection
          className="form__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <h4 className="header__text">
            {state === "GENERAL RECORDS"
              ? "GENERAL RECORDS"
              : "HOSPITAL RECORDS"}
          </h4>
          <span className="subheader__text">
            Please fill in your medical history, starting with your most recent.
          </span>
          <CustomForm
            formDetails={
              state === "GENERAL RECORDS"
                ? generalRecordsformDetails
                : hospitalRecordsformDetails
            }
            formFinishButtonText="Save Medical Record"
            submit={submit}
          />
        </FlexibleSection>
      </AddMedicalContainer>
    </DashboardLayout>
  )
}

export default AddMedical
