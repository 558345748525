import { getErrorMessage } from "common"
import { OIDButton, Progress, TOASTER_TYPE, ToastMessage } from "components"
import { Config } from "config"
import { useMe } from "helpers"
import { useResendVerificationEmailMutation } from "pages/DashboardOverview/Modals/ResendEmailVerificationModal/__types/VerifyEmail.types"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { FlexibleDiv } from "../../components/lib/Box/box.styles"
import { useUserVerifyEmailMutation } from "./gql/__types/verifyEmail.types"

const VerifyEmail = () => {
  const location = useLocation()
  const [mutate, { loading: verificationLoading }] =
    useUserVerifyEmailMutation()
  const [expired, setExpired] = useState(false)
  const me = useMe()
  const [resendEmail, { loading: resendTokenLoading }] =
    useResendVerificationEmailMutation()
  const { search } = location
  const token = search.split("=")[1]

  useEffect(() => {
    mutate({
      variables: { emailToken: token },
    }).then(({ data }) => {
      if (data?.verifyEmail.includes("already")) {
        window.location.href = `${Config.AUTH_GATEWAY_URL}?d=app&r=/`
      }

      if (data?.verifyEmail.includes("expired")) {
        setExpired(true)
      }

      if (data?.verifyEmail === "🚀 Verification Successful") {
        window.location.href = `${Config.AUTH_GATEWAY_URL}?d=app&r=/`
      }
    })
  }, [token, mutate])

  const handleReverification = async () => {
    try {
      await resendEmail({
        variables: { email: me?.email! },
      })
      ToastMessage(
        TOASTER_TYPE.SUCCESS,
        "Please Check Your Email For Verification Link"
      )
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <FlexibleDiv width="100vw" height="100vh">
      {verificationLoading || (resendTokenLoading && <Progress />)}

      {expired ? (
        <FlexibleDiv maxWidth="500px" flexDir="column">
          <h2 style={{ textAlign: "center" }}>
            Sorry, this token has expired. Please click the button below to get
            a new token in your mail
          </h2>
          <OIDButton onClick={handleReverification}>
            Resend Verification Token
          </OIDButton>
        </FlexibleDiv>
      ) : (
        <FlexibleDiv maxWidth="500px" flexDir="column">
          <h1>Verifying Email</h1>
          <h2 style={{ textAlign: "center", margin: "0" }}>
            You will automatically redirected to the login page... please wait
          </h2>
        </FlexibleDiv>
      )}
    </FlexibleDiv>
  )
}

export default VerifyEmail
