import { NoData } from "components"
import { AiOutlinePlus as Plus } from "react-icons/ai"
import { HiOutlineArrowRight as Arrow } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import {
  FlexibleDiv,
  FlexibleSection,
} from "../../../../components/lib/Box/box.styles"
import { OIDButton } from "../../../../components/lib/Button/button.styles"
import { GeneralHistoryContainer } from "./generalHistory.styles"

const GeneralHistory = () => {
  const navigate = useNavigate()
  const data = [
    {
      title: "Mental Check",
      height: "5'8",
      weight: "160lbs",
    },
    {
      title: "Birth Records",
      height: "0'9",
      weight: "10lbs",
    },
  ]
  return (
    <GeneralHistoryContainer flexDir="column">
      {!!data.length ? (
        <FlexibleSection
          justifyContent="space-between"
          alignItems="flex-start"
          className="general_wrap"
        >
          <FlexibleDiv className="medicalList__wrap" width="70%">
            <span
              className="add__medical__mobile"
              onClick={() =>
                navigate("/add-medical-history", { state: "GENERAL RECORDS" })
              }
            >
              Add New
            </span>

            {data.map((item: any, idx: number) => (
              <FlexibleDiv
                alignItems="flex-start"
                flexDir="column"
                className="record__box"
              >
                <FlexibleDiv justifyContent="space-between">
                  <h4 className="medicalcard__title">{item.title}</h4>
                  <div
                    onClick={() =>
                      navigate(`/medical/${item.title}`, {
                        state: "GENERAL RECORDS",
                      })
                    }
                  >
                    <Arrow />
                  </div>
                </FlexibleDiv>
                <p className="medicalcard__bodytext">
                  Your Height: {item.height}
                </p>
                <p className="medicalcard__bodytext">
                  Your Weight: {item.weight}
                </p>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>

          <OIDButton
            onClick={() =>
              navigate("/add-medical-history", { state: "GENERAL RECORDS" })
            }
          >
            <Plus />
            Add Hospital Record
          </OIDButton>
        </FlexibleSection>
      ) : (
        <NoData
          noDataTitle="No General Records"
          noDataMessage="Hi May, please click the button above to update your medical history"
          noDataButtonRoute="/add-medical-history"
          noDataButtonText="Add General Records"
          noDataNavigationData="GENERAL RECORDS"
        />
      )}
    </GeneralHistoryContainer>
  )
}

export default GeneralHistory
