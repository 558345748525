import React from "react"
import { ComingSoon } from "components"
import { CovidHistoryContainer } from "./covidHistory.styles"

const CovidHistory = () => {
  return (
    <CovidHistoryContainer flexDir="column">
      <ComingSoon comingPage="covid" />
    </CovidHistoryContainer>
  )
}

export default CovidHistory
