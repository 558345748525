import React from "react"
import { DashboardLayout } from "components"
import { ComingSoon } from "components"

const BankService = () => {
  return (
    <DashboardLayout headerText="Bank Services">
      <ComingSoon comingPage="Bank" />
    </DashboardLayout>
  )
}

export default BankService
