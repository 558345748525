import styled from "styled-components";
import { FlexibleDiv } from "../Box/box.styles";

type Custom = {
  noDataTabHeader?: boolean;
};
export const NodataContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  height: ${({ noDataTabHeader }) =>
    noDataTabHeader ? "calc(100vh - 130px)" : "calc(100vh - 70px)"};
  padding: 20px;
  align-items: flex-start;

  .nodata__header {
    margin: 0;
    text-align: center;
  }
  .nodata__text {
    max-width: 400px;
    color: #939393;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    width: 90%;
  }
  button {
    min-width: 200px;
  }

  @media screen and (max-width: 800px) {
    .nodata__main__wrap {
      background: #ffffff;
      box-shadow: 1px 2px 16px rgba(147, 147, 147, 0.08);
      border-radius: 10px;
    }
  }

  @media (max-height: 720px) and (max-width: 800px) {
    .nodata__main__wrap {
      height: 100%;
    }
  }
`;
