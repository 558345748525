import { DashboardLayout } from "components"
import { useState } from "react"
import { BsBellFill as Bell } from "react-icons/bs"
import { FlexibleDiv } from "../../components/lib/Box/box.styles"
import {
  newNotifications,
  newRequests,
  oldNotifications,
  oldRequests,
} from "../../utils/data-helpers/notifications"
import { NotificationsContainer } from "./notifications.styles"

const Notifications = () => {
  const [activeTab, setActiveTab] = useState("Activities")
  return (
    <DashboardLayout headerText="Notifications">
      <NotificationsContainer>
        <FlexibleDiv
          className="notification__box"
          flexDir="column"
          alignItems="flex-start"
        >
          <FlexibleDiv
            className="notifications__tabs"
            justifyContent="flex-start"
          >
            <FlexibleDiv
              onClick={() => setActiveTab("Activities")}
              id={activeTab === "Activities" ? "activetab" : ""}
              width="max-content"
              flexWrap="nowrap"
              className="notification__tabmain"
            >
              <p className="tab__text">Activities</p>
              <span className="num__of__notifications">
                {newNotifications?.length
                  ? `(${newNotifications?.length})`
                  : ""}
              </span>
            </FlexibleDiv>
            <FlexibleDiv
              width="max-content"
              flexWrap="nowrap"
              onClick={() => setActiveTab("Requests")}
              id={activeTab === "Requests" ? "activetab" : ""}
              className="notification__tabmain"
            >
              <p className="tab__text">Requests</p>
              <span className="num__of__notifications">
                {newRequests?.length ? `(${newRequests?.length})` : ""}
              </span>
            </FlexibleDiv>
          </FlexibleDiv>

          {activeTab === "Activities" && (
            <FlexibleDiv flexDir="column">
              {newNotifications.map((item, idx) => (
                <FlexibleDiv
                  justifyContent="space-between"
                  className="notification__item newnotification__item"
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  key={idx}
                >
                  <FlexibleDiv className="bell__wrap">
                    <Bell />
                  </FlexibleDiv>
                  <FlexibleDiv
                    width="80%"
                    flexDir="column"
                    alignItems="flex-start"
                  >
                    <h4 className="notification__item__title">{item.title}</h4>
                    <p className="notificaion__message">{item.message}</p>
                  </FlexibleDiv>
                  <span className="notification__time">{item.time}</span>
                </FlexibleDiv>
              ))}

              {oldNotifications.map((item, idx) => (
                <FlexibleDiv
                  justifyContent="space-between"
                  className="notification__item oldnotification__item"
                  flexWrap="nowrap"
                  key={idx}
                  alignItems="flex-start"
                >
                  <FlexibleDiv className="bell__wrap">
                    <Bell />
                  </FlexibleDiv>
                  <FlexibleDiv
                    width="80%"
                    flexDir="column"
                    alignItems="flex-start"
                  >
                    <h4 className="notification__item__title oldnotification__item__title">
                      {item.title}
                    </h4>
                    <p className="notificaion__message oldnotificaion__message">
                      {item.message}
                    </p>
                  </FlexibleDiv>
                  <span className="notification__time oldnotification__time">
                    {item.time}
                  </span>
                </FlexibleDiv>
              ))}

              {!oldNotifications?.length && !newNotifications?.length && (
                <FlexibleDiv className="notification__item ">
                  <h4 className="notification__item__title">No Data</h4>
                </FlexibleDiv>
              )}
            </FlexibleDiv>
          )}

          {activeTab === "Requests" && (
            <FlexibleDiv flexDir="column">
              {oldRequests.map((item, idx) => (
                <FlexibleDiv
                  justifyContent="space-between"
                  className="notification__item oldnotification__item"
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  key={idx}
                >
                  <FlexibleDiv className="bell__wrap">
                    <Bell />
                  </FlexibleDiv>
                  <FlexibleDiv
                    width="80%"
                    flexDir="column"
                    alignItems="flex-start"
                  >
                    <h4 className="notification__item__title">{item.title}</h4>
                    <p className="notificaion__message">{item.message}</p>
                  </FlexibleDiv>
                  <span className="notification__time">{item.time}</span>
                </FlexibleDiv>
              ))}

              {!oldRequests?.length && !newRequests?.length && (
                <FlexibleDiv className="notification__item ">
                  <h4 className="notification__item__title">No Data</h4>
                </FlexibleDiv>
              )}
            </FlexibleDiv>
          )}
        </FlexibleDiv>{" "}
      </NotificationsContainer>
    </DashboardLayout>
  )
}

export default Notifications
