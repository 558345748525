import * as React from "react"
import Box from "@mui/material/Box"
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress"

interface Props {
  progressProps?: LinearProgressProps
  top?: string
  position?: string
}
export const Progress: React.FC<Props> = ({
  progressProps,
  top = "0",
  position = "absolute",
}) => {
  return (
    <Box
      sx={{
        position,
        width: "100%",
        top: `${top}`,
        left: 0,
      }}
    >
      <LinearProgress
        sx={{ borderRadius: ".2rem" }}
        variant={progressProps?.variant ?? "query"}
        {...progressProps}
      />
    </Box>
  )
}
