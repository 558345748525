import React, { useState } from "react";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";
import { UpdateProfileLayoutContainer } from "../../../../components/layout/UpdateProfileLayout/updateProfile.styles";
import {
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components";
import { CustomForm } from "components";
import { LongArrowRightIcon } from "../../../../assets/images/dashboardLayout/svgExports";
import { useLocation, useNavigate } from "react-router-dom";
import { useMe } from "helpers";
import { getErrorMessage } from "common";
import * as Yup from "yup";
import { useUpdateUserSocialMediaMutation } from "../gql/__types/UpdateProfile.types";
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types";

const validationSchema = Yup.object().shape({
  instagram: Yup.string().min(3).nullable(),
  twitter: Yup.string().min(3).nullable(),
  linkedin: Yup.string().min(6).nullable(),
  facebook: Yup.string().min(3).nullable(),
});

const SocialDetailsUpdate = () => {
  const location = useLocation();
  const [step] = React.useState(location?.state);
  const navigate = useNavigate();
  const [mutate, { loading }] = useUpdateUserSocialMediaMutation();
  const me = useMe();
  const { data, refetch, loading: profileLoading } = useGetProfileDataQuery();
  const [hideFormWhileRefetch, sethideFormWhileRefetch] = useState(true);
  const profile = data && data.userGetProfile;

  const initialValues = {
    instagram: profile?.socialMedia?.instagram || "",
    facebook: profile?.socialMedia?.facebook || "",
    twitter: profile?.socialMedia?.twitter || "",
    linkedin: profile?.socialMedia?.linkedin || "",
  };

  const formDetails = [
    {
      name: "facebook",
      type: "text",
      width: "48%",
      label: "Facebook Username",
    },
    {
      name: "instagram",
      type: "text",
      width: "48%",
      label: "Instagram Username",
    },
    {
      name: "twitter",
      type: "text",
      width: "48%",
      label: "Twitter Username",
    },
    {
      name: "linkedin",
      type: "text",
      width: "48%",
      label: "Linkedin Username",
    },
  ];

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    const payload = {
      ...me?.profile,
      ...values,
    };

    try {
      await mutate({
        variables: payload,
      });
      sethideFormWhileRefetch(false);
      await refetch();

      if (!submitAction) {
        ToastMessage(
          TOASTER_TYPE.SUCCESS,
          "Social details updated successfully."
        );
      } else {
        if (submitAction === "save_continue") {
          navigate("/update-profile/emergency-contact", { state: true });
        } else {
          navigate("/profile");
        }
      }
      setSubmitting(false);
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error));
    }
  };

  return (
    <DashboardLayout headerText="Update Profile">
      <GoBack />

      {loading && <Progress position="relative" />}

      {profileLoading && !!hideFormWhileRefetch ? (
        <Progress position="relative" />
      ) : (
        <UpdateProfileLayoutContainer
          className="updateprofile__main__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          {!!step && (
            <FlexibleDiv className="progress__bar">
              <FlexibleDiv
                className="progress__bar__inner"
                width="80%"
              ></FlexibleDiv>
            </FlexibleDiv>
          )}
          <p className="form__title">Social Media Details</p>
          {!!step && (
            <span className="form__subtitle">
              Hi May, the journey 5 steps begin with just 1... or something like
              that
            </span>
          )}
          <CustomForm
            formDetails={formDetails}
            formFinishButtonText={!!step ? "Save & Exit" : "Update"}
            formContinueButtonText="Save & Continue"
            formColumnButtons={!!step ? true : false}
            submit={submit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
          {!!step && (
            <FlexibleDiv className="form__skip">
              <span
                className="skip__text"
                onClick={() =>
                  navigate("/update-profile/emergency-contact", { state: true })
                }
              >
                Skip
                <LongArrowRightIcon />
              </span>
            </FlexibleDiv>
          )}
        </UpdateProfileLayoutContainer>
      )}
    </DashboardLayout>
  );
};

export default SocialDetailsUpdate;
