import { getErrorMessage } from "common"
import { Progress, TOASTER_TYPE, ToastMessage } from "components"
import { OIDButton } from "components/lib/Button/button.styles"
import { useMe } from "helpers"
import { useState } from "react"
import { ModalContainer } from "../../../../../components/layout/ModalLayout/modalLayout.styles"
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles"
import { useResendVerificationEmailMutation } from "./VerifyEmail.types"

const VerifyEmail = () => {
  const me = useMe()
  const [openModal] = useState(me?.isVerified)
  const [resendEmail, { loading }] = useResendVerificationEmailMutation()

  const submit = async () => {
    try {
      await resendEmail({
        variables: { email: me?.email! },
      })
      ToastMessage(
        TOASTER_TYPE.SUCCESS,
        "Please Check Your Email For Verification Link"
      )
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <ModalContainer open={!openModal} maxWidth="450px">
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        {loading && <Progress />}

        <h3 className="modal__headertext">Verify Your Email</h3>
        <p className="modal__subheadertext">
          Please Verify Your Email To Continue
        </p>
        <FlexibleDiv maxWidth="290px" margin="20px 0 0 0">
          <OIDButton
            backgroundColor="transparent"
            color="#0B61DD"
            onClick={submit}
          >
            Resend Verification Email
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default VerifyEmail
