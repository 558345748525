import React from "react";
import { PinInputContainer } from "./pinInput.styles";
import PinInput from "react-pin-input";

interface IProps {
  data: any;
  disabled?: boolean;
}

const CustomPinInput: React.FC<IProps> = ({ data, disabled }) => {
  return (
    <PinInputContainer>
      <PinInput
        length={6}
        initialValue=""
        // secret
        onChange={(value, index) => {}}
        type="numeric"
        inputMode="number"
        style={{ padding: "10px" }}
        inputStyle={{ borderColor: "red" }}
        inputFocusStyle={{ borderColor: "blue" }}
        onComplete={(value, index) => {}}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </PinInputContainer>
  );
};

export default CustomPinInput;
