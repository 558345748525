import * as React from "react";
import { ForEnum } from "types";
import { renderSVG } from "./utils.helper";

export interface IScope {
  id?: number | string | any;
  name: string;
  value: ForEnum;
  read: boolean;
  write: boolean;
  icon?: any;
}

export const scopeLists: IScope[] = [
  {
    id: 1,
    name: "Personal Details",
    value: ForEnum.PERSONAL,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 8.83333H7.16667V0.5H0.5V8.83333ZM0.5 15.5H7.16667V10.5H0.5V15.5ZM8.83333 15.5H15.5V7.16667H8.83333V15.5ZM8.83333 0.5V5.5H15.5V0.5H8.83333Z"
          fill="#0B61DD"
        />
      </svg>
    ),
  },
  {
    id: 2,
    name: "Address",
    value: ForEnum.BILLING,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.657 13.6572L8 19.3142L2.343 13.6572C1.22422 12.5384 0.46234 11.1129 0.153691 9.56106C-0.154957 8.00922 0.00349361 6.40071 0.609007 4.93893C1.21452 3.47714 2.2399 2.22774 3.55548 1.3487C4.87107 0.46967 6.41777 0.000488281 8 0.000488281C9.58224 0.000488281 11.1289 0.46967 12.4445 1.3487C13.7601 2.22774 14.7855 3.47714 15.391 4.93893C15.9965 6.40071 16.155 8.00922 15.8463 9.56106C15.5377 11.1129 14.7758 12.5384 13.657 13.6572ZM1 20.0002H15V22.0002H1V20.0002Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 3,
    name: "KYC (BVN)",
    value: ForEnum.KYC_BVN,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0L17.217 1.826C17.4391 1.87536 17.6377 1.99897 17.78 2.1764C17.9224 2.35384 18 2.57452 18 2.802V12.789C17.9999 13.7767 17.756 14.7492 17.2899 15.62C16.8238 16.4908 16.1499 17.2331 15.328 17.781L9 22L2.672 17.781C1.85027 17.2332 1.17646 16.4911 0.710348 15.6205C0.244236 14.7498 0.000236356 13.7776 0 12.79V2.802C3.90378e-05 2.57452 0.0776379 2.35384 0.21999 2.1764C0.362341 1.99897 0.560937 1.87536 0.783 1.826L9 0ZM9 6C8.55975 6.00002 8.1318 6.1453 7.78253 6.41332C7.43326 6.68133 7.18219 7.0571 7.06824 7.48236C6.95429 7.90761 6.98384 8.35857 7.15231 8.76532C7.32077 9.17206 7.61874 9.51186 8 9.732V14H10L10.001 9.732C10.3823 9.51181 10.6803 9.17193 10.8488 8.76509C11.0172 8.35824 11.0467 7.90718 10.9326 7.48187C10.8186 7.05657 10.5673 6.6808 10.2179 6.41286C9.86843 6.14492 9.44034 5.9998 9 6Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 3,
    name: "KYC (NIN)",
    value: ForEnum.KYC_NIN,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0L17.217 1.826C17.4391 1.87536 17.6377 1.99897 17.78 2.1764C17.9224 2.35384 18 2.57452 18 2.802V12.789C17.9999 13.7767 17.756 14.7492 17.2899 15.62C16.8238 16.4908 16.1499 17.2331 15.328 17.781L9 22L2.672 17.781C1.85027 17.2332 1.17646 16.4911 0.710348 15.6205C0.244236 14.7498 0.000236356 13.7776 0 12.79V2.802C3.90378e-05 2.57452 0.0776379 2.35384 0.21999 2.1764C0.362341 1.99897 0.560937 1.87536 0.783 1.826L9 0ZM9 6C8.55975 6.00002 8.1318 6.1453 7.78253 6.41332C7.43326 6.68133 7.18219 7.0571 7.06824 7.48236C6.95429 7.90761 6.98384 8.35857 7.15231 8.76532C7.32077 9.17206 7.61874 9.51186 8 9.732V14H10L10.001 9.732C10.3823 9.51181 10.6803 9.17193 10.8488 8.76509C11.0172 8.35824 11.0467 7.90718 10.9326 7.48187C10.8186 7.05657 10.5673 6.6808 10.2179 6.41286C9.86843 6.14492 9.44034 5.9998 9 6Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 4,
    name: "Next of Kin",
    value: ForEnum.NEXTOFKIN,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="19"
        height="22"
        viewBox="0 0 19 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.841 14.659L14.017 14.836L14.195 14.659C14.4039 14.4501 14.652 14.2843 14.925 14.1713C15.1979 14.0582 15.4905 14 15.786 14C16.0815 14 16.3741 14.0582 16.647 14.1713C16.92 14.2843 17.1681 14.4501 17.377 14.659C17.5859 14.8679 17.7517 15.116 17.8647 15.389C17.9778 15.6619 18.036 15.9545 18.036 16.25C18.036 16.5455 17.9778 16.8381 17.8647 17.111C17.7517 17.384 17.5859 17.6321 17.377 17.841L14.017 21.2L10.659 17.841C10.237 17.419 9.99999 16.8467 9.99999 16.25C9.99999 15.6533 10.237 15.081 10.659 14.659C11.081 14.237 11.6533 14 12.25 14C12.8467 14 13.419 14.237 13.841 14.659ZM8 13V21H0C2.41087e-05 18.9216 0.808937 16.9247 2.25547 15.4323C3.702 13.9398 5.67259 13.069 7.75 13.004L8 13ZM8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 5,
    name: "Nationality Information",
    value: ForEnum.NATIONALITY,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H9.382C9.56766 9.91486e-05 9.74963 0.0518831 9.90753 0.149552C10.0654 0.247222 10.193 0.386919 10.276 0.553L11 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V14C18 14.2652 17.8946 14.5196 17.7071 14.7071C17.5196 14.8946 17.2652 15 17 15H10.618C10.4323 14.9999 10.2504 14.9481 10.0925 14.8504C9.93458 14.7528 9.80699 14.6131 9.724 14.447L9 13H2V19H0V0Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 6,
    name: "Emergency Contact(s)",
    value: ForEnum.EMERGENCY,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.99962 18V12C1.99962 9.87827 2.84247 7.84344 4.34276 6.34315C5.84305 4.84285 7.87788 4 9.99962 4C12.1213 4 14.1562 4.84285 15.6565 6.34315C17.1568 7.84344 17.9996 9.87827 17.9996 12V18H18.9996V20H0.999617V18H1.99962ZM3.99962 12H5.99962C5.99962 10.9391 6.42104 9.92172 7.17119 9.17157C7.92134 8.42143 8.93875 8 9.99962 8V6C8.40832 6 6.88219 6.63214 5.75698 7.75736C4.63176 8.88258 3.99962 10.4087 3.99962 12ZM8.99962 0H10.9996V3H8.99962V0ZM17.7776 2.808L19.1916 4.222L17.0716 6.343L15.6566 4.929L17.7776 2.808ZM0.807617 4.222L2.22162 2.808L4.34262 4.928L2.92962 6.344L0.807617 4.222Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 7,
    name: "Medical History",
    value: ForEnum.MEDICAL,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 8,
    name: "Educational Background",
    value: ForEnum.EDUCATION,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 20H3.5C2.57174 20 1.6815 19.6313 1.02513 18.9749C0.368749 18.3185 0 17.4283 0 16.5V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V15H3.5C3.10218 15 2.72064 15.158 2.43934 15.4393C2.15804 15.7206 2 16.1022 2 16.5C2 16.8978 2.15804 17.2794 2.43934 17.5607C2.72064 17.842 3.10218 18 3.5 18H16Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 9,
    name: "Work Experience",
    value: ForEnum.WORKEXPERIENCE,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 4V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H5ZM15 6V11H18V6H15ZM13 6H7V11H13V6ZM5 6H2V11H5V6ZM7 2V4H13V2H7Z"
          fill="#787878"
        />
      </svg>
    ),
  },
  {
    id: 10,
    name: "Travel Record",
    value: ForEnum.TRAVEL,
    read: false,
    write: false,
    icon: renderSVG(
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 0V2H13V0H15V2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H5V0H7ZM18 7H2V18H18V7ZM13.036 9.136L14.45 10.55L9.5 15.5L5.964 11.964L7.38 10.55L9.501 12.672L13.037 9.136H13.036Z"
          fill="#787878"
        />
      </svg>
    ),
  },
];
