import styled from "styled-components";
import { FlexibleDiv } from "../../components/lib/Box/box.styles";

type Custom = {};

export const VerifyUserContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px 30px;
  justify-content: flex-start;

  .verifyuser__result__wrap {
    background: #ffffff;
    border-radius: 18px;
    padding: 50px 15px;
    width: 80%;
    max-width: 800px;

    .verifyuser__result__subwrap {
      width: 75%;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 4px 12px rgba(68, 68, 68, 0.05);
      border-radius: 10px;
      padding: 40px 7%;
      .initials {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        background-color: #021a2d;
        .initials__text {
          margin: 0;
          text-transform: uppercase;
          color: #ffffff;
          font-size: 24px;
        }
      }
      .user__name {
        font-size: 18px;
        color: #424242;
      }
      .data__wrap {
        .data__resulttext {
          width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #272727;
          font-size: 16px;
        }
        .data__headertext {
          color: #272727;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    @media (max-width: 1100px) {
      width: 100%;
      max-width: 100%;

      .verifyuser__result__subwrap {
        width: 100%;
      }
    }
  }

  .verifyuser__main__wrap {
    background: #ffffff;
    border-radius: 18px;
    padding: 40px;
    width: 80%;
    min-height: 60vh;

    .user__name {
      margin: 0;
      font-size: 18px;
      color: #424242;
    }

    p {
      font-size: 16px;
      color: #424242;
      margin: 20px 0;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    padding: 40px 15px;
    .verifyuser__main__wrap {
      padding: 30px 20px;
    }
    .verifyuser__result__wrap {
      background: #ffffff;
      border-radius: 18px;
      padding: 30px 20px;

      .verifyuser__result__subwrap {
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        width: 90%;
      }
    }
  }

  @media (max-width: 500px) {
    .verifyuser__result__wrap {
      .verifyuser__result__subwrap {
        width: 100%;
        .initials {
          width: 50px;
          height: 50px;
          .initials__text {
            font-size: 18px;
          }
        }
        .data__wrap {
          .data__resulttext,
          .data__headertext {
            font-size: 12px;
          }
          .data__resulttext {
            width: 120px;
          }
        }
        button {
          height: 40px;
        }
      }
    }
  }
`;
