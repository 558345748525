import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";

type Custom = {};

export const EducationListContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  @media (max-width: 600px) {
    padding-top: 30px;
  }
  .educationlist__mainwrap {
    background: #ffffff;
    border: 0.5px solid #f2f2f2;
    box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
    border-radius: 12px;
    padding: 30px;
    margin-top: 30px;
    min-height: 190px;
    justify-content: space-between;
    transition: all 0.3s ease;

    .educationlist__headertext,
    svg {
      margin: 0;
      font-size: 18px;
    }
    .educationlist__subheadertext {
      font-size: 16px;
      color: #787878;
      font-weight: 600;
      margin: 0;
    }
    .educationlist__bodytext {
      font-weight: 600;
      font-size: 14px;
      color: #787878;
    }

    :hover {
      cursor: pointer;
      background: #fbfcff;
      transform: scale(1.04);
    }
    @media (max-width: 1010px) {
      padding: 20px 15px;
      min-height: 160px;
    }

    @media (max-width: 600px) {
      min-height: 150px;
      margin-top: 10px;
      .educationlist__headertext,
      svg {
        font-size: 16px;
      }
      .educationlist__subheadertext {
        font-size: 14px;
      }
      .educationlist__bodytext {
        font-size: 12px;
      }
    }
  }
`;
