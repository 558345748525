import { getErrorMessage } from "common"
import { OIDButton, Progress, TOASTER_TYPE, ToastMessage } from "components"
import { ModalContainer } from "components/layout/ModalLayout/modalLayout.styles"
import { FlexibleDiv } from "components/lib/Box/box.styles"
import { IRAPayload } from "pages/DashboardOverview/dashboardOverview"
import {
  useUserApproveProfileRequestMutation,
  useUserGetProfileAcessListQuery,
  useUserGetProfileRequestsQuery,
  useUserRejecteProfileRequestMutation,
  useUserRevokeAccesssMutation,
} from "pages/ViewAccessRequests/gql/__types/accessRequest.types"
import React from "react"

interface IProps {
  requestActionPayload?: IRAPayload
  setRequestActionPayload: (e: IRAPayload) => void
  fromPage: string
}

const RequestActionModal: React.FC<IProps> = ({
  setRequestActionPayload,
  requestActionPayload,
  fromPage,
}) => {
  const { loading: accessListLoading } = useUserGetProfileAcessListQuery()
  const [rejectProfileRequest, { loading: rejectRequestLoading }] =
    useUserRejecteProfileRequestMutation()
  const [approveProfileRequest, { loading: approveRequestLoading }] =
    useUserApproveProfileRequestMutation()
  const [revokeAccess, { loading: revokeAccessLoading }] =
    useUserRevokeAccesssMutation()
  const { loading: requestListLoading } = useUserGetProfileRequestsQuery()

  const close = () => {
    setRequestActionPayload({
      show: false,
      id: "",
      requestStatus: "",
    })
  }

  const completeAction = async () => {
    try {
      if (typeof requestActionPayload?.id === "string") {
        if (requestActionPayload?.requestStatus === "Revoke Access") {
          await revokeAccess({
            variables: {
              userId: requestActionPayload.id,
            },
          })
        } else {
          if (requestActionPayload?.requestStatus === "accept") {
            await approveProfileRequest({
              variables: { requestId: requestActionPayload.id },
            })
          } else {
            await rejectProfileRequest({
              variables: { requestId: requestActionPayload.id },
            })
          }
        }
      }
      ToastMessage(TOASTER_TYPE.SUCCESS, "request successful")
      window.location.replace(fromPage)
    } catch (e) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(e))
    }
  }

  return (
    <ModalContainer open={!!requestActionPayload?.show} onClose={close}>
      <FlexibleDiv className="wrap" height="100%">
        <FlexibleDiv
          width="max-content"
          flexWrap="nowrap"
          className="box__wrap"
          flexDir="column"
        >
          {(accessListLoading ||
            requestListLoading ||
            revokeAccessLoading ||
            rejectRequestLoading ||
            approveRequestLoading) && <Progress />}

          <h3 className="modal__headertext">
            {requestActionPayload?.requestStatus === "accept"
              ? "Accept Request"
              : requestActionPayload?.requestStatus === "decline"
              ? "Decline Request"
              : "Revoke Access"}
          </h3>
          {requestActionPayload?.requestStatus === "Revoke Access" ? (
            <p className="modal__subheadertext">
              Are You Sure You Want Revoke This Access?
            </p>
          ) : (
            <p className="modal__subheadertext">
              Are You Sure You Want To {requestActionPayload?.requestStatus}{" "}
              This Request
            </p>
          )}
          <FlexibleDiv
            maxWidth="290px"
            margin="20px 0 0 0"
            justifyContent="space-between"
          >
            <OIDButton onClick={close}>Cancel</OIDButton>
            <OIDButton
              onClick={completeAction}
              backgroundColor="transparent"
              color="#0B61DD"
            >
              {requestActionPayload?.requestStatus === "accept"
                ? "Accept Request"
                : requestActionPayload?.requestStatus === "decline"
                ? "Decline Request"
                : "Revoke Access"}{" "}
            </OIDButton>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default RequestActionModal
