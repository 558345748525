import { DashboardLayout, GoBack } from "components"
import { useState } from "react"
import { FaPen as Edit } from "react-icons/fa"
import {
  FlexibleDiv,
  FlexibleSection,
} from "../../../components/lib/Box/box.styles"
import { CustomForm } from "components"
import { SingleEducationContainer } from "./singleEducation.styles"

const SingleEducation = () => {
  const [editDisabled, setEditDisabled] = useState(true)
  const educationformDetails = [
    {
      name: "level",
      type: "select",
      width: "48%",
      label: "Level Of Institution",
      dataSet: ["Primary", "Secondary", "Tertiary"],
    },
    {
      name: "institution",
      type: "select",
      width: "48%",
      label: "Name Of Institution",
      dataSet: ["Primary", "Secondary", "Tertiary"],
    },
    {
      name: "course",
      type: "text",
      width: "48%",
      label: "Course Of Study",
    },
    {
      name: "grade",
      type: "text",
      width: "48%",
      label: "Grade",
    },
    {
      name: "certificate",
      type: "select",
      width: "48%",
      label: "Certificate Obtained",
      dataSet: ["Primary", "Secondary", "Tertiary"],
    },
    {
      name: "address",
      type: "text",
      width: "48%",
      label: "School Address",
    },
    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
  ]

  const submit = () => {}
  return (
    <DashboardLayout headerText="Educational Background">
      <GoBack />

      <SingleEducationContainer>
        <FlexibleSection
          className="form__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <FlexibleDiv justifyContent="space-between">
            <h4 className="header__text">
              King’s Court Secondary School
              <br />
              <br />
              <span className="education__duration">(2007 - 2001)</span>
            </h4>

            <span
              className="edit"
              onClick={() => setEditDisabled(!editDisabled)}
            >
              <Edit /> Edit
            </span>
          </FlexibleDiv>

          <CustomForm
            formDetails={educationformDetails}
            formFinishButtonText="Update Education"
            editDisabled={editDisabled}
            submit={submit}
          />
        </FlexibleSection>
      </SingleEducationContainer>
    </DashboardLayout>
  )
}

export default SingleEducation
