import React from "react"
import { NodataContainer } from "./nodata.styles"
import { FlexibleDiv } from "../Box/box.styles"
import { useNavigate } from "react-router-dom"
import { OIDButton } from "../Button/button.styles"
import NodataImage from "../../../assets/images/dashboardLayout/no-data-image.png"
import { AiOutlinePlus as Plus } from "react-icons/ai"
interface IProps {
  noDataMessage: string
  noDataTitle: string
  noDataButtonText?: string
  noDataButtonRoute?: string
  noDataNavigationData?: any
  noDataTabHeader?: boolean
  notFound?: boolean
}

export const NoData: React.FC<IProps> = ({
  noDataTitle,
  noDataMessage,
  noDataButtonRoute,
  noDataButtonText,
  noDataNavigationData,
  noDataTabHeader,
  notFound,
}) => {
  const navigate = useNavigate()

  const handleRoute = () => {
    if (noDataButtonRoute) {
      if (noDataNavigationData) {
        navigate(noDataButtonRoute, { state: noDataNavigationData })
      } else {
        navigate(noDataButtonRoute)
      }
    }
  }
  return (
    <NodataContainer noDataTabHeader={noDataTabHeader}>
      <FlexibleDiv
        height="80%"
        flexWrap="nowrap"
        flexDir="column"
        className="nodata__main__wrap"
      >
        <div className="image__wrap">
          <img src={NodataImage} alt="" />
        </div>
        <h4 className="nodata__header">{noDataTitle}</h4>
        <p className="nodata__text">{noDataMessage}</p>
        {noDataButtonText ? (
          <OIDButton onClick={handleRoute}>
            {!notFound && <Plus />}

            {noDataButtonText}
          </OIDButton>
        ) : (
          ""
        )}
      </FlexibleDiv>
    </NodataContainer>
  )
}
