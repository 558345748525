import React, { useState } from "react";
import { PrivacyAndSecurityContainer } from "./privacyAndSecurity.styles";
import SettingsLayout from "../../../components/layout/SettingsLayout/settingsLayout";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";
import { useNavigate } from "react-router-dom";
import KycPinModal from "./Sections/KycPin/kycPin";
import SuccessModal from "../../../components/lib/SuccessModal/successModal";
import {
  MdOutlineKeyboardArrowRight as ArrowRight,
  MdOutlineKeyboardArrowDown as ArrowDown,
} from "react-icons/md";
import EmailAuthenticationModal from "./Sections/MultiFAFeatrues/EmailAuthentication/emailAuthentication";
import PhoneAuthenticationModal from "./Sections/MultiFAFeatrues/PhoneAuthentication/phoneAuthentication";
import ScanCodeModal from "./Sections/MultiFAFeatrues/ScanCode/scanCode";
import SetupModal from "./Sections/MultiFAFeatrues/SetupKey/setup";
import PinModal from "./Sections/MultiFAFeatrues/PinVerification/pinModal";
import TwoFaModal from "./Sections/MultiFAFeatrues/TwoFA/twoFa";
import InstallModal from "./Sections/MultiFAFeatrues/InstallGA/install";
import ManageAccessModal from "./Sections/AccessFeatures/ManageAccess/Modals/manageAccessModal";
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types";
import { Progress, TOASTER_TYPE, ToastMessage } from "components";
import { useUpdatePrivacyOptionsMutation } from "./gql/__types/privacyAndSecurity.types";
import Switch from "@mui/material/Switch";
import { useMe } from "helpers";
import { getErrorMessage } from "common";

const PrivacyAndSecurity = () => {
  const [showKycPinModal, setShowKycPinModal] = useState(false);
  const [showKycSuccessModal, setShowKycSuccessModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [showEmailAuth, setShowEmailAuth] = useState(false);
  const [showPhoneAuth, setShowPhoneAuth] = useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [showScanModal, setShowScanModal] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [showManageAccessModal, setShowManageAccessModal] = useState(false);
  const [pinMessage, setPinMessage] = useState("");
  const [blockChecked, setBlockChecked] = useState(false);
  const me = useMe();
  const { loading: profileLoading } = useGetProfileDataQuery();
  const [mutate, { loading: isPrivacyOptionsLoading }] =
    useUpdatePrivacyOptionsMutation();

  const navigate = useNavigate();

  const handleDropdown = (value: string) => {
    if (!!showDropdown) {
      setShowDropdown("");
    } else {
      setShowDropdown(value);
    }
  };

  const handleBlockRequests = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (me?._id) {
        mutate({
          variables: {
            userId: me._id,
            isBlocked: !me?.privacyOptions?.isBlocked,
          },
        });
      }

      setBlockChecked(event.target.checked);
    } catch (e) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(e));
    }
  };

  React.useEffect(() => {
    if (
      me?.privacyOptions &&
      typeof me?.privacyOptions?.isBlocked === "boolean"
    ) {
      setBlockChecked(me?.privacyOptions?.isBlocked);
    }
  }, [me]);

  return (
    <SettingsLayout>
      {profileLoading || isPrivacyOptionsLoading ? (
        <Progress />
      ) : (
        <PrivacyAndSecurityContainer>
          <FlexibleDiv className="privacy__main__wrap">
            <FlexibleDiv
              className="body__textwrap"
              flexDir="column"
              id={showDropdown === "Third Party" ? "nohover__slab" : ""}
            >
              <FlexibleDiv justifyContent="space-between" flexWrap="nowrap">
                <FlexibleDiv className="body__textwrap__leftsection">
                  <h4 className="body__headertext">Manage 3rd party access </h4>
                  <p className="body__subheadertext">
                    Control and monitor third party access to your account
                  </p>
                </FlexibleDiv>
                <div
                  className="arrow__wrap"
                  onClick={() => handleDropdown("Third Party")}
                >
                  <ArrowDown />
                </div>
              </FlexibleDiv>

              {showDropdown === "Third Party" && (
                <FlexibleDiv flexDir="column" className="dropdown__itemswrap">
                  <FlexibleDiv
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    className="dropdown__itemssub"
                  >
                    <FlexibleDiv className="dropdown__leftsection">
                      <h5 className="dropdown__headertext">
                        Block incoming requests{" "}
                      </h5>
                      <p className="dropdown__subheadertext">
                        Block all incoming requests to access your data from 3rd
                        party{" "}
                      </p>
                    </FlexibleDiv>
                    <div>
                      <Switch
                        checked={blockChecked}
                        onChange={handleBlockRequests}
                      />
                    </div>
                  </FlexibleDiv>
                  {blockChecked === false && (
                    <FlexibleDiv
                      justifyContent="space-between"
                      flexWrap="nowrap"
                      className="dropdown__itemssub"
                    >
                      <FlexibleDiv className="dropdown__leftsection">
                        <h4 className="dropdown__headertext">
                          Manage access to my account{" "}
                        </h4>
                        <p className="dropdown__subheadertext">
                          Control what data 3rd party can access
                        </p>
                      </FlexibleDiv>
                      <div
                        className="arrow__wrap"
                        onClick={() => setShowManageAccessModal(true)}
                      >
                        <ArrowRight />
                      </div>
                    </FlexibleDiv>
                  )}

                  <FlexibleDiv
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    className="dropdown__itemssub"
                  >
                    <FlexibleDiv className="dropdown__leftsection">
                      <h4 className="dropdown__headertext">
                        Review 3rd party activities{" "}
                      </h4>
                      <p className="dropdown__subheadertext">
                        Review and manage 3rd party activities on your OneID
                        account
                      </p>
                    </FlexibleDiv>
                    <div
                      onClick={() => navigate("/settings/review-access")}
                      className="arrow__wrap"
                    >
                      <ArrowRight />
                    </div>
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </FlexibleDiv>

            <FlexibleDiv className="body__textwrap">
              <FlexibleDiv className="body__textwrap__leftsection">
                <h4 className="body__headertext">Report A User OneID Code</h4>
                <p className="body__subheadertext">
                  Report any user OneID Code here
                </p>
              </FlexibleDiv>
              <div
                className="arrow__wrap"
                onClick={() => navigate("/settings/report-user")}
              >
                <ArrowRight />
              </div>
            </FlexibleDiv>

            <FlexibleDiv
              className="body__textwrap"
              flexDir="column"
              id={showDropdown === "Multi Factor" ? "nohover__slab" : ""}
            >
              <FlexibleDiv justifyContent="space-between" flexWrap="nowrap">
                <FlexibleDiv className="body__textwrap__leftsection">
                  <h4
                    className="body__headertext new"
                    onClick={() => navigate("/settings/multi-fa")}
                  >
                    Enable Multi-Factor Authentication
                  </h4>
                  <p className="body__subheadertext">
                    Protect your OneID Account with Multi-Factor
                    Authentication.. Each time you sign in, you will need your
                    password & an authentication code.
                  </p>
                </FlexibleDiv>
                <div
                  className="arrow__wrap"
                  onClick={() => handleDropdown("Multi Factor")}
                >
                  <ArrowDown />
                </div>
              </FlexibleDiv>

              {showDropdown === "Multi Factor" && (
                <FlexibleDiv flexDir="column" className="dropdown__itemswrap">
                  <FlexibleDiv
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    className="dropdown__itemssub"
                  >
                    <FlexibleDiv className="dropdown__leftsection">
                      <h5 className="dropdown__headertext">
                        Email Authentication
                      </h5>
                      <p className="dropdown__subheadertext">
                        Use your email address to receive authentication codes
                      </p>
                    </FlexibleDiv>
                    <div onClick={() => setShowEmailAuth(true)}>
                      <Switch />
                    </div>
                  </FlexibleDiv>

                  <FlexibleDiv
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    className="dropdown__itemssub"
                  >
                    <FlexibleDiv className="dropdown__leftsection">
                      <h4 className="dropdown__headertext">
                        Mobile Phone Authentication
                      </h4>
                      <p className="dropdown__subheadertext">
                        Use your mobile phone to receive authentication codes{" "}
                      </p>
                    </FlexibleDiv>
                    <div onClick={() => setShowPhoneAuth(true)}>
                      <Switch />
                    </div>
                  </FlexibleDiv>

                  <FlexibleDiv
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    className="dropdown__itemssub"
                  >
                    <FlexibleDiv className="dropdown__leftsection">
                      <h4 className="dropdown__headertext">
                        Two-Factor Authentication{" "}
                      </h4>
                      <p className="dropdown__subheadertext">
                        Use the Google Authentication App to generate one time
                        authentication codes
                      </p>
                    </FlexibleDiv>
                    <div onClick={() => setShow2FAModal(true)}>
                      <Switch />
                    </div>
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </FlexibleDiv>

            <FlexibleDiv className="body__textwrap">
              <FlexibleDiv className="body__textwrap__leftsection">
                <h4 className="body__headertext new">Enable KYC PIN</h4>
                <p className="body__subheadertext">KYC PIN enabled</p>
              </FlexibleDiv>
              <div onClick={() => setShowKycPinModal(true)}>
                <Switch />
              </div>
            </FlexibleDiv>
          </FlexibleDiv>
        </PrivacyAndSecurityContainer>
      )}

      <SuccessModal
        open={showKycSuccessModal}
        close={setShowKycSuccessModal}
        text="Your KYC Pin Has Been Enabled Successfully"
      />

      <KycPinModal
        showSuccessModal={setShowKycSuccessModal}
        open={showKycPinModal}
        close={setShowKycPinModal}
      />

      <SuccessModal
        open={showSuccessModal}
        close={setShowSuccessModal}
        text={`Your ${successText} Authentication Has Been Enabled Successfully`}
      />

      <EmailAuthenticationModal
        open={showEmailAuth}
        close={setShowEmailAuth}
        setShowPinModal={setShowPinModal}
        setPinMessage={setPinMessage}
      />
      <PhoneAuthenticationModal
        open={showPhoneAuth}
        close={setShowPhoneAuth}
        setShowPinModal={setShowPinModal}
        setPinMessage={setPinMessage}
      />
      <ScanCodeModal
        open={showScanModal}
        close={setShowScanModal}
        setShowPinModal={setShowPinModal}
        setPinMessage={setPinMessage}
        setShowSetupModal={setShowSetupModal}
      />
      <SetupModal
        open={showSetupModal}
        close={setShowSetupModal}
        setShowPinModal={setShowPinModal}
        setPinMessage={setPinMessage}
        setShowScanModal={setShowScanModal}
      />
      <PinModal
        open={showPinModal}
        close={setShowPinModal}
        setShowSuccessModal={setShowSuccessModal}
        setSuccessText={setSuccessText}
        pinMessage={pinMessage}
      />
      <TwoFaModal
        open={show2FAModal}
        close={setShow2FAModal}
        setShowInstallModal={setShowInstallModal}
      />
      <InstallModal
        open={showInstallModal}
        close={setShowInstallModal}
        setShowScanModal={setShowScanModal}
      />
      <ManageAccessModal
        open={showManageAccessModal}
        close={setShowManageAccessModal}
      />
    </SettingsLayout>
  );
};

export default PrivacyAndSecurity;
