import {
  DashboardLayout,
  GoBack,
  NoData,
  OIDButton,
  Progress,
} from "components";
import { FlexibleDiv } from "components/lib/Box/box.styles";
import { format } from "date-fns";
import { useMe } from "helpers";
import RequestActionModal from "pages/DashboardOverview/Modals/RequestActionModal/requestAccessAction";
import React from "react";
import { capitalize } from "utils";
import { useUserGetProfileRequestsQuery } from "./gql/__types/accessRequest.types";
import { ViewAccessRequestsLayoutContainer } from "./viewAccessRequests.styles";

export interface IRAPayload {
  show: boolean;
  id: string | string[] | null;
  requestStatus: string | string[];
}

const ViewAccessRequestsPage = () => {
  const me = useMe();
  const [requestActionPayload, setRequestActionPayload] =
    React.useState<IRAPayload>({
      show: false,
      id: "",
      requestStatus: "",
    });
  const { data: requestData, loading: requestListLoading } =
    useUserGetProfileRequestsQuery();

  const handleRequestActionModal = (id: string, status: string) => {
    setRequestActionPayload({
      show: !requestActionPayload.show,
      id: id,
      requestStatus: status,
    });
  };

  return (
    <DashboardLayout headerText="Incoming Access Requests">
      {requestListLoading ? (
        <Progress />
      ) : (
        <>
          {requestData?.userGetProfileRequests?.length ? (
            <ViewAccessRequestsLayoutContainer>
              <GoBack />

              <FlexibleDiv className="profilerequest__listwrap">
                {requestData?.userGetProfileRequests?.map((request) => (
                  <FlexibleDiv
                    className="veiwrequests__accessrequest__card"
                    flexDir="column"
                    alignItems="flex-end"
                  >
                    <div className="accessrequest__overlay"></div>
                    {request?.createdAt && (
                      <small className="accessrequest__time">
                        {format(
                          new Date(request?.createdAt),
                          "hh:mm a, dd-MMMM-yy"
                        )}
                      </small>
                    )}
                    <FlexibleDiv
                      justifyContent="flex-start"
                      flexWrap="nowrap"
                      margin="10px 0 0 0"
                      className="accessrequest__header"
                    >
                      <h4 className="accessrequest__headertext">
                        Access Request from{" "}
                        {request?.thirdParty?.username ||
                          request?.thirdParty?.businessName}
                      </h4>
                    </FlexibleDiv>
                    <p className="accessrequest__bodytext">
                      To grant access, click accept request. and to decline,
                      click decline access."
                    </p>
                    <FlexibleDiv
                      justifyContent="flex-start"
                      margin="20px 0 0 0"
                      className="request-action__wrap"
                    >
                      <OIDButton
                        onClick={() =>
                          handleRequestActionModal(request?._id, "accept")
                        }
                      >
                        Accept Request
                      </OIDButton>
                      <span
                        className="decline__request"
                        onClick={() =>
                          handleRequestActionModal(request?._id, "decline")
                        }
                      >
                        Decline Request
                      </span>
                    </FlexibleDiv>
                  </FlexibleDiv>
                ))}
              </FlexibleDiv>
            </ViewAccessRequestsLayoutContainer>
          ) : (
            <NoData
              noDataTitle="No Incoming Requets"
              noDataMessage={`Hi ${capitalize(
                me?.username!
              )}, You currently do not have any access requests`}
              noDataButtonRoute="/"
              noDataButtonText="Back to Dashboard"
            />
          )}
        </>
      )}

      <RequestActionModal
        requestActionPayload={requestActionPayload}
        setRequestActionPayload={setRequestActionPayload}
        fromPage="/view-requests"
      />
    </DashboardLayout>
  );
};

export default ViewAccessRequestsPage;
