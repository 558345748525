import React from "react";
import { TextAreaContainer } from "./textarea.styles";
import { FormControlContainer } from "../FormControl/formControl.styles";
import { FlexibleDiv } from "../../Box/box.styles";
interface IProps {
  data: any;
  disabled?: boolean;
}

const TextArea: React.FC<IProps> = ({ data, disabled }) => {
  return (
    <FormControlContainer>
      {data.headerText && (
        <FlexibleDiv justifyContent="flex-start" margin="0 0 10px 0">
          <span className="textarea__label">{data.headerText}</span>
        </FlexibleDiv>
      )}

      <TextAreaContainer disabled={disabled} placeholder={data.label} />
    </FormControlContainer>
  );
};

export default TextArea;
