import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserGetProfileRequestsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserGetProfileRequestsQuery = { __typename?: 'Query', userGetProfileRequests?: Array<any | null> | null };

export type UserGetApprovedProfileRequestQueryVariables = Types.Exact<{
  requestId: Types.Scalars['ID'];
}>;


export type UserGetApprovedProfileRequestQuery = { __typename?: 'Query', getProfileRequestById?: any | null };

export type UserApproveProfileRequestMutationVariables = Types.Exact<{
  requestId: Types.Scalars['ID'];
}>;


export type UserApproveProfileRequestMutation = { __typename?: 'Mutation', approveProfileRequest?: string | null };

export type UserRejecteProfileRequestMutationVariables = Types.Exact<{
  requestId: Types.Scalars['ID'];
}>;


export type UserRejecteProfileRequestMutation = { __typename?: 'Mutation', rejectProfileRequest?: string | null };

export type UserGetThirdPartyRequestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserGetThirdPartyRequestQuery = { __typename?: 'Query', thirdPartyGetProfileRequests?: Array<any | null> | null };

export type UserGetThirdPartyPendingRequestQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserGetThirdPartyPendingRequestQuery = { __typename?: 'Query', thirdPartyGetPendingProfileRequests?: Array<any | null> | null };

export type UserGetProfileAcessListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserGetProfileAcessListQuery = { __typename?: 'Query', userGetAccesssList?: any | null };

export type UserRevokeAccesssMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
}>;


export type UserRevokeAccesssMutation = { __typename?: 'Mutation', userRevokeAccesss?: string | null };


export const UserGetProfileRequestsDocument = gql`
    query userGetProfileRequests {
  userGetProfileRequests
}
    `;

/**
 * __useUserGetProfileRequestsQuery__
 *
 * To run a query within a React component, call `useUserGetProfileRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetProfileRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetProfileRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGetProfileRequestsQuery(baseOptions?: Apollo.QueryHookOptions<UserGetProfileRequestsQuery, UserGetProfileRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetProfileRequestsQuery, UserGetProfileRequestsQueryVariables>(UserGetProfileRequestsDocument, options);
      }
export function useUserGetProfileRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetProfileRequestsQuery, UserGetProfileRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetProfileRequestsQuery, UserGetProfileRequestsQueryVariables>(UserGetProfileRequestsDocument, options);
        }
export type UserGetProfileRequestsQueryHookResult = ReturnType<typeof useUserGetProfileRequestsQuery>;
export type UserGetProfileRequestsLazyQueryHookResult = ReturnType<typeof useUserGetProfileRequestsLazyQuery>;
export type UserGetProfileRequestsQueryResult = Apollo.QueryResult<UserGetProfileRequestsQuery, UserGetProfileRequestsQueryVariables>;
export const UserGetApprovedProfileRequestDocument = gql`
    query userGetApprovedProfileRequest($requestId: ID!) {
  getProfileRequestById(requestId: $requestId)
}
    `;

/**
 * __useUserGetApprovedProfileRequestQuery__
 *
 * To run a query within a React component, call `useUserGetApprovedProfileRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetApprovedProfileRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetApprovedProfileRequestQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useUserGetApprovedProfileRequestQuery(baseOptions: Apollo.QueryHookOptions<UserGetApprovedProfileRequestQuery, UserGetApprovedProfileRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetApprovedProfileRequestQuery, UserGetApprovedProfileRequestQueryVariables>(UserGetApprovedProfileRequestDocument, options);
      }
export function useUserGetApprovedProfileRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetApprovedProfileRequestQuery, UserGetApprovedProfileRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetApprovedProfileRequestQuery, UserGetApprovedProfileRequestQueryVariables>(UserGetApprovedProfileRequestDocument, options);
        }
export type UserGetApprovedProfileRequestQueryHookResult = ReturnType<typeof useUserGetApprovedProfileRequestQuery>;
export type UserGetApprovedProfileRequestLazyQueryHookResult = ReturnType<typeof useUserGetApprovedProfileRequestLazyQuery>;
export type UserGetApprovedProfileRequestQueryResult = Apollo.QueryResult<UserGetApprovedProfileRequestQuery, UserGetApprovedProfileRequestQueryVariables>;
export const UserApproveProfileRequestDocument = gql`
    mutation userApproveProfileRequest($requestId: ID!) {
  approveProfileRequest(requestId: $requestId)
}
    `;
export type UserApproveProfileRequestMutationFn = Apollo.MutationFunction<UserApproveProfileRequestMutation, UserApproveProfileRequestMutationVariables>;

/**
 * __useUserApproveProfileRequestMutation__
 *
 * To run a mutation, you first call `useUserApproveProfileRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserApproveProfileRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userApproveProfileRequestMutation, { data, loading, error }] = useUserApproveProfileRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useUserApproveProfileRequestMutation(baseOptions?: Apollo.MutationHookOptions<UserApproveProfileRequestMutation, UserApproveProfileRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserApproveProfileRequestMutation, UserApproveProfileRequestMutationVariables>(UserApproveProfileRequestDocument, options);
      }
export type UserApproveProfileRequestMutationHookResult = ReturnType<typeof useUserApproveProfileRequestMutation>;
export type UserApproveProfileRequestMutationResult = Apollo.MutationResult<UserApproveProfileRequestMutation>;
export type UserApproveProfileRequestMutationOptions = Apollo.BaseMutationOptions<UserApproveProfileRequestMutation, UserApproveProfileRequestMutationVariables>;
export const UserRejecteProfileRequestDocument = gql`
    mutation userRejecteProfileRequest($requestId: ID!) {
  rejectProfileRequest(requestId: $requestId)
}
    `;
export type UserRejecteProfileRequestMutationFn = Apollo.MutationFunction<UserRejecteProfileRequestMutation, UserRejecteProfileRequestMutationVariables>;

/**
 * __useUserRejecteProfileRequestMutation__
 *
 * To run a mutation, you first call `useUserRejecteProfileRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRejecteProfileRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRejecteProfileRequestMutation, { data, loading, error }] = useUserRejecteProfileRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useUserRejecteProfileRequestMutation(baseOptions?: Apollo.MutationHookOptions<UserRejecteProfileRequestMutation, UserRejecteProfileRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRejecteProfileRequestMutation, UserRejecteProfileRequestMutationVariables>(UserRejecteProfileRequestDocument, options);
      }
export type UserRejecteProfileRequestMutationHookResult = ReturnType<typeof useUserRejecteProfileRequestMutation>;
export type UserRejecteProfileRequestMutationResult = Apollo.MutationResult<UserRejecteProfileRequestMutation>;
export type UserRejecteProfileRequestMutationOptions = Apollo.BaseMutationOptions<UserRejecteProfileRequestMutation, UserRejecteProfileRequestMutationVariables>;
export const UserGetThirdPartyRequestDocument = gql`
    query userGetThirdPartyRequest {
  thirdPartyGetProfileRequests
}
    `;

/**
 * __useUserGetThirdPartyRequestQuery__
 *
 * To run a query within a React component, call `useUserGetThirdPartyRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetThirdPartyRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetThirdPartyRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGetThirdPartyRequestQuery(baseOptions?: Apollo.QueryHookOptions<UserGetThirdPartyRequestQuery, UserGetThirdPartyRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetThirdPartyRequestQuery, UserGetThirdPartyRequestQueryVariables>(UserGetThirdPartyRequestDocument, options);
      }
export function useUserGetThirdPartyRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetThirdPartyRequestQuery, UserGetThirdPartyRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetThirdPartyRequestQuery, UserGetThirdPartyRequestQueryVariables>(UserGetThirdPartyRequestDocument, options);
        }
export type UserGetThirdPartyRequestQueryHookResult = ReturnType<typeof useUserGetThirdPartyRequestQuery>;
export type UserGetThirdPartyRequestLazyQueryHookResult = ReturnType<typeof useUserGetThirdPartyRequestLazyQuery>;
export type UserGetThirdPartyRequestQueryResult = Apollo.QueryResult<UserGetThirdPartyRequestQuery, UserGetThirdPartyRequestQueryVariables>;
export const UserGetThirdPartyPendingRequestDocument = gql`
    query userGetThirdPartyPendingRequest {
  thirdPartyGetPendingProfileRequests
}
    `;

/**
 * __useUserGetThirdPartyPendingRequestQuery__
 *
 * To run a query within a React component, call `useUserGetThirdPartyPendingRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetThirdPartyPendingRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetThirdPartyPendingRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGetThirdPartyPendingRequestQuery(baseOptions?: Apollo.QueryHookOptions<UserGetThirdPartyPendingRequestQuery, UserGetThirdPartyPendingRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetThirdPartyPendingRequestQuery, UserGetThirdPartyPendingRequestQueryVariables>(UserGetThirdPartyPendingRequestDocument, options);
      }
export function useUserGetThirdPartyPendingRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetThirdPartyPendingRequestQuery, UserGetThirdPartyPendingRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetThirdPartyPendingRequestQuery, UserGetThirdPartyPendingRequestQueryVariables>(UserGetThirdPartyPendingRequestDocument, options);
        }
export type UserGetThirdPartyPendingRequestQueryHookResult = ReturnType<typeof useUserGetThirdPartyPendingRequestQuery>;
export type UserGetThirdPartyPendingRequestLazyQueryHookResult = ReturnType<typeof useUserGetThirdPartyPendingRequestLazyQuery>;
export type UserGetThirdPartyPendingRequestQueryResult = Apollo.QueryResult<UserGetThirdPartyPendingRequestQuery, UserGetThirdPartyPendingRequestQueryVariables>;
export const UserGetProfileAcessListDocument = gql`
    query userGetProfileAcessList {
  userGetAccesssList
}
    `;

/**
 * __useUserGetProfileAcessListQuery__
 *
 * To run a query within a React component, call `useUserGetProfileAcessListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetProfileAcessListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetProfileAcessListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGetProfileAcessListQuery(baseOptions?: Apollo.QueryHookOptions<UserGetProfileAcessListQuery, UserGetProfileAcessListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetProfileAcessListQuery, UserGetProfileAcessListQueryVariables>(UserGetProfileAcessListDocument, options);
      }
export function useUserGetProfileAcessListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetProfileAcessListQuery, UserGetProfileAcessListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetProfileAcessListQuery, UserGetProfileAcessListQueryVariables>(UserGetProfileAcessListDocument, options);
        }
export type UserGetProfileAcessListQueryHookResult = ReturnType<typeof useUserGetProfileAcessListQuery>;
export type UserGetProfileAcessListLazyQueryHookResult = ReturnType<typeof useUserGetProfileAcessListLazyQuery>;
export type UserGetProfileAcessListQueryResult = Apollo.QueryResult<UserGetProfileAcessListQuery, UserGetProfileAcessListQueryVariables>;
export const UserRevokeAccesssDocument = gql`
    mutation userRevokeAccesss($userId: ID!) {
  userRevokeAccesss(userId: $userId)
}
    `;
export type UserRevokeAccesssMutationFn = Apollo.MutationFunction<UserRevokeAccesssMutation, UserRevokeAccesssMutationVariables>;

/**
 * __useUserRevokeAccesssMutation__
 *
 * To run a mutation, you first call `useUserRevokeAccesssMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRevokeAccesssMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRevokeAccesssMutation, { data, loading, error }] = useUserRevokeAccesssMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserRevokeAccesssMutation(baseOptions?: Apollo.MutationHookOptions<UserRevokeAccesssMutation, UserRevokeAccesssMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRevokeAccesssMutation, UserRevokeAccesssMutationVariables>(UserRevokeAccesssDocument, options);
      }
export type UserRevokeAccesssMutationHookResult = ReturnType<typeof useUserRevokeAccesssMutation>;
export type UserRevokeAccesssMutationResult = Apollo.MutationResult<UserRevokeAccesssMutation>;
export type UserRevokeAccesssMutationOptions = Apollo.BaseMutationOptions<UserRevokeAccesssMutation, UserRevokeAccesssMutationVariables>;