import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PrivacyOptionsFragmentFragmentDoc } from './PrivacyOptionsFragment.types';
export type UserFragmentFragment = { __typename?: 'User', _id?: string | null, email?: string | null, oneId?: string | null, username?: string | null, createdAt?: any | null, updatedAt?: any | null, isVerified?: boolean | null, role?: string | null, hasEnabledMFA?: boolean | null, hasEnabled2FA?: boolean | null, mfaType?: Types.MfaType | null, privacyOptions?: { __typename?: 'PrivacyOptions', isBlocked?: boolean | null, kyc_bvn?: boolean | null, kyc_nin?: boolean | null, personal?: boolean | null, billing?: boolean | null, nextOfKin?: boolean | null, emergency?: boolean | null, nationality?: boolean | null, travel?: boolean | null, medical?: boolean | null, education?: boolean | null, workExperience?: boolean | null, phoneVerification?: boolean | null } | null };

export const UserFragmentFragmentDoc = gql`
    fragment userFragment on User {
  _id
  email
  oneId
  username
  createdAt
  updatedAt
  isVerified
  role
  hasEnabledMFA
  hasEnabled2FA
  mfaType
  privacyOptions {
    ...privacyOptionsFragment
  }
}
    ${PrivacyOptionsFragmentFragmentDoc}`;