import { format, formatDistanceToNow } from "date-fns"

export const capitalize = (str = "") =>
  str
    ? str
        .toLowerCase()
        .replace(/\b[a-z](?=[a-z]{1})/g, letter => letter.toUpperCase())
    : str

export const cleanPath = (str: string) => {
  const cleanedString = str.split("/").slice(-1).join().replace(/-/g, " ")

  return capitalize(cleanedString)
}

export const truncate = (str = "", length = 20, ending = "...") =>
  str.length > length
    ? `${str.substring(0, length - ending.length)} ${ending}`
    : str

interface IGetInitialProps {
  fName?: string | null
  lName?: string | null
  username?: string
}
export const getInitials = ({
  fName = "",
  lName = "",
  username = "",
}: IGetInitialProps) => {
  if (!fName && !lName && !username) return "NO"

  if (!fName && !lName && username)
    return `${username.charAt(0).toUpperCase()}${username
      .charAt(1)
      .toUpperCase()}`

  if (!username && fName && lName)
    return `${fName.charAt(0).toUpperCase()}${lName.charAt(0).toUpperCase()}`

  return `${fName?.charAt(0).toUpperCase()}${lName?.charAt(0).toUpperCase()}`
}
export const getNameInitials = (fName = "", lName = "") =>
  `${fName.charAt(0).toUpperCase()}${lName.charAt(0).toUpperCase()}`

export const getUsernameInitials = (fName = "") =>
  `${fName.charAt(0).toUpperCase()}${fName.charAt(1).toUpperCase()}`

/**
 * formatDate
 * Returns a moment formatted date
 * @param {Date} date
 * @param options
 * */
export const formatDate = (
  date: Date | string | number,
  options = {
    format: "DD, MM YYYY",
    fromNow: false,
  }
) => {
  if (options["format"]) {
    return format(new Date(date), options.format)
  }

  if (options["fromNow"]) {
    return formatDistanceToNow(new Date(date), { addSuffix: true })
  }

  return formatDistanceToNow(new Date(date), { addSuffix: true })
}

// extra check to simplify search
export const checkName = (name: string, value: string) => {
  var pattern = value
    .split("")
    .map(x => {
      return `(?=.*${x})`
    })
    .join("")
  var regex = new RegExp(`${pattern}`, "g")
  return name.match(regex)
}

// decoded token
export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

export const logoutFromApp = () => {
  localStorage.removeItem("hasBuxProfile")
  localStorage.removeItem("1D_AU")
  localStorage.removeItem("1D_AUR")
  window.location.href = "/"
}

export const checkOnlineStatus = async () => {
  const networkCondition = navigator.onLine ? "online" : "offline"

  if (networkCondition === "online") {
    try {
      let online = await fetch("/1pixel.png")
      // online.status >= 200 && online.status < 300; // either true or false
      return online.ok
    } catch (err: any) {
      return false
    }
  } else {
    return false
  }
}

export const renderSVG = (svg: any) => {
  return svg
}

export const getPercentageValue = (value: number, total: number) => {
  let result = Math.floor((value / total) * 100)
  if (isNaN(result)) result = 0
  return result
}

export const sortByMostRecentDate = (data: any[]): any[] => {
  /**
   * sort all data using ***dateCreated*** property in the array of object
   * dateCreated - should be formated to Date from source data
   */

  const sortedData = data.sort(
    (objA, objB) => objB.dateCreated - objA.dateCreated
  )

  return sortedData
}

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ) // fragment locator
  return !!pattern.test(str)
}
