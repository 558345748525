import styled from "styled-components"

type Custom = {
  width?: string
  height?: string
  padding?: string
  margin?: string
  backgroundColor?: string
  border?: string
  borderColor?: string
  opacity?: string
  radius?: string
  boxShadow?: string
  fontSize?: string
  color?: string
  hoverColor?: string
  fontWeight?: string
  hoverBg?: string
  outlined?: boolean
  maxWidth?: string
}

export const OIDButton = styled.button<Custom>`
  width: ${({ width }) => width || "max-content"};
  margin: ${({ margin }) => margin || "0"};
  height: ${({ height }) => height || "50px"};
  padding: ${({ padding }) => padding || "0 30px"};
  max-width: ${({ maxWidth }) => maxWidth};
  border: ${({ border }) => border || "1.5px solid #0b61dd"};
  border-color: ${({ borderColor }) => borderColor || ""};
  background: ${({ backgroundColor, outlined }) =>
    outlined ? "transparent" : backgroundColor || "#0B61DD"};
  border-radius: ${({ radius }) => radius || "8px"};
  box-shadow: ${({ boxShadow }) => boxShadow || "none"};
  opacity: ${({ opacity }) => opacity || "1"};
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  color: ${({ color, outlined }) =>
    outlined ? "#0B61DD" : color || "#ffffff"};
  font-weight: ${({ fontWeight }) => fontWeight || "500"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 18px;
    margin: 0 5px -5px 0;
  }

  :focus,
  :active {
    background: ${({ backgroundColor }) => backgroundColor || "#0B61DD"};
    color: ${({ color }) => color || "#ffffff"};
  }
  :hover {
    background: ${({ hoverBg }) => hoverBg || "#09459c"};
    color: ${({ hoverColor }) => hoverColor || "#fff"};
  }

  :disabled {
    background: #ccc;
    cursor: wait;
    border-color: #ddd;
  }
`
