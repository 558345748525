import { ComingSoon, DashboardLayout } from "components"
// import { AppointmentsContainer } from "./allApointments.styles"
// import { FlexibleDiv } from "../../../components/lib/Box/box.styles"
// import NoData from "../../../components/lib/NoData/nodata"
// import Tabs from "../../../components/lib/Tabs/tabs"
// import TextField from "../../../components/lib/Form/TextField/textfield"
// import { AiFillCaretDown as DateArrow } from "react-icons/ai"

const Appointments = () => {
  // const [activeTab, setActiveTab] = useState("All")
  // const navigate = useNavigate()
  // const appointments = [
  //   {
  //     date: "Monday 3, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Interview with HR",
  //   },
  //   {
  //     date: "Monday 3, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Sales Interactive Session",
  //   },
  //   {
  //     date: "Monday 2, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Interview with HR",
  //   },
  //   {
  //     date: "Monday 2, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Sales Interactive Session",
  //   },
  // ]

  // const upcoming = [
  //   {
  //     date: "Monday 3, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Interview with HR",
  //   },
  //   {
  //     date: "Monday 3, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Sales Interactive Session",
  //   },
  // ]

  // const cancelled = [
  //   {
  //     date: "Monday 3, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Interview with HR",
  //   },
  //   {
  //     date: "Monday 3, July 2022",
  //     from: "09:00",
  //     to: "11: 00",
  //     activity: "Sales Interactive Session",
  //   },
  // ]
  // const tablist = ["Upcoming", "All", "Cancelled"]

  // const textFieldData = {
  //   label: "Search appointments here...",
  //   type: "text",
  //   search: true,
  // }

  return (
    <DashboardLayout headerText="Appointments">
      <ComingSoon comingPage="Appointments" />
      {/* {!!appointments?.length ? (
        <AppointmentsContainer alignItems="flex-start">
          <FlexibleDiv
            className="apointments__main__wrap"
            flexDir="column"
            justifyContent="flext-start"
            alignItems="flex-start"
          >
            <p className="appointments__welcometext">
              Hi Adeola, you can view and manage your appointments here.
            </p>
            <FlexibleDiv
              maxWidth="450px"
              margin="30px 0"
              className="search__wrap"
            >
              <TextField data={textFieldData} />
            </FlexibleDiv>

            <FlexibleDiv className="table__wrap" flexDir="column">
              <FlexibleDiv
                justifyContent="space-between"
                className="tabs__wrap"
              >
                <FlexibleDiv width="70%" justifyContent="flex-start">
                  <Tabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tablist={tablist}
                    noBorder
                  />
                </FlexibleDiv>
                <p className="sort">
                  Sort by: <b>Date</b>
                  <DateArrow />
                </p>
              </FlexibleDiv>

              {activeTab === "All" &&
                appointments.map((item, idx) => (
                  <>
                    {appointments[idx]?.date !==
                      appointments[idx - 1]?.date && (
                      <FlexibleDiv
                        justifyContent="flex-start"
                        className="appointment__date"
                      >
                        <p className="appointment__date__text">{item.date}</p>
                      </FlexibleDiv>
                    )}
                    <FlexibleDiv
                      className="apppointment__tab"
                      justifyContent="space-between"
                    >
                      <h4 className="appointmentcard__headertext">
                        {item.from} <small>AM</small> - {item.to}{" "}
                        <small>PM</small>
                      </h4>
                      <p className="appointmentcard__activity__text">
                        {item.activity}
                      </p>
                      <span
                        className="appointmentcard__viewdetails__text"
                        onClick={() =>
                          navigate(`/appointment/${item.activity}`)
                        }
                      >
                        View Details
                      </span>
                    </FlexibleDiv>
                  </>
                ))}

              {activeTab === "Upcoming" &&
                upcoming.map((item, idx) => (
                  <>
                    {upcoming[idx]?.date !== upcoming[idx - 1]?.date && (
                      <FlexibleDiv
                        justifyContent="flex-start"
                        className="appointment__date"
                      >
                        <p>{item.date}</p>
                      </FlexibleDiv>
                    )}
                    <FlexibleDiv
                      className="apppointment__tab"
                      justifyContent="space-between"
                    >
                      <h4 className="appointmentcard__headertext">
                        {item.from} <small>AM</small> - {item.to}{" "}
                        <small>PM</small>
                      </h4>
                      <p className="appointmentcard__activity__text">
                        {item.activity}
                      </p>
                      <span
                        className="appointmentcard__viewdetails__text"
                        onClick={() =>
                          navigate(`/appointment/${item.activity}`)
                        }
                      >
                        View Details
                      </span>
                    </FlexibleDiv>
                  </>
                ))}

              {activeTab === "Cancelled" &&
                cancelled.map((item, idx) => (
                  <>
                    {cancelled[idx]?.date !== cancelled[idx - 1]?.date && (
                      <FlexibleDiv
                        justifyContent="flex-start"
                        className="appointment__date"
                      >
                        <p>{item.date}</p>
                      </FlexibleDiv>
                    )}
                    <FlexibleDiv
                      className="apppointment__tab"
                      justifyContent="space-between"
                    >
                      <h4 className="appointmentcard__headertext">
                        {item.from} <small>AM</small> - {item.to}{" "}
                        <small>PM</small>
                      </h4>
                      <p className="appointmentcard__activity__text">
                        {item.activity}
                      </p>
                      <span
                        className="appointmentcard__viewdetails__text"
                        onClick={() =>
                          navigate(`/appointment/${item.activity}`)
                        }
                      >
                        View Details
                      </span>
                    </FlexibleDiv>
                  </>
                ))}
            </FlexibleDiv>
          </FlexibleDiv>
        </AppointmentsContainer>
      ) : (
        <NoData
          noDataTitle="No Education History"
          noDataMessage="Hi May, please click the button above to update your education history"
          noDataButtonRoute="/add-education"
          noDataButtonText="Add Education"
        />
      )} */}
    </DashboardLayout>
  )
}

export default Appointments
