import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type EmergencyContactFragmentFragment = { __typename?: 'EmergencyContact', name?: string | null, relationship?: string | null, email?: string | null, address?: string | null, phone?: string | null };

export const EmergencyContactFragmentFragmentDoc = gql`
    fragment emergencyContactFragment on EmergencyContact {
  name
  relationship
  email
  address
  phone
}
    `;