import { MenuItem, SelectProps } from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import React from "react"
import { FlexibleDiv } from "../../Box/box.styles"
import { Country, FormDetails } from "../CustomForm"
import { FormControlContainer } from "../FormControl/formControl.styles"
import { SelectContainer } from "./select.styles"

interface IProps {
  data: FormDetails
  getValue?: (value: string) => void
  selectProps: SelectProps
}
export const Select: React.FC<IProps> = ({ data, selectProps, getValue }) => {
  return (
    <React.Fragment>
      {selectProps.disabled && (
        <FlexibleDiv justifyContent="flex-start" margin="0 0 10px 0">
          <span className="disabled__label">{data.label}</span>
        </FlexibleDiv>
      )}
      <FormControlContainer>
        <SelectContainer
          {...selectProps}
          displayEmpty
          renderValue={selected => {
            if (selectProps.multiple && Array.isArray(selected)) {
              if (selected.length === 0) {
                return <em>{selectProps.placeholder}</em>
              }

              return selected.join(", ")
            } else if (typeof selected === "string" && selected !== "") {
              return selected
            } else return <em>{selectProps.placeholder}</em>
          }}
        >
          {data.dataSet?.map((item, idx: number) => {
            return typeof item === "string" ? (
              <MenuItem value={item} key={idx}>
                {item}
              </MenuItem>
            ) : (
              <MenuItem
                value={item.name}
                key={idx}
                onClick={getValue ? e => getValue(item.name) : undefined}
              >
                {item.name}
              </MenuItem>
            )
          })}
        </SelectContainer>
      </FormControlContainer>
    </React.Fragment>
  )
}

interface Props {
  selectItems: string[] | Country[]
  selectProps: SelectProps
}
export const SelectItem: React.FC<Props> = ({ selectProps, selectItems }) => {
  return (
    <React.Fragment>
      {selectProps.disabled && (
        <FlexibleDiv justifyContent="flex-start" margin="0 0 10px 0">
          <span className="disabled__label">{selectProps.label}</span>
        </FlexibleDiv>
      )}
      <FormControlContainer>
        <InputLabel id="select-label">{selectProps.label}</InputLabel>
        <SelectContainer {...selectProps}>
          {selectItems.map((item, idx: number) => {
            return typeof item === "string" ? (
              <MenuItem value={item} key={idx}>
                {item}
              </MenuItem>
            ) : (
              <MenuItem value={item.code} key={idx}>
                {item.name}
              </MenuItem>
            )
          })}
        </SelectContainer>
      </FormControlContainer>
    </React.Fragment>
  )
}
