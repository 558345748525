import { zonedTimeToUtc } from "date-fns-tz";

export const DEFAULT_DATE_FORMAT = "yyyy/MM/dd";
export const DEFAULT_TIME_ZONE = "UTC";

/**
 * Format a date to a string
 *
 * @param date
 */
export const formatDateTimeTZ = (date: string) => {
  if (!date) return new Date().toLocaleString();

  return zonedTimeToUtc(date, DEFAULT_TIME_ZONE);
};
