import styled from "styled-components";

type Custom = {
  accessRequestDisabled?: boolean;
};

export const DashboardOverviewContainer = styled.div<Custom>`
  width: 100%;
  padding: 40px;

  .cropImage__wrap {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999999999999;
    background: #000000e8;
    top: 0;
    left: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    .ReactCrop {
      height: 80%;
      max-width: 1000px;
    }
  }

  .overview__leftsection {
    .overview__accessrequest__card {
      background: #feffff;
      border: 1px solid rgba(0, 73, 155, 0.4);
      box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
      border-radius: 20px;
      transition: all 0.3s ease;
      position: relative;
      padding: 20px 30px;
      overflow: hidden;
      :hover {
        background-color: #f2f5f8;
      }

      .accessrequest__header {
        .accessrequest__headertext {
          margin: 0 30px 0 0;
          color: #0c0c0c;
        }
        .accessrequest__headerlink {
          text-decoration: none;
          font-size: 13px;
          color: #0b61dd;
        }
      }

      .accessrequest__bodytext {
        font-size: 15px;
        line-height: 25px;
        width: 100%;
        color: #464343;
        span {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .request-action__wrap {
        .decline__request {
          margin-left: 20px;
          color: #cf0d19;
          font-size: 14px;
        }
      }
    }

    .chart__wrap {
      background-color: #ffffff;
      border-radius: 20px;
      margin-top: 40px;
      padding-bottom: 30px;
      overflow: hidden;
    }
  }

  .overview__rightsection {
    .profile__card {
      background: #0b61dd;
      border-radius: 20px;
      padding: 30px 5%;
      flex-wrap: nowrap;
      margin-bottom: 30px;
      overflow: hidden;

      .image__wrap {
        overflow: hidden;
        width: 135px;
        height: 135px;
        min-width: 135px;
        min-height: 135px;
        border-radius: 100%;
        position: relative;
        margin-right: 20px;

        cursor: pointer;
        background-color: #eeeeee;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        svg {
          font-size: 100px;
        }
        .profileImage__label {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #00000099;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          transform: translateY(300px);
          top: 0;
          cursor: pointer;

          svg {
            font-size: 40px;
            color: #fff;
          }
        }

        :hover {
          .profileImage__label {
            transform: translateX(0);
          }
        }

        @media (max-width: 1200px) and (min-width: 950px) {
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
        }
      }
      .verifyIcons {
        svg {
          margin-right: 5px;
        }
      }

      .user__name,
      .user__role {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        margin: 5px 0;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .user__role {
        text-transform: uppercase;
        font-size: 14px;
      }
    }
    .recent__activities {
      background: #ffffff;
      box-shadow: 0px 4px 32px rgba(55, 73, 87, 0.04);
      border-radius: 20px;
      padding: 30px;
      margin-bottom: 30px;
      .recent-activities__header {
        margin: 0;
        color: #0c0c0c;
      }
      .recent-activities__subheader {
        line-height: 22px;
        font-size: 14px;
        color: #0c0c0c;
      }
      .reject__activity {
        color: #0b61dd;
        font-size: 13px;
        transition: all 0.3s ease;
        font-weight: 500;
        cursor: pointer;
        :hover {
          color: #0d499f;
        }
      }
    }

    .upcoming__events {
      background: #ffffff;
      box-shadow: 0px 4px 32px rgba(55, 73, 87, 0.04);
      border-radius: 20px;
      padding: 30px;
      margin-bottom: 30px;
      .upcomingevents__headertext {
        margin: 0;
        color: #0c0c0c;
      }
      .upcomingevents__subheader {
        line-height: 22px;
        font-size: 14px;
        color: #374957cc;
        margin: 5px 0;
      }

      .event__wrap {
        margin: 15px 0 10px 0;

        .event__date {
          height: 62px;
          width: 70px;
          min-width: 70px;
          border-radius: 20px;
          background-color: #ffd4a9;
          margin-right: 15px;
          .eventdate__header {
            font-size: 18px;
            margin: 0;
          }
          .eventdate__subheader {
            margin: 0;
          }
        }

        .event__action {
          justify-content: flex-start;
          .event__action__text {
            font-size: 16px;
          }
        }
      }
      #event1 {
        .event__date {
          background: #abfff7;
        }
      }
      #event2 {
        .event__date {
          background: #ffd5d3;
        }
      }
      #event3 {
        .event__date {
          background: #eeeeee;
        }
      }
    }
    @media (max-width: 1100px) and (min-width: 950px) {
      .profile__card {
        .user__name {
          font-size: 14px;
        }
        .user__role {
          font-size: 11px;
        }
      }
    }

    @media (min-width: 950px) {
      min-width: 250px;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 40px 15px;
  }

  @media screen and (max-width: 950px) {
    .overview__subwrap {
      flex-direction: column-reverse;
    }
    .overview__leftsection,
    .overview__rightsection {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    .overview__subwrap {
      flex-direction: column-reverse;
    }
    .overview__leftsection {
      .overview__accessrequest__card {
        align-items: center;
        padding: 20px 15px 80px 15px;

        .accessrequest__time {
          width: 100%;
          text-align: end;
        }
        .accessrequest__header {
          justify-content: center;
          .accessrequest__headerlink {
            position: absolute;
            bottom: 30px;
          }
        }
        .accessrequest__bodytext {
          text-align: center;
        }

        .request-action__wrap {
          justify-content: space-between;
          padding: 0 10%;
          flex-wrap: nowrap;
          button {
            font-size: 12px;
            padding: 0 20px;
            height: 42px;
            white-space: nowrap;
          }
          .decline__request {
            font-size: 12px;
            white-space: nowrap;
          }
        }
      }
    }

    .overview__rightsection {
      .profile__card {
        .image__wrap {
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
        }
        .user__name {
          font-size: 14px;
        }
        .user__role {
          font-size: 12px;
        }
      }

      .upcoming__events {
        .event__wrap {
          .event__action {
            .event__action__text {
              font-size: 14px;
            }
          }
        }
      }

      & > div {
        padding: 30px 15px !important;
      }
    }
  }

  @media screen and (max-width: 350px) {
    .overview__leftsection {
      .overview__accessrequest__card {
        .request-action__wrap {
          padding: 0;
        }
      }
    }
  }
`;
