import { FlexibleDiv } from "components/lib/Box/box.styles";
import styled from "styled-components";

export const DatePickerContainer = styled(FlexibleDiv)({
  width: "100%",

  ".MuiFormControl-root, .MuiTextField-root": {
    width: "100%",
  },

  ".MuiFormLabel-root": {
    color: "#27272799",
    fontSize: "14px",
    textTransform: "capitalize",
    padding: "0",
  },

  ".MuiInputLabel-root.Mui-focused": {
    textTransform: "capitalize",
    color: "#27272799",
    fontSize: "17px",
    padding: "5px 10px",
    marginTop: "-5px",
    backgroundColor: "#fff",
  },

  ".MuiInputBase-root, .MuiOutlinedInput-root": {
    width: "100%",

    "& fieldset": {
      borderColor: "#F0F4FF",
      background: "#FDFDFD",
      borderRadius: "8px",
      height: "57px",
      transition: "all .3s ease-in-out",
    },
    "& input": {
      color: "#272727",
      zIndex: "9",
      padding: "14px 0 10px 22px",
      fontSize: "14px",
      marginTop: "4px",
    },
    "& svg": {
      zIndex: "9",
      "& path": {
        fill: "#E6ECFF",
      },
    },
    "&:hover fieldset": {
      borderColor: "#0B61DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0B61DD",
      borderWidth: "1px",
    },
  },

  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    background: "#F2F2F2",
    "&:hover": {
      borderColor: "transparent",
    },
    "&.Mui-focused": {
      borderColor: "",
    },
  },

  "& .MuiButtonBase-root.Mui-disabled, .MuiIconButton-root.Mui-disabled": {
    "& svg": {
      "& path": {
        fill: "#787878",
      },
    },
  },
});
