import { ApolloProvider } from "@apollo/client"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./tests/reportWebVitals"
import "./index.css"
import "./vars.css"
import "toastr/build/toastr.min.css"

import { client } from "config"
import { UnSecuredView } from "components"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    {window.self === window.top ? (
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    ) : (
      <UnSecuredView />
    )}
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
