import React from "react";
import {
  AuthIcon,
  CloseIcon,
} from "../../../../../../assets/images/dashboardLayout/svgExports";
import { ModalContainer } from "../../../../../../components/layout/ModalLayout/modalLayout.styles";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";
import { OIDButton } from "../../../../../../components/lib/Button/button.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  setShowInstallModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
}

const TwoFaModal: React.FC<IProps> = ({ open, close, setShowInstallModal }) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    setShowInstallModal(true);
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <AuthIcon width="30" />
        <h3 className="modal__headertext">Two- Factor- Authentication</h3>
        <p className="modal__subheadertext">
          By generating a secure code on your device for sign-in approval, 2FA
          adds an extra layer of security to your OneID account.
        </p>
        <FlexibleDiv justifyContent="flex-end" margin="20px 0 0 0">
          <OIDButton margin="0 0 0 25px" onClick={submit}>
            Get Started
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default TwoFaModal;
