import { useFormik } from "formik"
import { formatDateTimeTZ } from "helpers/formatDateTimeZ"
import React from "react"
import { useNavigate } from "react-router-dom"
import { FlexibleDiv } from "../../Box/box.styles"
import { OIDButton } from "../../Button/button.styles"
import { Datepicker } from "../Datepicker"
import { Select } from "../Select"
import TextArea from "../TextArea/textarea"
import { TextField } from "../TextField"
import { CustomFormContainer } from "./customForm.styles"
import { FormDetails } from "./types"

interface IProps {
  initialValues?: any
  formDetails: FormDetails[]
  validationSchema?: any
  formFinishButtonText: string
  editDisabled?: boolean
  inputMargin?: string
  buttonPosition?: string
  formNoCancelButton?: boolean
  formNoBorderCancelButton?: boolean
  formColumnButtons?: boolean
  formContinueButtonText?: string
  submit: (e: string, a: any, b: any) => void
}

export const CustomForm: React.FC<IProps> = ({
  initialValues,
  formFinishButtonText,
  editDisabled,
  inputMargin,
  buttonPosition,
  formNoCancelButton,
  formNoBorderCancelButton,
  formColumnButtons,
  formContinueButtonText,
  validationSchema,
  formDetails,
  submit,
}) => {
  const [submitAction, setSubmitAction] = React.useState("")
  const navigate = useNavigate()
  const {
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      submit(submitAction, values, setSubmitting)
    },
  })

  return (
    <CustomFormContainer>
      <form onSubmit={handleSubmit}>
        {formDetails.map((item, idx) => {
          const inputKey = item.name as string

          return (
            <React.Fragment key={`${idx}__${item.name}`}>
              {item.break && (
                <FlexibleDiv justifyContent="flex-start" className="break">
                  <h4 className="break__text">{item.break}</h4>
                </FlexibleDiv>
              )}
              <FlexibleDiv
                margin={
                  inputMargin || editDisabled ? "0 0 20px 0" : "0 0 30px 0"
                }
                width={item.width}
              >
                {item.type === "select" ? (
                  <Select
                    data={item}
                    getValue={item?.getValue}
                    selectProps={{
                      name: item.name,
                      onBlur: handleBlur,
                      disabled: editDisabled,
                      onChange: handleChange,
                      value: values[inputKey as keyof typeof values],
                      placeholder: item.label,
                    }}
                  />
                ) : item.type === "date" ? (
                  <Datepicker
                    label={item.label}
                    name={item.name}
                    handleChange={newValue =>
                      setFieldValue("dob", formatDateTimeTZ(newValue))
                    }
                    value={values[inputKey as keyof typeof values]}
                  />
                ) : item.type === "textarea" ? (
                  <TextArea data={item} disabled={editDisabled} />
                ) : (
                  <TextField
                    textFieldProps={{
                      name: item.name,
                      placeholder: item.label,
                      onBlur: handleBlur,
                      onChange: handleChange,
                      disabled: editDisabled,
                      value: values[inputKey as keyof typeof values],
                    }}
                  />
                )}
              </FlexibleDiv>
            </React.Fragment>
          )
        })}

        {formColumnButtons ? (
          <FlexibleDiv flexDir="column">
            {formContinueButtonText && (
              <OIDButton
                width="80%"
                maxWidth="300px"
                margin="25px 0"
                type="submit"
                disabled={isSubmitting}
                onClick={() => setSubmitAction("save_continue")}
              >
                {formContinueButtonText}
              </OIDButton>
            )}

            <OIDButton
              width="80%"
              type="submit"
              maxWidth="300px"
              color="#0B61DD"
              disabled={isSubmitting}
              backgroundColor="transparent"
              onClick={() => setSubmitAction("save_exit")}
            >
              {formFinishButtonText}
            </OIDButton>
          </FlexibleDiv>
        ) : (
          <>
            {!editDisabled && (
              <FlexibleDiv
                justifyContent={
                  !formNoCancelButton && !buttonPosition
                    ? "flex-start"
                    : buttonPosition
                    ? buttonPosition
                    : "center"
                }
                className="custormform__buttons__wrap"
              >
                {formNoBorderCancelButton ? (
                  <span className="cancel__text" onClick={() => navigate(-1)}>
                    Cancel
                  </span>
                ) : (
                  <>
                    {!formNoCancelButton && (
                      <OIDButton
                        type="button"
                        height="45px"
                        margin="20px 30px 20px 0"
                        backgroundColor="transparent"
                        borderColor="#00499B"
                        color="#00499B"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </OIDButton>
                    )}
                  </>
                )}

                <OIDButton height="45px" margin="20px 0" type="submit">
                  {formFinishButtonText}
                </OIDButton>
              </FlexibleDiv>
            )}
          </>
        )}
      </form>
    </CustomFormContainer>
  )
}
