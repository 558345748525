import styled from "styled-components";

export const NotificationsContainer = styled.div`
  width: 100%;
  padding: 30px 30px 50px 30px;
  .notification__box {
    max-width: 900px;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 10px;

    .notifications__tabs {
      padding-left: 60px;
      .notification__tabmain {
        margin-right: 40px;
        cursor: pointer;
        transition: all 0.3s ease;
        .tab__text,
        .num__of__notifications {
          color: #7d7d7d;
          padding: 7px 1px;
          position: relative;
          margin: 0;
        }

        .tab__text {
          ::before {
            width: 0;
            transition: all 0.3s ease;
          }
        }
        :hover {
          .tab__text,
          .num__of__notifications {
            color: #0c0c0c;
          }
        }
      }
      #activetab {
        .num__of__notifications {
          color: #0b61dd;
        }

        .tab__text {
          color: #0b61dd;

          ::before {
            content: "";
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: 0;
            background-color: #0b61dd;
          }
        }
        :hover {
          .tab__text,
          .num__of__notifications {
            color: #0b61dd;
          }
        }
      }
    }

    .notification__item {
      box-shadow: 0px 4px 24px rgba(147, 147, 147, 0.08);
      border-radius: 8px;
      padding: 20px 60px;
      position: relative;
      transition: all 0.3s ease;
      margin-top: 10px;
      .bell__wrap {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background: #eff6ff;
        position: absolute;
        left: 20px;
        top: 15px;
        svg {
          color: #0b61dd;
        }
      }

      .notification__item__title {
        margin: 0;
        font-size: 14px;
        color: #0c0c0c;
      }
      .notificaion__message {
        font-size: 14px;
        color: #474747;
      }

      .notification__time {
        font-size: 12px;
        color: #939393;
        margin: 3px 0 0 10px;
        white-space: nowrap;
      }

      :hover {
        cursor: pointer;
        background: #fbfcff;
        transform: scale(1.04);
      }
    }

    .oldnotification__item {
      .bell__wrap {
        svg {
          color: #c7d1df;
        }
      }

      .oldnotification__item__title {
        color: #717171;
      }
      .oldnotificaion__message {
        color: #777777;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 40px 15px;

    .notification__box {
      .notifications__tabs {
        padding-left: 40px;
        .notification__tabmain {
          margin-right: 20px;

          .tab__text,
          .num__of__notifications {
            font-size: 14px;
          }
        }
      }

      .notification__item {
        padding: 20px 15px 20px 40px;
        .bell__wrap {
          width: 25px;
          height: 25px;
          left: 10px;
          svg {
            color: #0b61dd;
          }
        }

        .notification__item__title,
        .notificaion__message {
          font-size: 13px;
        }

        .notification__time {
          font-size: 11px;
        }
      }
    }
  }
`;
