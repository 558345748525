import { DashboardLayout, GoBack } from "components"
import { useState } from "react"
import { FlexibleSection } from "../../../components/lib/Box/box.styles"
import { CustomForm } from "components"
import Tabs from "../../../components/lib/Tabs/tabs"
import { AddEducationContainer } from "./addEducation.styles"

const AddEducation = () => {
  const [activeTab, setActiveTab] = useState("Education")
  const educationformDetails = [
    {
      name: "level",
      type: "select",
      width: "48%",
      label: "Level Of Institution",
      dataSet: ["Primary", "Secondary", "Tertiary"],
    },
    {
      name: "institution",
      type: "select",
      width: "48%",
      label: "Name Of Institution",
      dataSet: ["Primary", "Secondary", "Tertiary"],
    },
    {
      name: "course",
      type: "text",
      width: "48%",
      label: "Course Of Study",
    },
    {
      name: "grade",
      type: "text",
      width: "48%",
      label: "Grade",
    },
    {
      name: "certificate",
      type: "select",
      width: "48%",
      label: "Certificate Obtained",
      dataSet: ["Primary", "Secondary", "Tertiary"],
    },
    {
      name: "address",
      type: "text",
      width: "48%",
      label: "School Address",
    },
    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
  ]

  const trainingformDetails = [
    {
      name: "institution",
      type: "text",
      width: "100%",
      label: "Name Of Training Institute",
    },
    {
      name: "address",
      type: "text",
      width: "48%",
      label: "Address Of Institute",
    },

    {
      name: "certificate",
      type: "text",
      width: "48%",
      label: "Certification Title",
    },

    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
  ]

  const tablist = ["Education", "Training"]

  const submit = () => {}

  return (
    <DashboardLayout headerText="Educational Background">
      <GoBack />

      <AddEducationContainer>
        <FlexibleSection
          className="form__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <Tabs
            setActiveTab={setActiveTab}
            tablist={tablist}
            activeTab={activeTab}
          />
          <span className="subheader__text">
            Please fill in your employment history, starting with your most
            recent job.
          </span>
          <CustomForm
            formDetails={
              activeTab === "Education"
                ? educationformDetails
                : trainingformDetails
            }
            formFinishButtonText={
              activeTab === "Education" ? "Save Education" : "Save Training"
            }
            submit={submit}
          />
        </FlexibleSection>
      </AddEducationContainer>
    </DashboardLayout>
  )
}

export default AddEducation
