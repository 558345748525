import { DashboardLayout, NoData, Progress } from "components";
import format from "date-fns-tz/format";
import { useMe } from "helpers";
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types";
import { useState } from "react";
import {
  MdModeEdit as Edit,
  MdOutlineKeyboardArrowDown as ArrowDown,
} from "react-icons/md";
import { Link } from "react-router-dom";
import {
  BillingCircleIcon,
  CheckIcon,
  KycCircleIcon,
  MultiPersonCircleIcon,
  SinglePersonCircleIcon,
  SocialsCircleIcon,
} from "../../../assets/images/dashboardLayout/svgExports";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";
import { ViewProfileContainer } from "./viewProfile.styles";

const ViewProfile = () => {
  const [activeSlab, setActiveSlab] = useState("");
  const me = useMe();
  const { data, loading } = useGetProfileDataQuery();

  const profile = data && data.userGetProfile;

  const openSlab = (slab: string) => {
    if (activeSlab === slab) {
      setActiveSlab("");
    } else {
      setActiveSlab(slab);
    }
  };
  return loading ? (
    <Progress />
  ) : (
    <DashboardLayout headerText="Profile">
      {!profile?.firstName ? (
        <NoData
          noDataTitle="Profile Not Updated"
          noDataMessage="Hi May, please click the button above to update your profile"
          noDataButtonRoute="/update-profile/personal-details"
          noDataButtonText="Update Profile"
          noDataNavigationData={true}
        />
      ) : (
        <ViewProfileContainer>
          <FlexibleDiv flexDir="column" maxWidth="1000px">
            <FlexibleDiv flexDir="column" className="verification__box">
              <FlexibleDiv width="90px" justifyContent="space-evenly">
                <CheckIcon />
                {!!profile?.bvn && <CheckIcon pathFill="#C0C0C0" />}
              </FlexibleDiv>
              {!!profile?.bvn ? (
                <p className="verification__box__text">
                  You are fully verified and have earned all 2 badges on your
                  OneID account
                </p>
              ) : (
                <p className="verification__box__text longer__verification__boxtext">
                  Your email is verified and you have earned 1 badge on your
                  OneID account. Please update your KYC to earn more
                </p>
              )}
            </FlexibleDiv>
            {/* profile details */}
            <FlexibleDiv className="profile__details__wrap" flexDir="column">
              <FlexibleDiv
                className="profile__slab"
                id={activeSlab === "Personal Details" ? "active__slab" : ""}
                flexDir="column"
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  className="slab__header"
                >
                  <p className="slab__header__text">
                    <SinglePersonCircleIcon
                      circleFill={
                        activeSlab === "Personal Details" ? "#E6ECFF" : ""
                      }
                      pathFill={
                        activeSlab === "Personal Details" ? "#0B61DD" : ""
                      }
                    />
                    Your Personal Details{" "}
                  </p>
                  <div
                    onClick={() => openSlab("Personal Details")}
                    className="arrow__wrap"
                  >
                    <ArrowDown />
                  </div>
                </FlexibleDiv>

                {activeSlab === "Personal Details" && (
                  <FlexibleDiv className="slab__body" alignItems="flex-start">
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Name:</span>
                      <p className="data__text">{profile?.fullName}</p>
                    </FlexibleDiv>

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Gender:</span>
                      <p className="data__text">{profile?.gender}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Marital Status:</span>
                      <p className="data__text">{profile?.maritalStatus}</p>
                    </FlexibleDiv>

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Email:</span>
                      <p className="data__text">{me?.email}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Phone No:</span>
                      <p className="data__text">{profile?.phone}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">OneID:</span>
                      <p className="data__text">{me?.oneId}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Date Of Birth:</span>
                      <p className="data__text">
                        {" "}
                        {format(new Date(profile?.dob), "dd MMM, yyyy.")}
                      </p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Alternative Email:</span>
                      <p className="data__text">{profile?.secondaryEmail}</p>
                    </FlexibleDiv>

                    <Link
                      className="edit__wrap"
                      to="/update-profile/personal-details"
                    >
                      <Edit />
                    </Link>
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            </FlexibleDiv>

            {/* kyc details */}
            <FlexibleDiv className="profile__details__wrap" flexDir="column">
              <FlexibleDiv
                className="profile__slab"
                id={activeSlab === "KYC Details" ? "active__slab" : ""}
                flexDir="column"
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  className="slab__header"
                >
                  <p className="slab__header__text">
                    <KycCircleIcon
                      circleFill={activeSlab === "KYC Details" ? "#E6ECFF" : ""}
                      pathFill={activeSlab === "KYC Details" ? "#0B61DD" : ""}
                    />
                    Your KYC Details
                  </p>
                  <div
                    onClick={() => openSlab("KYC Details")}
                    className="arrow__wrap"
                  >
                    <ArrowDown />
                  </div>
                </FlexibleDiv>

                {activeSlab === "KYC Details" && (
                  <FlexibleDiv className="slab__body">
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">BVN:</span>
                      {/* TODO: mask this text */}
                      <p className="data__text">{profile?.bvn}</p>
                    </FlexibleDiv>
                    {!profile.bvn && (
                      <Link className="edit__wrap" to="/update-profile/kyc">
                        <Edit />
                      </Link>
                    )}
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            </FlexibleDiv>

            {/* billing details */}
            <FlexibleDiv className="profile__details__wrap" flexDir="column">
              <FlexibleDiv
                className="profile__slab"
                alignItems="flex-start"
                id={activeSlab === "Billing Info" ? "active__slab" : ""}
                flexDir="column"
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  className="slab__header"
                >
                  <p className="slab__header__text">
                    <BillingCircleIcon
                      circleFill={
                        activeSlab === "Billing Info" ? "#E6ECFF" : ""
                      }
                      pathFill={activeSlab === "Billing Info" ? "#0B61DD" : ""}
                    />
                    Your Billing Info{" "}
                  </p>
                  <div
                    onClick={() => openSlab("Billing Info")}
                    className="arrow__wrap"
                  >
                    <ArrowDown />
                  </div>
                </FlexibleDiv>

                {activeSlab === "Billing Info" && (
                  <FlexibleDiv className="slab__body">
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Resident Country:</span>
                      <p className="data__text">{profile?.country}</p>
                    </FlexibleDiv>

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">State Of Residence:</span>
                      <p className="data__text">{profile?.stateOfResidence}</p>
                    </FlexibleDiv>
                    {/* <FlexibleDiv className="single__detail">
                      <span className="data__header">City/ Province:</span>
                      TODO: API to add City or Province
                      <p className="data__text">{profile?.city}</p>
                    </FlexibleDiv> */}

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">House Address:</span>
                      <p className="data__text">{profile?.primaryAddress}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Country Of Origin:</span>
                      <p className="data__text">{profile?.country}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">State Of Origin:</span>
                      <p className="data__text">{profile?.stateOfOrigin}</p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">L.G.A:</span>
                      <p className="data__text">{profile?.lga}</p>
                    </FlexibleDiv>

                    <Link
                      className="edit__wrap"
                      to="/update-profile/billing-details"
                    >
                      <Edit />
                    </Link>
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            </FlexibleDiv>

            {/* socials details */}
            <FlexibleDiv className="profile__details__wrap" flexDir="column">
              <FlexibleDiv
                className="profile__slab"
                id={activeSlab === "Social Details" ? "active__slab" : ""}
                flexDir="column"
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  className="slab__header"
                >
                  <p className="slab__header__text">
                    <SocialsCircleIcon
                      circleFill={
                        activeSlab === "Social Details" ? "#E6ECFF" : ""
                      }
                      pathFill={
                        activeSlab === "Social Details" ? "#0B61DD" : ""
                      }
                    />
                    Your Social Details{" "}
                  </p>
                  <div
                    onClick={() => openSlab("Social Details")}
                    className="arrow__wrap"
                  >
                    <ArrowDown />
                  </div>
                </FlexibleDiv>

                {activeSlab === "Social Details" && (
                  <FlexibleDiv
                    className="slab__body longer__width__content"
                    alignItems="flex-start"
                  >
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Twitter:</span>
                      <p className="data__text">
                        {profile.socialMedia?.twitter}
                      </p>
                    </FlexibleDiv>

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Instagram:</span>
                      <p className="data__text">
                        {profile.socialMedia?.instagram}
                      </p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Facebook:</span>
                      <p className="data__text">
                        {profile.socialMedia?.facebook}
                      </p>
                    </FlexibleDiv>

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">LinkedIn:</span>
                      <p className="data__text">
                        {profile.socialMedia?.linkedin}
                      </p>
                    </FlexibleDiv>

                    <Link
                      className="edit__wrap"
                      to="/update-profile/social-details"
                    >
                      <Edit />
                    </Link>
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            </FlexibleDiv>

            {/* Next Of Kin */}
            <FlexibleDiv className="profile__details__wrap" flexDir="column">
              <FlexibleDiv
                className="profile__slab"
                id={activeSlab === "Next Of Kin" ? "active__slab" : ""}
                flexDir="column"
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  className="slab__header"
                >
                  <p className="slab__header__text">
                    <MultiPersonCircleIcon
                      circleFill={activeSlab === "Next Of Kin" ? "#E6ECFF" : ""}
                      pathFill={activeSlab === "Next Of Kin" ? "#0B61DD" : ""}
                    />
                    Next Of Kin Contact Info
                  </p>
                  <div
                    onClick={() => openSlab("Next Of Kin")}
                    className="arrow__wrap"
                  >
                    <ArrowDown />
                  </div>
                </FlexibleDiv>

                {activeSlab === "Next Of Kin" && (
                  <FlexibleDiv
                    className="slab__body longer__width__content"
                    alignItems="flex-start"
                  >
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Full name:</span>
                      <p className="data__text">
                        {profile?.nextOfKin?.fullName}
                      </p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">
                        Relationship With Next Of Kin:
                      </span>
                      <p className="data__text">
                        {profile?.nextOfKin?.relationship}
                      </p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">
                        Next Of Kin’s Address:
                      </span>
                      <p className="data__text">
                        {profile?.nextOfKin?.address}
                      </p>
                    </FlexibleDiv>
                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Phone No:</span>
                      <p className="data__text">{profile?.nextOfKin?.phone}</p>
                    </FlexibleDiv>

                    <FlexibleDiv className="single__detail">
                      <span className="data__header">Email:</span>
                      <p className="data__text">{profile?.nextOfKin?.email}</p>
                    </FlexibleDiv>
                    <Link
                      className="edit__wrap"
                      to="/update-profile/next-of-kin"
                    >
                      <Edit />
                    </Link>
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            </FlexibleDiv>

            {/* Emergency Contact */}
            <FlexibleDiv className="profile__details__wrap" flexDir="column">
              <FlexibleDiv
                className="profile__slab"
                id={activeSlab === "Emergency Contact" ? "active__slab" : ""}
                flexDir="column"
              >
                <FlexibleDiv
                  justifyContent="space-between"
                  className="slab__header"
                >
                  <p className="slab__header__text">
                    <MultiPersonCircleIcon
                      circleFill={
                        activeSlab === "Emergency Contact" ? "#E6ECFF" : ""
                      }
                      pathFill={
                        activeSlab === "Emergency Contact" ? "#0B61DD" : ""
                      }
                    />
                    Emergency Contact(s)
                  </p>
                  <div
                    onClick={() => openSlab("Emergency Contact")}
                    className="arrow__wrap"
                  >
                    <ArrowDown />
                  </div>
                </FlexibleDiv>

                {activeSlab === "Emergency Contact" &&
                !!profile?.emergencyContacts?.length
                  ? profile.emergencyContacts.map((ec, idx) => (
                      <FlexibleDiv
                        className="slab__body longer__width__content"
                        alignItems="flex-start"
                        key={idx}
                      >
                        <FlexibleDiv className="single__detail">
                          <span className="data__header">Full name:</span>
                          <p className="data__text">{ec?.name}</p>
                        </FlexibleDiv>

                        <FlexibleDiv className="single__detail">
                          <span className="data__header">
                            Relationship With Contact:
                          </span>
                          <p className="data__text">{ec?.relationship}</p>
                        </FlexibleDiv>
                        <FlexibleDiv className="single__detail">
                          <span className="data__header">
                            Contact's Address:
                          </span>
                          <p className="data__text">{ec?.address}</p>
                        </FlexibleDiv>

                        <FlexibleDiv className="single__detail">
                          <span className="data__header">Phone No:</span>
                          <p className="data__text">{ec?.phone}</p>
                        </FlexibleDiv>

                        <FlexibleDiv className="single__detail">
                          <span className="data__header">Email:</span>
                          <p className="data__text">{ec?.email}</p>
                        </FlexibleDiv>

                        <Link
                          className="edit__wrap"
                          to="/update-profile/emergency-contact"
                        >
                          <Edit />
                        </Link>
                      </FlexibleDiv>
                    ))
                  : activeSlab === "Emergency Contact" && (
                      <FlexibleDiv
                        className="slab__body longer__width__content"
                        alignItems="flex-start"
                      >
                        <p className="data__text">No contacts provided.</p>

                        <Link
                          className="edit__wrap"
                          to="/update-profile/emergency-contact"
                        >
                          <Edit />
                        </Link>
                      </FlexibleDiv>
                    )}
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </ViewProfileContainer>
      )}
    </DashboardLayout>
  );
};

export default ViewProfile;
