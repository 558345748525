import { DashboardLayout } from "components"
import { useState } from "react"
import { FlexibleDiv } from "../../../components/lib/Box/box.styles"
import Tabs from "../../../components/lib/Tabs/tabs"
import CovidHistory from "./CovidHistory/covidHostory"
import GeneralHistory from "./GeneralHistory/generalHistory"
import HospitalRecords from "./HospitalRecords/hospitalRecords"
import { MedicalHistoryContainer } from "./medicalHistory.styles"

const MedicalHistory = () => {
  const [activeTab, setActiveTab] = useState("GENERAL RECORDS")
  const tablist = ["COVID 19", "GENERAL RECORDS", "HOSPITAL RECORDS"]

  return (
    <DashboardLayout headerText="Medical History">
      <MedicalHistoryContainer
        alignItems="flex-start"
        flexDir="column"
        className="medicalhistory__mainwrap"
      >
        <FlexibleDiv className="tabs__wrap">
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tablist={tablist}
          />
        </FlexibleDiv>
        {activeTab === "COVID 19" && <CovidHistory />}
        {activeTab === "GENERAL RECORDS" && <GeneralHistory />}
        {activeTab === "HOSPITAL RECORDS" && <HospitalRecords />}
      </MedicalHistoryContainer>
    </DashboardLayout>
  )
}

export default MedicalHistory
