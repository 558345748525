import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const LegalContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px;
  justify-content: flex-start;

  .privacy__main__wrap {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px 30px 50px 30px;
    max-width: 800px;
    align-items: flex-start;
    flex-direction: column;

    h2 {
      color: #272727;
      font-size: 18px;
      margin: 0;
    }
    p,
    li,
    h3 {
      color: #272727;
      font-size: 16px;
      line-height: 27px;
      .read__more {
        cursor: pointer;
      }
      margin: 0;

      a {
        font-weight: bold;
        text-decoration: none;
        color: #000;
      }
      .link {
        font-weight: 300;
        color: #0b61dd;
      }
    }
    h3 {
      margin: 20px 0 10px 0;
    }

    .bullet {
      margin-bottom: 20px;
    }
    .nospacebullet {
      margin-bottom: 7px;
    }
  }

  @media (max-width: 900px) {
    padding: 40px 15px;

    .privacy__main__wrap {
      padding: 30px 15px;
    }
  }

  @media (max-width: 500px) {
    .privacy__main__wrap {
      padding-top: 50px;
      h2 {
        font-size: 15px;
        margin-top: -20px;
      }
      p,
      li,
      h3 {
        font-size: 13px;
      }

      h3 {
        margin: 13px 0 7px 0;
      }

      .bullet {
        margin-bottom: 15px;
      }
      .nospacebullet {
        margin-bottom: 5px;
      }
    }
  }
`;
