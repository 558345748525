import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { BarChartMain, PieChartWrap } from "./chart.styles";
import { OverFlowScrollBar } from "../OverflowScroll/overflow.styles";
import { FlexibleDiv } from "../Box/box.styles";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BiCaretDown as ArrowDown } from "react-icons/bi";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

interface BarIProps {
  title?: string;
  subHeaderText?: string;
  barChartBk?: string;
  barChartData: any;
  lineChartBk?: string;
  hovLabel?: string;
  label: any;
  color?: string;
  barDataSet: any;
}

interface PieIProps {
  title?: string;
  subHeaderText?: string;
  barChartBk?: string;
  pieBkCol?: string;
  lineChartBk?: string;
  hovLabel?: string;
  pieChartData?: any;
  label: any;
  color?: string;
  pieDataSet: any;
}

export const BarChart: React.FC<BarIProps> = ({
  title,
  subHeaderText,
  barChartBk,
  barChartData,
  label,
  color,
  barDataSet,
}) => {
  const data = {
    labels: label,
    datasets: barDataSet?.map((data: any) => {
      return {
        data: barChartData,
        backgroundColor: "#91B3FA",
        hoverBackgroundColor: "#3F7BFF",
        borderWidth: 0,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointBorderWidth: 3,
        pointRadius: 7,
        lineTension: 0.4,
        pointHoverRadius: 0,
        fill: true,
        pointHoverBorderWidth: 3,
        pointHitRadius: 7,
        spanGaps: false,
      };
    }),
  };

  const options: any = {
    cornerRadius: 20,
    barRoundness: 1,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        display: false,
        title: {
          display: false,
        },
      },
      labels: {
        fontSize: 6,
      },
      tooltip: {
        backgroundColor: "#fff",
        titleColor: "#6A6A6A",
        caretSize: "0",
        borderColor: "#ddd",
        borderWidth: "1",
        bodyColor: "#6A6A6A",
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderColor: "#ddd",
          borderWidth: 0,
        },
        ticks: {
          beginAtZero: true,
          max: 100,
          min: 5,
          stepSize: 5,
        },

        gridLines: {
          display: false,
          color: "#eee",
        },
      },
      x: {
        ticks: {
          tickLength: 20,
          tickMaxLength: 1,
          reverse: false,
          fontSize: 3,
        },

        grid: {
          display: false,
          borderColor: "transparent",
          borderWidth: 2,
        },
      },
    },
  };

  return (
    <BarChartMain barChartBk={barChartBk}>
      <FlexibleDiv>
        <FlexibleDiv
          className="barchart__header__wrap"
          justifyContent="space-between"
        >
          <h3 className="barchart__header">Profile Requests</h3>
          <p className="barchart__subheader">
            Days <ArrowDown />
          </p>
        </FlexibleDiv>
        <OverFlowScrollBar className="scrolling">
          <div className="canvas">
            <Bar data={data} options={options} />
          </div>
        </OverFlowScrollBar>
      </FlexibleDiv>
    </BarChartMain>
  );
};

export const PieChart: React.FC<PieIProps> = ({
  pieBkCol,
  lineChartBk,
  hovLabel,
  pieChartData,
  label,
  pieDataSet,
}) => {
  const data = {
    labels: label,

    datasets: pieDataSet?.map((data: any) => {
      return {
        label: hovLabel || "Data",
        backgroundColor: ["#FF5C56", "#FFD664", "#3F7BFF"],
        borderColor: "transparent",
        data: pieChartData,
        datalabels: {
          color: "transparent",
        },
      };
    }),
  };
  const plugins = [ChartDataLabels];

  const options: any = {
    maintainAspectRatio: false,
    tooltipTemplate: "<%= Math.round(circumference / 6.283 * 100) %>%",

    plugins: {
      datalabels: {
        formatter: (value: any) => {
          return value;
        },
        font: {
          size: 14,
        },
      },
      tooltip: {
        backgroundColor: "#fff",
        bodyColor: "#282828",
        caretSize: "0",
        borderColor: "#ddd",
        borderWidth: "1",
      },
      legend: {
        position: "right",
        align: "center",

        labels: {
          boxWidth: 12,
          font: {
            size: 12,
            weight: "bold",
          },
        },
      },
    },
    layout: {
      padding: 20,
    },
  };

  return (
    <PieChartWrap alignItems="flex-start" flexDir="column">
      <p className="piechart__headertext">Access Requests Overview</p>
      <FlexibleDiv className="canvas" justifyContent="flex-start">
        <Doughnut options={options} data={data} plugins={plugins} />
      </FlexibleDiv>
    </PieChartWrap>
  );
};
