import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const PrivacyAndSecurityContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px;
  justify-content: flex-start;

  .privacy__main__wrap {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px 30px 50px 30px;
    max-width: 1000px;
    align-items: flex-start;
    flex-direction: column;

    .body__textwrap {
      margin-bottom: 25px;
      box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
      border-radius: 4px;
      padding: 15px 20px;
      justify-content: space-between;
      flex-wrap: nowrap;
      cursor: pointer;
      transition: all 0.3s ease;

      :hover {
        transform: scale(1.04);
        background-color: #fbfcff;
      }
      .arrow__wrap {
        opacity: 0.7;
        svg {
          color: #424242;
          font-size: 30px;
        }
      }

      .body__textwrap__leftsection {
        flex-direction: column;
        align-items: flex-start;
        width: 90%;

        .body__headertext {
          margin: 0;
          color: #272727;
          font-size: 18px;
          cursor: pointer;
        }
        .body__subheadertext {
          color: #7b8087;
          font-size: 16px;
          margin: 10px 0;
          max-width: 500px;
        }
        .new {
          position: relative;
          ::after {
            content: "NEW";
            font-size: 12px;
            color: #0b61dd;
            background: #e7efff;
            border-radius: 3px;
            padding: 5px 9px;
            position: absolute;
            right: -55px;
            top: -5px;
          }
        }
      }

      .dropdown__itemswrap {
        padding: 20px 0;
        width: 95%;
        align-items: flex-start;
        .dropdown__itemssub {
          justify-content: space-between;
          margin-top: 10px;
          max-width: 850px;
          border-bottom: 1px solid #eee;
          padding: 10px;
          .dropdown__leftsection {
            flex-direction: column;
            width: 85%;
            align-items: flex-start;

            .dropdown__headertext {
              margin: 0;
              font-size: 16px;
              color: #424242;
            }
            .dropdown__subheadertext {
              font-size: 14px;
              color: #939393;
            }
          }
        }
      }
    }

    #nohover__slab {
      :hover {
        transform: scale(1);
        background-color: #fff;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 40px 15px;

    .privacy__main__wrap {
      padding: 30px 15px;
    }
  }

  @media (max-width: 500px) {
    .privacy__main__wrap {
      .header__text {
        font-size: 15px;
      }
      .body__textwrap {
        margin-bottom: 25px;
        box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
        border-radius: 4px;
        padding: 15px;
        justify-content: space-between;
        flex-wrap: nowrap;
        cursor: pointer;
        transition: all 0.3s ease;

        :hover {
          transform: scale(1.04);
          background-color: #fbfcff;
        }
        .arrow__wrap {
          svg {
            font-size: 25px;
          }
        }

        .body__textwrap__leftsection {
          .body__headertext {
            font-size: 15px;
          }
          .body__subheadertext {
            font-size: 13px;
          }
          .new {
            position: relative;
            ::after {
              font-size: 10px;
            }
          }
        }

        .dropdown__itemswrap {
          padding: 20px 0;
          width: 97%;
          .dropdown__itemssub {
            .dropdown__leftsection {
              .dropdown__headertext {
                font-size: 14px;
              }
              .dropdown__subheadertext {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;
