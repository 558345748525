import React from "react";
import { FlexibleDiv } from "components/lib/Box/box.styles";
import { ModalContainer } from "components/layout/ModalLayout/modalLayout.styles";
import { useUserGetApprovedProfileRequestQuery } from "pages/ViewAccessRequests/gql/__types/accessRequest.types";
import { OIDButton, Progress } from "components";

interface IProps {
  showRequestModal?: boolean;
  setShowRequestModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  requestId: string;
}

const AccessApprovedModal: React.FC<IProps> = ({
  showRequestModal,
  setShowRequestModal,
  requestId,
}) => {
  const { data, loading } = useUserGetApprovedProfileRequestQuery({
    variables: { requestId: requestId },
    skip: !requestId,
  });

  const close = () => {
    window.location.replace("/");
    setShowRequestModal(false);
  };

  return (
    <ModalContainer open={!!showRequestModal} onClose={close}>
      {loading ? (
        <Progress />
      ) : (
        <FlexibleDiv className="wrap" height="100%">
          <FlexibleDiv
            width="max-content"
            flexWrap="nowrap"
            className="box__wrap"
            flexDir="column"
          >
            <h3 className="modal__headertext">Requested Details</h3>
            {data?.getProfileRequestById[0]?.queriedUser?.businessName ? (
              <h3 className="appprovedrequest__titles">Business Name:</h3>
            ) : (
              <h3 className="appprovedrequest__titles">Username:</h3>
            )}
            <span>
              {data?.getProfileRequestById[0]?.queriedUser?.username ||
                data?.getProfileRequestById[0]?.queriedUser?.businessName}
            </span>
            <h3 className="appprovedrequest__titles">Code:</h3>{" "}
            <span>{data?.getProfileRequestById[0]?.queriedUser?.oneId}</span>
            <h3 className="appprovedrequest__titles">Email:</h3>{" "}
            <span>
              {data?.getProfileRequestById[0]?.queriedUser?.email ||
                data?.getProfileRequestById[0]?.queriedUser?.businessEmail}
            </span>
            <OIDButton onClick={close} width="150px" margin="50px 0 0 0">
              Close
            </OIDButton>
          </FlexibleDiv>
        </FlexibleDiv>
      )}
    </ModalContainer>
  );
};

export default AccessApprovedModal;
