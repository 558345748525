import styled from "styled-components";
import { FlexibleDiv } from "../../lib/Box/box.styles";

export const UpdateProfileLayoutContainer = styled(FlexibleDiv)`
  width: 100%;
  padding: 40px;

  .form__title {
    font-weight: 400;
    font-size: 24px;
    color: #0c0c0c;
    margin: 0;
  }
  .form__subtitle {
    font-size: 16px;
    color: #272727;
    margin: 20px 0;
  }

  .progress__bar {
    width: 300px;
    height: 5px;
    background: #0b61dd33;
    justify-content: flex-start;
    margin: 0 0 40px 0;
    border-radius: 152px;
    .progress__bar__inner {
      height: 100%;
      background: #0b61dd;
      border-radius: 152px;
    }
  }
  .form__skip {
    margin-top: 40px;

    .skip__text {
      color: #00499b;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
    }
    svg {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .kyc__info__card {
    padding: 15px 20px 15px 15px;
    margin: 30px 0 10px 0;
    background: #fffcf4;
    border: 0.5px solid #e4e4e4;
    box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
    border-radius: 12px;
    position: relative;

    svg {
      color: #272727;
      font-size: 30px;
    }
    .kyc__info__card__text {
      font-size: 13px;
      margin-left: 10px;
      font-weight: 600;
    }
  }

  fieldset {
    box-shadow: 0px 4px 12px rgba(57, 57, 57, 0.05);
  }
  @media (min-width: 1000px) {
    max-width: 90%;

    .kyc__info__card {
      max-width: 320px;
      position: absolute;
      right: 4%;
      top: 100px;
    }
  }
  @media (max-width: 500px) {
    padding: 40px 15px;

    .form__title {
      font-size: 20px;
    }
    .form__subtitle {
      margin: 10px 0;
    }
    .progress__bar {
      width: 100%;
    }
  }
`;
