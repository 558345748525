import React, { useState } from "react";
import { LegalContainer } from "./legal.styles";
import SettingsLayout from "../../../components/layout/SettingsLayout/settingsLayout";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

const Legal = () => {
  const [readMore, setReadMore] = useState(false);
  return (
    <SettingsLayout>
      <LegalContainer>
        <FlexibleDiv className="privacy__main__wrap">
          <h2>Privacy Policy</h2>
          <br />
          <br />

          <p>
            Welcome to One Identity.
            <br /> <br />
            One Identity (“us”, “we”, or “our”) operates https://oneidtech.com
            (hereinafter referred to as"Service").
            <br /> <br />
            Our Privacy Policy governs your visit to https://oneidtech.com, and
            explains how we collect, safeguard and disclose information that
            results from your use of our Service.
            <br /> <br />
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
            <br /> <br />
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).{" "}
            {!readMore && (
              <span
                className="read__more"
                onClick={() => setReadMore(!readMore)}
              >
                <b>Read more...</b>
              </span>
            )}
          </p>
          {!!readMore && (
            <>
              <br />
              <br />
              <br />
              <h2>Definitions</h2>
              <ul>
                <li className="bullet">
                  <b>Service </b> is the https://oneidtech.com website operated
                  by One Identity.
                </li>
                <li className="bullet">
                  <b>Personal </b> Data is data about a living individual who
                  can be identified from those data (or from those and other
                  information either in our possession or likely to come into
                  our possession).
                </li>
                <li className="bullet">
                  <b>Usage Data </b> is data collected automatically either
                  generated by the use of Service or from Service infrastructure
                  itself (for example, the duration of a page visit).
                </li>
                <li className="bullet">
                  <b>Cookies</b> are small files stored on your device (computer
                  or mobile device)
                </li>
                <li className="bullet">
                  <b>Data Controller</b> is a natural or legal person who
                  (either alone or jointly or in common with other persons)
                  determines the purposes for which and the manner in which any
                  personal data are, or are to be, processed. For the purpose of
                  this Privacy Policy, we are a Data Controller of your data.
                </li>
                <li className="bullet">
                  <b>Data PROCESSORS</b> (OR SERVICE PROVIDERS) is any natural
                  or legal person who processes the data on behalf of the Data
                  Controller. We may use the services of various Service
                  Providers in order to process your data more effectively.
                </li>
                <li className="bullet">
                  <b> Data Subject</b> is any living individual who is the
                  subject of Personal Data.
                </li>
                <li className="bullet">
                  <b>The User</b> is the individual using our Service. The User
                  corresponds to the Data Subject, who is the subject of
                  Personal Data.
                </li>
              </ul>
              <br />
              <br />
              <h2>Information Collection and Use </h2>
              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
              <br />
              <br />
              <h2>Types of Data Collected</h2>
              <h3>Personal Data</h3>
              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (“Personal Data”). Personally
                identifiable information may include, but is not limited to:
              </p>
              <ul>
                <li className="bullet nospacebullet">Email Address</li>
                <li className="bullet nospacebullet">
                  First name and last name
                </li>
                <li className="bullet nospacebullet">
                  Address, State, Province, ZIP/Postal code, City
                </li>
                <li className="bullet nospacebullet">Cookies and Usage Data</li>
              </ul>
              <p>
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link or by emailing at{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto: support@oneidtech.com"
                >
                  support@oneidtech.com.
                </a>
              </p>
              <br />
              <br />
              <h2>Usage Data</h2>
              <br />
              <p>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through a
                mobile device (“Usage Data”).
                <br /> <br />
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
                <br /> <br />
                When you access Service with a mobile device, this Usage Data
                may include information such as the type of mobile device you
                use, your mobile device unique ID, the IP address of your mobile
                device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic data.
                <br /> <br />
                <br />
              </p>
              <h2>Location Data</h2>
              <br />
              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
                <br /> <br />
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
                <br /> <br />
                <br />
              </p>
              <h2>Tracking Cookies Data</h2>
              <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
                <br /> <br />
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
                <br /> <br />
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
                <br /> <br />
                Examples of Cookies we use:
              </p>
              <ul>
                <li className="bullet">
                  <b> Session Cookies.</b> We use Session Cookies to operate our
                  Service.
                </li>
                <li className="bullet">
                  <b>Preference Cookies.</b> We use Preference Cookies to
                  remember your preferences and various settings.
                </li>
                <li className="bullet">
                  <b>Advertising Cookies.</b> Advertising Cookies are used to
                  serve you with advertisements that may be relevant to you and
                  your interests.
                </li>
              </ul>
              <br /> <br />
              <h2>Other Data</h2>
              <br />
              <p>
                While using our Service, we may also collect the following
                information: sex, age, date of birth, place of birth, passport
                details, citizenship, registration at place of residence and
                actual address, telephone number (work, mobile), details of
                documents on education, qualification, professional training,
                employment agreements, non-disclosure agreements, information on
                bonuses and compensation, information on marital status, family
                members, social security (or other taxpayer identification)
                number, office location and other data.
              </p>
              <br /> <br />
              <h2>Use of Data</h2>
              <p>One Identity uses the collected data for various purposes:</p>
              <ul>
                <li className="nospacebullet">
                  To provide and maintain our Service.
                </li>
                <li className="nospacebullet">
                  To notify you about changes to our Service.
                </li>
                <li className="nospacebullet">
                  To allow you to participate in interactive features of our
                  Service when you choose to do so.
                </li>

                <li className="nospacebullet">To provide customer support.</li>
                <li className="nospacebullet">
                  To gather analysis or valuable information so that we can
                  improve our Service.
                </li>
                <li className="nospacebullet">
                  To monitor the usage of our Service.
                </li>
                <li className="nospacebullet">
                  To detect, prevent and address technical issues.
                </li>
                <li className="nospacebullet">
                  To fulfill any other purpose for which you provide it.
                </li>
                <li className="nospacebullet">
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection.
                </li>

                <li className="nospacebullet">
                  To provide you with notices about your account and/or
                  subscription, including expiration and renewal notices,
                  email-instructions, etc.
                </li>
                <li className="nospacebullet">
                  To provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information.
                </li>

                <li className="nospacebullet">
                  In any other way we may describe when you provide the
                  information
                </li>
                <li className="nospacebullet">
                  For any other purpose with your consent.
                </li>
              </ul>
              <br /> <br />
              <h2>Retention Of Data</h2>
              <br />
              <p>
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                <br /> <br />
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>
              <br /> <br /> <br />
              <h2>Transfer Of Data</h2>
              <br />
              <p>
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
                <br />
                <br />
                If you are located outside Nigeria and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to Nigeria and process it there. Your
                consent to this Privacy Policy followed by your submission of
                such information represents your agreement to that transfer.
                <br /> <br />
                One Identity will take all the steps reasonably necessary to
                ensure that your data is treated securely and in accordance with
                this Privacy Policy and no transfer of your Personal Data will
                take place to an organisation or a country unless there are
                adequate controls in place including the security of your data
                and other personal information.
              </p>
              <br /> <br /> <br />
              <h2>Disclosure Of Data</h2>
              <h3>Disclosure for Law Enforcement</h3>
              <p>
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities.
              </p>
              <h3>Business Transaction</h3>
              <p>
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
              </p>
              <br />
              <h3>Other cases. We may disclose your information also:</h3>
              <ul>
                <li className="nospacebullet">
                  To our subsidiaries and affiliates
                </li>
                <li className="nospacebullet">
                  To contractors, service providers, and other third parties we
                  use to support our business;
                </li>
                <li className="nospacebullet">
                  To fulfill the purpose for which you provide it;
                </li>
                <li className="nospacebullet">
                  For any other purpose disclosed by us when you provide the
                  information;
                </li>
                <li className="nospacebullet">
                  With your consent in any other cases;
                </li>
                <li className="nospacebullet">
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers, or others.
                </li>
              </ul>
              <br /> <br /> <br />
              <h2>Security of Data</h2>
              <br />
              <p>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <br /> <br /> <br />
              <h2>
                Your Data Protection Rights Under Nigeria Data- Protection
                Regulation (GDPR
              </h2>
              <br />
              <p>
                If you are a resident of the European Union (EU) and European
                Economic Area (EEA), you have certain data protection rights,
                covered by GDPR. – See more at{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://eur-lex.europa.eu/eli/reg/2016/679/oj"
                  className="link"
                >
                  https://eur-lex.europa.eu/eli/reg/2016/679/oj
                </a>
              </p>
              <br />
              <p>
                We aim to take reasonable steps to allow you to correct, amend,
                delete, or limit the use of your Personal Data. If you wish to
                be informed what Personal Data we hold about you and you want it
                to be removed from our systems, please email us at
                support@oneidtech.com.
                <br /> <br />
                In certain circumstances, you have the following data protection
                rights:
              </p>
              <br />
              <ul>
                <li className="bullet">
                  <b>
                    The right to access, update or to delete the information we
                    have on you;
                  </b>
                </li>
                <li className="bullet">
                  <b>The right of rectification.</b>
                  You have the right to have your information rectified if that
                  information is inaccurate or incomplete
                </li>
                <li className="bullet">
                  <b>The right to object.</b>
                  You have the right to object to our processing of your
                  Personal Data;
                </li>
                <li className="bullet">
                  <b> The right of restriction.</b>
                  You have the right to request that we restrict the processing
                  of your personal information;
                </li>
                <li className="bullet">
                  <b> The right to data portability.</b>
                  You have the right to be provided with a copy of your Personal
                  Data in a structured, machine-readable and commonly used
                  format;
                </li>
                <li className="bullet">
                  <b> The right to withdraw consent.</b>
                  You also have the right to withdraw your consent at any time
                  where we rely on your consent to process your personal
                  information
                </li>
              </ul>
              <br />
              <p>
                Please note that we may ask you to verify your identity before
                responding to such requests. Please note, we may not able to
                provide Service without some necessary data.
                <br /> <br />
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </p>
              <br /> <br /> <br />
              <h2>
                Your Data Protection Rights under the California Privacy
                Protection Act (CalOPPA)
              </h2>
              <br />
              <p>
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivable the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/"
                >
                  https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                </a>
              </p>
              <br />
              <p>According to CalOPPA we agree to the following:</p>
              <ul>
                <li className="nospacebullet">
                  Users can visit our site anonymously.
                </li>
                <li className="nospacebullet">
                  Our Privacy Policy link includes the word “Privacy”, and can
                  easily be found on the page specified above on the home page
                  of our website.
                </li>
                <li className="nospacebullet">
                  Users will be notified of any privacy policy changes on our
                  Privacy Policy Page.
                </li>
                <li className="nospacebullet">
                  Users are able to change their personal information by
                  emailing us at{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto: support@oneidtech.com"
                  >
                    support@oneidtech.com.
                  </a>
                </li>
              </ul>
              <br /> <br />
              <h2>Our Policy on “Do Not Track” Signals</h2>
              <br />
              <p>
                We honor Do Not Track signals and do not track, plant cookies,
                or use advertising when a Do Not Track browser mechanism is in
                place. Do Not Track is a preference you can set in your web
                browser to inform websites that you do not want to be tracked.
                <br /> <br />
                You can enable or disable Do Not Track by visiting the
                Preferences or Settings page of your web browser.
              </p>
              <br /> <br /> <br />
              <h2>Service Providers</h2>
              <br />
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (“Service Providers”), provide Service on
                our behalf, perform Service-related services or assist us in
                analysing how our Service is used. These third parties have
                access to your Personal Data only to perform these tasks on our
                behalf and are obligated not to disclose or use it for any other
                purpose.
              </p>
              <br /> <br /> <br />
              <h2>Analytics</h2>
              <br />
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <br />
              <ul>
                <li className="bullet">
                  <b>Google Analytics</b>. Google Analytics is a web analytics
                  service offered by Google that tracks and reports website
                  traffic. Google uses the data collected to track and monitor
                  the use of our Service. This data is shared with other Google
                  services. Google may use the collected data to contextualise
                  and personalise the ads of its own advertising network.
                  <br /> <br />
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en"
                    className="link"
                  >
                    https://policies.google.com/privacy?hl=en
                  </a>
                  We also encourage you to review the Google's policy for
                  safeguarding your data:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.google.com/analytics/answer/6004245"
                    className="link"
                  >
                    https://support.google.com/analytics/answer/6004245
                  </a>
                </li>

                <li className="bullet">
                  <b>Firebase.</b> Firebase is analytics service provided by
                  Google Inc. You may opt-out of certain Firebase features
                  through your mobile device settings, such as your device
                  advertising settings or by following the instructions provided
                  by Google in their Privacy Policy:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en"
                    className="link"
                  >
                    https://policies.google.com/privacy?hl=en
                  </a>
                  <br /> <br />
                  For more information on what type of information Firebase
                  collects, please visit the Google Privacy Terms web page:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy?hl=en"
                    className="link"
                  >
                    https://policies.google.com/privacy?hl=en
                  </a>
                </li>
                <li className="bullet">
                  <b> Cloudflare analytics</b> Cloudflare analytics is a web
                  analytics service operated by Cloudflare Inc. Read the Privacy
                  Policy here:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cloudflare.com/privacypolicy/"
                    className="link"
                  >
                    https://www.cloudflare.com/privacypolicy/
                  </a>
                </li>
                <li className="bullet">
                  <b> Cookies</b> are small files stored on your device
                  (computer or mobile device)
                </li>
                <li className="bullet">
                  <b> Mixpanel</b> . Mixpanel is provided by Mixpanel Inc. You
                  can prevent Mixpanel from using your information for analytics
                  purposes by opting-out. To opt-out of Mixpanel service, please
                  visit this page:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://mixpanel.com/optout/"
                    className="link"
                  >
                    https://mixpanel.com/optout/
                  </a>
                  <br /> <br />
                  For more information on what type of information Mixpanel
                  collects, please visit the Terms of Use page of Mixpanel:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://mixpanel.com/terms/"
                    className="link"
                  >
                    https://mixpanel.com/terms/
                  </a>
                </li>
              </ul>
              <br /> <br /> <br />
              <h2>CI/CD tools</h2>
              <br />
              <p>
                We may use third-party Service Providers to automate the
                development process of our Service.
              </p>
              <br /> <br />
              <p>
                <b>GitHub</b>. Github is provided by GitHub, Inc. It is a
                development platform to host and review code, manage projects,
                and build software.
                <br /> <br />
                For more information on what data GitHub collects for what
                purpose and how the protection of the data is ensured, please
                visit GitHub Privacy Policy page:
                <a
                  href="https://help.github.com/en/articles/github-privacy-statement"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  https://help.github.com/en/articles/github-privacy-statement.
                </a>
              </p>
              <br /> <br />
              <p>
                <b>Twitter</b>. Twitter remarketing service is provided by
                Twitter Inc. You can opt-out from Twitter's interest-based ads
                by following their instructions:
                <a
                  href="https://support.twitter.com/articles/20170405"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  https://support.twitter.com/articles/20170405
                </a>
                <br /> <br />
                You can learn more about the privacy practices and policies of
                Twitter by visiting their Privacy Policy page:
                <a
                  href="https://twitter.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  https://twitter.com/privacy
                </a>
              </p>
              <br /> <br /> <br />
              <h2>Payments</h2>
              <br />
              <p>
                We may provide paid products and/or services within Service. In
                that case, we use third-party services for payment processing
                (e.g. payment processors).
                <br /> <br />
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
                <br /> <br />
                The payment processors we work with are:
                <br />
                <b>PayPal or Braintree.</b> Their Privacy Policy can be viewed
                at{" "}
                <a
                  className="link"
                  href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.paypal.com/webapps/mpp/ua/privacy-full
                </a>
              </p>
              <br /> <br /> <br />
              <h2>Links to Other Sites</h2>
              <br />
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
                <br /> <br />
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <br /> <br /> <br />
              <h2>Children's Privacy</h2>
              <br />
              <p>
                Our Services are not intended for use by children under the age
                of 13 (“Children”).
                <br /> <br />
                We do not knowingly collect personally identifiable information
                from Children under 13. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that ce have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
              <br /> <br /> <br />
              <h2>Changes to This Privacy Policy</h2>
              <br />
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
                <br /> <br />
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
                <br /> <br />
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <br /> <br /> <br />
              <h2>Contact Us</h2>
              <br />
              <p>
                f you have any questions about this Privacy Policy, please
                contact us:
              </p>
              <br />
              <ul>
                <li className="bullet">
                  By Email.{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="support@oneidtech.com"
                  >
                    support@oneidtech.com
                  </a>
                </li>
                <li className="bullet">
                  By visiting this page on our website.
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://oneidtech.com/contact-us"
                  >
                    https://oneidtech.com/contact-us
                  </a>
                </li>
                <li className="bullet">
                  By phone number. <a href="tel:  08023319400"> 08023319400</a>
                </li>
              </ul>
            </>
          )}
        </FlexibleDiv>
      </LegalContainer>
    </SettingsLayout>
  );
};

export default Legal;
