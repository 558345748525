import { TextField } from "components"
import { useState } from "react"
import { MdOutlineKeyboardArrowDown as ArrowDown } from "react-icons/md"
import EmailIcon from "../../../assets/images/help/email.svg"
import SettingsLayout from "../../../components/layout/SettingsLayout/settingsLayout"
import { FlexibleDiv } from "../../../components/lib/Box/box.styles"
import { helpData } from "../../../utils/data-helpers/help"
import { HelpContainer } from "./help.styles"

const Help = () => {
  const [openTab, setOpenTab] = useState("")
  const [openFaq, setOpenFaq] = useState("")
  const [showEmail, setShowEmail] = useState(false)

  const handleTab = (value: string) => {
    if (openTab === value) {
      setOpenTab("")
    } else {
      setOpenTab(value)
    }
  }

  const handleFaq = (value: string) => {
    if (openFaq === value) {
      setOpenFaq("")
    } else {
      setOpenFaq(value)
    }
  }

  return (
    <SettingsLayout>
      <HelpContainer showEmail={showEmail}>
        <FlexibleDiv className="help__main__wrap">
          <h3 className="helpheader__title">Help Center</h3>
          <FlexibleDiv margin="15px 0 30px 0" className="search__wrap">
            <TextField
              textFieldProps={{
                type: "text",
                label: "Type your question here...",
              }}
              search
            />
          </FlexibleDiv>

          <FlexibleDiv className="content__wrap">
            {helpData.map((item, idx) => (
              <FlexibleDiv
                className="help__card"
                id={openTab === item.title ? "active__card" : ""}
                key={idx}
              >
                <FlexibleDiv className="close__card">
                  <FlexibleDiv
                    className="helpcard__header"
                    onClick={() => handleTab(item.title)}
                  >
                    <img src={item.icon} alt="" />
                    <h3 className="card__title">{item.title}</h3>
                  </FlexibleDiv>

                  <p className="decription">{item.description}</p>
                </FlexibleDiv>
                {item.title === openTab && (
                  <FlexibleDiv className="slabs__wrap">
                    {item.faqs.map((faq, faqIndex) => (
                      <FlexibleDiv className="faq__wrap" key={faqIndex}>
                        <FlexibleDiv className="faq__textsection">
                          <h4 className="faq__title">{faq.question}</h4>
                          <div
                            className="arrow__wrap"
                            onClick={() => handleFaq(faq.question)}
                          >
                            <ArrowDown />
                          </div>
                        </FlexibleDiv>
                        {faq.question === openFaq && item.title === openTab && (
                          <p className="faq__subtitle">{faq.answer}</p>
                        )}
                      </FlexibleDiv>
                    ))}
                  </FlexibleDiv>
                )}
              </FlexibleDiv>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv className="answer__card">
          <h3 className="email__question">Didn’t find an answer?</h3>
          <FlexibleDiv className="email__header">
            <img src={EmailIcon} alt="" />
            <h2 className="email__title">Send us an email</h2>
          </FlexibleDiv>
          <p className="email__description">
            If you did not find an answer here, you can always email us and we
            will see what we can do to help.
          </p>
        </FlexibleDiv>
        <h1 className="popup__wrap" onClick={() => setShowEmail(!showEmail)}>
          ?
        </h1>
      </HelpContainer>
    </SettingsLayout>
  )
}

export default Help
