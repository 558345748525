import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SocialMediaFragmentFragment = { __typename?: 'SocialMedia', instagram?: string | null, facebook?: string | null, twitter?: string | null, linkedin?: string | null };

export const SocialMediaFragmentFragmentDoc = gql`
    fragment socialMediaFragment on SocialMedia {
  instagram
  facebook
  twitter
  linkedin
}
    `;