import * as Types from "../../../../../types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UserChangePasswordMutationVariables = Types.Exact<{
  oldPassword: Types.Scalars["String"];
  newPassword: Types.Scalars["String"];
}>;

export type UserChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword?: string | null;
};

export const UserChangePasswordDocument = gql`
  mutation userChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(
      data: { oldPassword: $oldPassword, newPassword: $newPassword }
    )
  }
`;
export type UserChangePasswordMutationFn = Apollo.MutationFunction<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
>;

/**
 * __useUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangePasswordMutation, { data, loading, error }] = useUserChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserChangePasswordMutation,
    UserChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserChangePasswordMutation,
    UserChangePasswordMutationVariables
  >(UserChangePasswordDocument, options);
}
export type UserChangePasswordMutationHookResult = ReturnType<
  typeof useUserChangePasswordMutation
>;
export type UserChangePasswordMutationResult =
  Apollo.MutationResult<UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  UserChangePasswordMutation,
  UserChangePasswordMutationVariables
>;
