import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserVerifyEmailMutationVariables = Types.Exact<{
  emailToken: Types.Scalars['String'];
}>;


export type UserVerifyEmailMutation = { __typename?: 'Mutation', verifyEmail: string };


export const UserVerifyEmailDocument = gql`
    mutation userVerifyEmail($emailToken: String!) {
  verifyEmail(emailToken: $emailToken)
}
    `;
export type UserVerifyEmailMutationFn = Apollo.MutationFunction<UserVerifyEmailMutation, UserVerifyEmailMutationVariables>;

/**
 * __useUserVerifyEmailMutation__
 *
 * To run a mutation, you first call `useUserVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userVerifyEmailMutation, { data, loading, error }] = useUserVerifyEmailMutation({
 *   variables: {
 *      emailToken: // value for 'emailToken'
 *   },
 * });
 */
export function useUserVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<UserVerifyEmailMutation, UserVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserVerifyEmailMutation, UserVerifyEmailMutationVariables>(UserVerifyEmailDocument, options);
      }
export type UserVerifyEmailMutationHookResult = ReturnType<typeof useUserVerifyEmailMutation>;
export type UserVerifyEmailMutationResult = Apollo.MutationResult<UserVerifyEmailMutation>;
export type UserVerifyEmailMutationOptions = Apollo.BaseMutationOptions<UserVerifyEmailMutation, UserVerifyEmailMutationVariables>;