import TextField from "@mui/material/TextField"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import React from "react"
import { FlexibleDiv } from "../../Box/box.styles"
import { DatePickerContainer } from "./datepicker.styles"

interface IProps {
  label: string
  value: string
  name: string
  disabled?: boolean
  handleChange(e: any): void
}

export const Datepicker: React.FC<IProps> = ({
  label,
  name,
  value,
  disabled,
  handleChange,
}) => {
  return (
    <DatePickerContainer>
      {disabled && (
        <FlexibleDiv justifyContent="flex-start" margin="0 0 10px 0">
          <span className="disabled__label">{label}</span>
        </FlexibleDiv>
      )}

      <DesktopDatePicker
        value={value}
        onChange={handleChange}
        inputFormat="dd MMM, yyyy"
        renderInput={(params: any) => (
          <TextField
            {...params}
            helperText={""}
            inputProps={{
              ...params.inputProps,
              // placeholder: label,
            }}
          />
        )}
        disabled={disabled}
      />
    </DatePickerContainer>
  )
}
