import React, { useState } from "react";
import { NotificationSettingsContainer } from "./notificationSettings.styles";
import SettingsLayout from "../../../components/layout/SettingsLayout/settingsLayout";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";
import OIDSwitch from "../../../components/lib/Switch/switch";
import SuccessModal from "../../../components/lib/SuccessModal/successModal";
import ChangeEmailModal from "./Modals/ChangeEmail/changeEmail";
import ChangePhoneNumberModal from "./Modals/ChangePhoneNumber/changePhoneNumbef";

const NotificationSettings = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openPhoneModal, setOpenPhoneModal] = useState(false);

  return (
    <SettingsLayout>
      <NotificationSettingsContainer>
        <FlexibleDiv className="privacy__main__wrap">
          <span className="header__text top__header">
            Update Notification Email & Phone No
          </span>
          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            className="body__textwrap"
          >
            <h4
              className="body__textheader"
              onClick={() => setOpenEmailModal(true)}
            >
              Change notification email
            </h4>
            <p className="body__textsubheader">
              Change and update your notification email address
            </p>
          </FlexibleDiv>
          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            className="body__textwrap"
          >
            <h4
              className="body__textheader"
              onClick={() => setOpenPhoneModal(true)}
            >
              Change notification phone no
            </h4>
            <p className="body__textsubheader">
              Change and update your notification phone number
            </p>
          </FlexibleDiv>
          <br />
          <span className="header__text">Notify Me On The Following;</span>

          <FlexibleDiv
            justifyContent="space-between"
            flexWrap="nowrap"
            className="body__textwrap switch__wrap"
          >
            <h4 className="body__textheader">New Access Requests</h4>

            <OIDSwitch />
          </FlexibleDiv>
          <FlexibleDiv
            justifyContent="space-between"
            flexWrap="nowrap"
            className="body__textwrap switch__wrap"
          >
            <h4 className="body__textheader">Pending Access Requests</h4>

            <OIDSwitch />
          </FlexibleDiv>
          <FlexibleDiv
            justifyContent="space-between"
            flexWrap="nowrap"
            className="body__textwrap switch__wrap"
          >
            <h4 className="body__textheader">Access ativities by 3rd party</h4>

            <OIDSwitch />
          </FlexibleDiv>
          <FlexibleDiv
            justifyContent="space-between"
            flexWrap="nowrap"
            className="body__textwrap switch__wrap"
          >
            <h4 className="body__textheader">Account updates</h4>

            <OIDSwitch />
          </FlexibleDiv>
        </FlexibleDiv>
      </NotificationSettingsContainer>

      <ChangeEmailModal
        open={openEmailModal}
        close={setOpenEmailModal}
        showSuccessModal={setShowSuccessModal}
        setSuccessText={setSuccessText}
      />

      <ChangePhoneNumberModal
        open={openPhoneModal}
        close={setOpenPhoneModal}
        showSuccessModal={setShowSuccessModal}
        setSuccessText={setSuccessText}
      />

      <SuccessModal
        open={showSuccessModal}
        close={setShowSuccessModal}
        text={successText}
      />
    </SettingsLayout>
  );
};

export default NotificationSettings;
