import React from "react";
import { AccountSettingsContainer } from "./account.styles";
import SettingsLayout from "../../../components/layout/SettingsLayout/settingsLayout";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowRight as ArrowRight } from "react-icons/md";
import { Config } from "config";
import DeleteAccountModal from "./Sections/DeleteAccounModal/deleteAccountModal";

const AccountSettings = () => {
  const navigate = useNavigate();
  const [showDeleteAccountModal, setShowDeleteAccountModal] =
    React.useState(false);

  const signout = () => {
    localStorage.removeItem("1D_AUR");
    window.location.href = `${Config.AUTH_GATEWAY_URL}`;
  };

  return (
    <SettingsLayout>
      <AccountSettingsContainer>
        <FlexibleDiv className="account__main__wrap">
          <FlexibleDiv className="account__subwrap">
            <FlexibleDiv className="account__subwrap__textsection">
              <h4 className="accountconttent__title">Change Password</h4>
              <p className="accountconttent__subtitle">
                Change your old password to a new one
              </p>
            </FlexibleDiv>
            <div
              className="arrow__wrap"
              onClick={() => navigate("/settings/change-password")}
            >
              <ArrowRight />
            </div>
          </FlexibleDiv>

          <FlexibleDiv className="account__subwrap">
            <FlexibleDiv className="account__subwrap__textsection">
              <h4 className="accountconttent__title">Sign Out</h4>
              <p className="accountconttent__subtitle">
                Sign out of your OneID account
              </p>
            </FlexibleDiv>
            <div className="arrow__wrap" onClick={signout}>
              <ArrowRight />
            </div>
          </FlexibleDiv>

          <FlexibleDiv className="account__subwrap">
            <FlexibleDiv className="account__subwrap__textsection">
              <h4 className="accountconttent__title">
                Deactivate Your Account
              </h4>
              <p className="accountconttent__subtitle">
                This action deletes your existing account with OneID
              </p>
            </FlexibleDiv>
            <div
              className="arrow__wrap"
              onClick={() => setShowDeleteAccountModal(true)}
            >
              <ArrowRight />
            </div>
          </FlexibleDiv>
        </FlexibleDiv>
      </AccountSettingsContainer>
      <DeleteAccountModal
        open={showDeleteAccountModal}
        setShowDeleteAccountModal={setShowDeleteAccountModal}
      />
    </SettingsLayout>
  );
};

export default AccountSettings;
