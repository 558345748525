import {
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components"
import { useState } from "react"
import { AiFillExclamationCircle as InfoIcon } from "react-icons/ai"
import { UpdateProfileLayoutContainer } from "../../../../components/layout/UpdateProfileLayout/updateProfile.styles"
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles"
import { CustomForm } from "components"
import KycFailedModal from "./Modals/failedVerification"
import KycSuccessModal from "./Modals/successfulVerification"
import { useVerifyUserBvnMutation } from "../gql/__types/UpdateProfile.types"
import { getErrorMessage } from "common"
import * as Yup from "yup"
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types"

const validationSchema = Yup.object().shape({
  bvn: Yup.string().min(10).nullable(),
})

const KYCUpdate = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFailedModal, setShowFailedModal] = useState(false)
  const [mutate, { loading }] = useVerifyUserBvnMutation()
  const { data, refetch, loading: profileLoading } = useGetProfileDataQuery()
  const [hideFormWhileRefetch, sethideFormWhileRefetch] = useState(true)
  const profile = data && data.userGetProfile

  const initialValues = {
    bvn: profile?.bvn || "",
  }

  const formDetails = [
    {
      name: "bvn",
      type: "text",
      width: "48%",
      label: "Enter BVN",
    },
  ]

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    try {
      const { data } = await mutate({
        variables: values,
      })
      if (data?.verifyBvn?.status === false) {
        setShowFailedModal(true)
      } else {
        setShowSuccessModal(true)
      }
      setSubmitting(false)
      sethideFormWhileRefetch(false)
      await refetch()
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <DashboardLayout headerText="Update Profile">
      <GoBack />

      {loading && <Progress position="relative" />}

      {profileLoading && !!hideFormWhileRefetch ? (
        <Progress position="relative" />
      ) : (
        <UpdateProfileLayoutContainer
          className="updateprofile__main__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <p className="form__title">KYC Details</p>

          <FlexibleDiv className="kyc__info__card" flexWrap="nowrap">
            <InfoIcon />
            <span className="kyc__info__card__text">
              Your BVN will be used to ONLY verify your full name & date of
              birth.
            </span>
          </FlexibleDiv>
          <CustomForm
            formDetails={formDetails}
            formFinishButtonText={"Save Details"}
            submit={submit}
            validationSchema={validationSchema}
            initialValues={initialValues}
          />

          <KycFailedModal open={showFailedModal} close={setShowFailedModal} />

          <KycSuccessModal
            open={showSuccessModal}
            close={setShowSuccessModal}
          />
        </UpdateProfileLayoutContainer>
      )}
    </DashboardLayout>
  )
}

export default KYCUpdate
