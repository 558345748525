import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserProfileMutationVariables = Types.Exact<{
  bvn?: Types.InputMaybe<Types.Scalars['String']>;
  nin?: Types.InputMaybe<Types.Scalars['String']>;
  lga?: Types.InputMaybe<Types.Scalars['String']>;
  phone?: Types.InputMaybe<Types.Scalars['String']>;
  dob?: Types.InputMaybe<Types.Scalars['DateTime']>;
  gender?: Types.InputMaybe<Types.Scalars['String']>;
  country?: Types.InputMaybe<Types.Scalars['String']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']>;
  middleName?: Types.InputMaybe<Types.Scalars['String']>;
  firstName: Types.Scalars['String'];
  postalCode?: Types.InputMaybe<Types.Scalars['String']>;
  nationality?: Types.InputMaybe<Types.Scalars['String']>;
  fathersName?: Types.InputMaybe<Types.Scalars['String']>;
  mothersName?: Types.InputMaybe<Types.Scalars['String']>;
  nextOfKin?: Types.InputMaybe<Types.NextOfKinInput>;
  stateOfOrigin?: Types.InputMaybe<Types.Scalars['String']>;
  maritalStatus?: Types.InputMaybe<Types.Scalars['String']>;
  parentsAddress?: Types.InputMaybe<Types.Scalars['String']>;
  primaryAddress?: Types.InputMaybe<Types.Scalars['String']>;
  secondaryEmail?: Types.InputMaybe<Types.Scalars['String']>;
  secondaryAddress?: Types.InputMaybe<Types.Scalars['String']>;
  employmentStatus?: Types.InputMaybe<Types.Scalars['String']>;
  stateOfResidence?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', userCreateProfile: string };

export type UpdateUserEmergencyContactMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  phone: Types.Scalars['String'];
  email: Types.Scalars['String'];
  address: Types.Scalars['String'];
  relationship: Types.Scalars['String'];
}>;


export type UpdateUserEmergencyContactMutation = { __typename?: 'Mutation', userAddEmergencyContact: string };

export type UpdateUserSocialMediaMutationVariables = Types.Exact<{
  twitter?: Types.InputMaybe<Types.Scalars['String']>;
  linkedin?: Types.InputMaybe<Types.Scalars['String']>;
  facebook?: Types.InputMaybe<Types.Scalars['String']>;
  instagram?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateUserSocialMediaMutation = { __typename?: 'Mutation', updateSocialMedia: string };

export type VerifyUserBvnMutationVariables = Types.Exact<{
  bvn: Types.Scalars['String'];
}>;


export type VerifyUserBvnMutation = { __typename?: 'Mutation', verifyBvn?: any | null };


export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($bvn: String, $nin: String, $lga: String, $phone: String, $dob: DateTime, $gender: String, $country: String, $lastName: String, $middleName: String, $firstName: String!, $postalCode: String, $nationality: String, $fathersName: String, $mothersName: String, $nextOfKin: nextOfKinInput, $stateOfOrigin: String, $maritalStatus: String, $parentsAddress: String, $primaryAddress: String, $secondaryEmail: String, $secondaryAddress: String, $employmentStatus: String, $stateOfResidence: String) {
  userCreateProfile(
    data: {bvn: $bvn, nin: $nin, dob: $dob, lga: $lga, phone: $phone, gender: $gender, country: $country, lastName: $lastName, firstName: $firstName, middleName: $middleName, nextOfKin: $nextOfKin, postalCode: $postalCode, nationality: $nationality, fathersName: $fathersName, mothersName: $mothersName, maritalStatus: $maritalStatus, stateOfOrigin: $stateOfOrigin, parentsAddress: $parentsAddress, secondaryEmail: $secondaryEmail, primaryAddress: $primaryAddress, secondaryAddress: $secondaryAddress, stateOfResidence: $stateOfResidence, employmentStatus: $employmentStatus}
  )
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      bvn: // value for 'bvn'
 *      nin: // value for 'nin'
 *      lga: // value for 'lga'
 *      phone: // value for 'phone'
 *      dob: // value for 'dob'
 *      gender: // value for 'gender'
 *      country: // value for 'country'
 *      lastName: // value for 'lastName'
 *      middleName: // value for 'middleName'
 *      firstName: // value for 'firstName'
 *      postalCode: // value for 'postalCode'
 *      nationality: // value for 'nationality'
 *      fathersName: // value for 'fathersName'
 *      mothersName: // value for 'mothersName'
 *      nextOfKin: // value for 'nextOfKin'
 *      stateOfOrigin: // value for 'stateOfOrigin'
 *      maritalStatus: // value for 'maritalStatus'
 *      parentsAddress: // value for 'parentsAddress'
 *      primaryAddress: // value for 'primaryAddress'
 *      secondaryEmail: // value for 'secondaryEmail'
 *      secondaryAddress: // value for 'secondaryAddress'
 *      employmentStatus: // value for 'employmentStatus'
 *      stateOfResidence: // value for 'stateOfResidence'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const UpdateUserEmergencyContactDocument = gql`
    mutation UpdateUserEmergencyContact($name: String!, $phone: String!, $email: String!, $address: String!, $relationship: String!) {
  userAddEmergencyContact(
    data: {name: $name, email: $email, phone: $phone, address: $address, relationship: $relationship}
  )
}
    `;
export type UpdateUserEmergencyContactMutationFn = Apollo.MutationFunction<UpdateUserEmergencyContactMutation, UpdateUserEmergencyContactMutationVariables>;

/**
 * __useUpdateUserEmergencyContactMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmergencyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmergencyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmergencyContactMutation, { data, loading, error }] = useUpdateUserEmergencyContactMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      address: // value for 'address'
 *      relationship: // value for 'relationship'
 *   },
 * });
 */
export function useUpdateUserEmergencyContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEmergencyContactMutation, UpdateUserEmergencyContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEmergencyContactMutation, UpdateUserEmergencyContactMutationVariables>(UpdateUserEmergencyContactDocument, options);
      }
export type UpdateUserEmergencyContactMutationHookResult = ReturnType<typeof useUpdateUserEmergencyContactMutation>;
export type UpdateUserEmergencyContactMutationResult = Apollo.MutationResult<UpdateUserEmergencyContactMutation>;
export type UpdateUserEmergencyContactMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmergencyContactMutation, UpdateUserEmergencyContactMutationVariables>;
export const UpdateUserSocialMediaDocument = gql`
    mutation UpdateUserSocialMedia($twitter: String, $linkedin: String, $facebook: String, $instagram: String) {
  updateSocialMedia(
    data: {twitter: $twitter, linkedin: $linkedin, facebook: $facebook, instagram: $instagram}
  )
}
    `;
export type UpdateUserSocialMediaMutationFn = Apollo.MutationFunction<UpdateUserSocialMediaMutation, UpdateUserSocialMediaMutationVariables>;

/**
 * __useUpdateUserSocialMediaMutation__
 *
 * To run a mutation, you first call `useUpdateUserSocialMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSocialMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSocialMediaMutation, { data, loading, error }] = useUpdateUserSocialMediaMutation({
 *   variables: {
 *      twitter: // value for 'twitter'
 *      linkedin: // value for 'linkedin'
 *      facebook: // value for 'facebook'
 *      instagram: // value for 'instagram'
 *   },
 * });
 */
export function useUpdateUserSocialMediaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSocialMediaMutation, UpdateUserSocialMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSocialMediaMutation, UpdateUserSocialMediaMutationVariables>(UpdateUserSocialMediaDocument, options);
      }
export type UpdateUserSocialMediaMutationHookResult = ReturnType<typeof useUpdateUserSocialMediaMutation>;
export type UpdateUserSocialMediaMutationResult = Apollo.MutationResult<UpdateUserSocialMediaMutation>;
export type UpdateUserSocialMediaMutationOptions = Apollo.BaseMutationOptions<UpdateUserSocialMediaMutation, UpdateUserSocialMediaMutationVariables>;
export const VerifyUserBvnDocument = gql`
    mutation verifyUserBvn($bvn: String!) {
  verifyBvn(data: {bvn: $bvn})
}
    `;
export type VerifyUserBvnMutationFn = Apollo.MutationFunction<VerifyUserBvnMutation, VerifyUserBvnMutationVariables>;

/**
 * __useVerifyUserBvnMutation__
 *
 * To run a mutation, you first call `useVerifyUserBvnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserBvnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserBvnMutation, { data, loading, error }] = useVerifyUserBvnMutation({
 *   variables: {
 *      bvn: // value for 'bvn'
 *   },
 * });
 */
export function useVerifyUserBvnMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserBvnMutation, VerifyUserBvnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserBvnMutation, VerifyUserBvnMutationVariables>(VerifyUserBvnDocument, options);
      }
export type VerifyUserBvnMutationHookResult = ReturnType<typeof useVerifyUserBvnMutation>;
export type VerifyUserBvnMutationResult = Apollo.MutationResult<VerifyUserBvnMutation>;
export type VerifyUserBvnMutationOptions = Apollo.BaseMutationOptions<VerifyUserBvnMutation, VerifyUserBvnMutationVariables>;