import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import { TOASTER_TYPE, ToastMessage } from "components"
import { Config } from "config/Config"
import possibleTypes from "./possibleTypes.json"

const httpLinkOptions = { uri: Config.API_BASE_URL }

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("1D_AU") || ""
  const rToken = localStorage.getItem("1D_AUR") || ""

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token && token,
      "x-refresh-token": rToken,
    },
  }
})

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors.some(err => err.message.includes("Login to continue"))
      ) {
        localStorage.removeItem("1D_AU")
        window.location.href = `${Config.AUTH_GATEWAY_URL}?d=app&r=${window.location.pathname}`
        return
      }
      graphQLErrors.map(err => ToastMessage(TOASTER_TYPE.ERROR, err.message))
      return
    }

    if (networkError) {
      return ToastMessage(TOASTER_TYPE.ERROR, "Network Error")
    }
  }),
  authLink,
  createUploadLink(httpLinkOptions),
])

// https://www.apollographql.com/docs/react/caching/cache-configuration
export const cache = new InMemoryCache({
  typePolicies: {
    User: {
      keyFields: ["_id", "username", "email", "oneId"],
    },
  },
  possibleTypes,
})

// Setup the apollo client
export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})
