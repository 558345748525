import styled from "styled-components";
import Modal from "@mui/material/Modal";

type Custom = {
  maxWidth?: string;
};

export const ModalContainer = styled(Modal)<Custom>`
  display: flex;
  justify-content: center;
  align-items: center;

  .box__wrap {
    background: #ffffff;
    border-radius: 12px;
    padding: 30px 20px 50px 20px;
    width: 90%;
    max-width: ${({ maxWidth }) => maxWidth || "600px"};
    outline: none;
    position: relative;

    .closeIcon {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }

    .modal__headertext {
      text-transform: capitalize;
      margin: 20px 0 0 0;
      font-size: 20px;
    }
    .modal__subheadertext {
      max-width: 100%;
      font-size: 14px;
      color: #777777;
      margin: 7px 0;
    }

    .activity__wrap {
      justify-content: space-between;
      padding: 10px;
      .activity {
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 45%;
        padding: 15px;
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
        border-radius: 12px;
        margin-top: 15px;
        .actvity__text {
          font-size: 15px;
          color: #424242;
          margin: 0;
        }
      }
    }

    @media (max-width: 680px) {
      .activity__wrap {
        .activity {
          width: 100%;
        }
      }
    }
  }
`;
