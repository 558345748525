import { NoData } from "components"
import { AiOutlinePlus as Plus } from "react-icons/ai"
import { HiOutlineArrowRight as Arrow } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import {
  FlexibleDiv,
  FlexibleSection,
} from "../../../../components/lib/Box/box.styles"
import { OIDButton } from "../../../../components/lib/Button/button.styles"
import { HospitalRecordsContainer } from "./hospitalRecords.styles"

const HospitalRecords = () => {
  const navigate = useNavigate()
  const data: any = []
  return (
    <HospitalRecordsContainer flexDir="column">
      {!!data.length ? (
        <FlexibleSection
          justifyContent="space-between"
          alignItems="flex-start"
          className="hospital_wrap"
        >
          <FlexibleDiv className="medicalList__wrap" width="70%">
            <span
              className="add__medical__mobile"
              onClick={() => navigate("/add-medical-history")}
            >
              Add New
            </span>

            {data.map((item: any, idx: number) => (
              <FlexibleDiv
                alignItems="flex-start"
                flexDir="column"
                className="record__box"
              >
                <FlexibleDiv justifyContent="space-between">
                  <h4 className="medicalcard__title">{item.title}</h4>
                  <div onClick={() => navigate(`/medical/${item.title}`)}>
                    <Arrow />
                  </div>
                </FlexibleDiv>
                <p className="medicalcard__bodytext">Ailment: {item.ailment}</p>
                <p className="medicalcard__bodytext">
                  Healthcare Facility: {item.facility}
                </p>
              </FlexibleDiv>
            ))}
          </FlexibleDiv>

          <OIDButton onClick={() => navigate("/add-medical-history")}>
            <Plus />
            Add Medical Record
          </OIDButton>
        </FlexibleSection>
      ) : (
        <NoData
          noDataTitle="No Hospital Records"
          noDataMessage="Hi May, please click the button above to update your medical history"
          noDataButtonRoute="/add-medical-history"
          noDataButtonText="Add Hospital Records"
          noDataTabHeader
        />
      )}
    </HospitalRecordsContainer>
  )
}

export default HospitalRecords
