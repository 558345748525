import React from "react";
import { QRCode } from "../../../../../../assets/images/dashboardLayout/qrcode";
import { CloseIcon } from "../../../../../../assets/images/dashboardLayout/svgExports";
import { ModalContainer } from "../../../../../../components/layout/ModalLayout/modalLayout.styles";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";
import { OIDButton } from "../../../../../../components/lib/Button/button.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  setShowPinModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  setShowSetupModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  setPinMessage: (value: string | ((previousValue: string) => string)) => void;
}

const ScanCodeModal: React.FC<IProps> = ({
  open,
  close,
  setShowPinModal,
  setShowSetupModal,
  setPinMessage,
}) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    setShowPinModal(true);
    setPinMessage("Two- Factor");
  };

  const setup = () => {
    handleClose();
    setShowSetupModal(true);
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <QRCode width="180" />
        <h3 className="modal__headertext">Scan QR Code</h3>
        <p className="modal__subheadertext">
          Launch Google Authenticator on your device, then scan the QR code
          above. When you have successfully added the key above, click done.
        </p>
        <h3 className="modal__headertext">OR</h3>
        <p className="modal__subheadertext">
          Skip the QR code and set up manually using the setup key instead.
        </p>
        <FlexibleDiv justifyContent="flex-end" margin="20px 0 0 0">
          <span className="buttonSpan" onClick={setup}>
            Set Up Manually
          </span>{" "}
          <OIDButton margin="0 0 0 25px" onClick={submit}>
            Done{" "}
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default ScanCodeModal;
