import styled from "styled-components";
import { FlexibleDiv } from "../Box/box.styles";

type Custom = {
  barChartBk?: string;
};

export const BarChartMain = styled.section<Custom>`
  background: ${({ barChartBk }) => barChartBk || "#fff"};
  position: relative;
  padding: 0 20px;
  background: white;
  width: 100%;
  max-height: 400px;

  @media (max-width: 500px) {
    padding: 0 10px;
  }

  .barchart__header__wrap {
    padding: 20px 0;

    .barchart__header {
      color: #00499b;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .barchart__subheader {
      color: #272643;
      cursor: pointer;

      svg {
        margin: 0 0 -3px -1px;
      }
    }
  }
  .canvas {
    padding-bottom: 20px;
    min-width: 550px;

    canvas {
      width: 400px;
      height: 300px;
    }
  }
`;

export const PieChartWrap = styled(FlexibleDiv)`
  margin-top: 20px;
  padding: 0;
  border-radius: 28px;
  background: white;
  width: 100%;
  height: 320px;
  overflow: hidden;
  flex-wrap: nowrap;

  .piechart__headertext {
    padding: 5px 0 0 60px;
    color: #001935;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: 560px) {
      margin: 0;
      padding: 10px 0 0 20px;
    }
  }
  .canvas {
    width: 65%;
    min-width: 320px;
    height: 80%;
    canvas {
      height: 220px;
    }
  }
  @media (max-width: 760px) {
    height: 250px;
  }

  @media (max-width: 360px) {
    height: 250px;
    .canvas {
      @media (max-width: 360px) {
        min-width: 270px;
      }
    }
  }
`;
