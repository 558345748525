import styled from "styled-components";
import { TextField } from "@mui/material";

export const TextFieldContainer = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F0F4FF",
      background: "#FDFDFD",
      borderRadius: "8px",
      height: "57px",
      transition: "all .3s ease-in-out",
    },
    "& input": {
      color: "#272727",
      zIndex: "9",
      padding: "14px 0 10px 22px",
      fontSize: "14px",
      marginTop: "4px",
      height: "100%",
    },
    "&:hover fieldset": {
      borderColor: "#0B61DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0B61DD",
      borderWidth: "1px",
    },
  },

  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    background: "#F2F2F2",
    "&:hover": {
      borderColor: "transparent",
    },
    "&.Mui-focused": {
      borderColor: "",
    },
  },
});
