import { getErrorMessage } from "common";
import {
  CustomForm,
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components";
import { useMe } from "helpers";
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { UpdateProfileLayoutContainer } from "../../../../components/layout/UpdateProfileLayout/updateProfile.styles";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";
import { useUpdateUserProfileMutation } from "../gql/__types/UpdateProfile.types";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(3).required("Your first name is required"),
  middleName: Yup.string().min(3).nullable(),
  lastName: Yup.string().min(3).nullable(),
  secondaryEmail: Yup.string().email().nullable(),
  gender: Yup.string().oneOf(["Male", "Female", "others"]).nullable(),
  dob: Yup.date().nullable(),
  phone: Yup.string().min(10).nullable(),
  maritalStatus: Yup.string()
    .oneOf(["Married", "Separated", "Divorced", "Widowed", "Single"])
    .nullable(),
});

const PersonalDetailsUpdate: React.FC = () => {
  const [mutate, { loading }] = useUpdateUserProfileMutation();
  const me = useMe();
  const location = useLocation();
  const [step] = React.useState(location?.state);
  const { data, refetch, loading: profileLoading } = useGetProfileDataQuery();
  const [hideFormWhileRefetch, sethideFormWhileRefetch] = useState(true);
  const navigate = useNavigate();
  const profile = data && data.userGetProfile;

  const initialValues = {
    firstName: profile?.firstName || "",
    middleName: profile?.middleName || "",
    lastName: profile?.lastName || "",
    gender: profile?.gender || "",
    dob: profile?.dob || null,
    phone: profile?.phone || "",
    secondaryEmail: profile?.secondaryEmail || "",
    maritalStatus: profile?.maritalStatus || "",
  };

  const formDetails = [
    {
      name: "firstName",
      type: "text",
      width: "48%",
      label: "First Name",
    },

    {
      name: "middleName",
      type: "text",
      width: "48%",
      label: "Middle Name",
    },
    {
      name: "lastName",
      type: "text",
      width: "48%",
      label: "Last Name",
    },
    {
      name: "dob",
      type: "date",
      width: "48%",
      label: "Date of birth",
    },
    {
      name: "gender",
      type: "select",
      width: "48%",
      label: "Gender",
      dataSet: ["Male", "Female", "Other"],
    },
    {
      name: "maritalStatus",
      type: "select",
      width: "48%",
      label: "Relationship status",
      dataSet: ["Married", "Separated", "Divorced", "Widowed", "Single"],
    },
    {
      name: "phone",
      type: "text",
      width: "48%",
      label: "Phone",
    },

    {
      name: "secondaryEmail",
      type: "text",
      width: "48%",
      label: "Secondary Email",
    },
  ];

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    try {
      await mutate({
        variables: values,
      });
      sethideFormWhileRefetch(false);
      await refetch();
      if (!!me?.profile) {
        ToastMessage(
          TOASTER_TYPE.SUCCESS,
          "Personal details updated successfully."
        );
      } else {
        if (submitAction === "save_continue") {
          navigate("/update-profile/billing-details", { state: true });
        } else {
          navigate("/profile");
        }
      }
      setSubmitting(false);
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error));
    }
  };

  return (
    <DashboardLayout headerText="Update Profile">
      <GoBack />

      {loading && <Progress position="relative" />}

      {profileLoading && !!hideFormWhileRefetch ? (
        <Progress position="relative" />
      ) : (
        <>
          <UpdateProfileLayoutContainer
            className="updateprofile__main__wrap"
            flexDir="column"
            alignItems="flex-start"
          >
            {!!step && (
              <FlexibleDiv className="progress__bar">
                <FlexibleDiv
                  className="progress__bar__inner"
                  width="20%"
                ></FlexibleDiv>
              </FlexibleDiv>
            )}
            <p className="form__title">Personal Details</p>
            {!!step && (
              <span className="form__subtitle">
                First things first, let’s get to know you...
              </span>
            )}

            <CustomForm
              formDetails={formDetails}
              formFinishButtonText={!!step ? "Save & Exit" : "Update"}
              formContinueButtonText="Save & Continue"
              formColumnButtons={!!step ? true : false}
              submit={submit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            />
          </UpdateProfileLayoutContainer>
        </>
      )}
    </DashboardLayout>
  );
};

export default PersonalDetailsUpdate;
