import CustomPinInput from "../../../../../../components/lib/Form/PinInput/pinInput";
import React from "react";
import { CloseIcon } from "../../../../../../assets/images/dashboardLayout/svgExports";
import { ModalContainer } from "../../../../../../components/layout/ModalLayout/modalLayout.styles";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";
import { OIDButton } from "../../../../../../components/lib/Button/button.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  pinMessage: string;
  setShowSuccessModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  setSuccessText: (value: string | ((previousValue: string) => string)) => void;
}

const PinModal: React.FC<IProps> = ({
  open,
  close,
  setShowSuccessModal,
  setSuccessText,
  pinMessage,
}) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    setSuccessText(pinMessage);
    setShowSuccessModal(true);
  };

  return (
    <ModalContainer open={open} onClose={handleClose} maxWidth="450px">
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <CustomPinInput data="" />
        <h3 className="modal__headertext">Enter 6-Digit Code</h3>
        <p className="modal__subheadertext">
          {pinMessage === "Email"
            ? `Enter the 6-digit authentication code that was sent to adebuyansade@gmail.com`
            : pinMessage === "Phone"
            ? "Enter the 6-digit authentication code that was sent to +234 810 101 1010"
            : "Enter the 6-digit code generated by your authenticator for OneID Tech (nwosugochi7@gmail.com)."}
        </p>
        <FlexibleDiv justifyContent="flex-end" margin="20px 0 0 0">
          <span className="buttonSpan">Resend Code</span>{" "}
          <OIDButton margin="0 0 0 25px" onClick={submit}>
            Continue
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default PinModal;
