import React, { useState } from "react"
import { SingleMedicalRecordContainer } from "./singleMedicalRecord.styles"
import {
  FlexibleDiv,
  FlexibleSection,
} from "../../../components/lib/Box/box.styles"
import { DashboardLayout, GoBack } from "components"
import { CustomForm } from "components"
import { FaPen as Edit } from "react-icons/fa"
import { useLocation } from "react-router-dom"

const SingleMedicalRecord = () => {
  const [editDisabled, setEditDisabled] = useState(true)
  const location = useLocation()
  const { state } = location

  const generalRecordsformDetails = [
    {
      name: "height",
      type: "text",
      width: "48%",
      label: "Your height",
    },
    {
      name: "weight",
      type: "text",
      width: "48%",
      label: "Your weight",
    },
    {
      name: "bloodType",
      type: "select",
      width: "48%",
      label: "Blood type",
      dataSet: ["O Negative", "O Positive"],
    },
    {
      name: "genotype",
      type: "select",
      width: "48%",
      label: "Your Genotype",
      dataSet: ["AS", "AC", "AA", "SS"],
    },
    {
      name: "disabilities",
      type: "select",
      width: "48%",
      label: "Disabilities",
      dataSet: ["Yes", "No"],
      break: "Disabilities & Allergies",
    },
    {
      name: "enterDisability",
      type: "text",
      width: "48%",
      label: "Disability",
    },
    {
      name: "allergies",
      type: "select",
      width: "48%",
      label: "Allergies",
      dataSet: ["Yes", "No"],
    },
    {
      name: "enterAllergy",
      type: "text",
      width: "48%",
      label: "Allergey",
    },
  ]
  const hospitalRecordsformDetails = [
    {
      name: "facility",
      type: "text",
      width: "100%",
      label: "Healthcare Facility",
    },
    {
      name: "ailment",
      type: "text",
      width: "48%",
      label: "Ailment",
    },
    {
      name: "symptoms",
      type: "text",
      width: "48%",
      label: "Symptoms Of Ailment",
    },
    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
    {
      name: "physician",
      type: "text",
      width: "100%",
      label: "Physician",
    },
  ]

  const submit = () => {}
  return (
    <DashboardLayout headerText="Educational Background">
      <GoBack />

      <SingleMedicalRecordContainer>
        <FlexibleSection
          className="form__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <FlexibleDiv justifyContent="space-between">
            <h4 className="header__text">Mental Check</h4>

            <span
              className="edit"
              onClick={() => setEditDisabled(!editDisabled)}
            >
              <Edit /> Edit
            </span>
          </FlexibleDiv>

          <CustomForm
            formDetails={
              state === "GENERAL RECORDS"
                ? generalRecordsformDetails
                : hospitalRecordsformDetails
            }
            formFinishButtonText="Update Medical Record"
            editDisabled={editDisabled}
            submit={submit}
          />
        </FlexibleSection>
      </SingleMedicalRecordContainer>
    </DashboardLayout>
  )
}

export default SingleMedicalRecord
