import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const AccountSettingsContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px;
  justify-content: flex-start;

  .account__main__wrap {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 50px 30px;
    max-width: 900px;

    .account__subwrap {
      margin-bottom: 25px;
      box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
      border-radius: 4px;
      padding: 15px 20px;
      justify-content: space-between;
      flex-wrap: nowrap;
      cursor: pointer;
      transition: all 0.3s ease;

      :hover {
        transform: scale(1.04);
        background-color: #fbfcff;
      }
      .account__subwrap__textsection {
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
        .accountconttent__title {
          margin: 0;
          color: #272727;
          font-size: 18px;
          cursor: pointer;
        }
        .accountconttent__subtitle {
          color: #7b8087;
          font-size: 16px;
          margin: 10px 0;
        }
      }

      .arrow__wrap {
        opacity: 0.7;

        svg {
          color: #424242;
          font-size: 30px;
        }
      }
    }
  }

  @media (max-width: 900px) {
    padding: 40px 15px;

    .account__main__wrap {
      padding: 30px 15px;
    }
  }

  @media (max-width: 500px) {
    .account__main__wrap {
      .account__subwrap {
        margin-bottom: 20px;
        .accountconttent__title {
          font-size: 15px;
        }
        .accountconttent__subtitle {
          font-size: 13px;
        }
      }
    }
  }
`;
