import React from "react";
import { TextFieldContainer } from "./textfield.styles";
import { FormControlContainer } from "../FormControl/formControl.styles";
import { FlexibleDiv } from "../../Box/box.styles";
import { RiSearch2Line as Search } from "react-icons/ri";
import { TextFieldProps } from "@mui/material";

interface IProps {
  search?: boolean;
  textFieldProps: TextFieldProps;
}

export const TextField: React.FC<IProps> = ({ search, textFieldProps }) => {
  return (
    <FormControlContainer>
      {textFieldProps.disabled && (
        <FlexibleDiv justifyContent="flex-start" margin="0 0 10px 0">
          <span className="disabled__label">{textFieldProps.label}</span>
        </FlexibleDiv>
      )}

      <TextFieldContainer {...textFieldProps} />

      {search && (
        <div className="search">
          <Search />
        </div>
      )}
    </FormControlContainer>
  );
};
