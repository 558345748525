import styled from "styled-components"

// export const CustomFormContainer = styled("form")`
export const CustomFormContainer = styled("div")`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: 800px) {
    form {
      & > div {
        width: 100%;
      }
    }
  }

  form {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  .break {
    .break__text {
      font-size: 14px;
      padding-left: 5px;
      color: #424242;
    }
  }
  .cancel__text {
    font-size: 15px;
    color: #0b61dd;
    font-weight: 600;
    margin-right: 30px;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    .customform__buttons__wrap {
      justify-content: space-evenly;

      .cancel__text {
        font-size: 13px;
        margin-right: 0;
      }
      button {
        margin-right: 0;
        padding: 0 20px;
      }
    }
  }
`
