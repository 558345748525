export const newNotifications = [
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
];

export const oldNotifications = [
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
];

export const newRequests = [];

export const oldRequests = [
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },

  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
  {
    title: "Dangote LTD has access to your ONEID code",
    message: "You granted Dangote LTD access to your ONEID code",
    time: "30 Mins ago",
  },
];
