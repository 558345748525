import React from "react";
import { ComingSoonContainer } from "./comingsoon.styles";
import { FlexibleDiv } from "../Box/box.styles";
import { OIDButton } from "../Button/button.styles";
import ComingSoonImage from "../../../assets/images/dashboardLayout/coming-soon.svg";
interface IProps {
  comingPage: string;
}

export const ComingSoon: React.FC<IProps> = ({ comingPage }) => {
  const handleRoute = () => {};

  return (
    <ComingSoonContainer>
      <FlexibleDiv
        height="80%"
        flexWrap="nowrap"
        flexDir="column"
        className="comingSoon__main__wrap"
      >
        <div className="image__wrap">
          <img src={ComingSoonImage} alt="" />
        </div>

        <p className="comingSoon__text">
          We are excited about what we are building and can’t wait to share with
          you soon.
        </p>

        <OIDButton onClick={handleRoute}>
          Notify me when this feature is available
        </OIDButton>
      </FlexibleDiv>
    </ComingSoonContainer>
  );
};
