import React from "react"
import { DashboardLayout, GoBack, NoData, Progress } from "components"
import { useUserGetProfileAcessListQuery } from "pages/ViewAccessRequests/gql/__types/accessRequest.types"
import { useState } from "react"
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles"
import { OIDButton } from "../../../../../../components/lib/Button/button.styles"
import { ReviewThirdPartyContainer } from "./reviewThirdparty.styles"
import RequestActionModal from "pages/DashboardOverview/Modals/RequestActionModal/requestAccessAction"

export interface IRAPayload {
  show: boolean
  id: string | string[] | null
  requestStatus: string | string[]
}

const ReviewThirdParty = () => {
  useState(false)
  const [requestActionPayload, setRequestActionPayload] =
    React.useState<IRAPayload>({
      show: false,
      id: "",
      requestStatus: "",
    })
  const { data: acessList, loading: accessListLoading } =
    useUserGetProfileAcessListQuery()

  const handleRequestActionModal = (id: string, status: string) => {
    setRequestActionPayload({
      show: !requestActionPayload.show,
      id: id,
      requestStatus: status,
    })
  }
  return (
    <DashboardLayout headerText="Settings">
      <GoBack />
      {accessListLoading ? (
        <Progress />
      ) : (
        <>
          {acessList?.userGetAccesssList?.length ? (
            <ReviewThirdPartyContainer justifyContent="flex-start">
              <FlexibleDiv
                className="reviewaccess__main__wrap"
                flexDir="column"
                justifyContent="flext-start"
                alignItems="flex-start"
              >
                <h4 className="review__title">Review third party access </h4>
                <p className="review__subtitle">
                  {" "}
                  Control and monitor third party access to your OneID account
                </p>

                {acessList?.userGetAccesssList?.map(
                  (access: any, idx: number) => (
                    <FlexibleDiv
                      className="access__cards"
                      flexDir="column"
                      key={idx + "lkwds"}
                      alignItems="flex-start"
                    >
                      <h3 className="user__title">
                        {access?.username || access?.businessName}
                      </h3>
                      <span className="pass__subtitle">
                        {" "}
                        Has access to your OneID code. To revoke access, click
                        the button below
                      </span>

                      {/* <span className="pass__subtitle">Has access to your:</span> */}

                      <FlexibleDiv
                        justifyContent="flex-end"
                        margin="10px 0 0 0"
                        className="footer__wrap"
                      >
                        {/* <FlexibleDiv
                    width="70%"
                    justifyContent="flex-start"
                    className="passes__wrap"
                  >
                    {item.passes.map((pass, idx) => (
                      <p key={idx} className="pass__items">
                        {pass}
                      </p>
                    ))}
                  </FlexibleDiv> */}
                        <OIDButton
                          backgroundColor="transparent"
                          color="#00499B"
                          borderColor="#00499B"
                          height="40px"
                          width="140px"
                          padding="0"
                          onClick={() =>
                            handleRequestActionModal(
                              access?._id,
                              "Revoke Access"
                            )
                          }
                        >
                          Revoke Access
                        </OIDButton>
                      </FlexibleDiv>
                    </FlexibleDiv>
                  )
                )}
              </FlexibleDiv>
            </ReviewThirdPartyContainer>
          ) : (
            <NoData
              noDataTitle="Review Third Party Acesss"
              noDataMessage="Hi Kay, there's currently no third party access to your account"
              noDataTabHeader
            />
          )}
        </>
      )}

      <RequestActionModal
        requestActionPayload={requestActionPayload}
        setRequestActionPayload={setRequestActionPayload}
        fromPage="/settings/review-access"
      />
    </DashboardLayout>
  )
}

export default ReviewThirdParty
