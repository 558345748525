import { useLazyQuery } from "@apollo/client"
import { getErrorMessage } from "common"
import {
  CustomForm,
  DashboardLayout,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components"
import { getInitials } from "helpers"
import { useState } from "react"
import * as Yup from "yup"
import { FlexibleDiv } from "../../components/lib/Box/box.styles"
import { OIDButton } from "../../components/lib/Button/button.styles"
import VerificationFailedModal from "./Modals/VerificationFailed/verificationFailed"
import { VERIFY_ONEID_USER } from "./gql/verifyUser"
import { VerifyUserContainer } from "./verifyUser.styles"

const VerifyUser = () => {
  const [checkFinishedOptions] = useState([false, "BLOCKED"])
  const [onVerifyUser, { loading, data, called }] =
    useLazyQuery(VERIFY_ONEID_USER)

  const validationSchema = Yup.object().shape({
    oneIDorEmail: Yup.string().required(),
  })

  const initialValues = {
    oneIDorEmail: "",
  }

  const formDetails = [
    {
      name: "oneIDorEmail",
      type: "text",
      width: "100%",
      label: "Enter User’s OneID Code Or Email",
    },
  ]

  const handleResultClose = () => {
    window.location.pathname = "/verify-users"
  }

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    try {
      onVerifyUser({
        variables: { ...values },
      })
      setSubmitting(false)
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <DashboardLayout headerText="Verify Users">
      {called && loading && <Progress />}
      <VerifyUserContainer alignItems="flex-start">
        {called &&
        !loading &&
        data?.userQueryOneId &&
        !checkFinishedOptions.includes(data?.userQueryOneId?.status) ? (
          <FlexibleDiv className="verifyuser__result__wrap" flexDir="column">
            <FlexibleDiv
              flexDir="column"
              className="verifyuser__result__subwrap"
            >
              <FlexibleDiv className="initials">
                <p className="initials__text">
                  {getInitials({
                    fName: data?.userQueryOneId?.firstName,
                    lName: data?.userQueryOneId?.lastName,
                    username: data?.userQueryOneId?.username!,
                  })}
                </p>
              </FlexibleDiv>
              <h4 className="user__name">
                {data?.userQueryOneId?.profile?.fullName}
              </h4>
              <FlexibleDiv
                justifyContent="space-between"
                className="data__wrap"
              >
                <p className="data__headertext">Username: </p>
                <span className="data__resulttext">
                  {" "}
                  {data?.userQueryOneId?.username}
                </span>
              </FlexibleDiv>
              <FlexibleDiv
                justifyContent="space-between"
                className="data__wrap"
              >
                <p className="data__headertext">OneID: </p>
                <span className="data__resulttext">
                  {data?.userQueryOneId?.oneId}
                </span>
              </FlexibleDiv>
              <FlexibleDiv
                justifyContent="space-between"
                className="data__wrap"
              >
                <p className="data__headertext">Verification Level: </p>
                <span className="data__resulttext">
                  {data?.userQueryOneId?.profile
                    ? data?.userQueryOneId?.profile?.verificationLevel
                    : 1}
                </span>
              </FlexibleDiv>
              <OIDButton margin="30px 0" onClick={handleResultClose}>
                Finish
              </OIDButton>
            </FlexibleDiv>
          </FlexibleDiv>
        ) : (
          <FlexibleDiv
            className="verifyuser__main__wrap"
            flexDir="column"
            justifyContent="flext-start"
            alignItems="flex-start"
          >
            <h4>Want to Verify A User?</h4>
            <p>I’ll come back to put something more appropriate here.</p>
            <FlexibleDiv maxWidth="700px">
              <CustomForm
                formDetails={formDetails}
                formFinishButtonText={"Verify User"}
                submit={submit}
                buttonPosition="flex-start"
                validationSchema={validationSchema}
                initialValues={initialValues}
              />
            </FlexibleDiv>
          </FlexibleDiv>
        )}
      </VerifyUserContainer>
      {data?.userQueryOneId?.message && (
        <VerificationFailedModal result={data?.userQueryOneId} />
      )}
    </DashboardLayout>
  )
}

export default VerifyUser
