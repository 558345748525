import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserChangePasswordMutationVariables = Types.Exact<{
  oldPassword: Types.Scalars['String'];
  newPassword: Types.Scalars['String'];
}>;


export type UserChangePasswordMutation = { __typename?: 'Mutation', changePassword?: string | null };

export type UserDeleteAccountMutationVariables = Types.Exact<{
  _id: Types.Scalars['ID'];
}>;


export type UserDeleteAccountMutation = { __typename?: 'Mutation', deleteUser?: string | null };


export const UserChangePasswordDocument = gql`
    mutation userChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(data: {oldPassword: $oldPassword, newPassword: $newPassword})
}
    `;
export type UserChangePasswordMutationFn = Apollo.MutationFunction<UserChangePasswordMutation, UserChangePasswordMutationVariables>;

/**
 * __useUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangePasswordMutation, { data, loading, error }] = useUserChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useUserChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserChangePasswordMutation, UserChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserChangePasswordMutation, UserChangePasswordMutationVariables>(UserChangePasswordDocument, options);
      }
export type UserChangePasswordMutationHookResult = ReturnType<typeof useUserChangePasswordMutation>;
export type UserChangePasswordMutationResult = Apollo.MutationResult<UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = Apollo.BaseMutationOptions<UserChangePasswordMutation, UserChangePasswordMutationVariables>;
export const UserDeleteAccountDocument = gql`
    mutation userDeleteAccount($_id: ID!) {
  deleteUser(_id: $_id)
}
    `;
export type UserDeleteAccountMutationFn = Apollo.MutationFunction<UserDeleteAccountMutation, UserDeleteAccountMutationVariables>;

/**
 * __useUserDeleteAccountMutation__
 *
 * To run a mutation, you first call `useUserDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteAccountMutation, { data, loading, error }] = useUserDeleteAccountMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useUserDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteAccountMutation, UserDeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteAccountMutation, UserDeleteAccountMutationVariables>(UserDeleteAccountDocument, options);
      }
export type UserDeleteAccountMutationHookResult = ReturnType<typeof useUserDeleteAccountMutation>;
export type UserDeleteAccountMutationResult = Apollo.MutationResult<UserDeleteAccountMutation>;
export type UserDeleteAccountMutationOptions = Apollo.BaseMutationOptions<UserDeleteAccountMutation, UserDeleteAccountMutationVariables>;