import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";

type Custom = {};

export const HospitalRecordsContainer = styled(FlexibleDiv)<Custom>`
  .hospital_wrap {
    padding: 40px 30px;
    button {
      position: fixed;
      right: 30px;
    }
    .medicalList__wrap {
      background: #ffffff;
      border-radius: 10px;
      padding: 0 30px 30px 30px;
      margin-right: 20px;
      position: relative;

      .add__medical__mobile {
        position: absolute;
        right: 15px;
        top: 20px;
        color: #0b61dd;
        font-weight: bold;
        font-size: 14px;

        @media (min-width: 1010px) {
          display: none;
        }
      }

      .record__box {
        background: #ffffff;
        border: 0.5px solid #f2f2f2;
        box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
        border-radius: 12px;
        padding: 30px;
        margin-top: 30px;
        min-height: 160px;
        justify-content: space-between;
        transition: all 0.3s ease;

        .medicalcard__title,
        svg {
          margin: 0;
          font-size: 18px;
        }
        .medicalcard__bodytext {
          font-size: 16px;
          color: #787878;
          margin: 0;
        }

        :hover {
          cursor: pointer;
          background: #fbfcff;
          transform: scale(1.04);
        }
        @media (max-width: 1010px) {
          padding: 20px 15px;
          min-height: 140px;
        }

        @media (max-width: 600px) {
          min-height: 120px;
          margin-top: 10px;
          .medicalcard__title,
          svg {
            font-size: 16px;
          }
          .medicalcard__bodytext {
            font-size: 14px;
          }
        }
      }
    }

    @media (max-width: 1010px) {
      padding: 15px;

      .medicalList__wrap {
        padding: 30px 15px 30px 15px;
        width: 100%;
        margin-right: 0;
      }
      button {
        display: none;
      }
    }

    @media (max-width: 800px) {
      .medicalList__wrap {
        padding: 0;
        background-color: transparent;
        .add__medical__mobile {
          top: 0;
        }
      }
    }

    @media (max-width: 600px) {
      padding-top: 40px;

      .medicalList__wrap {
        .add__medical__mobile {
          top: -20px;
        }
      }
    }
  }
`;
