import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { FlexibleDiv } from "../../lib/Box/box.styles"
import { DashboardLayout } from "../DashboardLayout"
import { SettingsLayoutContainer, TabsContainer } from "./settingsLayout.styles"

interface IProps {
  children: JSX.Element | JSX.Element[]
}

const tabRoutes = [
  { path: "/settings/account", title: "ACCOUNT" },
  { path: "/settings/privacy-security", title: "PRIVACY & SECURITY" },
  { path: "/settings/notifications", title: "NOTIFICATIONS" },
  { path: "/settings/privacy-policy", title: "LEGAL" },
  {
    path: "/settings/help",
    title: "HELP",
  },
]

const SettingsLayout: React.FC<IProps> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <DashboardLayout headerText="Settings">
      <SettingsLayoutContainer>
        <TabsContainer justifyContent="flex-start">
          {tabRoutes.map((item: any, idx: number) => (
            <FlexibleDiv
              onClick={() => navigate(item.path)}
              id={location.pathname === item.path ? "activetab" : ""}
              width="max-content"
              flexWrap="nowrap"
              className="tab__text__wrap"
            >
              <p className="tab__text">{item.title}</p>
            </FlexibleDiv>
          ))}
        </TabsContainer>
        {children}
      </SettingsLayoutContainer>
    </DashboardLayout>
  )
}

export default SettingsLayout
