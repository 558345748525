const env = process.env
const isProd = env.NODE_ENV === "production"

export const Config = {
  PORT: env.PORT ? env.PORT : 3010,
  DEBUG: isProd ? false : true,
  API_BASE_URL: env.REACT_APP_API_BASE_URL || "http://localhost:4000/graphql",
  BASE_URL: env.REACT_APP_BASE_URL || "http://localhost:3000",
  FRONT_DESK_URL: env.REACT_APP_FRONT_DESK_URL || "http://localhost:3001",
  CONSOLE_URL: env.REACT_APP_CONSOLE_URL || "http://localhost:3002",
  AUTH_GATEWAY_URL: env.REACT_APP_AUTH_GATEWAY_URL || "http://localhost:5000",
  DOCS_URL: env.REACT_APP_DOCS_URL || "https://developer.oneidtech.com",
}
