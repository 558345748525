import React from "react"
import { ModalContainer } from "../../../../components/layout/ModalLayout/modalLayout.styles"
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles"
import { OIDButton } from "../../../../components/lib/Button/button.styles"

interface IProps {
  result: any
}

const VerificationFailedModal: React.FC<IProps> = ({ result }) => {
  const [modalData] = React.useState(result)

  const handleClose = () => {
    window.location.pathname = "/verify-users"
  }

  return (
    <ModalContainer
      open={modalData?.message}
      onClose={handleClose}
      maxWidth="450px"
    >
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        {result?.status && (
          <h3 className="modal__headertext">{result?.status}</h3>
        )}
        <p className="modal__subheadertext">{result?.message}</p>

        <FlexibleDiv margin="15px 0 0 0">
          <OIDButton onClick={handleClose}>Close</OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default VerificationFailedModal
