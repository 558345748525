import * as React from "react";
import { SwitchContainer } from "./switch.styles";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function OIDSwitch() {
  return (
    <div>
      <SwitchContainer {...label} />
    </div>
  );
}
