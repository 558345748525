import React, { useState } from "react";
import { DashboardLayoutContainer } from "./dashboardLayout.styles";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";

interface IProps {
  children: React.ReactNode;
  headerText: string;
}

export const DashboardLayout: React.FC<IProps> = ({ children, headerText }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <DashboardLayoutContainer>
      <Sidebar setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} />
      <Header
        setIsDrawerOpen={setIsDrawerOpen}
        isDrawerOpen={isDrawerOpen}
        headerText={headerText}
      />

      {/* main page content */}
      <main>{children}</main>
    </DashboardLayoutContainer>
  );
};
