import styled from "styled-components"
import { FlexibleDiv } from "../../lib/Box/box.styles"

export const DashboardLayoutContainer = styled(FlexibleDiv)`
  width: 100vw;
  height: 100vh;

  main {
    position: relative;
    width: calc(100vw - 240px);
    margin: 50px 0 0 240px;
    min-height: calc(100vh - 70px);
    background-color: #f8f8f8;
    border-top: 1px solid #f2f2f2;

    @media screen and (max-width: 800px) {
      width: 100vw;
      margin: 60px 0 0 0;
    }
  }
`
