import {
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components";
import { useState } from "react";
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles";
import { CustomForm } from "components";
import SuccessModal from "../../../../../components/lib/SuccessModal/successModal";
import { ChangePasswordContainer } from "./changePassword.styles";
import * as Yup from "yup";
import { useUserChangePasswordMutation } from "../../gql/__types/changePassword.types";
import { getErrorMessage } from "common";
import { Config } from "config";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().min(8).required("Your old password is required"),
  newPassword: Yup.string().min(8).required("Your new password is required"),
});

const ChangePassword = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [mutate, { loading }] = useUserChangePasswordMutation();

  const signout = () => {
    localStorage.removeItem("1D_AUR");
    window.location.href = `${Config.AUTH_GATEWAY_URL}`;
  };

  const formDetails = [
    {
      name: "oldPassword",
      type: "text",
      width: "100%",
      label: "Enter Your Old Password",
    },
    {
      name: "newPassword",
      type: "text",
      width: "100%",
      label: "Enter Your New Password",
    },
  ];

  const initialValues = {
    oldPassword: "",
    newPassword: "",
  };

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    try {
      await mutate({
        variables: { ...values },
      });
      setShowSuccessModal(true);
      setSubmitting(false);

      setTimeout(() => {
        setShowSuccessModal(true);
        signout();
      }, 3000);
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error));
    }
  };

  return (
    <DashboardLayout headerText="Settings">
      <GoBack />

      {loading && <Progress />}

      <ChangePasswordContainer justifyContent="flex-start">
        <FlexibleDiv
          className="changepassword__main__wrap"
          flexDir="column"
          justifyContent="flext-start"
          alignItems="flex-start"
        >
          <h4 className="changepassword__headertext">CHANGE PASSWORDS</h4>
          <CustomForm
            formDetails={formDetails}
            formFinishButtonText={"Save Password"}
            submit={submit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
        </FlexibleDiv>
      </ChangePasswordContainer>
      <SuccessModal
        open={showSuccessModal}
        close={setShowSuccessModal}
        text="Your New Password Has Been Enabled Successfully. You will be logged out to complete this proccess"
      />
    </DashboardLayout>
  );
};

export default ChangePassword;
