import styled from "styled-components";

// Flexible box  div element

type Custom = {
  flex?: string;
  justifyContent?: string;
  alignItems?: string;
  flexDir?: string;
  flexWrap?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  height?: string;
  padding?: string;
  margin?: string;
  position?: string;
  bgColor?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  border?: string;
  gridCol?: string;
  gridRow?: string;
  gap?: string;
};

export const FlexibleDiv = styled.div<Custom>`
  display: flex;
  flex: ${({ flex }) => flex || ""};
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  flex-direction: ${({ flexDir }) => flexDir || "row"};
  width: ${({ width }) => width || "100%"};
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height || "max-content"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  background: ${({ bgColor }) => bgColor || ""};
  position: ${({ position }) => position || ""};
  top: ${({ top }) => top || ""};
  bottom: ${({ bottom }) => bottom || ""};
  left: ${({ left }) => left || ""};
  right: ${({ right }) => right || ""};
  border: ${({ border }) => border || ""};
`;

// Flexible box section element
export const FlexibleSection = styled.section<Custom>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  flex-direction: ${({ flexDir }) => flexDir || "row"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "max-content"};
  background: ${({ bgColor }) => bgColor || ""};
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
`;

// Flexible box section element
export const FlexibleUL = styled.ol<Custom>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  flex-direction: ${({ flexDir }) => flexDir || "row"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "max-content"};
  background: ${({ bgColor }) => bgColor || ""};
`;

// Gridable div element
export const GridableDiv = styled.div<Custom>`
  display: grid;
  grid-template-columns: ${({ gridCol }) => gridCol || "1fr"};
  grid-template-rows: ${({ gridRow }) => gridRow || "auto"};
  grid-gap: ${({ gap }) => gap || "10px"};
`;

// Gridable section element
export const GridSection = styled.section<Custom>`
  padding: 50px 0;
  display: grid;
  grid-template-columns: ${({ gridCol }) => gridCol || "1fr  1fr 1fr 1fr"};
  grid-template-rows: ${({ gridRow }) => gridRow || "auto"};
  grid-gap: ${({ gap }) => gap || "10px"};
`;
