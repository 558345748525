import { FlexibleDiv } from "components/lib/Box/box.styles";
import styled from "styled-components";

export const ViewAccessRequestsLayoutContainer = styled(FlexibleDiv)`
  width: 100%;
  align-items: flex-start;
  flex-direction: column;

  .profilerequest__listwrap {
    padding: 40px;
    max-width: 1000px;

    .veiwrequests__accessrequest__card {
      background: #feffff;
      border: 1px solid rgba(0, 73, 155, 0.4);
      box-shadow: 0px 4px 16px rgba(147, 147, 147, 0.08);
      border-radius: 20px;
      transition: all 0.3s ease;
      position: relative;
      padding: 20px 30px;
      overflow: hidden;
      margin-bottom: 40px;
      :hover {
        background-color: #f2f5f8;
      }

      .accessrequest__header {
        .accessrequest__headertext {
          margin: 0;
          color: #0c0c0c;
        }
      }

      .accessrequest__bodytext {
        font-size: 15px;
        line-height: 25px;
        width: 100%;
        color: #464343;
      }
      .request-action__wrap {
        .decline__request {
          margin-left: 20px;
          color: #cf0d19;
          font-size: 14px;
          cursor: pointer;
          transition: font-weight 0.3s ease;

          :hover {
            font-weight: bold;
          }
        }
      }
    }
    @media screen and (max-width: 500px) {
      padding: 40px 15px;
      .veiwrequests__accessrequest__card {
        align-items: center;
        padding: 20px 15px 30px 15px;

        .accessrequest__time {
          width: 100%;
          text-align: end;
          margin-bottom: 10px;
        }
        .accessrequest__header {
          justify-content: center;
        }
        .accessrequest__bodytext {
          text-align: center;
        }

        .request-action__wrap {
          justify-content: space-between;
          padding: 0 10%;
          flex-wrap: nowrap;
          button {
            font-size: 12px;
            padding: 0 20px;
            height: 42px;
            white-space: nowrap;
          }
          .decline__request {
            font-size: 12px;
            white-space: nowrap;
          }
        }
      }
    }

    @media screen and (max-width: 350px) {
      .veiwrequests__accessrequest__card {
        .request-action__wrap {
          padding: 0;
        }
      }
    }
  }
`;
