import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "components";
import React from "react";
import { MainRoutes } from "routes";

const App = () => {
  return (
    <React.Fragment>
      <ThemeProvider>
        <CssBaseline />
        <MainRoutes />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default App;
