import styled from "styled-components";
import { TextareaAutosize } from "@mui/material";

export const TextAreaContainer = styled(TextareaAutosize)`
  min-height: 90px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  background-color: #fafafa;
  font-size: 14px;

  ::placeholder {
    max-width: 270px;
  }

  :active,
  :visited,
  :focus {
    border: 1px solid #0b61dd;
    outline: none;
  }
`;
