import { getErrorMessage } from "common"
import {
  CustomForm,
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components"
import { omitTypename, updateNullProperties, useMe } from "helpers"
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { countries } from "utils/data-helpers/countries"
import * as Yup from "yup"
import { LongArrowRightIcon } from "../../../../assets/images/dashboardLayout/svgExports"
import { UpdateProfileLayoutContainer } from "../../../../components/layout/UpdateProfileLayout/updateProfile.styles"
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles"
import { useUpdateUserProfileMutation } from "../gql/__types/UpdateProfile.types"

const validationSchema = Yup.object().shape({
  primaryAddress: Yup.string().min(6).nullable(),
  secondaryAddress: Yup.string().min(6).nullable(),
  postalCode: Yup.string().min(6).nullable(),
  stateOfResidence: Yup.string().min(3).nullable(),
  stateOfOrigin: Yup.string().min(3).nullable(),
  lga: Yup.string().min(3).nullable(),
})

const BillingDetailsUpdate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [step] = React.useState(location?.state)
  const [mutate, { loading }] = useUpdateUserProfileMutation()
  const me = useMe()
  const { data, refetch, loading: profileLoading } = useGetProfileDataQuery()
  const [hideFormWhileRefetch, sethideFormWhileRefetch] = React.useState(true)

  const profile = data && data.userGetProfile

  const unwantedValues = {
    nextOfKin: {
      fullName: profile?.nextOfKin?.fullName || "",
      address: profile?.nextOfKin?.address || "",
      gender: profile?.nextOfKin?.gender || "",
      dob: profile?.nextOfKin?.dob || null,
      phone: profile?.nextOfKin?.phone || "",
      email: profile?.nextOfKin?.email || "",
      relationship: profile?.nextOfKin?.relationship || "",
    },
  }

  const initialValues = {
    country: profile?.country || "",
    stateOfResidence: profile?.stateOfResidence || "",
    nationality: profile?.nationality || "",
    lga: profile?.lga || "",
    postalCode: profile?.postalCode || null,
    primaryAddress: profile?.primaryAddress || "",
    secondaryAddress: profile?.secondaryAddress || "",
    stateOfOrigin: profile?.stateOfOrigin || "",
  }

  const formDetails = [
    {
      name: "country",
      type: "select",
      width: "48%",
      label: "Country of Residence",
      dataSet: countries,
      // getValue: (value: string) => setSelectedCountry(value),
    },

    {
      name: "stateOfResidence",
      type: "text",
      width: "48%",
      label: "State Of Residence",
    },

    {
      name: "postalCode",
      type: "text",
      width: "48%",
      label: "Postal Code",
    },
    {
      name: "primaryAddress",
      type: "text",
      width: "48%",
      label: "Primary Address",
    },
    {
      name: "secondaryAddress",
      type: "text",
      width: "48%",
      label: "Secondary Address",
    },

    {
      name: "nationality",
      type: "select",
      width: "48%",
      label: "Country Of Origin",
      dataSet: countries,
      break: "Nativity Info",
    },
    {
      name: "stateOfOrigin",
      type: "text",
      width: "48%",
      label: "State Of Origin",
      // dataSet: ["Abia", "Lagos"],
    },

    {
      name: "lga",
      type: "text",
      width: "48%",
      label: "L.G.A",
      // dataSet: ["Abia", "Lagos"],
    },
  ]

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    const payload = {
      ...updateNullProperties(omitTypename(me?.profile), ""),
      ...values,
      ...unwantedValues,
    }

    try {
      await mutate({
        variables: payload,
      })
      sethideFormWhileRefetch(false)
      await refetch()

      if (!submitAction) {
        ToastMessage(
          TOASTER_TYPE.SUCCESS,
          "Billing details updated successfully."
        )
      } else {
        if (submitAction === "save_continue") {
          navigate("/update-profile/next-of-kin", { state: true })
        } else {
          navigate("/profile")
        }
      }
      setSubmitting(false)
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <DashboardLayout headerText="Update Profile">
      <GoBack />

      {loading && <Progress position="relative" />}

      {profileLoading && !!hideFormWhileRefetch ? (
        <Progress position="relative" />
      ) : (
        <UpdateProfileLayoutContainer
          className="updateprofile__main__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          {!!step && (
            <FlexibleDiv className="progress__bar">
              <FlexibleDiv
                className="progress__bar__inner"
                width="40%"
              ></FlexibleDiv>
            </FlexibleDiv>
          )}
          <p className="form__title">Billing Details</p>
          {!!step && (
            <span className="form__subtitle">
              Hi May, the journey 5 steps begin with just 1... or something like
              that
            </span>
          )}
          <CustomForm
            formDetails={formDetails}
            formFinishButtonText={!!step ? "Save & Exit" : "Update"}
            formContinueButtonText="Save & Continue"
            formColumnButtons={!!step ? true : false}
            submit={submit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
          {!!step && (
            <FlexibleDiv className="form__skip">
              <span
                className="skip__text"
                onClick={() =>
                  navigate("/update-profile/next-of-kin", { state: true })
                }
              >
                Skip
                <LongArrowRightIcon />
              </span>
            </FlexibleDiv>
          )}
        </UpdateProfileLayoutContainer>
      )}
    </DashboardLayout>
  )
}

export default BillingDetailsUpdate
