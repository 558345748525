import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const EducationHistoryContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px 30px;
  flex-wrap: nowrap;

  .education__wrap {
    background: #ffffff;
    border-radius: 10px;
    padding: 30px;
    margin-right: 20px;
    position: relative;
    font-size: 14px;

    .add__education__mobile {
      position: absolute;
      right: 15px;
      top: 30px;
      color: #0b61dd;
      font-weight: bold;

      @media (min-width: 1010px) {
        display: none;
      }
    }
  }
  button {
    position: fixed;
    right: 30px;
  }

  @media (max-width: 1010px) {
    padding: 15px;

    .education__wrap {
      padding: 60px 15px 30px 15px;
      width: 100%;
      margin-right: 0;
    }
    button {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .education__wrap {
      padding: 0;
      background-color: transparent;
      .add__education__mobile {
        top: 0;
        right: 0;
      }
      .tabs__wrap {
        display: none;
      }
    }
  }
`;
