import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";

type Custom = {};

export const CovidHistoryContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  height: 80vh;
  .comingsoon__header {
    font-size: 16px;
    text-align: center;
    color: #0c0c0c;
    margin: 0;
  }
  .comingsoon__subheader {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #939393;
    width: 90%;
    margin: 0;
  }
`;
