import styled from "styled-components";
import Switch from "@mui/material/Switch";

export const SwitchContainer = styled(Switch)({
  height: "48px",
  width: "68px",
  "& .MuiSwitch-track": {
    borderRadius: "12px",
  },
});
