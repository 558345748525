import { DashboardLayout, NoData } from "components"
import { useState } from "react"
import { AiOutlinePlus as Plus } from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { FlexibleDiv } from "../../../components/lib/Box/box.styles"
import { OIDButton } from "../../../components/lib/Button/button.styles"
import Tabs from "../../../components/lib/Tabs/tabs"
import { EmploymentHistoryContainer } from "./employmentHistory.styles"
import EmploymentList from "./EmploymentList/employmentList"

const EmploymentHistory = () => {
  const [activeTab, setActiveTab] = useState("All")
  const navigate = useNavigate()
  const employmentHistory = [
    {
      data: "data",
    },
  ]
  const tablist = ["All", "2022", "2021", "2020", "2019"]

  return (
    <DashboardLayout headerText="Employment History">
      {!!employmentHistory?.length ? (
        <EmploymentHistoryContainer
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FlexibleDiv className="emploments__wrap" width="70%">
            <span
              className="add__employment__mobile"
              onClick={() => navigate("/add-employment")}
            >
              Add New
            </span>
            <FlexibleDiv className="tabs__wrap">
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tablist={tablist}
              />
            </FlexibleDiv>

            <EmploymentList activeTab={activeTab} />
          </FlexibleDiv>

          <OIDButton onClick={() => navigate("/add-employment")}>
            <Plus />
            Add Employment
          </OIDButton>
        </EmploymentHistoryContainer>
      ) : (
        <NoData
          noDataTitle="No Employment History"
          noDataMessage="Hi May, please click the button above to update your employment history"
          noDataButtonRoute="/add-employment"
          noDataButtonText="Add Employment"
        />
      )}
    </DashboardLayout>
  )
}

export default EmploymentHistory
