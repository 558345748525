import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const MedicalHistoryContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  flex-wrap: nowrap;

  .tabs__wrap {
    background-color: #fff;
    padding: 20px 30px 0 30px;

    & > div {
      border-bottom: none;
    }
  }

  @media (max-width: 1010px) {
    .tabs__wrap {
      padding: 20px 15px 0 15px;
    }
  }

  @media (max-width: 500px) {
    .tabs__wrap {
      padding: 10px 15px 0 15px;
      & > div {
        & > div {
          margin-right: 15px;
          p {
            font-size: 11px;
          }
        }
      }
    }
  }
`;
