import React from "react"
import { DashboardLayout, ComingSoon } from "components"

const TravelHistory = () => {
  return (
    <DashboardLayout headerText="Travel History">
      <ComingSoon comingPage="Tickets" />
    </DashboardLayout>
  )
}

export default TravelHistory
