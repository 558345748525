import React from "react";
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles";
import { CustomForm } from "components";
import { ModalContainer } from "../../../../../components/layout/ModalLayout/modalLayout.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  showSuccessModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
}

const KycPinModal: React.FC<IProps> = ({ open, close, showSuccessModal }) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    showSuccessModal(true);
  };

  const formDetails = [
    {
      name: "startDate",
      type: "text",
      width: "100%",
      label: "Enter  KYC pin",
    },
    {
      name: "endDate",
      type: "text",
      width: "100%",
      label: "Confirm  pin",
    },
  ];

  return (
    <ModalContainer open={open} onClose={handleClose} maxWidth="450px">
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <h3 className="modal__headertext">Enable KYC Pin</h3>
        <p className="modal__subheadertext">
          Create new KYC pin to add an extra layer of security on your KYC
          information
        </p>
        <CustomForm
          formDetails={formDetails}
          formFinishButtonText={"Save Pin"}
          submit={submit}
          formNoBorderCancelButton
          buttonPosition="flex-end"
        />
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default KycPinModal;
