import React from "react"
import { SuccessModalContainer } from "./successModal.styles"
import { FlexibleDiv } from "../Box/box.styles"
import SuccessCheck from "../../../assets/images/dashboardLayout/success-check.png"
import { CloseIcon } from "../../../assets/images/dashboardLayout/svgExports"

interface IProps {
  text: string
  open: boolean
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void
}

const SuccessModal: React.FC<IProps> = ({ text, open, close }) => {
  const handleClose = () => {
    close(!open)
  }
  return (
    <SuccessModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <img src={SuccessCheck} alt="" className="success__check" />
        <h4 className="modal__header__text">{text}</h4>
      </FlexibleDiv>
    </SuccessModalContainer>
  )
}

export default SuccessModal
