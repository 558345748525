import React from "react"
import { ModalContainer } from "../../../../../components/layout/ModalLayout/modalLayout.styles"
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles"
import { CustomForm } from "components"

interface IProps {
  open: boolean
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void
  showSuccessModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void
  setSuccessText: (value: string | ((previousValue: string) => string)) => void
}

const ChangePhoneNumberModal: React.FC<IProps> = ({
  open,
  close,
  showSuccessModal,
  setSuccessText,
}) => {
  const handleClose = () => {
    close(!open)
  }
  const submit = () => {
    handleClose()
    setSuccessText("Your notification phone no has been changed successfully")
    showSuccessModal(true)
  }

  const formDetails = [
    {
      name: "current",
      type: "text",
      width: "100%",
      label: "Current phone no.",
    },
    {
      name: "endDate",
      type: "text",
      width: "100%",
      label: "New Phone no.",
    },
  ]

  return (
    <ModalContainer open={open} onClose={handleClose} maxWidth="450px">
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <h3 className="modal__headertext">Change Notification Phone Number </h3>
        <p className="modal__subheadertext">
          Recieve your OneID notifications via a different phone number{" "}
        </p>
        <CustomForm
          formDetails={formDetails}
          formFinishButtonText={"Save Phone No."}
          submit={submit}
          formNoCancelButton
          buttonPosition="flex-end"
          formNoBorderCancelButton
        />
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default ChangePhoneNumberModal
