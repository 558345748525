export interface ISvgProps {
  width?: string;
  height?: string;
  fill?: string;
  stroke?: string;
  pathFill?: string;
  transform?: string;
}

export const QRCode: React.FC<ISvgProps> = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "200"}
    height="200"
    viewBox="0 0 200 200"
    fill="none"
  >
    <g clip-path="url(#clip0_2097_5315)">
      <path d="M200 0H0V200H200V0Z" fill="white" />
      <path d="M12.3866 8.3125H8.31152V12.3875H12.3866V8.3125Z" fill="black" />
      <path d="M16.4616 8.3125H12.3866V12.3875H16.4616V8.3125Z" fill="black" />
      <path d="M20.5366 8.3125H16.4616V12.3875H20.5366V8.3125Z" fill="black" />
      <path d="M24.6117 8.3125H20.5366V12.3875H24.6117V8.3125Z" fill="black" />
      <path d="M28.6867 8.3125H24.6117V12.3875H28.6867V8.3125Z" fill="black" />
      <path d="M32.7617 8.3125H28.6867V12.3875H32.7617V8.3125Z" fill="black" />
      <path d="M36.8368 8.3125H32.7617V12.3875H36.8368V8.3125Z" fill="black" />
      <path d="M44.9869 8.3125H40.9118V12.3875H44.9869V8.3125Z" fill="black" />
      <path d="M57.212 8.3125H53.1369V12.3875H57.212V8.3125Z" fill="black" />
      <path d="M69.4371 8.3125H65.362V12.3875H69.4371V8.3125Z" fill="black" />
      <path d="M77.5871 8.3125H73.5121V12.3875H77.5871V8.3125Z" fill="black" />
      <path d="M85.7372 8.3125H81.6622V12.3875H85.7372V8.3125Z" fill="black" />
      <path d="M93.8873 8.3125H89.8122V12.3875H93.8873V8.3125Z" fill="black" />
      <path d="M97.9623 8.3125H93.8873V12.3875H97.9623V8.3125Z" fill="black" />
      <path d="M110.187 8.3125H106.112V12.3875H110.187V8.3125Z" fill="black" />
      <path d="M114.262 8.3125H110.187V12.3875H114.262V8.3125Z" fill="black" />
      <path d="M118.338 8.3125H114.262V12.3875H118.338V8.3125Z" fill="black" />
      <path d="M122.413 8.3125H118.338V12.3875H122.413V8.3125Z" fill="black" />
      <path d="M126.488 8.3125H122.413V12.3875H126.488V8.3125Z" fill="black" />
      <path d="M130.563 8.3125H126.488V12.3875H130.563V8.3125Z" fill="black" />
      <path d="M134.638 8.3125H130.563V12.3875H134.638V8.3125Z" fill="black" />
      <path d="M159.088 8.3125H155.013V12.3875H159.088V8.3125Z" fill="black" />
      <path d="M167.238 8.3125H163.163V12.3875H167.238V8.3125Z" fill="black" />
      <path d="M171.313 8.3125H167.238V12.3875H171.313V8.3125Z" fill="black" />
      <path d="M175.388 8.3125H171.313V12.3875H175.388V8.3125Z" fill="black" />
      <path d="M179.463 8.3125H175.388V12.3875H179.463V8.3125Z" fill="black" />
      <path d="M183.538 8.3125H179.463V12.3875H183.538V8.3125Z" fill="black" />
      <path d="M187.613 8.3125H183.538V12.3875H187.613V8.3125Z" fill="black" />
      <path d="M191.688 8.3125H187.613V12.3875H191.688V8.3125Z" fill="black" />
      <path
        d="M12.3866 12.3875H8.31152V16.4626H12.3866V12.3875Z"
        fill="black"
      />
      <path
        d="M36.8368 12.3875H32.7617V16.4626H36.8368V12.3875Z"
        fill="black"
      />
      <path d="M65.362 12.3875H61.287V16.4626H65.362V12.3875Z" fill="black" />
      <path
        d="M89.8122 12.3875H85.7372V16.4626H89.8122V12.3875Z"
        fill="black"
      />
      <path
        d="M93.8873 12.3875H89.8122V16.4626H93.8873V12.3875Z"
        fill="black"
      />
      <path
        d="M102.037 12.3875H97.9623V16.4626H102.037V12.3875Z"
        fill="black"
      />
      <path
        d="M118.338 12.3875H114.262V16.4626H118.338V12.3875Z"
        fill="black"
      />
      <path
        d="M126.488 12.3875H122.413V16.4626H126.488V12.3875Z"
        fill="black"
      />
      <path
        d="M130.563 12.3875H126.488V16.4626H130.563V12.3875Z"
        fill="black"
      />
      <path
        d="M138.713 12.3875H134.638V16.4626H138.713V12.3875Z"
        fill="black"
      />
      <path
        d="M155.013 12.3875H150.938V16.4626H155.013V12.3875Z"
        fill="black"
      />
      <path
        d="M167.238 12.3875H163.163V16.4626H167.238V12.3875Z"
        fill="black"
      />
      <path
        d="M191.688 12.3875H187.613V16.4626H191.688V12.3875Z"
        fill="black"
      />
      <path
        d="M12.3866 16.4626H8.31152V20.5376H12.3866V16.4626Z"
        fill="black"
      />
      <path
        d="M20.5366 16.4626H16.4616V20.5376H20.5366V16.4626Z"
        fill="black"
      />
      <path
        d="M24.6117 16.4626H20.5366V20.5376H24.6117V16.4626Z"
        fill="black"
      />
      <path
        d="M28.6867 16.4626H24.6117V20.5376H28.6867V16.4626Z"
        fill="black"
      />
      <path
        d="M36.8368 16.4626H32.7617V20.5376H36.8368V16.4626Z"
        fill="black"
      />
      <path
        d="M49.0619 16.4626H44.9869V20.5376H49.0619V16.4626Z"
        fill="black"
      />
      <path
        d="M53.1369 16.4626H49.0619V20.5376H53.1369V16.4626Z"
        fill="black"
      />
      <path d="M61.287 16.4626H57.212V20.5376H61.287V16.4626Z" fill="black" />
      <path d="M65.362 16.4626H61.287V20.5376H65.362V16.4626Z" fill="black" />
      <path d="M69.4371 16.4626H65.362V20.5376H69.4371V16.4626Z" fill="black" />
      <path
        d="M73.5121 16.4626H69.4371V20.5376H73.5121V16.4626Z"
        fill="black"
      />
      <path
        d="M85.7372 16.4626H81.6622V20.5376H85.7372V16.4626Z"
        fill="black"
      />
      <path
        d="M89.8122 16.4626H85.7372V20.5376H89.8122V16.4626Z"
        fill="black"
      />
      <path
        d="M102.037 16.4626H97.9623V20.5376H102.037V16.4626Z"
        fill="black"
      />
      <path
        d="M114.262 16.4626H110.187V20.5376H114.262V16.4626Z"
        fill="black"
      />
      <path
        d="M118.338 16.4626H114.262V20.5376H118.338V16.4626Z"
        fill="black"
      />
      <path
        d="M122.413 16.4626H118.338V20.5376H122.413V16.4626Z"
        fill="black"
      />
      <path
        d="M130.563 16.4626H126.488V20.5376H130.563V16.4626Z"
        fill="black"
      />
      <path
        d="M142.788 16.4626H138.713V20.5376H142.788V16.4626Z"
        fill="black"
      />
      <path
        d="M155.013 16.4626H150.938V20.5376H155.013V16.4626Z"
        fill="black"
      />
      <path
        d="M167.238 16.4626H163.163V20.5376H167.238V16.4626Z"
        fill="black"
      />
      <path
        d="M175.388 16.4626H171.313V20.5376H175.388V16.4626Z"
        fill="black"
      />
      <path
        d="M179.463 16.4626H175.388V20.5376H179.463V16.4626Z"
        fill="black"
      />
      <path
        d="M183.538 16.4626H179.463V20.5376H183.538V16.4626Z"
        fill="black"
      />
      <path
        d="M191.688 16.4626H187.613V20.5376H191.688V16.4626Z"
        fill="black"
      />
      <path
        d="M12.3866 20.5376H8.31152V24.6126H12.3866V20.5376Z"
        fill="black"
      />
      <path
        d="M20.5366 20.5376H16.4616V24.6126H20.5366V20.5376Z"
        fill="black"
      />
      <path
        d="M24.6117 20.5376H20.5366V24.6126H24.6117V20.5376Z"
        fill="black"
      />
      <path
        d="M28.6867 20.5376H24.6117V24.6126H28.6867V20.5376Z"
        fill="black"
      />
      <path
        d="M36.8368 20.5376H32.7617V24.6126H36.8368V20.5376Z"
        fill="black"
      />
      <path
        d="M44.9869 20.5376H40.9118V24.6126H44.9869V20.5376Z"
        fill="black"
      />
      <path
        d="M49.0619 20.5376H44.9869V24.6126H49.0619V20.5376Z"
        fill="black"
      />
      <path d="M57.212 20.5376H53.1369V24.6126H57.212V20.5376Z" fill="black" />
      <path d="M61.287 20.5376H57.212V24.6126H61.287V20.5376Z" fill="black" />
      <path d="M65.362 20.5376H61.287V24.6126H65.362V20.5376Z" fill="black" />
      <path
        d="M77.5871 20.5376H73.5121V24.6126H77.5871V20.5376Z"
        fill="black"
      />
      <path
        d="M85.7372 20.5376H81.6622V24.6126H85.7372V20.5376Z"
        fill="black"
      />
      <path
        d="M97.9623 20.5376H93.8873V24.6126H97.9623V20.5376Z"
        fill="black"
      />
      <path
        d="M106.112 20.5376H102.037V24.6126H106.112V20.5376Z"
        fill="black"
      />
      <path
        d="M118.338 20.5376H114.262V24.6126H118.338V20.5376Z"
        fill="black"
      />
      <path
        d="M134.638 20.5376H130.563V24.6126H134.638V20.5376Z"
        fill="black"
      />
      <path
        d="M138.713 20.5376H134.638V24.6126H138.713V20.5376Z"
        fill="black"
      />
      <path
        d="M142.788 20.5376H138.713V24.6126H142.788V20.5376Z"
        fill="black"
      />
      <path
        d="M146.863 20.5376H142.788V24.6126H146.863V20.5376Z"
        fill="black"
      />
      <path
        d="M155.013 20.5376H150.938V24.6126H155.013V20.5376Z"
        fill="black"
      />
      <path
        d="M159.088 20.5376H155.013V24.6126H159.088V20.5376Z"
        fill="black"
      />
      <path
        d="M167.238 20.5376H163.163V24.6126H167.238V20.5376Z"
        fill="black"
      />
      <path
        d="M175.388 20.5376H171.313V24.6126H175.388V20.5376Z"
        fill="black"
      />
      <path
        d="M179.463 20.5376H175.388V24.6126H179.463V20.5376Z"
        fill="black"
      />
      <path
        d="M183.538 20.5376H179.463V24.6126H183.538V20.5376Z"
        fill="black"
      />
      <path
        d="M191.688 20.5376H187.613V24.6126H191.688V20.5376Z"
        fill="black"
      />
      <path
        d="M12.3866 24.6126H8.31152V28.6877H12.3866V24.6126Z"
        fill="black"
      />
      <path
        d="M20.5366 24.6126H16.4616V28.6877H20.5366V24.6126Z"
        fill="black"
      />
      <path
        d="M24.6117 24.6126H20.5366V28.6877H24.6117V24.6126Z"
        fill="black"
      />
      <path
        d="M28.6867 24.6126H24.6117V28.6877H28.6867V24.6126Z"
        fill="black"
      />
      <path
        d="M36.8368 24.6126H32.7617V28.6877H36.8368V24.6126Z"
        fill="black"
      />
      <path
        d="M44.9869 24.6126H40.9118V28.6877H44.9869V24.6126Z"
        fill="black"
      />
      <path d="M57.212 24.6126H53.1369V28.6877H57.212V24.6126Z" fill="black" />
      <path d="M69.4371 24.6126H65.362V28.6877H69.4371V24.6126Z" fill="black" />
      <path
        d="M81.6622 24.6126H77.5871V28.6877H81.6622V24.6126Z"
        fill="black"
      />
      <path
        d="M89.8122 24.6126H85.7372V28.6877H89.8122V24.6126Z"
        fill="black"
      />
      <path
        d="M93.8873 24.6126H89.8122V28.6877H93.8873V24.6126Z"
        fill="black"
      />
      <path
        d="M97.9623 24.6126H93.8873V28.6877H97.9623V24.6126Z"
        fill="black"
      />
      <path
        d="M102.037 24.6126H97.9623V28.6877H102.037V24.6126Z"
        fill="black"
      />
      <path
        d="M106.112 24.6126H102.037V28.6877H106.112V24.6126Z"
        fill="black"
      />
      <path
        d="M110.187 24.6126H106.112V28.6877H110.187V24.6126Z"
        fill="black"
      />
      <path
        d="M122.413 24.6126H118.338V28.6877H122.413V24.6126Z"
        fill="black"
      />
      <path
        d="M126.488 24.6126H122.413V28.6877H126.488V24.6126Z"
        fill="black"
      />
      <path
        d="M130.563 24.6126H126.488V28.6877H130.563V24.6126Z"
        fill="black"
      />
      <path
        d="M134.638 24.6126H130.563V28.6877H134.638V24.6126Z"
        fill="black"
      />
      <path
        d="M142.788 24.6126H138.713V28.6877H142.788V24.6126Z"
        fill="black"
      />
      <path
        d="M146.863 24.6126H142.788V28.6877H146.863V24.6126Z"
        fill="black"
      />
      <path
        d="M150.938 24.6126H146.863V28.6877H150.938V24.6126Z"
        fill="black"
      />
      <path
        d="M155.013 24.6126H150.938V28.6877H155.013V24.6126Z"
        fill="black"
      />
      <path
        d="M159.088 24.6126H155.013V28.6877H159.088V24.6126Z"
        fill="black"
      />
      <path
        d="M167.238 24.6126H163.163V28.6877H167.238V24.6126Z"
        fill="black"
      />
      <path
        d="M175.388 24.6126H171.313V28.6877H175.388V24.6126Z"
        fill="black"
      />
      <path
        d="M179.463 24.6126H175.388V28.6877H179.463V24.6126Z"
        fill="black"
      />
      <path
        d="M183.538 24.6126H179.463V28.6877H183.538V24.6126Z"
        fill="black"
      />
      <path
        d="M191.688 24.6126H187.613V28.6877H191.688V24.6126Z"
        fill="black"
      />
      <path
        d="M12.3866 28.6877H8.31152V32.7627H12.3866V28.6877Z"
        fill="black"
      />
      <path
        d="M36.8368 28.6877H32.7617V32.7627H36.8368V28.6877Z"
        fill="black"
      />
      <path
        d="M44.9869 28.6877H40.9118V32.7627H44.9869V28.6877Z"
        fill="black"
      />
      <path
        d="M49.0619 28.6877H44.9869V32.7627H49.0619V28.6877Z"
        fill="black"
      />
      <path
        d="M53.1369 28.6877H49.0619V32.7627H53.1369V28.6877Z"
        fill="black"
      />
      <path
        d="M77.5871 28.6877H73.5121V32.7627H77.5871V28.6877Z"
        fill="black"
      />
      <path
        d="M89.8122 28.6877H85.7372V32.7627H89.8122V28.6877Z"
        fill="black"
      />
      <path
        d="M93.8873 28.6877H89.8122V32.7627H93.8873V28.6877Z"
        fill="black"
      />
      <path
        d="M110.187 28.6877H106.112V32.7627H110.187V28.6877Z"
        fill="black"
      />
      <path
        d="M126.488 28.6877H122.413V32.7627H126.488V28.6877Z"
        fill="black"
      />
      <path
        d="M142.788 28.6877H138.713V32.7627H142.788V28.6877Z"
        fill="black"
      />
      <path
        d="M167.238 28.6877H163.163V32.7627H167.238V28.6877Z"
        fill="black"
      />
      <path
        d="M191.688 28.6877H187.613V32.7627H191.688V28.6877Z"
        fill="black"
      />
      <path
        d="M12.3866 32.7627H8.31152V36.8378H12.3866V32.7627Z"
        fill="black"
      />
      <path
        d="M16.4616 32.7627H12.3866V36.8378H16.4616V32.7627Z"
        fill="black"
      />
      <path
        d="M20.5366 32.7627H16.4616V36.8378H20.5366V32.7627Z"
        fill="black"
      />
      <path
        d="M24.6117 32.7627H20.5366V36.8378H24.6117V32.7627Z"
        fill="black"
      />
      <path
        d="M28.6867 32.7627H24.6117V36.8378H28.6867V32.7627Z"
        fill="black"
      />
      <path
        d="M32.7617 32.7627H28.6867V36.8378H32.7617V32.7627Z"
        fill="black"
      />
      <path
        d="M36.8368 32.7627H32.7617V36.8378H36.8368V32.7627Z"
        fill="black"
      />
      <path
        d="M44.9869 32.7627H40.9118V36.8378H44.9869V32.7627Z"
        fill="black"
      />
      <path
        d="M53.1369 32.7627H49.0619V36.8378H53.1369V32.7627Z"
        fill="black"
      />
      <path d="M61.287 32.7627H57.212V36.8378H61.287V32.7627Z" fill="black" />
      <path d="M69.4371 32.7627H65.362V36.8378H69.4371V32.7627Z" fill="black" />
      <path
        d="M77.5871 32.7627H73.5121V36.8378H77.5871V32.7627Z"
        fill="black"
      />
      <path
        d="M85.7372 32.7627H81.6622V36.8378H85.7372V32.7627Z"
        fill="black"
      />
      <path
        d="M93.8873 32.7627H89.8122V36.8378H93.8873V32.7627Z"
        fill="black"
      />
      <path
        d="M102.037 32.7627H97.9623V36.8378H102.037V32.7627Z"
        fill="black"
      />
      <path
        d="M110.187 32.7627H106.112V36.8378H110.187V32.7627Z"
        fill="black"
      />
      <path
        d="M118.338 32.7627H114.262V36.8378H118.338V32.7627Z"
        fill="black"
      />
      <path
        d="M126.488 32.7627H122.413V36.8378H126.488V32.7627Z"
        fill="black"
      />
      <path
        d="M134.638 32.7627H130.563V36.8378H134.638V32.7627Z"
        fill="black"
      />
      <path
        d="M142.788 32.7627H138.713V36.8378H142.788V32.7627Z"
        fill="black"
      />
      <path
        d="M150.938 32.7627H146.863V36.8378H150.938V32.7627Z"
        fill="black"
      />
      <path
        d="M159.088 32.7627H155.013V36.8378H159.088V32.7627Z"
        fill="black"
      />
      <path
        d="M167.238 32.7627H163.163V36.8378H167.238V32.7627Z"
        fill="black"
      />
      <path
        d="M171.313 32.7627H167.238V36.8378H171.313V32.7627Z"
        fill="black"
      />
      <path
        d="M175.388 32.7627H171.313V36.8378H175.388V32.7627Z"
        fill="black"
      />
      <path
        d="M179.463 32.7627H175.388V36.8378H179.463V32.7627Z"
        fill="black"
      />
      <path
        d="M183.538 32.7627H179.463V36.8378H183.538V32.7627Z"
        fill="black"
      />
      <path
        d="M187.613 32.7627H183.538V36.8378H187.613V32.7627Z"
        fill="black"
      />
      <path
        d="M191.688 32.7627H187.613V36.8378H191.688V32.7627Z"
        fill="black"
      />
      <path
        d="M44.9869 36.8378H40.9118V40.9128H44.9869V36.8378Z"
        fill="black"
      />
      <path
        d="M49.0619 36.8378H44.9869V40.9128H49.0619V36.8378Z"
        fill="black"
      />
      <path
        d="M53.1369 36.8378H49.0619V40.9128H53.1369V36.8378Z"
        fill="black"
      />
      <path
        d="M85.7372 36.8378H81.6622V40.9128H85.7372V36.8378Z"
        fill="black"
      />
      <path
        d="M89.8122 36.8378H85.7372V40.9128H89.8122V36.8378Z"
        fill="black"
      />
      <path
        d="M93.8873 36.8378H89.8122V40.9128H93.8873V36.8378Z"
        fill="black"
      />
      <path
        d="M110.187 36.8378H106.112V40.9128H110.187V36.8378Z"
        fill="black"
      />
      <path
        d="M118.338 36.8378H114.262V40.9128H118.338V36.8378Z"
        fill="black"
      />
      <path
        d="M122.413 36.8378H118.338V40.9128H122.413V36.8378Z"
        fill="black"
      />
      <path
        d="M130.563 36.8378H126.488V40.9128H130.563V36.8378Z"
        fill="black"
      />
      <path
        d="M134.638 36.8378H130.563V40.9128H134.638V36.8378Z"
        fill="black"
      />
      <path
        d="M138.713 36.8378H134.638V40.9128H138.713V36.8378Z"
        fill="black"
      />
      <path
        d="M142.788 36.8378H138.713V40.9128H142.788V36.8378Z"
        fill="black"
      />
      <path
        d="M146.863 36.8378H142.788V40.9128H146.863V36.8378Z"
        fill="black"
      />
      <path
        d="M155.013 36.8378H150.938V40.9128H155.013V36.8378Z"
        fill="black"
      />
      <path
        d="M159.088 36.8378H155.013V40.9128H159.088V36.8378Z"
        fill="black"
      />
      <path
        d="M12.3866 40.9128H8.31152V44.9878H12.3866V40.9128Z"
        fill="black"
      />
      <path
        d="M16.4616 40.9128H12.3866V44.9878H16.4616V40.9128Z"
        fill="black"
      />
      <path
        d="M20.5366 40.9128H16.4616V44.9878H20.5366V40.9128Z"
        fill="black"
      />
      <path
        d="M28.6867 40.9128H24.6117V44.9878H28.6867V40.9128Z"
        fill="black"
      />
      <path
        d="M36.8368 40.9128H32.7617V44.9878H36.8368V40.9128Z"
        fill="black"
      />
      <path
        d="M44.9869 40.9128H40.9118V44.9878H44.9869V40.9128Z"
        fill="black"
      />
      <path
        d="M49.0619 40.9128H44.9869V44.9878H49.0619V40.9128Z"
        fill="black"
      />
      <path d="M57.212 40.9128H53.1369V44.9878H57.212V40.9128Z" fill="black" />
      <path d="M61.287 40.9128H57.212V44.9878H61.287V40.9128Z" fill="black" />
      <path
        d="M77.5871 40.9128H73.5121V44.9878H77.5871V40.9128Z"
        fill="black"
      />
      <path
        d="M85.7372 40.9128H81.6622V44.9878H85.7372V40.9128Z"
        fill="black"
      />
      <path
        d="M93.8873 40.9128H89.8122V44.9878H93.8873V40.9128Z"
        fill="black"
      />
      <path
        d="M97.9623 40.9128H93.8873V44.9878H97.9623V40.9128Z"
        fill="black"
      />
      <path
        d="M102.037 40.9128H97.9623V44.9878H102.037V40.9128Z"
        fill="black"
      />
      <path
        d="M106.112 40.9128H102.037V44.9878H106.112V40.9128Z"
        fill="black"
      />
      <path
        d="M110.187 40.9128H106.112V44.9878H110.187V40.9128Z"
        fill="black"
      />
      <path
        d="M114.262 40.9128H110.187V44.9878H114.262V40.9128Z"
        fill="black"
      />
      <path
        d="M122.413 40.9128H118.338V44.9878H122.413V40.9128Z"
        fill="black"
      />
      <path
        d="M126.488 40.9128H122.413V44.9878H126.488V40.9128Z"
        fill="black"
      />
      <path
        d="M130.563 40.9128H126.488V44.9878H130.563V40.9128Z"
        fill="black"
      />
      <path
        d="M134.638 40.9128H130.563V44.9878H134.638V40.9128Z"
        fill="black"
      />
      <path
        d="M138.713 40.9128H134.638V44.9878H138.713V40.9128Z"
        fill="black"
      />
      <path
        d="M142.788 40.9128H138.713V44.9878H142.788V40.9128Z"
        fill="black"
      />
      <path
        d="M150.938 40.9128H146.863V44.9878H150.938V40.9128Z"
        fill="black"
      />
      <path
        d="M155.013 40.9128H150.938V44.9878H155.013V40.9128Z"
        fill="black"
      />
      <path
        d="M163.163 40.9128H159.088V44.9878H163.163V40.9128Z"
        fill="black"
      />
      <path
        d="M167.238 40.9128H163.163V44.9878H167.238V40.9128Z"
        fill="black"
      />
      <path
        d="M171.313 40.9128H167.238V44.9878H171.313V40.9128Z"
        fill="black"
      />
      <path
        d="M175.388 40.9128H171.313V44.9878H175.388V40.9128Z"
        fill="black"
      />
      <path
        d="M179.463 40.9128H175.388V44.9878H179.463V40.9128Z"
        fill="black"
      />
      <path
        d="M191.688 40.9128H187.613V44.9878H191.688V40.9128Z"
        fill="black"
      />
      <path
        d="M20.5366 44.9878H16.4616V49.0629H20.5366V44.9878Z"
        fill="black"
      />
      <path
        d="M24.6117 44.9878H20.5366V49.0629H24.6117V44.9878Z"
        fill="black"
      />
      <path
        d="M32.7617 44.9878H28.6867V49.0629H32.7617V44.9878Z"
        fill="black"
      />
      <path
        d="M40.9118 44.9878H36.8368V49.0629H40.9118V44.9878Z"
        fill="black"
      />
      <path
        d="M49.0619 44.9878H44.9869V49.0629H49.0619V44.9878Z"
        fill="black"
      />
      <path d="M61.287 44.9878H57.212V49.0629H61.287V44.9878Z" fill="black" />
      <path d="M65.362 44.9878H61.287V49.0629H65.362V44.9878Z" fill="black" />
      <path
        d="M73.5121 44.9878H69.4371V49.0629H73.5121V44.9878Z"
        fill="black"
      />
      <path
        d="M77.5871 44.9878H73.5121V49.0629H77.5871V44.9878Z"
        fill="black"
      />
      <path
        d="M81.6622 44.9878H77.5871V49.0629H81.6622V44.9878Z"
        fill="black"
      />
      <path
        d="M85.7372 44.9878H81.6622V49.0629H85.7372V44.9878Z"
        fill="black"
      />
      <path
        d="M89.8122 44.9878H85.7372V49.0629H89.8122V44.9878Z"
        fill="black"
      />
      <path
        d="M97.9623 44.9878H93.8873V49.0629H97.9623V44.9878Z"
        fill="black"
      />
      <path
        d="M102.037 44.9878H97.9623V49.0629H102.037V44.9878Z"
        fill="black"
      />
      <path
        d="M106.112 44.9878H102.037V49.0629H106.112V44.9878Z"
        fill="black"
      />
      <path
        d="M114.262 44.9878H110.187V49.0629H114.262V44.9878Z"
        fill="black"
      />
      <path
        d="M118.338 44.9878H114.262V49.0629H118.338V44.9878Z"
        fill="black"
      />
      <path
        d="M126.488 44.9878H122.413V49.0629H126.488V44.9878Z"
        fill="black"
      />
      <path
        d="M142.788 44.9878H138.713V49.0629H142.788V44.9878Z"
        fill="black"
      />
      <path
        d="M146.863 44.9878H142.788V49.0629H146.863V44.9878Z"
        fill="black"
      />
      <path
        d="M150.938 44.9878H146.863V49.0629H150.938V44.9878Z"
        fill="black"
      />
      <path
        d="M167.238 44.9878H163.163V49.0629H167.238V44.9878Z"
        fill="black"
      />
      <path
        d="M171.313 44.9878H167.238V49.0629H171.313V44.9878Z"
        fill="black"
      />
      <path
        d="M175.388 44.9878H171.313V49.0629H175.388V44.9878Z"
        fill="black"
      />
      <path
        d="M187.613 44.9878H183.538V49.0629H187.613V44.9878Z"
        fill="black"
      />
      <path
        d="M191.688 44.9878H187.613V49.0629H191.688V44.9878Z"
        fill="black"
      />
      <path
        d="M12.3866 49.0629H8.31152V53.1379H12.3866V49.0629Z"
        fill="black"
      />
      <path
        d="M20.5366 49.0629H16.4616V53.1379H20.5366V49.0629Z"
        fill="black"
      />
      <path
        d="M24.6117 49.0629H20.5366V53.1379H24.6117V49.0629Z"
        fill="black"
      />
      <path
        d="M36.8368 49.0629H32.7617V53.1379H36.8368V49.0629Z"
        fill="black"
      />
      <path
        d="M40.9118 49.0629H36.8368V53.1379H40.9118V49.0629Z"
        fill="black"
      />
      <path
        d="M49.0619 49.0629H44.9869V53.1379H49.0619V49.0629Z"
        fill="black"
      />
      <path
        d="M53.1369 49.0629H49.0619V53.1379H53.1369V49.0629Z"
        fill="black"
      />
      <path d="M57.212 49.0629H53.1369V53.1379H57.212V49.0629Z" fill="black" />
      <path d="M65.362 49.0629H61.287V53.1379H65.362V49.0629Z" fill="black" />
      <path
        d="M73.5121 49.0629H69.4371V53.1379H73.5121V49.0629Z"
        fill="black"
      />
      <path
        d="M89.8122 49.0629H85.7372V53.1379H89.8122V49.0629Z"
        fill="black"
      />
      <path
        d="M93.8873 49.0629H89.8122V53.1379H93.8873V49.0629Z"
        fill="black"
      />
      <path
        d="M102.037 49.0629H97.9623V53.1379H102.037V49.0629Z"
        fill="black"
      />
      <path
        d="M110.187 49.0629H106.112V53.1379H110.187V49.0629Z"
        fill="black"
      />
      <path
        d="M122.413 49.0629H118.338V53.1379H122.413V49.0629Z"
        fill="black"
      />
      <path
        d="M134.638 49.0629H130.563V53.1379H134.638V49.0629Z"
        fill="black"
      />
      <path
        d="M138.713 49.0629H134.638V53.1379H138.713V49.0629Z"
        fill="black"
      />
      <path
        d="M142.788 49.0629H138.713V53.1379H142.788V49.0629Z"
        fill="black"
      />
      <path
        d="M163.163 49.0629H159.088V53.1379H163.163V49.0629Z"
        fill="black"
      />
      <path
        d="M167.238 49.0629H163.163V53.1379H167.238V49.0629Z"
        fill="black"
      />
      <path
        d="M171.313 49.0629H167.238V53.1379H171.313V49.0629Z"
        fill="black"
      />
      <path
        d="M28.6867 53.1379H24.6117V57.2129H28.6867V53.1379Z"
        fill="black"
      />
      <path
        d="M40.9118 53.1379H36.8368V57.2129H40.9118V53.1379Z"
        fill="black"
      />
      <path
        d="M49.0619 53.1379H44.9869V57.2129H49.0619V53.1379Z"
        fill="black"
      />
      <path
        d="M53.1369 53.1379H49.0619V57.2129H53.1369V53.1379Z"
        fill="black"
      />
      <path d="M57.212 53.1379H53.1369V57.2129H57.212V53.1379Z" fill="black" />
      <path d="M61.287 53.1379H57.212V57.2129H61.287V53.1379Z" fill="black" />
      <path
        d="M73.5121 53.1379H69.4371V57.2129H73.5121V53.1379Z"
        fill="black"
      />
      <path
        d="M77.5871 53.1379H73.5121V57.2129H77.5871V53.1379Z"
        fill="black"
      />
      <path
        d="M89.8122 53.1379H85.7372V57.2129H89.8122V53.1379Z"
        fill="black"
      />
      <path
        d="M93.8873 53.1379H89.8122V57.2129H93.8873V53.1379Z"
        fill="black"
      />
      <path
        d="M97.9623 53.1379H93.8873V57.2129H97.9623V53.1379Z"
        fill="black"
      />
      <path
        d="M106.112 53.1379H102.037V57.2129H106.112V53.1379Z"
        fill="black"
      />
      <path
        d="M114.262 53.1379H110.187V57.2129H114.262V53.1379Z"
        fill="black"
      />
      <path
        d="M122.413 53.1379H118.338V57.2129H122.413V53.1379Z"
        fill="black"
      />
      <path
        d="M126.488 53.1379H122.413V57.2129H126.488V53.1379Z"
        fill="black"
      />
      <path
        d="M146.863 53.1379H142.788V57.2129H146.863V53.1379Z"
        fill="black"
      />
      <path
        d="M150.938 53.1379H146.863V57.2129H150.938V53.1379Z"
        fill="black"
      />
      <path
        d="M155.013 53.1379H150.938V57.2129H155.013V53.1379Z"
        fill="black"
      />
      <path
        d="M159.088 53.1379H155.013V57.2129H159.088V53.1379Z"
        fill="black"
      />
      <path
        d="M163.163 53.1379H159.088V57.2129H163.163V53.1379Z"
        fill="black"
      />
      <path
        d="M167.238 53.1379H163.163V57.2129H167.238V53.1379Z"
        fill="black"
      />
      <path
        d="M171.313 53.1379H167.238V57.2129H171.313V53.1379Z"
        fill="black"
      />
      <path
        d="M179.463 53.1379H175.388V57.2129H179.463V53.1379Z"
        fill="black"
      />
      <path
        d="M191.688 53.1379H187.613V57.2129H191.688V53.1379Z"
        fill="black"
      />
      <path d="M20.5366 57.2129H16.4616V61.288H20.5366V57.2129Z" fill="black" />
      <path d="M24.6117 57.2129H20.5366V61.288H24.6117V57.2129Z" fill="black" />
      <path d="M28.6867 57.2129H24.6117V61.288H28.6867V57.2129Z" fill="black" />
      <path d="M36.8368 57.2129H32.7617V61.288H36.8368V57.2129Z" fill="black" />
      <path d="M40.9118 57.2129H36.8368V61.288H40.9118V57.2129Z" fill="black" />
      <path d="M44.9869 57.2129H40.9118V61.288H44.9869V57.2129Z" fill="black" />
      <path d="M49.0619 57.2129H44.9869V61.288H49.0619V57.2129Z" fill="black" />
      <path d="M53.1369 57.2129H49.0619V61.288H53.1369V57.2129Z" fill="black" />
      <path d="M57.212 57.2129H53.1369V61.288H57.212V57.2129Z" fill="black" />
      <path d="M65.362 57.2129H61.287V61.288H65.362V57.2129Z" fill="black" />
      <path d="M73.5121 57.2129H69.4371V61.288H73.5121V57.2129Z" fill="black" />
      <path d="M81.6622 57.2129H77.5871V61.288H81.6622V57.2129Z" fill="black" />
      <path d="M85.7372 57.2129H81.6622V61.288H85.7372V57.2129Z" fill="black" />
      <path d="M97.9623 57.2129H93.8873V61.288H97.9623V57.2129Z" fill="black" />
      <path d="M106.112 57.2129H102.037V61.288H106.112V57.2129Z" fill="black" />
      <path d="M110.187 57.2129H106.112V61.288H110.187V57.2129Z" fill="black" />
      <path d="M118.338 57.2129H114.262V61.288H118.338V57.2129Z" fill="black" />
      <path d="M126.488 57.2129H122.413V61.288H126.488V57.2129Z" fill="black" />
      <path d="M142.788 57.2129H138.713V61.288H142.788V57.2129Z" fill="black" />
      <path d="M155.013 57.2129H150.938V61.288H155.013V57.2129Z" fill="black" />
      <path d="M159.088 57.2129H155.013V61.288H159.088V57.2129Z" fill="black" />
      <path d="M175.388 57.2129H171.313V61.288H175.388V57.2129Z" fill="black" />
      <path d="M183.538 57.2129H179.463V61.288H183.538V57.2129Z" fill="black" />
      <path d="M187.613 57.2129H183.538V61.288H187.613V57.2129Z" fill="black" />
      <path d="M16.4616 61.288H12.3866V65.363H16.4616V61.288Z" fill="black" />
      <path d="M20.5366 61.288H16.4616V65.363H20.5366V61.288Z" fill="black" />
      <path d="M24.6117 61.288H20.5366V65.363H24.6117V61.288Z" fill="black" />
      <path d="M28.6867 61.288H24.6117V65.363H28.6867V61.288Z" fill="black" />
      <path d="M32.7617 61.288H28.6867V65.363H32.7617V61.288Z" fill="black" />
      <path d="M49.0619 61.288H44.9869V65.363H49.0619V61.288Z" fill="black" />
      <path d="M57.212 61.288H53.1369V65.363H57.212V61.288Z" fill="black" />
      <path d="M65.362 61.288H61.287V65.363H65.362V61.288Z" fill="black" />
      <path d="M81.6622 61.288H77.5871V65.363H81.6622V61.288Z" fill="black" />
      <path d="M93.8873 61.288H89.8122V65.363H93.8873V61.288Z" fill="black" />
      <path d="M106.112 61.288H102.037V65.363H106.112V61.288Z" fill="black" />
      <path d="M110.187 61.288H106.112V65.363H110.187V61.288Z" fill="black" />
      <path d="M122.413 61.288H118.338V65.363H122.413V61.288Z" fill="black" />
      <path d="M130.563 61.288H126.488V65.363H130.563V61.288Z" fill="black" />
      <path d="M142.788 61.288H138.713V65.363H142.788V61.288Z" fill="black" />
      <path d="M146.863 61.288H142.788V65.363H146.863V61.288Z" fill="black" />
      <path d="M159.088 61.288H155.013V65.363H159.088V61.288Z" fill="black" />
      <path d="M171.313 61.288H167.238V65.363H171.313V61.288Z" fill="black" />
      <path d="M175.388 61.288H171.313V65.363H175.388V61.288Z" fill="black" />
      <path d="M187.613 61.288H183.538V65.363H187.613V61.288Z" fill="black" />
      <path d="M191.688 61.288H187.613V65.363H191.688V61.288Z" fill="black" />
      <path d="M24.6117 65.363H20.5366V69.438H24.6117V65.363Z" fill="black" />
      <path d="M32.7617 65.363H28.6867V69.438H32.7617V65.363Z" fill="black" />
      <path d="M36.8368 65.363H32.7617V69.438H36.8368V65.363Z" fill="black" />
      <path d="M40.9118 65.363H36.8368V69.438H40.9118V65.363Z" fill="black" />
      <path d="M44.9869 65.363H40.9118V69.438H44.9869V65.363Z" fill="black" />
      <path d="M57.212 65.363H53.1369V69.438H57.212V65.363Z" fill="black" />
      <path d="M65.362 65.363H61.287V69.438H65.362V65.363Z" fill="black" />
      <path d="M73.5121 65.363H69.4371V69.438H73.5121V65.363Z" fill="black" />
      <path d="M81.6622 65.363H77.5871V69.438H81.6622V65.363Z" fill="black" />
      <path d="M85.7372 65.363H81.6622V69.438H85.7372V65.363Z" fill="black" />
      <path d="M89.8122 65.363H85.7372V69.438H89.8122V65.363Z" fill="black" />
      <path d="M93.8873 65.363H89.8122V69.438H93.8873V65.363Z" fill="black" />
      <path d="M102.037 65.363H97.9623V69.438H102.037V65.363Z" fill="black" />
      <path d="M110.187 65.363H106.112V69.438H110.187V65.363Z" fill="black" />
      <path d="M118.338 65.363H114.262V69.438H118.338V65.363Z" fill="black" />
      <path d="M150.938 65.363H146.863V69.438H150.938V65.363Z" fill="black" />
      <path d="M155.013 65.363H150.938V69.438H155.013V65.363Z" fill="black" />
      <path d="M175.388 65.363H171.313V69.438H175.388V65.363Z" fill="black" />
      <path d="M179.463 65.363H175.388V69.438H179.463V65.363Z" fill="black" />
      <path d="M183.538 65.363H179.463V69.438H183.538V65.363Z" fill="black" />
      <path d="M191.688 65.363H187.613V69.438H191.688V65.363Z" fill="black" />
      <path d="M12.3866 69.438H8.31152V73.5131H12.3866V69.438Z" fill="black" />
      <path d="M16.4616 69.438H12.3866V73.5131H16.4616V69.438Z" fill="black" />
      <path d="M20.5366 69.438H16.4616V73.5131H20.5366V69.438Z" fill="black" />
      <path d="M53.1369 69.438H49.0619V73.5131H53.1369V69.438Z" fill="black" />
      <path d="M69.4371 69.438H65.362V73.5131H69.4371V69.438Z" fill="black" />
      <path d="M73.5121 69.438H69.4371V73.5131H73.5121V69.438Z" fill="black" />
      <path d="M85.7372 69.438H81.6622V73.5131H85.7372V69.438Z" fill="black" />
      <path d="M89.8122 69.438H85.7372V73.5131H89.8122V69.438Z" fill="black" />
      <path d="M93.8873 69.438H89.8122V73.5131H93.8873V69.438Z" fill="black" />
      <path d="M97.9623 69.438H93.8873V73.5131H97.9623V69.438Z" fill="black" />
      <path d="M102.037 69.438H97.9623V73.5131H102.037V69.438Z" fill="black" />
      <path d="M118.338 69.438H114.262V73.5131H118.338V69.438Z" fill="black" />
      <path d="M126.488 69.438H122.413V73.5131H126.488V69.438Z" fill="black" />
      <path d="M130.563 69.438H126.488V73.5131H130.563V69.438Z" fill="black" />
      <path d="M146.863 69.438H142.788V73.5131H146.863V69.438Z" fill="black" />
      <path d="M155.013 69.438H150.938V73.5131H155.013V69.438Z" fill="black" />
      <path d="M163.163 69.438H159.088V73.5131H163.163V69.438Z" fill="black" />
      <path d="M171.313 69.438H167.238V73.5131H171.313V69.438Z" fill="black" />
      <path d="M175.388 69.438H171.313V73.5131H175.388V69.438Z" fill="black" />
      <path
        d="M16.4616 73.5131H12.3866V77.5881H16.4616V73.5131Z"
        fill="black"
      />
      <path
        d="M20.5366 73.5131H16.4616V77.5881H20.5366V73.5131Z"
        fill="black"
      />
      <path
        d="M32.7617 73.5131H28.6867V77.5881H32.7617V73.5131Z"
        fill="black"
      />
      <path
        d="M36.8368 73.5131H32.7617V77.5881H36.8368V73.5131Z"
        fill="black"
      />
      <path
        d="M40.9118 73.5131H36.8368V77.5881H40.9118V73.5131Z"
        fill="black"
      />
      <path
        d="M49.0619 73.5131H44.9869V77.5881H49.0619V73.5131Z"
        fill="black"
      />
      <path d="M69.4371 73.5131H65.362V77.5881H69.4371V73.5131Z" fill="black" />
      <path
        d="M77.5871 73.5131H73.5121V77.5881H77.5871V73.5131Z"
        fill="black"
      />
      <path
        d="M81.6622 73.5131H77.5871V77.5881H81.6622V73.5131Z"
        fill="black"
      />
      <path
        d="M85.7372 73.5131H81.6622V77.5881H85.7372V73.5131Z"
        fill="black"
      />
      <path
        d="M97.9623 73.5131H93.8873V77.5881H97.9623V73.5131Z"
        fill="black"
      />
      <path
        d="M106.112 73.5131H102.037V77.5881H106.112V73.5131Z"
        fill="black"
      />
      <path
        d="M114.262 73.5131H110.187V77.5881H114.262V73.5131Z"
        fill="black"
      />
      <path
        d="M118.338 73.5131H114.262V77.5881H118.338V73.5131Z"
        fill="black"
      />
      <path
        d="M122.413 73.5131H118.338V77.5881H122.413V73.5131Z"
        fill="black"
      />
      <path
        d="M126.488 73.5131H122.413V77.5881H126.488V73.5131Z"
        fill="black"
      />
      <path
        d="M130.563 73.5131H126.488V77.5881H130.563V73.5131Z"
        fill="black"
      />
      <path
        d="M142.788 73.5131H138.713V77.5881H142.788V73.5131Z"
        fill="black"
      />
      <path
        d="M150.938 73.5131H146.863V77.5881H150.938V73.5131Z"
        fill="black"
      />
      <path
        d="M159.088 73.5131H155.013V77.5881H159.088V73.5131Z"
        fill="black"
      />
      <path
        d="M175.388 73.5131H171.313V77.5881H175.388V73.5131Z"
        fill="black"
      />
      <path
        d="M179.463 73.5131H175.388V77.5881H179.463V73.5131Z"
        fill="black"
      />
      <path
        d="M20.5366 77.5881H16.4616V81.6632H20.5366V77.5881Z"
        fill="black"
      />
      <path
        d="M24.6117 77.5881H20.5366V81.6632H24.6117V77.5881Z"
        fill="black"
      />
      <path
        d="M32.7617 77.5881H28.6867V81.6632H32.7617V77.5881Z"
        fill="black"
      />
      <path
        d="M40.9118 77.5881H36.8368V81.6632H40.9118V77.5881Z"
        fill="black"
      />
      <path
        d="M49.0619 77.5881H44.9869V81.6632H49.0619V77.5881Z"
        fill="black"
      />
      <path d="M57.212 77.5881H53.1369V81.6632H57.212V77.5881Z" fill="black" />
      <path d="M61.287 77.5881H57.212V81.6632H61.287V77.5881Z" fill="black" />
      <path d="M69.4371 77.5881H65.362V81.6632H69.4371V77.5881Z" fill="black" />
      <path
        d="M81.6622 77.5881H77.5871V81.6632H81.6622V77.5881Z"
        fill="black"
      />
      <path
        d="M89.8122 77.5881H85.7372V81.6632H89.8122V77.5881Z"
        fill="black"
      />
      <path
        d="M93.8873 77.5881H89.8122V81.6632H93.8873V77.5881Z"
        fill="black"
      />
      <path
        d="M97.9623 77.5881H93.8873V81.6632H97.9623V77.5881Z"
        fill="black"
      />
      <path
        d="M106.112 77.5881H102.037V81.6632H106.112V77.5881Z"
        fill="black"
      />
      <path
        d="M118.338 77.5881H114.262V81.6632H118.338V77.5881Z"
        fill="black"
      />
      <path
        d="M122.413 77.5881H118.338V81.6632H122.413V77.5881Z"
        fill="black"
      />
      <path
        d="M126.488 77.5881H122.413V81.6632H126.488V77.5881Z"
        fill="black"
      />
      <path
        d="M130.563 77.5881H126.488V81.6632H130.563V77.5881Z"
        fill="black"
      />
      <path
        d="M142.788 77.5881H138.713V81.6632H142.788V77.5881Z"
        fill="black"
      />
      <path
        d="M159.088 77.5881H155.013V81.6632H159.088V77.5881Z"
        fill="black"
      />
      <path
        d="M175.388 77.5881H171.313V81.6632H175.388V77.5881Z"
        fill="black"
      />
      <path
        d="M179.463 77.5881H175.388V81.6632H179.463V77.5881Z"
        fill="black"
      />
      <path
        d="M16.4616 81.6632H12.3866V85.7382H16.4616V81.6632Z"
        fill="black"
      />
      <path
        d="M20.5366 81.6632H16.4616V85.7382H20.5366V81.6632Z"
        fill="black"
      />
      <path
        d="M24.6117 81.6632H20.5366V85.7382H24.6117V81.6632Z"
        fill="black"
      />
      <path
        d="M32.7617 81.6632H28.6867V85.7382H32.7617V81.6632Z"
        fill="black"
      />
      <path
        d="M36.8368 81.6632H32.7617V85.7382H36.8368V81.6632Z"
        fill="black"
      />
      <path
        d="M40.9118 81.6632H36.8368V85.7382H40.9118V81.6632Z"
        fill="black"
      />
      <path
        d="M44.9869 81.6632H40.9118V85.7382H44.9869V81.6632Z"
        fill="black"
      />
      <path
        d="M49.0619 81.6632H44.9869V85.7382H49.0619V81.6632Z"
        fill="black"
      />
      <path
        d="M53.1369 81.6632H49.0619V85.7382H53.1369V81.6632Z"
        fill="black"
      />
      <path d="M57.212 81.6632H53.1369V85.7382H57.212V81.6632Z" fill="black" />
      <path d="M61.287 81.6632H57.212V85.7382H61.287V81.6632Z" fill="black" />
      <path d="M69.4371 81.6632H65.362V85.7382H69.4371V81.6632Z" fill="black" />
      <path
        d="M73.5121 81.6632H69.4371V85.7382H73.5121V81.6632Z"
        fill="black"
      />
      <path
        d="M77.5871 81.6632H73.5121V85.7382H77.5871V81.6632Z"
        fill="black"
      />
      <path
        d="M85.7372 81.6632H81.6622V85.7382H85.7372V81.6632Z"
        fill="black"
      />
      <path
        d="M89.8122 81.6632H85.7372V85.7382H89.8122V81.6632Z"
        fill="black"
      />
      <path
        d="M93.8873 81.6632H89.8122V85.7382H93.8873V81.6632Z"
        fill="black"
      />
      <path
        d="M114.262 81.6632H110.187V85.7382H114.262V81.6632Z"
        fill="black"
      />
      <path
        d="M118.338 81.6632H114.262V85.7382H118.338V81.6632Z"
        fill="black"
      />
      <path
        d="M134.638 81.6632H130.563V85.7382H134.638V81.6632Z"
        fill="black"
      />
      <path
        d="M146.863 81.6632H142.788V85.7382H146.863V81.6632Z"
        fill="black"
      />
      <path
        d="M150.938 81.6632H146.863V85.7382H150.938V81.6632Z"
        fill="black"
      />
      <path
        d="M155.013 81.6632H150.938V85.7382H155.013V81.6632Z"
        fill="black"
      />
      <path
        d="M171.313 81.6632H167.238V85.7382H171.313V81.6632Z"
        fill="black"
      />
      <path
        d="M175.388 81.6632H171.313V85.7382H175.388V81.6632Z"
        fill="black"
      />
      <path
        d="M187.613 81.6632H183.538V85.7382H187.613V81.6632Z"
        fill="black"
      />
      <path
        d="M191.688 81.6632H187.613V85.7382H191.688V81.6632Z"
        fill="black"
      />
      <path
        d="M16.4616 85.7382H12.3866V89.8132H16.4616V85.7382Z"
        fill="black"
      />
      <path
        d="M28.6867 85.7382H24.6117V89.8132H28.6867V85.7382Z"
        fill="black"
      />
      <path
        d="M44.9869 85.7382H40.9118V89.8132H44.9869V85.7382Z"
        fill="black"
      />
      <path
        d="M49.0619 85.7382H44.9869V89.8132H49.0619V85.7382Z"
        fill="black"
      />
      <path d="M57.212 85.7382H53.1369V89.8132H57.212V85.7382Z" fill="black" />
      <path d="M61.287 85.7382H57.212V89.8132H61.287V85.7382Z" fill="black" />
      <path d="M69.4371 85.7382H65.362V89.8132H69.4371V85.7382Z" fill="black" />
      <path
        d="M73.5121 85.7382H69.4371V89.8132H73.5121V85.7382Z"
        fill="black"
      />
      <path
        d="M85.7372 85.7382H81.6622V89.8132H85.7372V85.7382Z"
        fill="black"
      />
      <path
        d="M102.037 85.7382H97.9623V89.8132H102.037V85.7382Z"
        fill="black"
      />
      <path
        d="M110.187 85.7382H106.112V89.8132H110.187V85.7382Z"
        fill="black"
      />
      <path
        d="M118.338 85.7382H114.262V89.8132H118.338V85.7382Z"
        fill="black"
      />
      <path
        d="M134.638 85.7382H130.563V89.8132H134.638V85.7382Z"
        fill="black"
      />
      <path
        d="M138.713 85.7382H134.638V89.8132H138.713V85.7382Z"
        fill="black"
      />
      <path
        d="M142.788 85.7382H138.713V89.8132H142.788V85.7382Z"
        fill="black"
      />
      <path
        d="M146.863 85.7382H142.788V89.8132H146.863V85.7382Z"
        fill="black"
      />
      <path
        d="M159.088 85.7382H155.013V89.8132H159.088V85.7382Z"
        fill="black"
      />
      <path
        d="M163.163 85.7382H159.088V89.8132H163.163V85.7382Z"
        fill="black"
      />
      <path
        d="M171.313 85.7382H167.238V89.8132H171.313V85.7382Z"
        fill="black"
      />
      <path
        d="M183.538 85.7382H179.463V89.8132H183.538V85.7382Z"
        fill="black"
      />
      <path
        d="M187.613 85.7382H183.538V89.8132H187.613V85.7382Z"
        fill="black"
      />
      <path
        d="M12.3866 89.8132H8.31152V93.8883H12.3866V89.8132Z"
        fill="black"
      />
      <path
        d="M20.5366 89.8132H16.4616V93.8883H20.5366V89.8132Z"
        fill="black"
      />
      <path
        d="M28.6867 89.8132H24.6117V93.8883H28.6867V89.8132Z"
        fill="black"
      />
      <path
        d="M32.7617 89.8132H28.6867V93.8883H32.7617V89.8132Z"
        fill="black"
      />
      <path
        d="M36.8368 89.8132H32.7617V93.8883H36.8368V89.8132Z"
        fill="black"
      />
      <path
        d="M40.9118 89.8132H36.8368V93.8883H40.9118V89.8132Z"
        fill="black"
      />
      <path
        d="M44.9869 89.8132H40.9118V93.8883H44.9869V89.8132Z"
        fill="black"
      />
      <path
        d="M49.0619 89.8132H44.9869V93.8883H49.0619V89.8132Z"
        fill="black"
      />
      <path
        d="M53.1369 89.8132H49.0619V93.8883H53.1369V89.8132Z"
        fill="black"
      />
      <path d="M57.212 89.8132H53.1369V93.8883H57.212V89.8132Z" fill="black" />
      <path d="M69.4371 89.8132H65.362V93.8883H69.4371V89.8132Z" fill="black" />
      <path
        d="M73.5121 89.8132H69.4371V93.8883H73.5121V89.8132Z"
        fill="black"
      />
      <path
        d="M77.5871 89.8132H73.5121V93.8883H77.5871V89.8132Z"
        fill="black"
      />
      <path
        d="M89.8122 89.8132H85.7372V93.8883H89.8122V89.8132Z"
        fill="black"
      />
      <path
        d="M93.8873 89.8132H89.8122V93.8883H93.8873V89.8132Z"
        fill="black"
      />
      <path
        d="M97.9623 89.8132H93.8873V93.8883H97.9623V89.8132Z"
        fill="black"
      />
      <path
        d="M102.037 89.8132H97.9623V93.8883H102.037V89.8132Z"
        fill="black"
      />
      <path
        d="M106.112 89.8132H102.037V93.8883H106.112V89.8132Z"
        fill="black"
      />
      <path
        d="M110.187 89.8132H106.112V93.8883H110.187V89.8132Z"
        fill="black"
      />
      <path
        d="M118.338 89.8132H114.262V93.8883H118.338V89.8132Z"
        fill="black"
      />
      <path
        d="M138.713 89.8132H134.638V93.8883H138.713V89.8132Z"
        fill="black"
      />
      <path
        d="M146.863 89.8132H142.788V93.8883H146.863V89.8132Z"
        fill="black"
      />
      <path
        d="M155.013 89.8132H150.938V93.8883H155.013V89.8132Z"
        fill="black"
      />
      <path
        d="M159.088 89.8132H155.013V93.8883H159.088V89.8132Z"
        fill="black"
      />
      <path
        d="M163.163 89.8132H159.088V93.8883H163.163V89.8132Z"
        fill="black"
      />
      <path
        d="M167.238 89.8132H163.163V93.8883H167.238V89.8132Z"
        fill="black"
      />
      <path
        d="M171.313 89.8132H167.238V93.8883H171.313V89.8132Z"
        fill="black"
      />
      <path
        d="M175.388 89.8132H171.313V93.8883H175.388V89.8132Z"
        fill="black"
      />
      <path
        d="M16.4616 93.8883H12.3866V97.9633H16.4616V93.8883Z"
        fill="black"
      />
      <path
        d="M24.6117 93.8883H20.5366V97.9633H24.6117V93.8883Z"
        fill="black"
      />
      <path
        d="M28.6867 93.8883H24.6117V97.9633H28.6867V93.8883Z"
        fill="black"
      />
      <path
        d="M44.9869 93.8883H40.9118V97.9633H44.9869V93.8883Z"
        fill="black"
      />
      <path
        d="M49.0619 93.8883H44.9869V97.9633H49.0619V93.8883Z"
        fill="black"
      />
      <path d="M57.212 93.8883H53.1369V97.9633H57.212V93.8883Z" fill="black" />
      <path d="M61.287 93.8883H57.212V97.9633H61.287V93.8883Z" fill="black" />
      <path
        d="M85.7372 93.8883H81.6622V97.9633H85.7372V93.8883Z"
        fill="black"
      />
      <path
        d="M89.8122 93.8883H85.7372V97.9633H89.8122V93.8883Z"
        fill="black"
      />
      <path
        d="M93.8873 93.8883H89.8122V97.9633H93.8873V93.8883Z"
        fill="black"
      />
      <path
        d="M110.187 93.8883H106.112V97.9633H110.187V93.8883Z"
        fill="black"
      />
      <path
        d="M159.088 93.8883H155.013V97.9633H159.088V93.8883Z"
        fill="black"
      />
      <path
        d="M175.388 93.8883H171.313V97.9633H175.388V93.8883Z"
        fill="black"
      />
      <path
        d="M179.463 93.8883H175.388V97.9633H179.463V93.8883Z"
        fill="black"
      />
      <path
        d="M183.538 93.8883H179.463V97.9633H183.538V93.8883Z"
        fill="black"
      />
      <path
        d="M187.613 93.8883H183.538V97.9633H187.613V93.8883Z"
        fill="black"
      />
      <path
        d="M191.688 93.8883H187.613V97.9633H191.688V93.8883Z"
        fill="black"
      />
      <path
        d="M12.3866 97.9633H8.31152V102.038H12.3866V97.9633Z"
        fill="black"
      />
      <path
        d="M20.5366 97.9633H16.4616V102.038H20.5366V97.9633Z"
        fill="black"
      />
      <path
        d="M28.6867 97.9633H24.6117V102.038H28.6867V97.9633Z"
        fill="black"
      />
      <path
        d="M36.8368 97.9633H32.7617V102.038H36.8368V97.9633Z"
        fill="black"
      />
      <path
        d="M44.9869 97.9633H40.9118V102.038H44.9869V97.9633Z"
        fill="black"
      />
      <path
        d="M49.0619 97.9633H44.9869V102.038H49.0619V97.9633Z"
        fill="black"
      />
      <path
        d="M53.1369 97.9633H49.0619V102.038H53.1369V97.9633Z"
        fill="black"
      />
      <path d="M57.212 97.9633H53.1369V102.038H57.212V97.9633Z" fill="black" />
      <path d="M69.4371 97.9633H65.362V102.038H69.4371V97.9633Z" fill="black" />
      <path
        d="M73.5121 97.9633H69.4371V102.038H73.5121V97.9633Z"
        fill="black"
      />
      <path
        d="M81.6622 97.9633H77.5871V102.038H81.6622V97.9633Z"
        fill="black"
      />
      <path
        d="M85.7372 97.9633H81.6622V102.038H85.7372V97.9633Z"
        fill="black"
      />
      <path
        d="M93.8873 97.9633H89.8122V102.038H93.8873V97.9633Z"
        fill="black"
      />
      <path
        d="M102.037 97.9633H97.9623V102.038H102.037V97.9633Z"
        fill="black"
      />
      <path
        d="M110.187 97.9633H106.112V102.038H110.187V97.9633Z"
        fill="black"
      />
      <path
        d="M114.262 97.9633H110.187V102.038H114.262V97.9633Z"
        fill="black"
      />
      <path
        d="M118.338 97.9633H114.262V102.038H118.338V97.9633Z"
        fill="black"
      />
      <path
        d="M122.413 97.9633H118.338V102.038H122.413V97.9633Z"
        fill="black"
      />
      <path
        d="M142.788 97.9633H138.713V102.038H142.788V97.9633Z"
        fill="black"
      />
      <path
        d="M150.938 97.9633H146.863V102.038H150.938V97.9633Z"
        fill="black"
      />
      <path
        d="M159.088 97.9633H155.013V102.038H159.088V97.9633Z"
        fill="black"
      />
      <path
        d="M167.238 97.9633H163.163V102.038H167.238V97.9633Z"
        fill="black"
      />
      <path
        d="M175.388 97.9633H171.313V102.038H175.388V97.9633Z"
        fill="black"
      />
      <path
        d="M183.538 97.9633H179.463V102.038H183.538V97.9633Z"
        fill="black"
      />
      <path
        d="M187.613 97.9633H183.538V102.038H187.613V97.9633Z"
        fill="black"
      />
      <path
        d="M12.3866 102.038H8.31152V106.113H12.3866V102.038Z"
        fill="black"
      />
      <path
        d="M20.5366 102.038H16.4616V106.113H20.5366V102.038Z"
        fill="black"
      />
      <path
        d="M24.6117 102.038H20.5366V106.113H24.6117V102.038Z"
        fill="black"
      />
      <path
        d="M28.6867 102.038H24.6117V106.113H28.6867V102.038Z"
        fill="black"
      />
      <path
        d="M44.9869 102.038H40.9118V106.113H44.9869V102.038Z"
        fill="black"
      />
      <path d="M57.212 102.038H53.1369V106.113H57.212V102.038Z" fill="black" />
      <path d="M69.4371 102.038H65.362V106.113H69.4371V102.038Z" fill="black" />
      <path
        d="M73.5121 102.038H69.4371V106.113H73.5121V102.038Z"
        fill="black"
      />
      <path
        d="M89.8122 102.038H85.7372V106.113H89.8122V102.038Z"
        fill="black"
      />
      <path
        d="M93.8873 102.038H89.8122V106.113H93.8873V102.038Z"
        fill="black"
      />
      <path
        d="M110.187 102.038H106.112V106.113H110.187V102.038Z"
        fill="black"
      />
      <path
        d="M118.338 102.038H114.262V106.113H118.338V102.038Z"
        fill="black"
      />
      <path
        d="M122.413 102.038H118.338V106.113H122.413V102.038Z"
        fill="black"
      />
      <path
        d="M134.638 102.038H130.563V106.113H134.638V102.038Z"
        fill="black"
      />
      <path
        d="M146.863 102.038H142.788V106.113H146.863V102.038Z"
        fill="black"
      />
      <path
        d="M150.938 102.038H146.863V106.113H150.938V102.038Z"
        fill="black"
      />
      <path
        d="M159.088 102.038H155.013V106.113H159.088V102.038Z"
        fill="black"
      />
      <path
        d="M175.388 102.038H171.313V106.113H175.388V102.038Z"
        fill="black"
      />
      <path
        d="M191.688 102.038H187.613V106.113H191.688V102.038Z"
        fill="black"
      />
      <path
        d="M16.4616 106.113H12.3866V110.188H16.4616V106.113Z"
        fill="black"
      />
      <path
        d="M20.5366 106.113H16.4616V110.188H20.5366V106.113Z"
        fill="black"
      />
      <path
        d="M24.6117 106.113H20.5366V110.188H24.6117V106.113Z"
        fill="black"
      />
      <path
        d="M28.6867 106.113H24.6117V110.188H28.6867V106.113Z"
        fill="black"
      />
      <path
        d="M32.7617 106.113H28.6867V110.188H32.7617V106.113Z"
        fill="black"
      />
      <path
        d="M36.8368 106.113H32.7617V110.188H36.8368V106.113Z"
        fill="black"
      />
      <path
        d="M40.9118 106.113H36.8368V110.188H40.9118V106.113Z"
        fill="black"
      />
      <path
        d="M44.9869 106.113H40.9118V110.188H44.9869V106.113Z"
        fill="black"
      />
      <path
        d="M49.0619 106.113H44.9869V110.188H49.0619V106.113Z"
        fill="black"
      />
      <path d="M69.4371 106.113H65.362V110.188H69.4371V106.113Z" fill="black" />
      <path
        d="M85.7372 106.113H81.6622V110.188H85.7372V106.113Z"
        fill="black"
      />
      <path
        d="M89.8122 106.113H85.7372V110.188H89.8122V106.113Z"
        fill="black"
      />
      <path
        d="M93.8873 106.113H89.8122V110.188H93.8873V106.113Z"
        fill="black"
      />
      <path
        d="M97.9623 106.113H93.8873V110.188H97.9623V106.113Z"
        fill="black"
      />
      <path
        d="M102.037 106.113H97.9623V110.188H102.037V106.113Z"
        fill="black"
      />
      <path
        d="M106.112 106.113H102.037V110.188H106.112V106.113Z"
        fill="black"
      />
      <path
        d="M110.187 106.113H106.112V110.188H110.187V106.113Z"
        fill="black"
      />
      <path
        d="M114.262 106.113H110.187V110.188H114.262V106.113Z"
        fill="black"
      />
      <path
        d="M122.413 106.113H118.338V110.188H122.413V106.113Z"
        fill="black"
      />
      <path
        d="M134.638 106.113H130.563V110.188H134.638V106.113Z"
        fill="black"
      />
      <path
        d="M138.713 106.113H134.638V110.188H138.713V106.113Z"
        fill="black"
      />
      <path
        d="M155.013 106.113H150.938V110.188H155.013V106.113Z"
        fill="black"
      />
      <path
        d="M159.088 106.113H155.013V110.188H159.088V106.113Z"
        fill="black"
      />
      <path
        d="M163.163 106.113H159.088V110.188H163.163V106.113Z"
        fill="black"
      />
      <path
        d="M167.238 106.113H163.163V110.188H167.238V106.113Z"
        fill="black"
      />
      <path
        d="M171.313 106.113H167.238V110.188H171.313V106.113Z"
        fill="black"
      />
      <path
        d="M175.388 106.113H171.313V110.188H175.388V106.113Z"
        fill="black"
      />
      <path
        d="M191.688 106.113H187.613V110.188H191.688V106.113Z"
        fill="black"
      />
      <path
        d="M12.3866 110.188H8.31152V114.263H12.3866V110.188Z"
        fill="black"
      />
      <path
        d="M16.4616 110.188H12.3866V114.263H16.4616V110.188Z"
        fill="black"
      />
      <path
        d="M28.6867 110.188H24.6117V114.263H28.6867V110.188Z"
        fill="black"
      />
      <path
        d="M32.7617 110.188H28.6867V114.263H32.7617V110.188Z"
        fill="black"
      />
      <path
        d="M44.9869 110.188H40.9118V114.263H44.9869V110.188Z"
        fill="black"
      />
      <path d="M57.212 110.188H53.1369V114.263H57.212V110.188Z" fill="black" />
      <path d="M65.362 110.188H61.287V114.263H65.362V110.188Z" fill="black" />
      <path d="M69.4371 110.188H65.362V114.263H69.4371V110.188Z" fill="black" />
      <path
        d="M81.6622 110.188H77.5871V114.263H81.6622V110.188Z"
        fill="black"
      />
      <path
        d="M85.7372 110.188H81.6622V114.263H85.7372V110.188Z"
        fill="black"
      />
      <path
        d="M97.9623 110.188H93.8873V114.263H97.9623V110.188Z"
        fill="black"
      />
      <path
        d="M110.187 110.188H106.112V114.263H110.187V110.188Z"
        fill="black"
      />
      <path
        d="M130.563 110.188H126.488V114.263H130.563V110.188Z"
        fill="black"
      />
      <path
        d="M134.638 110.188H130.563V114.263H134.638V110.188Z"
        fill="black"
      />
      <path
        d="M146.863 110.188H142.788V114.263H146.863V110.188Z"
        fill="black"
      />
      <path
        d="M155.013 110.188H150.938V114.263H155.013V110.188Z"
        fill="black"
      />
      <path
        d="M163.163 110.188H159.088V114.263H163.163V110.188Z"
        fill="black"
      />
      <path
        d="M167.238 110.188H163.163V114.263H167.238V110.188Z"
        fill="black"
      />
      <path
        d="M175.388 110.188H171.313V114.263H175.388V110.188Z"
        fill="black"
      />
      <path
        d="M179.463 110.188H175.388V114.263H179.463V110.188Z"
        fill="black"
      />
      <path
        d="M183.538 110.188H179.463V114.263H183.538V110.188Z"
        fill="black"
      />
      <path
        d="M191.688 110.188H187.613V114.263H191.688V110.188Z"
        fill="black"
      />
      <path
        d="M12.3866 114.263H8.31152V118.338H12.3866V114.263Z"
        fill="black"
      />
      <path
        d="M20.5366 114.263H16.4616V118.338H20.5366V114.263Z"
        fill="black"
      />
      <path
        d="M28.6867 114.263H24.6117V118.338H28.6867V114.263Z"
        fill="black"
      />
      <path
        d="M32.7617 114.263H28.6867V118.338H32.7617V114.263Z"
        fill="black"
      />
      <path
        d="M36.8368 114.263H32.7617V118.338H36.8368V114.263Z"
        fill="black"
      />
      <path
        d="M49.0619 114.263H44.9869V118.338H49.0619V114.263Z"
        fill="black"
      />
      <path d="M61.287 114.263H57.212V118.338H61.287V114.263Z" fill="black" />
      <path d="M65.362 114.263H61.287V118.338H65.362V114.263Z" fill="black" />
      <path d="M69.4371 114.263H65.362V118.338H69.4371V114.263Z" fill="black" />
      <path
        d="M73.5121 114.263H69.4371V118.338H73.5121V114.263Z"
        fill="black"
      />
      <path
        d="M77.5871 114.263H73.5121V118.338H77.5871V114.263Z"
        fill="black"
      />
      <path
        d="M85.7372 114.263H81.6622V118.338H85.7372V114.263Z"
        fill="black"
      />
      <path
        d="M89.8122 114.263H85.7372V118.338H89.8122V114.263Z"
        fill="black"
      />
      <path
        d="M97.9623 114.263H93.8873V118.338H97.9623V114.263Z"
        fill="black"
      />
      <path
        d="M110.187 114.263H106.112V118.338H110.187V114.263Z"
        fill="black"
      />
      <path
        d="M114.262 114.263H110.187V118.338H114.262V114.263Z"
        fill="black"
      />
      <path
        d="M118.338 114.263H114.262V118.338H118.338V114.263Z"
        fill="black"
      />
      <path
        d="M122.413 114.263H118.338V118.338H122.413V114.263Z"
        fill="black"
      />
      <path
        d="M126.488 114.263H122.413V118.338H126.488V114.263Z"
        fill="black"
      />
      <path
        d="M138.713 114.263H134.638V118.338H138.713V114.263Z"
        fill="black"
      />
      <path
        d="M142.788 114.263H138.713V118.338H142.788V114.263Z"
        fill="black"
      />
      <path
        d="M146.863 114.263H142.788V118.338H146.863V114.263Z"
        fill="black"
      />
      <path
        d="M159.088 114.263H155.013V118.338H159.088V114.263Z"
        fill="black"
      />
      <path
        d="M163.163 114.263H159.088V118.338H163.163V114.263Z"
        fill="black"
      />
      <path
        d="M167.238 114.263H163.163V118.338H167.238V114.263Z"
        fill="black"
      />
      <path
        d="M175.388 114.263H171.313V118.338H175.388V114.263Z"
        fill="black"
      />
      <path
        d="M12.3866 118.338H8.31152V122.414H12.3866V118.338Z"
        fill="black"
      />
      <path
        d="M16.4616 118.338H12.3866V122.414H16.4616V118.338Z"
        fill="black"
      />
      <path
        d="M20.5366 118.338H16.4616V122.414H20.5366V118.338Z"
        fill="black"
      />
      <path
        d="M24.6117 118.338H20.5366V122.414H24.6117V118.338Z"
        fill="black"
      />
      <path
        d="M28.6867 118.338H24.6117V122.414H28.6867V118.338Z"
        fill="black"
      />
      <path
        d="M32.7617 118.338H28.6867V122.414H32.7617V118.338Z"
        fill="black"
      />
      <path
        d="M44.9869 118.338H40.9118V122.414H44.9869V118.338Z"
        fill="black"
      />
      <path
        d="M49.0619 118.338H44.9869V122.414H49.0619V118.338Z"
        fill="black"
      />
      <path
        d="M53.1369 118.338H49.0619V122.414H53.1369V118.338Z"
        fill="black"
      />
      <path d="M65.362 118.338H61.287V122.414H65.362V118.338Z" fill="black" />
      <path d="M69.4371 118.338H65.362V122.414H69.4371V118.338Z" fill="black" />
      <path
        d="M81.6622 118.338H77.5871V122.414H81.6622V118.338Z"
        fill="black"
      />
      <path
        d="M85.7372 118.338H81.6622V122.414H85.7372V118.338Z"
        fill="black"
      />
      <path
        d="M97.9623 118.338H93.8873V122.414H97.9623V118.338Z"
        fill="black"
      />
      <path
        d="M146.863 118.338H142.788V122.414H146.863V118.338Z"
        fill="black"
      />
      <path
        d="M163.163 118.338H159.088V122.414H163.163V118.338Z"
        fill="black"
      />
      <path
        d="M183.538 118.338H179.463V122.414H183.538V118.338Z"
        fill="black"
      />
      <path
        d="M187.613 118.338H183.538V122.414H187.613V118.338Z"
        fill="black"
      />
      <path
        d="M12.3866 122.414H8.31152V126.489H12.3866V122.414Z"
        fill="black"
      />
      <path
        d="M24.6117 122.414H20.5366V126.489H24.6117V122.414Z"
        fill="black"
      />
      <path
        d="M36.8368 122.414H32.7617V126.489H36.8368V122.414Z"
        fill="black"
      />
      <path
        d="M40.9118 122.414H36.8368V126.489H40.9118V122.414Z"
        fill="black"
      />
      <path
        d="M44.9869 122.414H40.9118V126.489H44.9869V122.414Z"
        fill="black"
      />
      <path
        d="M49.0619 122.414H44.9869V126.489H49.0619V122.414Z"
        fill="black"
      />
      <path d="M61.287 122.414H57.212V126.489H61.287V122.414Z" fill="black" />
      <path
        d="M81.6622 122.414H77.5871V126.489H81.6622V122.414Z"
        fill="black"
      />
      <path
        d="M85.7372 122.414H81.6622V126.489H85.7372V122.414Z"
        fill="black"
      />
      <path
        d="M89.8122 122.414H85.7372V126.489H89.8122V122.414Z"
        fill="black"
      />
      <path
        d="M93.8873 122.414H89.8122V126.489H93.8873V122.414Z"
        fill="black"
      />
      <path
        d="M106.112 122.414H102.037V126.489H106.112V122.414Z"
        fill="black"
      />
      <path
        d="M110.187 122.414H106.112V126.489H110.187V122.414Z"
        fill="black"
      />
      <path
        d="M114.262 122.414H110.187V126.489H114.262V122.414Z"
        fill="black"
      />
      <path
        d="M118.338 122.414H114.262V126.489H118.338V122.414Z"
        fill="black"
      />
      <path
        d="M130.563 122.414H126.488V126.489H130.563V122.414Z"
        fill="black"
      />
      <path
        d="M134.638 122.414H130.563V126.489H134.638V122.414Z"
        fill="black"
      />
      <path
        d="M138.713 122.414H134.638V126.489H138.713V122.414Z"
        fill="black"
      />
      <path
        d="M142.788 122.414H138.713V126.489H142.788V122.414Z"
        fill="black"
      />
      <path
        d="M150.938 122.414H146.863V126.489H150.938V122.414Z"
        fill="black"
      />
      <path
        d="M159.088 122.414H155.013V126.489H159.088V122.414Z"
        fill="black"
      />
      <path
        d="M163.163 122.414H159.088V126.489H163.163V122.414Z"
        fill="black"
      />
      <path
        d="M171.313 122.414H167.238V126.489H171.313V122.414Z"
        fill="black"
      />
      <path
        d="M183.538 122.414H179.463V126.489H183.538V122.414Z"
        fill="black"
      />
      <path
        d="M187.613 122.414H183.538V126.489H187.613V122.414Z"
        fill="black"
      />
      <path
        d="M191.688 122.414H187.613V126.489H191.688V122.414Z"
        fill="black"
      />
      <path
        d="M16.4616 126.489H12.3866V130.564H16.4616V126.489Z"
        fill="black"
      />
      <path
        d="M32.7617 126.489H28.6867V130.564H32.7617V126.489Z"
        fill="black"
      />
      <path
        d="M40.9118 126.489H36.8368V130.564H40.9118V126.489Z"
        fill="black"
      />
      <path
        d="M44.9869 126.489H40.9118V130.564H44.9869V126.489Z"
        fill="black"
      />
      <path
        d="M49.0619 126.489H44.9869V130.564H49.0619V126.489Z"
        fill="black"
      />
      <path
        d="M53.1369 126.489H49.0619V130.564H53.1369V126.489Z"
        fill="black"
      />
      <path d="M57.212 126.489H53.1369V130.564H57.212V126.489Z" fill="black" />
      <path d="M65.362 126.489H61.287V130.564H65.362V126.489Z" fill="black" />
      <path
        d="M77.5871 126.489H73.5121V130.564H77.5871V126.489Z"
        fill="black"
      />
      <path
        d="M81.6622 126.489H77.5871V130.564H81.6622V126.489Z"
        fill="black"
      />
      <path
        d="M85.7372 126.489H81.6622V130.564H85.7372V126.489Z"
        fill="black"
      />
      <path
        d="M89.8122 126.489H85.7372V130.564H89.8122V126.489Z"
        fill="black"
      />
      <path
        d="M93.8873 126.489H89.8122V130.564H93.8873V126.489Z"
        fill="black"
      />
      <path
        d="M97.9623 126.489H93.8873V130.564H97.9623V126.489Z"
        fill="black"
      />
      <path
        d="M102.037 126.489H97.9623V130.564H102.037V126.489Z"
        fill="black"
      />
      <path
        d="M110.187 126.489H106.112V130.564H110.187V126.489Z"
        fill="black"
      />
      <path
        d="M114.262 126.489H110.187V130.564H114.262V126.489Z"
        fill="black"
      />
      <path
        d="M122.413 126.489H118.338V130.564H122.413V126.489Z"
        fill="black"
      />
      <path
        d="M134.638 126.489H130.563V130.564H134.638V126.489Z"
        fill="black"
      />
      <path
        d="M163.163 126.489H159.088V130.564H163.163V126.489Z"
        fill="black"
      />
      <path
        d="M171.313 126.489H167.238V130.564H171.313V126.489Z"
        fill="black"
      />
      <path
        d="M175.388 126.489H171.313V130.564H175.388V126.489Z"
        fill="black"
      />
      <path
        d="M183.538 126.489H179.463V130.564H183.538V126.489Z"
        fill="black"
      />
      <path
        d="M187.613 126.489H183.538V130.564H187.613V126.489Z"
        fill="black"
      />
      <path
        d="M12.3866 130.564H8.31152V134.639H12.3866V130.564Z"
        fill="black"
      />
      <path
        d="M20.5366 130.564H16.4616V134.639H20.5366V130.564Z"
        fill="black"
      />
      <path
        d="M24.6117 130.564H20.5366V134.639H24.6117V130.564Z"
        fill="black"
      />
      <path
        d="M28.6867 130.564H24.6117V134.639H28.6867V130.564Z"
        fill="black"
      />
      <path
        d="M36.8368 130.564H32.7617V134.639H36.8368V130.564Z"
        fill="black"
      />
      <path
        d="M40.9118 130.564H36.8368V134.639H40.9118V130.564Z"
        fill="black"
      />
      <path
        d="M49.0619 130.564H44.9869V134.639H49.0619V130.564Z"
        fill="black"
      />
      <path d="M57.212 130.564H53.1369V134.639H57.212V130.564Z" fill="black" />
      <path d="M65.362 130.564H61.287V134.639H65.362V130.564Z" fill="black" />
      <path
        d="M73.5121 130.564H69.4371V134.639H73.5121V130.564Z"
        fill="black"
      />
      <path
        d="M81.6622 130.564H77.5871V134.639H81.6622V130.564Z"
        fill="black"
      />
      <path
        d="M89.8122 130.564H85.7372V134.639H89.8122V130.564Z"
        fill="black"
      />
      <path
        d="M97.9623 130.564H93.8873V134.639H97.9623V130.564Z"
        fill="black"
      />
      <path
        d="M114.262 130.564H110.187V134.639H114.262V130.564Z"
        fill="black"
      />
      <path
        d="M130.563 130.564H126.488V134.639H130.563V130.564Z"
        fill="black"
      />
      <path
        d="M138.713 130.564H134.638V134.639H138.713V130.564Z"
        fill="black"
      />
      <path
        d="M142.788 130.564H138.713V134.639H142.788V130.564Z"
        fill="black"
      />
      <path
        d="M146.863 130.564H142.788V134.639H146.863V130.564Z"
        fill="black"
      />
      <path
        d="M163.163 130.564H159.088V134.639H163.163V130.564Z"
        fill="black"
      />
      <path
        d="M175.388 130.564H171.313V134.639H175.388V130.564Z"
        fill="black"
      />
      <path
        d="M179.463 130.564H175.388V134.639H179.463V130.564Z"
        fill="black"
      />
      <path
        d="M20.5366 134.639H16.4616V138.714H20.5366V134.639Z"
        fill="black"
      />
      <path
        d="M24.6117 134.639H20.5366V138.714H24.6117V134.639Z"
        fill="black"
      />
      <path
        d="M49.0619 134.639H44.9869V138.714H49.0619V134.639Z"
        fill="black"
      />
      <path
        d="M53.1369 134.639H49.0619V138.714H53.1369V134.639Z"
        fill="black"
      />
      <path d="M61.287 134.639H57.212V138.714H61.287V134.639Z" fill="black" />
      <path
        d="M73.5121 134.639H69.4371V138.714H73.5121V134.639Z"
        fill="black"
      />
      <path
        d="M77.5871 134.639H73.5121V138.714H77.5871V134.639Z"
        fill="black"
      />
      <path
        d="M81.6622 134.639H77.5871V138.714H81.6622V134.639Z"
        fill="black"
      />
      <path
        d="M89.8122 134.639H85.7372V138.714H89.8122V134.639Z"
        fill="black"
      />
      <path
        d="M93.8873 134.639H89.8122V138.714H93.8873V134.639Z"
        fill="black"
      />
      <path
        d="M97.9623 134.639H93.8873V138.714H97.9623V134.639Z"
        fill="black"
      />
      <path
        d="M102.037 134.639H97.9623V138.714H102.037V134.639Z"
        fill="black"
      />
      <path
        d="M114.262 134.639H110.187V138.714H114.262V134.639Z"
        fill="black"
      />
      <path
        d="M126.488 134.639H122.413V138.714H126.488V134.639Z"
        fill="black"
      />
      <path
        d="M134.638 134.639H130.563V138.714H134.638V134.639Z"
        fill="black"
      />
      <path
        d="M138.713 134.639H134.638V138.714H138.713V134.639Z"
        fill="black"
      />
      <path
        d="M146.863 134.639H142.788V138.714H146.863V134.639Z"
        fill="black"
      />
      <path
        d="M163.163 134.639H159.088V138.714H163.163V134.639Z"
        fill="black"
      />
      <path
        d="M175.388 134.639H171.313V138.714H175.388V134.639Z"
        fill="black"
      />
      <path
        d="M183.538 134.639H179.463V138.714H183.538V134.639Z"
        fill="black"
      />
      <path
        d="M187.613 134.639H183.538V138.714H187.613V134.639Z"
        fill="black"
      />
      <path
        d="M12.3866 138.714H8.31152V142.789H12.3866V138.714Z"
        fill="black"
      />
      <path
        d="M16.4616 138.714H12.3866V142.789H16.4616V138.714Z"
        fill="black"
      />
      <path
        d="M20.5366 138.714H16.4616V142.789H20.5366V138.714Z"
        fill="black"
      />
      <path
        d="M24.6117 138.714H20.5366V142.789H24.6117V138.714Z"
        fill="black"
      />
      <path
        d="M28.6867 138.714H24.6117V142.789H28.6867V138.714Z"
        fill="black"
      />
      <path
        d="M36.8368 138.714H32.7617V142.789H36.8368V138.714Z"
        fill="black"
      />
      <path d="M57.212 138.714H53.1369V142.789H57.212V138.714Z" fill="black" />
      <path d="M61.287 138.714H57.212V142.789H61.287V138.714Z" fill="black" />
      <path d="M65.362 138.714H61.287V142.789H65.362V138.714Z" fill="black" />
      <path
        d="M77.5871 138.714H73.5121V142.789H77.5871V138.714Z"
        fill="black"
      />
      <path
        d="M81.6622 138.714H77.5871V142.789H81.6622V138.714Z"
        fill="black"
      />
      <path
        d="M106.112 138.714H102.037V142.789H106.112V138.714Z"
        fill="black"
      />
      <path
        d="M110.187 138.714H106.112V142.789H110.187V138.714Z"
        fill="black"
      />
      <path
        d="M114.262 138.714H110.187V142.789H114.262V138.714Z"
        fill="black"
      />
      <path
        d="M126.488 138.714H122.413V142.789H126.488V138.714Z"
        fill="black"
      />
      <path
        d="M146.863 138.714H142.788V142.789H146.863V138.714Z"
        fill="black"
      />
      <path
        d="M155.013 138.714H150.938V142.789H155.013V138.714Z"
        fill="black"
      />
      <path
        d="M159.088 138.714H155.013V142.789H159.088V138.714Z"
        fill="black"
      />
      <path
        d="M163.163 138.714H159.088V142.789H163.163V138.714Z"
        fill="black"
      />
      <path
        d="M171.313 138.714H167.238V142.789H171.313V138.714Z"
        fill="black"
      />
      <path
        d="M175.388 138.714H171.313V142.789H175.388V138.714Z"
        fill="black"
      />
      <path
        d="M183.538 138.714H179.463V142.789H183.538V138.714Z"
        fill="black"
      />
      <path
        d="M16.4616 142.789H12.3866V146.864H16.4616V142.789Z"
        fill="black"
      />
      <path
        d="M20.5366 142.789H16.4616V146.864H20.5366V142.789Z"
        fill="black"
      />
      <path
        d="M24.6117 142.789H20.5366V146.864H24.6117V142.789Z"
        fill="black"
      />
      <path
        d="M32.7617 142.789H28.6867V146.864H32.7617V142.789Z"
        fill="black"
      />
      <path
        d="M44.9869 142.789H40.9118V146.864H44.9869V142.789Z"
        fill="black"
      />
      <path
        d="M53.1369 142.789H49.0619V146.864H53.1369V142.789Z"
        fill="black"
      />
      <path d="M57.212 142.789H53.1369V146.864H57.212V142.789Z" fill="black" />
      <path d="M61.287 142.789H57.212V146.864H61.287V142.789Z" fill="black" />
      <path d="M69.4371 142.789H65.362V146.864H69.4371V142.789Z" fill="black" />
      <path
        d="M77.5871 142.789H73.5121V146.864H77.5871V142.789Z"
        fill="black"
      />
      <path
        d="M85.7372 142.789H81.6622V146.864H85.7372V142.789Z"
        fill="black"
      />
      <path
        d="M93.8873 142.789H89.8122V146.864H93.8873V142.789Z"
        fill="black"
      />
      <path
        d="M102.037 142.789H97.9623V146.864H102.037V142.789Z"
        fill="black"
      />
      <path
        d="M110.187 142.789H106.112V146.864H110.187V142.789Z"
        fill="black"
      />
      <path
        d="M118.338 142.789H114.262V146.864H118.338V142.789Z"
        fill="black"
      />
      <path
        d="M126.488 142.789H122.413V146.864H126.488V142.789Z"
        fill="black"
      />
      <path
        d="M130.563 142.789H126.488V146.864H130.563V142.789Z"
        fill="black"
      />
      <path
        d="M138.713 142.789H134.638V146.864H138.713V142.789Z"
        fill="black"
      />
      <path
        d="M142.788 142.789H138.713V146.864H142.788V142.789Z"
        fill="black"
      />
      <path
        d="M150.938 142.789H146.863V146.864H150.938V142.789Z"
        fill="black"
      />
      <path
        d="M159.088 142.789H155.013V146.864H159.088V142.789Z"
        fill="black"
      />
      <path
        d="M163.163 142.789H159.088V146.864H163.163V142.789Z"
        fill="black"
      />
      <path
        d="M175.388 142.789H171.313V146.864H175.388V142.789Z"
        fill="black"
      />
      <path
        d="M179.463 142.789H175.388V146.864H179.463V142.789Z"
        fill="black"
      />
      <path
        d="M187.613 142.789H183.538V146.864H187.613V142.789Z"
        fill="black"
      />
      <path
        d="M191.688 142.789H187.613V146.864H191.688V142.789Z"
        fill="black"
      />
      <path
        d="M28.6867 146.864H24.6117V150.939H28.6867V146.864Z"
        fill="black"
      />
      <path
        d="M36.8368 146.864H32.7617V150.939H36.8368V146.864Z"
        fill="black"
      />
      <path
        d="M44.9869 146.864H40.9118V150.939H44.9869V146.864Z"
        fill="black"
      />
      <path
        d="M49.0619 146.864H44.9869V150.939H49.0619V146.864Z"
        fill="black"
      />
      <path
        d="M53.1369 146.864H49.0619V150.939H53.1369V146.864Z"
        fill="black"
      />
      <path d="M61.287 146.864H57.212V150.939H61.287V146.864Z" fill="black" />
      <path
        d="M73.5121 146.864H69.4371V150.939H73.5121V146.864Z"
        fill="black"
      />
      <path
        d="M81.6622 146.864H77.5871V150.939H81.6622V146.864Z"
        fill="black"
      />
      <path
        d="M97.9623 146.864H93.8873V150.939H97.9623V146.864Z"
        fill="black"
      />
      <path
        d="M102.037 146.864H97.9623V150.939H102.037V146.864Z"
        fill="black"
      />
      <path
        d="M106.112 146.864H102.037V150.939H106.112V146.864Z"
        fill="black"
      />
      <path
        d="M110.187 146.864H106.112V150.939H110.187V146.864Z"
        fill="black"
      />
      <path
        d="M122.413 146.864H118.338V150.939H122.413V146.864Z"
        fill="black"
      />
      <path
        d="M130.563 146.864H126.488V150.939H130.563V146.864Z"
        fill="black"
      />
      <path
        d="M138.713 146.864H134.638V150.939H138.713V146.864Z"
        fill="black"
      />
      <path
        d="M146.863 146.864H142.788V150.939H146.863V146.864Z"
        fill="black"
      />
      <path
        d="M150.938 146.864H146.863V150.939H150.938V146.864Z"
        fill="black"
      />
      <path
        d="M155.013 146.864H150.938V150.939H155.013V146.864Z"
        fill="black"
      />
      <path
        d="M159.088 146.864H155.013V150.939H159.088V146.864Z"
        fill="black"
      />
      <path
        d="M171.313 146.864H167.238V150.939H171.313V146.864Z"
        fill="black"
      />
      <path
        d="M179.463 146.864H175.388V150.939H179.463V146.864Z"
        fill="black"
      />
      <path
        d="M191.688 146.864H187.613V150.939H191.688V146.864Z"
        fill="black"
      />
      <path
        d="M16.4616 150.939H12.3866V155.014H16.4616V150.939Z"
        fill="black"
      />
      <path
        d="M20.5366 150.939H16.4616V155.014H20.5366V150.939Z"
        fill="black"
      />
      <path
        d="M24.6117 150.939H20.5366V155.014H24.6117V150.939Z"
        fill="black"
      />
      <path
        d="M28.6867 150.939H24.6117V155.014H28.6867V150.939Z"
        fill="black"
      />
      <path
        d="M40.9118 150.939H36.8368V155.014H40.9118V150.939Z"
        fill="black"
      />
      <path
        d="M44.9869 150.939H40.9118V155.014H44.9869V150.939Z"
        fill="black"
      />
      <path
        d="M49.0619 150.939H44.9869V155.014H49.0619V150.939Z"
        fill="black"
      />
      <path
        d="M53.1369 150.939H49.0619V155.014H53.1369V150.939Z"
        fill="black"
      />
      <path d="M57.212 150.939H53.1369V155.014H57.212V150.939Z" fill="black" />
      <path d="M61.287 150.939H57.212V155.014H61.287V150.939Z" fill="black" />
      <path d="M65.362 150.939H61.287V155.014H65.362V150.939Z" fill="black" />
      <path
        d="M73.5121 150.939H69.4371V155.014H73.5121V150.939Z"
        fill="black"
      />
      <path
        d="M89.8122 150.939H85.7372V155.014H89.8122V150.939Z"
        fill="black"
      />
      <path
        d="M97.9623 150.939H93.8873V155.014H97.9623V150.939Z"
        fill="black"
      />
      <path
        d="M106.112 150.939H102.037V155.014H106.112V150.939Z"
        fill="black"
      />
      <path
        d="M110.187 150.939H106.112V155.014H110.187V150.939Z"
        fill="black"
      />
      <path
        d="M122.413 150.939H118.338V155.014H122.413V150.939Z"
        fill="black"
      />
      <path
        d="M126.488 150.939H122.413V155.014H126.488V150.939Z"
        fill="black"
      />
      <path
        d="M130.563 150.939H126.488V155.014H130.563V150.939Z"
        fill="black"
      />
      <path
        d="M134.638 150.939H130.563V155.014H134.638V150.939Z"
        fill="black"
      />
      <path
        d="M138.713 150.939H134.638V155.014H138.713V150.939Z"
        fill="black"
      />
      <path
        d="M150.938 150.939H146.863V155.014H150.938V150.939Z"
        fill="black"
      />
      <path
        d="M159.088 150.939H155.013V155.014H159.088V150.939Z"
        fill="black"
      />
      <path
        d="M175.388 150.939H171.313V155.014H175.388V150.939Z"
        fill="black"
      />
      <path
        d="M187.613 150.939H183.538V155.014H187.613V150.939Z"
        fill="black"
      />
      <path
        d="M12.3866 155.014H8.31152V159.089H12.3866V155.014Z"
        fill="black"
      />
      <path
        d="M24.6117 155.014H20.5366V159.089H24.6117V155.014Z"
        fill="black"
      />
      <path
        d="M28.6867 155.014H24.6117V159.089H28.6867V155.014Z"
        fill="black"
      />
      <path
        d="M36.8368 155.014H32.7617V159.089H36.8368V155.014Z"
        fill="black"
      />
      <path
        d="M49.0619 155.014H44.9869V159.089H49.0619V155.014Z"
        fill="black"
      />
      <path
        d="M53.1369 155.014H49.0619V159.089H53.1369V155.014Z"
        fill="black"
      />
      <path d="M57.212 155.014H53.1369V159.089H57.212V155.014Z" fill="black" />
      <path d="M65.362 155.014H61.287V159.089H65.362V155.014Z" fill="black" />
      <path d="M69.4371 155.014H65.362V159.089H69.4371V155.014Z" fill="black" />
      <path
        d="M81.6622 155.014H77.5871V159.089H81.6622V155.014Z"
        fill="black"
      />
      <path
        d="M85.7372 155.014H81.6622V159.089H85.7372V155.014Z"
        fill="black"
      />
      <path
        d="M89.8122 155.014H85.7372V159.089H89.8122V155.014Z"
        fill="black"
      />
      <path
        d="M93.8873 155.014H89.8122V159.089H93.8873V155.014Z"
        fill="black"
      />
      <path
        d="M97.9623 155.014H93.8873V159.089H97.9623V155.014Z"
        fill="black"
      />
      <path
        d="M102.037 155.014H97.9623V159.089H102.037V155.014Z"
        fill="black"
      />
      <path
        d="M106.112 155.014H102.037V159.089H106.112V155.014Z"
        fill="black"
      />
      <path
        d="M110.187 155.014H106.112V159.089H110.187V155.014Z"
        fill="black"
      />
      <path
        d="M130.563 155.014H126.488V159.089H130.563V155.014Z"
        fill="black"
      />
      <path
        d="M134.638 155.014H130.563V159.089H134.638V155.014Z"
        fill="black"
      />
      <path
        d="M146.863 155.014H142.788V159.089H146.863V155.014Z"
        fill="black"
      />
      <path
        d="M159.088 155.014H155.013V159.089H159.088V155.014Z"
        fill="black"
      />
      <path
        d="M163.163 155.014H159.088V159.089H163.163V155.014Z"
        fill="black"
      />
      <path
        d="M167.238 155.014H163.163V159.089H167.238V155.014Z"
        fill="black"
      />
      <path
        d="M171.313 155.014H167.238V159.089H171.313V155.014Z"
        fill="black"
      />
      <path
        d="M175.388 155.014H171.313V159.089H175.388V155.014Z"
        fill="black"
      />
      <path
        d="M183.538 155.014H179.463V159.089H183.538V155.014Z"
        fill="black"
      />
      <path
        d="M187.613 155.014H183.538V159.089H187.613V155.014Z"
        fill="black"
      />
      <path
        d="M191.688 155.014H187.613V159.089H191.688V155.014Z"
        fill="black"
      />
      <path
        d="M44.9869 159.089H40.9118V163.164H44.9869V159.089Z"
        fill="black"
      />
      <path
        d="M49.0619 159.089H44.9869V163.164H49.0619V159.089Z"
        fill="black"
      />
      <path
        d="M53.1369 159.089H49.0619V163.164H53.1369V159.089Z"
        fill="black"
      />
      <path
        d="M73.5121 159.089H69.4371V163.164H73.5121V159.089Z"
        fill="black"
      />
      <path
        d="M85.7372 159.089H81.6622V163.164H85.7372V159.089Z"
        fill="black"
      />
      <path
        d="M89.8122 159.089H85.7372V163.164H89.8122V159.089Z"
        fill="black"
      />
      <path
        d="M93.8873 159.089H89.8122V163.164H93.8873V159.089Z"
        fill="black"
      />
      <path
        d="M110.187 159.089H106.112V163.164H110.187V159.089Z"
        fill="black"
      />
      <path
        d="M126.488 159.089H122.413V163.164H126.488V159.089Z"
        fill="black"
      />
      <path
        d="M134.638 159.089H130.563V163.164H134.638V159.089Z"
        fill="black"
      />
      <path
        d="M138.713 159.089H134.638V163.164H138.713V159.089Z"
        fill="black"
      />
      <path
        d="M150.938 159.089H146.863V163.164H150.938V159.089Z"
        fill="black"
      />
      <path
        d="M159.088 159.089H155.013V163.164H159.088V159.089Z"
        fill="black"
      />
      <path
        d="M175.388 159.089H171.313V163.164H175.388V159.089Z"
        fill="black"
      />
      <path
        d="M183.538 159.089H179.463V163.164H183.538V159.089Z"
        fill="black"
      />
      <path
        d="M191.688 159.089H187.613V163.164H191.688V159.089Z"
        fill="black"
      />
      <path
        d="M12.3866 163.164H8.31152V167.239H12.3866V163.164Z"
        fill="black"
      />
      <path
        d="M16.4616 163.164H12.3866V167.239H16.4616V163.164Z"
        fill="black"
      />
      <path
        d="M20.5366 163.164H16.4616V167.239H20.5366V163.164Z"
        fill="black"
      />
      <path
        d="M24.6117 163.164H20.5366V167.239H24.6117V163.164Z"
        fill="black"
      />
      <path
        d="M28.6867 163.164H24.6117V167.239H28.6867V163.164Z"
        fill="black"
      />
      <path
        d="M32.7617 163.164H28.6867V167.239H32.7617V163.164Z"
        fill="black"
      />
      <path
        d="M36.8368 163.164H32.7617V167.239H36.8368V163.164Z"
        fill="black"
      />
      <path
        d="M49.0619 163.164H44.9869V167.239H49.0619V163.164Z"
        fill="black"
      />
      <path d="M65.362 163.164H61.287V167.239H65.362V163.164Z" fill="black" />
      <path d="M69.4371 163.164H65.362V167.239H69.4371V163.164Z" fill="black" />
      <path
        d="M89.8122 163.164H85.7372V167.239H89.8122V163.164Z"
        fill="black"
      />
      <path
        d="M93.8873 163.164H89.8122V167.239H93.8873V163.164Z"
        fill="black"
      />
      <path
        d="M102.037 163.164H97.9623V167.239H102.037V163.164Z"
        fill="black"
      />
      <path
        d="M110.187 163.164H106.112V167.239H110.187V163.164Z"
        fill="black"
      />
      <path
        d="M126.488 163.164H122.413V167.239H126.488V163.164Z"
        fill="black"
      />
      <path
        d="M134.638 163.164H130.563V167.239H134.638V163.164Z"
        fill="black"
      />
      <path
        d="M146.863 163.164H142.788V167.239H146.863V163.164Z"
        fill="black"
      />
      <path
        d="M150.938 163.164H146.863V167.239H150.938V163.164Z"
        fill="black"
      />
      <path
        d="M155.013 163.164H150.938V167.239H155.013V163.164Z"
        fill="black"
      />
      <path
        d="M159.088 163.164H155.013V167.239H159.088V163.164Z"
        fill="black"
      />
      <path
        d="M167.238 163.164H163.163V167.239H167.238V163.164Z"
        fill="black"
      />
      <path
        d="M175.388 163.164H171.313V167.239H175.388V163.164Z"
        fill="black"
      />
      <path
        d="M183.538 163.164H179.463V167.239H183.538V163.164Z"
        fill="black"
      />
      <path
        d="M187.613 163.164H183.538V167.239H187.613V163.164Z"
        fill="black"
      />
      <path
        d="M191.688 163.164H187.613V167.239H191.688V163.164Z"
        fill="black"
      />
      <path
        d="M12.3866 167.239H8.31152V171.314H12.3866V167.239Z"
        fill="black"
      />
      <path
        d="M36.8368 167.239H32.7617V171.314H36.8368V167.239Z"
        fill="black"
      />
      <path
        d="M49.0619 167.239H44.9869V171.314H49.0619V167.239Z"
        fill="black"
      />
      <path d="M65.362 167.239H61.287V171.314H65.362V167.239Z" fill="black" />
      <path d="M69.4371 167.239H65.362V171.314H69.4371V167.239Z" fill="black" />
      <path
        d="M81.6622 167.239H77.5871V171.314H81.6622V167.239Z"
        fill="black"
      />
      <path
        d="M89.8122 167.239H85.7372V171.314H89.8122V167.239Z"
        fill="black"
      />
      <path
        d="M93.8873 167.239H89.8122V171.314H93.8873V167.239Z"
        fill="black"
      />
      <path
        d="M110.187 167.239H106.112V171.314H110.187V167.239Z"
        fill="black"
      />
      <path
        d="M114.262 167.239H110.187V171.314H114.262V167.239Z"
        fill="black"
      />
      <path
        d="M118.338 167.239H114.262V171.314H118.338V167.239Z"
        fill="black"
      />
      <path
        d="M122.413 167.239H118.338V171.314H122.413V167.239Z"
        fill="black"
      />
      <path
        d="M126.488 167.239H122.413V171.314H126.488V167.239Z"
        fill="black"
      />
      <path
        d="M130.563 167.239H126.488V171.314H130.563V167.239Z"
        fill="black"
      />
      <path
        d="M142.788 167.239H138.713V171.314H142.788V167.239Z"
        fill="black"
      />
      <path
        d="M146.863 167.239H142.788V171.314H146.863V167.239Z"
        fill="black"
      />
      <path
        d="M150.938 167.239H146.863V171.314H150.938V167.239Z"
        fill="black"
      />
      <path
        d="M155.013 167.239H150.938V171.314H155.013V167.239Z"
        fill="black"
      />
      <path
        d="M159.088 167.239H155.013V171.314H159.088V167.239Z"
        fill="black"
      />
      <path
        d="M175.388 167.239H171.313V171.314H175.388V167.239Z"
        fill="black"
      />
      <path
        d="M187.613 167.239H183.538V171.314H187.613V167.239Z"
        fill="black"
      />
      <path
        d="M191.688 167.239H187.613V171.314H191.688V167.239Z"
        fill="black"
      />
      <path
        d="M12.3866 171.314H8.31152V175.389H12.3866V171.314Z"
        fill="black"
      />
      <path
        d="M20.5366 171.314H16.4616V175.389H20.5366V171.314Z"
        fill="black"
      />
      <path
        d="M24.6117 171.314H20.5366V175.389H24.6117V171.314Z"
        fill="black"
      />
      <path
        d="M28.6867 171.314H24.6117V175.389H28.6867V171.314Z"
        fill="black"
      />
      <path
        d="M36.8368 171.314H32.7617V175.389H36.8368V171.314Z"
        fill="black"
      />
      <path
        d="M44.9869 171.314H40.9118V175.389H44.9869V171.314Z"
        fill="black"
      />
      <path
        d="M49.0619 171.314H44.9869V175.389H49.0619V171.314Z"
        fill="black"
      />
      <path d="M61.287 171.314H57.212V175.389H61.287V171.314Z" fill="black" />
      <path
        d="M89.8122 171.314H85.7372V175.389H89.8122V171.314Z"
        fill="black"
      />
      <path
        d="M93.8873 171.314H89.8122V175.389H93.8873V171.314Z"
        fill="black"
      />
      <path
        d="M97.9623 171.314H93.8873V175.389H97.9623V171.314Z"
        fill="black"
      />
      <path
        d="M102.037 171.314H97.9623V175.389H102.037V171.314Z"
        fill="black"
      />
      <path
        d="M106.112 171.314H102.037V175.389H106.112V171.314Z"
        fill="black"
      />
      <path
        d="M110.187 171.314H106.112V175.389H110.187V171.314Z"
        fill="black"
      />
      <path
        d="M126.488 171.314H122.413V175.389H126.488V171.314Z"
        fill="black"
      />
      <path
        d="M130.563 171.314H126.488V175.389H130.563V171.314Z"
        fill="black"
      />
      <path
        d="M146.863 171.314H142.788V175.389H146.863V171.314Z"
        fill="black"
      />
      <path
        d="M155.013 171.314H150.938V175.389H155.013V171.314Z"
        fill="black"
      />
      <path
        d="M159.088 171.314H155.013V175.389H159.088V171.314Z"
        fill="black"
      />
      <path
        d="M163.163 171.314H159.088V175.389H163.163V171.314Z"
        fill="black"
      />
      <path
        d="M167.238 171.314H163.163V175.389H167.238V171.314Z"
        fill="black"
      />
      <path
        d="M171.313 171.314H167.238V175.389H171.313V171.314Z"
        fill="black"
      />
      <path
        d="M175.388 171.314H171.313V175.389H175.388V171.314Z"
        fill="black"
      />
      <path
        d="M183.538 171.314H179.463V175.389H183.538V171.314Z"
        fill="black"
      />
      <path
        d="M12.3866 175.389H8.31152V179.464H12.3866V175.389Z"
        fill="black"
      />
      <path
        d="M20.5366 175.389H16.4616V179.464H20.5366V175.389Z"
        fill="black"
      />
      <path
        d="M24.6117 175.389H20.5366V179.464H24.6117V175.389Z"
        fill="black"
      />
      <path
        d="M28.6867 175.389H24.6117V179.464H28.6867V175.389Z"
        fill="black"
      />
      <path
        d="M36.8368 175.389H32.7617V179.464H36.8368V175.389Z"
        fill="black"
      />
      <path
        d="M49.0619 175.389H44.9869V179.464H49.0619V175.389Z"
        fill="black"
      />
      <path d="M57.212 175.389H53.1369V179.464H57.212V175.389Z" fill="black" />
      <path d="M61.287 175.389H57.212V179.464H61.287V175.389Z" fill="black" />
      <path d="M69.4371 175.389H65.362V179.464H69.4371V175.389Z" fill="black" />
      <path
        d="M73.5121 175.389H69.4371V179.464H73.5121V175.389Z"
        fill="black"
      />
      <path
        d="M89.8122 175.389H85.7372V179.464H89.8122V175.389Z"
        fill="black"
      />
      <path
        d="M97.9623 175.389H93.8873V179.464H97.9623V175.389Z"
        fill="black"
      />
      <path
        d="M106.112 175.389H102.037V179.464H106.112V175.389Z"
        fill="black"
      />
      <path
        d="M110.187 175.389H106.112V179.464H110.187V175.389Z"
        fill="black"
      />
      <path
        d="M122.413 175.389H118.338V179.464H122.413V175.389Z"
        fill="black"
      />
      <path
        d="M126.488 175.389H122.413V179.464H126.488V175.389Z"
        fill="black"
      />
      <path
        d="M134.638 175.389H130.563V179.464H134.638V175.389Z"
        fill="black"
      />
      <path
        d="M138.713 175.389H134.638V179.464H138.713V175.389Z"
        fill="black"
      />
      <path
        d="M146.863 175.389H142.788V179.464H146.863V175.389Z"
        fill="black"
      />
      <path
        d="M150.938 175.389H146.863V179.464H150.938V175.389Z"
        fill="black"
      />
      <path
        d="M171.313 175.389H167.238V179.464H171.313V175.389Z"
        fill="black"
      />
      <path
        d="M179.463 175.389H175.388V179.464H179.463V175.389Z"
        fill="black"
      />
      <path
        d="M187.613 175.389H183.538V179.464H187.613V175.389Z"
        fill="black"
      />
      <path
        d="M12.3866 179.464H8.31152V183.539H12.3866V179.464Z"
        fill="black"
      />
      <path
        d="M20.5366 179.464H16.4616V183.539H20.5366V179.464Z"
        fill="black"
      />
      <path
        d="M24.6117 179.464H20.5366V183.539H24.6117V179.464Z"
        fill="black"
      />
      <path
        d="M28.6867 179.464H24.6117V183.539H28.6867V179.464Z"
        fill="black"
      />
      <path
        d="M36.8368 179.464H32.7617V183.539H36.8368V179.464Z"
        fill="black"
      />
      <path
        d="M44.9869 179.464H40.9118V183.539H44.9869V179.464Z"
        fill="black"
      />
      <path d="M57.212 179.464H53.1369V183.539H57.212V179.464Z" fill="black" />
      <path d="M65.362 179.464H61.287V183.539H65.362V179.464Z" fill="black" />
      <path d="M69.4371 179.464H65.362V183.539H69.4371V179.464Z" fill="black" />
      <path
        d="M73.5121 179.464H69.4371V183.539H73.5121V179.464Z"
        fill="black"
      />
      <path
        d="M81.6622 179.464H77.5871V183.539H81.6622V179.464Z"
        fill="black"
      />
      <path
        d="M85.7372 179.464H81.6622V183.539H85.7372V179.464Z"
        fill="black"
      />
      <path
        d="M89.8122 179.464H85.7372V183.539H89.8122V179.464Z"
        fill="black"
      />
      <path
        d="M106.112 179.464H102.037V183.539H106.112V179.464Z"
        fill="black"
      />
      <path
        d="M114.262 179.464H110.187V183.539H114.262V179.464Z"
        fill="black"
      />
      <path
        d="M118.338 179.464H114.262V183.539H118.338V179.464Z"
        fill="black"
      />
      <path
        d="M122.413 179.464H118.338V183.539H122.413V179.464Z"
        fill="black"
      />
      <path
        d="M130.563 179.464H126.488V183.539H130.563V179.464Z"
        fill="black"
      />
      <path
        d="M134.638 179.464H130.563V183.539H134.638V179.464Z"
        fill="black"
      />
      <path
        d="M138.713 179.464H134.638V183.539H138.713V179.464Z"
        fill="black"
      />
      <path
        d="M155.013 179.464H150.938V183.539H155.013V179.464Z"
        fill="black"
      />
      <path
        d="M163.163 179.464H159.088V183.539H163.163V179.464Z"
        fill="black"
      />
      <path
        d="M167.238 179.464H163.163V183.539H167.238V179.464Z"
        fill="black"
      />
      <path
        d="M171.313 179.464H167.238V183.539H171.313V179.464Z"
        fill="black"
      />
      <path
        d="M183.538 179.464H179.463V183.539H183.538V179.464Z"
        fill="black"
      />
      <path
        d="M187.613 179.464H183.538V183.539H187.613V179.464Z"
        fill="black"
      />
      <path
        d="M12.3866 183.539H8.31152V187.614H12.3866V183.539Z"
        fill="black"
      />
      <path
        d="M36.8368 183.539H32.7617V187.614H36.8368V183.539Z"
        fill="black"
      />
      <path
        d="M44.9869 183.539H40.9118V187.614H44.9869V183.539Z"
        fill="black"
      />
      <path
        d="M53.1369 183.539H49.0619V187.614H53.1369V183.539Z"
        fill="black"
      />
      <path d="M65.362 183.539H61.287V187.614H65.362V183.539Z" fill="black" />
      <path
        d="M93.8873 183.539H89.8122V187.614H93.8873V183.539Z"
        fill="black"
      />
      <path
        d="M102.037 183.539H97.9623V187.614H102.037V183.539Z"
        fill="black"
      />
      <path
        d="M122.413 183.539H118.338V187.614H122.413V183.539Z"
        fill="black"
      />
      <path
        d="M138.713 183.539H134.638V187.614H138.713V183.539Z"
        fill="black"
      />
      <path
        d="M150.938 183.539H146.863V187.614H150.938V183.539Z"
        fill="black"
      />
      <path
        d="M155.013 183.539H150.938V187.614H155.013V183.539Z"
        fill="black"
      />
      <path
        d="M159.088 183.539H155.013V187.614H159.088V183.539Z"
        fill="black"
      />
      <path
        d="M179.463 183.539H175.388V187.614H179.463V183.539Z"
        fill="black"
      />
      <path
        d="M187.613 183.539H183.538V187.614H187.613V183.539Z"
        fill="black"
      />
      <path
        d="M12.3866 187.614H8.31152V191.689H12.3866V187.614Z"
        fill="black"
      />
      <path
        d="M16.4616 187.614H12.3866V191.689H16.4616V187.614Z"
        fill="black"
      />
      <path
        d="M20.5366 187.614H16.4616V191.689H20.5366V187.614Z"
        fill="black"
      />
      <path
        d="M24.6117 187.614H20.5366V191.689H24.6117V187.614Z"
        fill="black"
      />
      <path
        d="M28.6867 187.614H24.6117V191.689H28.6867V187.614Z"
        fill="black"
      />
      <path
        d="M32.7617 187.614H28.6867V191.689H32.7617V187.614Z"
        fill="black"
      />
      <path
        d="M36.8368 187.614H32.7617V191.689H36.8368V187.614Z"
        fill="black"
      />
      <path
        d="M44.9869 187.614H40.9118V191.689H44.9869V187.614Z"
        fill="black"
      />
      <path
        d="M53.1369 187.614H49.0619V191.689H53.1369V187.614Z"
        fill="black"
      />
      <path d="M61.287 187.614H57.212V191.689H61.287V187.614Z" fill="black" />
      <path
        d="M73.5121 187.614H69.4371V191.689H73.5121V187.614Z"
        fill="black"
      />
      <path
        d="M81.6622 187.614H77.5871V191.689H81.6622V187.614Z"
        fill="black"
      />
      <path
        d="M85.7372 187.614H81.6622V191.689H85.7372V187.614Z"
        fill="black"
      />
      <path
        d="M102.037 187.614H97.9623V191.689H102.037V187.614Z"
        fill="black"
      />
      <path
        d="M110.187 187.614H106.112V191.689H110.187V187.614Z"
        fill="black"
      />
      <path
        d="M114.262 187.614H110.187V191.689H114.262V187.614Z"
        fill="black"
      />
      <path
        d="M126.488 187.614H122.413V191.689H126.488V187.614Z"
        fill="black"
      />
      <path
        d="M130.563 187.614H126.488V191.689H130.563V187.614Z"
        fill="black"
      />
      <path
        d="M134.638 187.614H130.563V191.689H134.638V187.614Z"
        fill="black"
      />
      <path
        d="M138.713 187.614H134.638V191.689H138.713V187.614Z"
        fill="black"
      />
      <path
        d="M150.938 187.614H146.863V191.689H150.938V187.614Z"
        fill="black"
      />
      <path
        d="M171.313 187.614H167.238V191.689H171.313V187.614Z"
        fill="black"
      />
      <path
        d="M175.388 187.614H171.313V191.689H175.388V187.614Z"
        fill="black"
      />
      <path
        d="M179.463 187.614H175.388V191.689H179.463V187.614Z"
        fill="black"
      />
      <path
        d="M183.538 187.614H179.463V191.689H183.538V187.614Z"
        fill="black"
      />
      <path
        d="M187.613 187.614H183.538V191.689H187.613V187.614Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2097_5315">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
