import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type PrivacyOptionsFragmentFragment = { __typename?: 'PrivacyOptions', isBlocked?: boolean | null, kyc_bvn?: boolean | null, kyc_nin?: boolean | null, personal?: boolean | null, billing?: boolean | null, nextOfKin?: boolean | null, emergency?: boolean | null, nationality?: boolean | null, travel?: boolean | null, medical?: boolean | null, education?: boolean | null, workExperience?: boolean | null, phoneVerification?: boolean | null };

export const PrivacyOptionsFragmentFragmentDoc = gql`
    fragment privacyOptionsFragment on PrivacyOptions {
  isBlocked
  kyc_bvn
  kyc_nin
  personal
  billing
  nextOfKin
  emergency
  nationality
  travel
  medical
  education
  workExperience
  phoneVerification
}
    `;