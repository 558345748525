import React from "react";
import {
  AuthIcon,
  CloseIcon,
} from "../../../../../../assets/images/dashboardLayout/svgExports";
import { ModalContainer } from "../../../../../../components/layout/ModalLayout/modalLayout.styles";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";
import { CustomForm } from "components";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  setShowPinModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  setPinMessage: (value: string | ((previousValue: string) => string)) => void;
}

const PhoneAuthenticationModal: React.FC<IProps> = ({
  open,
  close,
  setShowPinModal,
  setPinMessage,
}) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    setPinMessage("Phone");
    setShowPinModal(true);
  };

  const formDetails = [
    {
      name: "startDate",
      type: "text",
      width: "100%",
      label: "Enter phone address",
    },
  ];

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <AuthIcon width="30" />
        <h3 className="modal__headertext">Phone Authentication</h3>
        <p className="modal__subheadertext">
          We will send a verification code to the Phone Number you provide below
          whenever you sign in to your OneID Account
        </p>
        <CustomForm
          formDetails={formDetails}
          formFinishButtonText={"Continue"}
          submit={submit}
          formNoBorderCancelButton
          buttonPosition="flex-end"
        />
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default PhoneAuthenticationModal;
