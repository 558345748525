import React from "react";
import { EmploymentListContainer } from "./employmentList.styles";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";
import { HiOutlineArrowRight as Arrow } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface IProps {
  activeTab: string;
}

const EmploymentList: React.FC<IProps> = ({ activeTab }) => {
  const navigate = useNavigate();
  const employmentList = [
    {
      title: "Product Designer",
      year: "2022",
      startDate: "June 6, 2022",
      endDate: "Present",
      state: "Lagos State",
      country: "Nigeria",
      institution: "Interswitch Nigeria Limited",
    },
    {
      title: "UX Researcher",
      year: "2021",
      startDate: "June 6, 2021",
      endDate: "Present",
      state: "Lagos State",
      country: "Nigeria",
      institution: "Interswitch Nigeria Limited",
    },
    {
      title: "Product Designer",
      year: "2020",
      startDate: "June 6, 2020",
      endDate: "Present",
      state: "Lagos State",
      country: "Nigeria",
      institution: "Interswitch Nigeria Limited",
    },
    {
      title: "UI/UX Design Intern",
      year: "2019",
      startDate: "June 6, 2019",
      endDate: "Present",
      state: "Lagos State",
      country: "Nigeria",
      institution: "Interswitch Nigeria Limited",
    },
  ];

  return (
    <EmploymentListContainer>
      {employmentList.map((item: any, idx: number) =>
        activeTab === "All" ? (
          <FlexibleDiv
            alignItems="flex-start"
            flexDir="column"
            className="employmentlist__mainwrap"
          >
            <FlexibleDiv justifyContent="space-between">
              <h4 className="employmentlist__headertext">{item.title}</h4>
              <div onClick={() => navigate(`/employment/${item.title}`)}>
                <Arrow />
              </div>
            </FlexibleDiv>
            <p className="employment__subheadertext">{item.institution}</p>
            <span className="emplaoyment__bodytext">
              {item.startDate} - {item.endDate}
            </span>
            <span className="employment__bodytext">
              {item.state}, {item.country}
            </span>
          </FlexibleDiv>
        ) : (
          item.year === activeTab && (
            <FlexibleDiv
              alignItems="flex-start"
              flexDir="column"
              className="employmentlist__mainwrap"
            >
              <FlexibleDiv justifyContent="space-between">
                <h4>{item.title}</h4>{" "}
                <div onClick={() => navigate(`/employment/${item.title}`)}>
                  <Arrow />
                </div>
              </FlexibleDiv>
              <p>{item.institution}</p>
              <span>
                {item.startDate} - {item.endDate}
              </span>
              <span>
                {item.state}, {item.country}
              </span>
            </FlexibleDiv>
          )
        )
      )}
    </EmploymentListContainer>
  );
};

export default EmploymentList;
