import React from "react";
import SuccessCheck from "../../../../../assets/images/dashboardLayout/success-check.png";
import { CloseIcon } from "../../../../../assets/images/dashboardLayout/svgExports";
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles";
import { ModalContainer } from "../../../../../components/layout/ModalLayout/modalLayout.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
}

const KycFailedModal: React.FC<IProps> = ({ open, close }) => {
  const handleClose = () => {
    close(!open);
  };
  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <img src={SuccessCheck} alt="" className="success__check" />
        <h4 className="modal__header__text">
          BVN verification failed. No match found.
        </h4>
        <p>Please check that your full name matches your documents</p>
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default KycFailedModal;
