import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserUpdatePersonProfileImageMutationVariables = Types.Exact<{
  profileImage: Types.Scalars['Upload'];
}>;


export type UserUpdatePersonProfileImageMutation = { __typename?: 'Mutation', setProfileImage: string };


export const UserUpdatePersonProfileImageDocument = gql`
    mutation userUpdatePersonProfileImage($profileImage: Upload!) {
  setProfileImage(profileImage: $profileImage)
}
    `;
export type UserUpdatePersonProfileImageMutationFn = Apollo.MutationFunction<UserUpdatePersonProfileImageMutation, UserUpdatePersonProfileImageMutationVariables>;

/**
 * __useUserUpdatePersonProfileImageMutation__
 *
 * To run a mutation, you first call `useUserUpdatePersonProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePersonProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePersonProfileImageMutation, { data, loading, error }] = useUserUpdatePersonProfileImageMutation({
 *   variables: {
 *      profileImage: // value for 'profileImage'
 *   },
 * });
 */
export function useUserUpdatePersonProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatePersonProfileImageMutation, UserUpdatePersonProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatePersonProfileImageMutation, UserUpdatePersonProfileImageMutationVariables>(UserUpdatePersonProfileImageDocument, options);
      }
export type UserUpdatePersonProfileImageMutationHookResult = ReturnType<typeof useUserUpdatePersonProfileImageMutation>;
export type UserUpdatePersonProfileImageMutationResult = Apollo.MutationResult<UserUpdatePersonProfileImageMutation>;
export type UserUpdatePersonProfileImageMutationOptions = Apollo.BaseMutationOptions<UserUpdatePersonProfileImageMutation, UserUpdatePersonProfileImageMutationVariables>;