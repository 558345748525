import styled from "styled-components";
import FormControl from "@mui/material/FormControl";

export const FormControlContainer = styled(FormControl)`
  width: 100%;
  position: relative;

  .MuiFormLabel-root {
    font-size: 14px;
    color: #7d7d7d;
    text-transform: capitalize;
    padding: 0;
  }
  .MuiFormLabel-root.Mui-focused {
    background-color: #fff;
    padding: 5px 10px;
    margin-top: -5px;
    text-transform: capitalize;
    color: #27272799;
    font-size: 17px;
  }
  .Mui-active .search {
    position: absolute;
    right: 15px;
    top: 15px;

    svg {
      font-size: 22px;
      color: #939393;
    }
  }

  .textarea__label {
    color: #7d7d7d;
    font-size: 14px;
    margin-top: 7px;
  }
`;
