import React, { useState } from "react"
import { SingleEmploymentContainer } from "./singleEmployment.styles"
import {
  FlexibleDiv,
  FlexibleSection,
} from "../../../components/lib/Box/box.styles"
import { DashboardLayout, GoBack } from "components"
import { CustomForm } from "components"
import { FaPen as Edit } from "react-icons/fa"

const SingleEmployment = () => {
  const [editDisabled, setEditDisabled] = useState(true)
  const formDetails = [
    {
      name: "company",
      type: "text",
      width: "100%",
      label: "Name Of Company",
    },
    {
      name: "address",
      type: "text",
      width: "100%",
      label: "Company’s Address",
    },
    {
      name: "website",
      type: "text",
      width: "100%",
      label: "Company’s Website",
    },
    {
      name: "title",
      type: "text",
      width: "48%",
      label: "Job Title",
    },
    {
      name: "salary",
      type: "select",
      width: "48%",
      label: "Salary (Monthly)",
      dataSet: [
        "N500,000 - N750,000",
        "N750,000 - N1,250,000",
        "N5,000,000 - N50,000,000",
      ],
    },
    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
  ]
  const submit = () => {}

  return (
    <DashboardLayout headerText="Employment History">
      <GoBack />

      <SingleEmploymentContainer>
        <FlexibleSection
          className="form__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <FlexibleDiv justifyContent="space-between">
            <h4 className="header__text">
              INTERSWITCH <span className="employmemt__year">(2022)</span>
            </h4>
            <span
              className="edit"
              onClick={() => setEditDisabled(!editDisabled)}
            >
              <Edit /> Edit
            </span>
          </FlexibleDiv>

          <CustomForm
            formDetails={formDetails}
            formFinishButtonText="Update Employment"
            editDisabled={editDisabled}
            submit={submit}
          />
        </FlexibleSection>
      </SingleEmploymentContainer>
    </DashboardLayout>
  )
}

export default SingleEmployment
