import React from "react";
import { EducationListContainer } from "./educationList.styles";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";
import { HiOutlineArrowRight as Arrow } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface IProps {
  activeTab: string;
}

const EducationList: React.FC<IProps> = ({ activeTab }) => {
  const navigate = useNavigate();
  const educationList = [
    {
      institution: "Yaba College Of Technology",
      type: "Tertiary",
      startDate: "Oct 8, 2017",
      endDate: "Jun 24, 2022",
      state: "Lagos State",
      country: "Nigeria",
      course: "HND Business Admin",
    },
    {
      institution: "King’s Court Secondary School",
      type: "Secondary",
      startDate: "May 20, 2011",
      endDate: "Aug 27, 2017",
      state: "Lagos State",
      country: "Nigeria",
      course: "SSCE",
    },
    {
      institution: "King’s Court Secondary School",
      type: "Primary",
      startDate: "Jan 20, 2004",
      endDate: "July 30, 2011",
      state: "Lagos State",
      country: "Nigeria",
      course: "Leaving School Cert",
    },
  ];

  return (
    <EducationListContainer>
      {educationList.map((item: any, idx: number) =>
        activeTab === "All" ? (
          <FlexibleDiv
            alignItems="flex-start"
            flexDir="column"
            className="educationlist__mainwrap"
          >
            <FlexibleDiv justifyContent="space-between">
              <h4 className="educationlist__headertext">{item.institution}</h4>
              <div onClick={() => navigate(`/education/${item.institution}`)}>
                <Arrow />
              </div>
            </FlexibleDiv>
            <p className="educationlist__subheadertext">{item.course}</p>
            <span className="educationlist__bodytext">
              {item.startDate} - {item.endDate}
            </span>
            <span className="educationlist__bodytext">
              {item.state}, {item.country}
            </span>
          </FlexibleDiv>
        ) : (
          item.type === activeTab && (
            <FlexibleDiv
              alignItems="flex-start"
              flexDir="column"
              className="educationlist__mainwrap"
            >
              <FlexibleDiv justifyContent="space-between">
                <h4 className="educationlist__headertext">
                  {item.institution}
                </h4>
                <div
                  onClick={() => navigate(`/employment/${item.institution}`)}
                >
                  <Arrow />
                </div>
              </FlexibleDiv>
              <p className="educationlist__subheadertext">{item.course}</p>
              <span className="educationlist__bodytext">
                {item.startDate} - {item.endDate}
              </span>
              <span className="educationlist__bodytext">
                {item.state}, {item.country}
              </span>
            </FlexibleDiv>
          )
        )
      )}
    </EducationListContainer>
  );
};

export default EducationList;
