import React from "react";
import { LongArrowRightIcon } from "assets/images/dashboardLayout/svgExports";
import {
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components";
import { UpdateProfileLayoutContainer } from "../../../../components/layout/UpdateProfileLayout/updateProfile.styles";
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles";
import { CustomForm } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { useMe } from "helpers";
import { getErrorMessage } from "common";
import * as Yup from "yup";
import { useUpdateUserEmergencyContactMutation } from "../gql/__types/UpdateProfile.types";
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(6).nullable(),
  email: Yup.string().email().nullable(),
  relationship: Yup.string()
    .oneOf([
      "Mother",
      "Father",
      "Sister",
      "Brother",
      "Spouse",
      "Cousin",
      "Friend",
      "Guardian",
    ])
    .nullable(),
  phone: Yup.string().min(10).nullable(),
  address: Yup.string().min(6).nullable(),
});

const EmergencyContactUpdate = () => {
  const location = useLocation();
  const [step] = React.useState(location?.state);
  const navigate = useNavigate();
  const [mutate, { loading }] = useUpdateUserEmergencyContactMutation();
  const me = useMe();
  const { data, refetch, loading: profileLoading } = useGetProfileDataQuery();
  const profile = data && data.userGetProfile;
  const [hideFormWhileRefetch, sethideFormWhileRefetch] = React.useState(true);
  const emergencyContacts =
    profile && profile.emergencyContacts && profile.emergencyContacts[0];

  const initialValues = {
    name: emergencyContacts?.name || "",
    phone: emergencyContacts?.phone || "",
    email: emergencyContacts?.email || "",
    address: emergencyContacts?.address || "",
    relationship: emergencyContacts?.relationship || "",
  };

  const formDetails = [
    {
      name: "name",
      type: "text",
      width: "48%",
      label: "Contact's Full Name",
    },

    {
      name: "relationship",
      type: "select",
      width: "48%",
      label: "Relationship with Contact",
      dataSet: [
        "Mother",
        "Father",
        "Sister",
        "Brother",
        "Spouse",
        "Cousin",
        "Friend",
        "Guardian",
      ],
    },

    {
      name: "phone",
      type: "text",
      width: "48%",
      label: "Next Of Kin's Phone Number",
    },

    {
      name: "email",
      type: "text",
      width: "48%",
      label: "Your Contact's Email",
    },
    {
      name: "address",
      type: "text",
      width: "100%",
      label: "Enter Contact's Address",
    },
  ];

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    const payload = {
      ...me?.profile,
      ...values,
    };

    try {
      await mutate({
        variables: payload,
      });

      sethideFormWhileRefetch(false);
      await refetch();

      if (!step) {
        ToastMessage(
          TOASTER_TYPE.SUCCESS,
          "Emergency contact details updated successfully."
        );
      } else {
        navigate("/profile");
      }
      setSubmitting(false);
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error));
    }
  };

  return (
    <DashboardLayout headerText="Update Profile">
      <GoBack />
      {loading && <Progress position="relative" />}

      {profileLoading && !!hideFormWhileRefetch ? (
        <Progress position="relative" />
      ) : (
        <UpdateProfileLayoutContainer
          className="updateprofile__main__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          {!!step && (
            <FlexibleDiv className="progress__bar">
              <FlexibleDiv
                className="progress__bar__inner"
                width="100%"
              ></FlexibleDiv>
            </FlexibleDiv>
          )}
          <p className="form__title">Emergency Details</p>

          <CustomForm
            formDetails={formDetails}
            formFinishButtonText={!!step ? "Save Details" : "Update"}
            formColumnButtons={!!step ? true : false}
            submit={submit}
            validationSchema={validationSchema}
            initialValues={initialValues}
          />
          {!!step && (
            <FlexibleDiv className="form__skip">
              <span className="skip__text" onClick={() => navigate("/profile")}>
                Skip
                <LongArrowRightIcon />
              </span>
            </FlexibleDiv>
          )}
        </UpdateProfileLayoutContainer>
      )}
    </DashboardLayout>
  );
};

export default EmergencyContactUpdate;
