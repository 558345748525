import styled from "styled-components";
import Modal from "@mui/material/Modal";

type Custom = {
  maxWidth?: string;
};

export const ModalContainer = styled(Modal)<Custom>`
  display: flex;
  justify-content: center;
  align-items: center;

  .box__wrap {
    background: #ffffff;
    border-radius: 12px;
    padding: 40px;
    width: 90%;
    max-width: ${({ maxWidth }) => maxWidth || "520px"};
    outline: none;
    position: relative;
    .appprovedrequest__titles {
      margin: 10px 0 0 0;
    }

    .closeIcon {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }
    .success__check {
      width: 30%;
      min-width: 80px;
      max-width: 120px;
    }

    .verify__next {
      margin-top: 40px;
      color: #00499b;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
      svg {
        margin: 0 0 -2px 10px;
        cursor: pointer;
      }
    }

    .key__copy {
      padding: 15px;
      flex-wrap: nowrap;
      background: rgba(221, 235, 255, 0.8);
      border-radius: 8px;
      max-width: 300px;
      position: relative;
      margin-bottom: 20px;

      .key__code {
        color: #0b61dd;
        font-weight: bold;
        margin-right: 15px;
      }

      .copy {
        position: absolute;
        right: 15px;
        top: 12px;
        cursor: pointer;
      }
    }

    .modal__headertext {
      text-transform: capitalize;
      margin: 20px 0 0 0;
      font-size: 20px;
    }
    .modal__subheadertext {
      max-width: 100%;
      text-align: center;
      font-size: 15px;
      color: #0c0c0c;
    }
    .buttonSpan {
      color: #0b61dd;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
    .MuiOutlinedInput-root {
      fieldset {
        border-color: #c9c9c9;
        background-color: #fafafa;
      }

      input {
        padding-left: 15px;
        margin-top: 0;
        font-size: 14px;
      }
      .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
        color: #939393;
        font-size: 14px;
      }
      textarea {
        ::placeholder {
          color: #939393;
        }
      }
    }

    .Mui-focused fieldset {
      border-color: #0b61dd;
    }

    @media (max-width: 450px) {
      padding: 40px 20px;
    }
  }
`;
