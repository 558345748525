import { getErrorMessage } from "common"
import { OIDButton, Progress, TOASTER_TYPE, ToastMessage } from "components"
import { Config } from "config"
import { useMe } from "helpers"
import React from "react"
import { ModalContainer } from "../../../../../components/layout/ModalLayout/modalLayout.styles"
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles"
import { useUserDeleteAccountMutation } from "../../gql/__types/account.types"

interface IProps {
  open: boolean
  setShowDeleteAccountModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void
}

const DeleteAccountModal: React.FC<IProps> = ({
  open,
  setShowDeleteAccountModal,
}) => {
  const me = useMe()
  const [mutate, { loading }] = useUserDeleteAccountMutation()

  const handleClose = () => {
    setShowDeleteAccountModal(!open)
  }

  const deleteAccount = async () => {
    try {
      me?._id &&
        (await mutate({
          variables: { _id: me?._id },
        }))
      localStorage.removeItem("1D_AUR")
      window.location.href = `${Config.AUTH_GATEWAY_URL}`
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <ModalContainer open={open} onClose={handleClose} maxWidth="450px">
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        {loading && <Progress />}

        <h3 className="modal__headertext">Deactivate Account </h3>
        <p className="modal__subheadertext">
          Are you sure you want to deactivate your account{" "}
        </p>
        <FlexibleDiv
          maxWidth="290px"
          margin="20px 0 0 0"
          justifyContent="space-between"
        >
          <OIDButton onClick={handleClose}>Cancel</OIDButton>
          <OIDButton
            onClick={deleteAccount}
            backgroundColor="transparent"
            color="#0B61DD"
          >
            Deactivate
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default DeleteAccountModal
