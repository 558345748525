import styled from "styled-components";
import { FlexibleDiv } from "../../components/lib/Box/box.styles";

type Custom = {};

export const ManageCardsContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px 30px;
  justify-content: flex-start;

  .manageCards__main__wrap {
    background: #ffffff;
    border-radius: 18px;
    padding: 40px;
    width: 80%;
    min-height: 60vh;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }
`;
