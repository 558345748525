import React from "react"
import { Link, useLocation } from "react-router-dom"
import Logo from "../../../../assets/images/dashboardLayout/logo-with-text.png"
import {
  AppointmentsIcon,
  CardsIcon,
  EducationIcon,
  EmploymentIcon,
  MedicalIcon,
  OverviewIcon,
  SettingsIcon,
  TravelIcon,
  VerifyUserIcon,
} from "../../../../assets/images/dashboardLayout/svgExports"
import { FlexibleDiv } from "../../../lib/Box/box.styles"
import { MobileDrawer, SidebarContainer } from "./sidebar.styles"

interface IProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void
}

export const Sidebar: React.FC<IProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const location = useLocation()

  const sidebarItems = [
    {
      section: "Your OneID Overview",
      sectionItems: [
        {
          sectionItemsTitle: "Dashboard",
          path: "/",
          icon: (
            <OverviewIcon
              width="18"
              pathFill={location.pathname === "/" ? "#0B61DD" : ""}
            />
          ),
          keyWord: "dashboard",
        },
      ],
    },
    {
      section: "Manage Your OneID",
      sectionItems: [
        {
          sectionItemsTitle: "Employment History",
          path: "/employment-history",
          icon: (
            <EmploymentIcon
              width="18"
              pathFill={
                location.pathname.includes("employment") ? "#0B61DD" : ""
              }
            />
          ),
          keyWord: "employment",
        },
        {
          sectionItemsTitle: "Educational Background",
          path: "/educational-background",
          icon: (
            <EducationIcon
              width="18"
              pathFill={
                location.pathname.includes("education") ? "#0B61DD" : ""
              }
            />
          ),
          keyWord: "education",
        },
        {
          sectionItemsTitle: "Medical History",
          path: "/medical-history",
          icon: (
            <MedicalIcon
              width="18"
              pathFill={location.pathname.includes("medical") ? "#0B61DD" : ""}
              fill={location.pathname.includes("medical") ? "#fff" : ""}
            />
          ),
          keyWord: "medical",
        },
        {
          sectionItemsTitle: "Tickets & Passes",
          path: "/travel-history",
          icon: (
            <TravelIcon
              width="18"
              pathFill={location.pathname.includes("travel") ? "#0B61DD" : ""}
            />
          ),
          keyWord: "travel",
        },
      ],
    },

    {
      section: "Services",
      sectionItems: [
        {
          sectionItemsTitle: "Appointments",
          path: "/appointments",
          icon: (
            <AppointmentsIcon
              width="18"
              pathFill={
                location.pathname.includes("appointment") ? "#0B61DD" : ""
              }
            />
          ),
          keyWord: "appointment",
        },
        {
          sectionItemsTitle: "Verify Users",
          path: "/verify-users",
          icon: (
            <VerifyUserIcon
              width="18"
              pathFill={location.pathname.includes("verify") ? "#0B61DD" : ""}
            />
          ),
          keyWord: "verify",
        },
        // {
        //   sectionItemsTitle: "Open Bank Account",
        //   path: "/bank-services",
        //   icon: (
        //     <BankIcon
        //       width="18"
        //       pathFill={location.pathname.includes("bank") ? "#0B61DD" : ""}
        //     />
        //   ),
        //   keyWord: "bank",
        // },
        {
          sectionItemsTitle: "ID Documents",
          path: "/cards",
          icon: (
            <CardsIcon
              width="18"
              pathFill={location.pathname.includes("cards") ? "#0B61DD" : ""}
              stroke={location.pathname.includes("cards") ? "#fff" : ""}
            />
          ),
          keyWord: "cards",
        },
      ],
    },
  ]

  return (
    <SidebarContainer>
      <FlexibleDiv
        flexDir="column"
        className="sidebar__wrap__web"
        justifyContent="flex-start"
        flexWrap="nowrap"
      >
        <FlexibleDiv className="sidebar__logo" justifyContent="flex-start">
          <img alt="" src={Logo} />
        </FlexibleDiv>
        <FlexibleDiv
          className="sidebar__items__wrap"
          flexDir="column"
          justifyContent="flex-start"
        >
          {sidebarItems.map((data, idx) => (
            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              className="sidebar__items"
              key={idx}
            >
              <h3 className="sidebar__section__headertext">{data.section}</h3>
              {data.sectionItems.map((item, idx) => (
                <Link
                  to={item.path}
                  id={
                    location.pathname === item.path ||
                    location.pathname.includes(item.keyWord)
                      ? "active__path"
                      : ""
                  }
                  key={idx}
                  className="sidebar__link"
                >
                  {item.icon}
                  {item.sectionItemsTitle}
                </Link>
              ))}
            </FlexibleDiv>
          ))}

          <FlexibleDiv
            flexDir="column"
            alignItems="flex-start"
            className="sidebar__items settings_section"
          >
            <Link
              to="/settings/account"
              id={location.pathname.includes("settings") ? "active__path" : ""}
              className="sidebar__link"
            >
              <SettingsIcon
                width="18"
                pathFill={
                  location.pathname.includes("settings") ? "#0B61DD" : ""
                }
              />
              Settings
            </Link>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>

      <MobileDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <FlexibleDiv className="sidebar__wrap__mobile">
          <FlexibleDiv className="sidebar__logo" justifyContent="flex-start">
            <img alt="" src={Logo} />
          </FlexibleDiv>
          <FlexibleDiv
            className="sidebar__items__wrap"
            flexDir="column"
            justifyContent="flex-start"
            flexWrap="nowrap"
          >
            {sidebarItems.map((data, idx) => (
              <FlexibleDiv
                flexDir="column"
                alignItems="flex-start"
                className="sidebar__items"
                key={idx}
              >
                <h3 className="sidebar__section__headertext">{data.section}</h3>
                {data.sectionItems.map((item, idx) => (
                  <Link
                    to={item.path}
                    id={location.pathname === item.path ? "active__path" : ""}
                    className="sidebar__link"
                    key={idx}
                  >
                    {item.icon}

                    {item.sectionItemsTitle}
                  </Link>
                ))}
              </FlexibleDiv>
            ))}

            <FlexibleDiv
              flexDir="column"
              alignItems="flex-start"
              className="sidebar__items settings_section"
            >
              <Link
                to="/settings/account"
                id={
                  location.pathname.includes("settings") ? "active__path" : ""
                }
                className="sidebar__link"
              >
                <SettingsIcon
                  width="18"
                  pathFill={
                    location.pathname.includes("settings") ? "#0B61DD" : ""
                  }
                />
                Settings
              </Link>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </MobileDrawer>
    </SidebarContainer>
  )
}
