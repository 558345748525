import React from "react"
import {
  DashboardLayout,
  NoData,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components"
import { useMe } from "helpers"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { capitalize } from "utils"
import { CheckIcon } from "../../assets/images/dashboardLayout/svgExports"
import {
  FlexibleDiv,
  FlexibleSection,
} from "../../components/lib/Box/box.styles"
import { OIDButton } from "../../components/lib/Button/button.styles"
import { DashboardOverviewContainer } from "./dashboardOverview.styles"
import VerifyEmail from "./Modals/ResendEmailVerificationModal/__types/verifyEmail"
import { MdPhotoCamera as CameraIcon } from "react-icons/md"
import { getErrorMessage } from "common"
import ReactCrop, { Crop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { useGetProfileDataQuery } from "./gql/__types/UserProfile.types"
import { useUserUpdatePersonProfileImageMutation } from "./gql/__types/overview.types"
import RequestActionModal from "./Modals/RequestActionModal/requestAccessAction"
import queryString from "query-string"
import {
  useUserGetProfileAcessListQuery,
  useUserGetProfileRequestsQuery,
} from "pages/ViewAccessRequests/gql/__types/accessRequest.types"
import { format } from "date-fns"
import AccessApprovedModal from "./Modals/AccessApprovedModal"
import { FaUser as UserDemo } from "react-icons/fa"

export interface IRAPayload {
  show: boolean
  id: string | string[] | null
  requestStatus: string | string[]
}

export interface RequestedUserDataPayload {
  id: string | string[] | null
}

const DashboardOverview = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const locationQueryValue = queryString.parse(location.search);
  const [showRequestModal, setShowRequestModal] = React.useState(false)
  const [requestedUserDataId, setRequestedUserDataId] =
    React.useState<RequestedUserDataPayload>({
      id: "",
    })
  const { data: acessList, loading: accessListLoading } =
    useUserGetProfileAcessListQuery()
  const { data: requestData, loading: requestListLoading } =
    useUserGetProfileRequestsQuery()

  const [requestActionPayload, setRequestActionPayload] =
    React.useState<IRAPayload>({
      show: false,
      id: "",
      requestStatus: "",
    })
  const me = useMe()
  const [crop, setCrop] = React.useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  })
  const [imageToCrop, setImageToCrop] = React.useState("")
  const {
    data: userProfileData,
    loading: profileLoading,
    refetch,
  } = useGetProfileDataQuery()
  const [mutate, { loading: imageUploading }] =
    useUserUpdatePersonProfileImageMutation()

  const profileData = userProfileData?.userGetProfile

  React.useEffect(() => {
    const locationQueryValue = queryString.parse(location.search)

    if (locationQueryValue?.status) {
      if (locationQueryValue.status === "thirdpartyapproved") {
        setShowRequestModal(true)
        setRequestedUserDataId({
          id: locationQueryValue.rid,
        })
      } else {
        setRequestActionPayload({
          show: true,
          id: locationQueryValue.rid,
          requestStatus: locationQueryValue.status,
        })
      }
    }
  }, [location.search])

  const handleRequestActionModal = (id: string, status: string) => {
    setRequestActionPayload({
      show: !requestActionPayload.show,
      id: id,
      requestStatus: status,
    })
  }

  const handleGetImageFile = async (e: any) => {
    if (!e.target.files[0]) {
      return
    } else {
      setImageToCrop(URL.createObjectURL(e.target.files[0]))
    }
  }

  const cropImageNow = async () => {
    if (imageToCrop) {
      const currentCropImage = document.createElement("img")
      const canvas = document.createElement("canvas")
      currentCropImage.src = imageToCrop
      const scaleX = currentCropImage.naturalWidth / currentCropImage.width
      const scaleY = currentCropImage.naturalHeight / currentCropImage.height
      canvas.width = crop.width
      canvas.height = crop.height
      const ctx = canvas.getContext("2d")
      const pixelRatio = window.devicePixelRatio
      canvas.width = crop?.width * pixelRatio
      canvas.height = crop?.height * pixelRatio

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = "high"
        ctx.drawImage(
          currentCropImage,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        )
      }
      const base64Image = canvas.toDataURL("image/jpeg")
      try {
        await mutate({
          variables: { profileImage: base64Image },
        })
        await refetch()
        setImageToCrop("")
      } catch (error) {
        ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
      }
    }
  }

  return profileLoading || requestListLoading || accessListLoading ? (
    <Progress />
  ) : (
    <DashboardLayout headerText="Dashboard Overview">
      {!!me && !me?.isVerified && <VerifyEmail />}

      {profileData ? (
        <DashboardOverviewContainer>
          {!!imageToCrop && (
            <FlexibleDiv className="cropImage__wrap">
              {imageUploading && <Progress />}

              <ReactCrop crop={crop} onChange={setCrop}>
                <img src={imageToCrop} alt="upload data" />
              </ReactCrop>
              <br />
              <FlexibleDiv maxWidth="320px" justifyContent="space-between">
                <OIDButton
                  onClick={() => setImageToCrop("")}
                  backgroundColor="transparent"
                >
                  Cancel Upload
                </OIDButton>
                <OIDButton onClick={cropImageNow}>Upload Crop</OIDButton>
              </FlexibleDiv>
            </FlexibleDiv>
          )}
          <FlexibleDiv
            justifyContent="space-between"
            className="overview__subwrap"
            alignItems="flex-start"
            flexWrap="nowrap"
          >
            <FlexibleSection className="overview__leftsection" width="60%">
              {requestData?.userGetProfileRequests?.length ? (
                requestData?.userGetProfileRequests
                  ?.slice(0, 1)
                  .map(request => (
                    <FlexibleDiv
                      className="overview__accessrequest__card"
                      flexDir="column"
                      alignItems="flex-end"
                    >
                      {request?.createdAt && (
                        <small className="accessrequest__time">
                          {format(
                            new Date(request?.createdAt),
                            "hh:mm a, dd-MMMM-yy"
                          )}
                        </small>
                      )}
                      <FlexibleDiv
                        justifyContent="space-between"
                        flexWrap="nowrap"
                        margin="10px 0 0 0"
                        className="accessrequest__header"
                      >
                        <h4 className="accessrequest__headertext">
                          OneID Access Request
                        </h4>
                        {requestData?.userGetProfileRequests &&
                          requestData?.userGetProfileRequests?.length > 1 && (
                            <Link
                              to="/access-requests"
                              className="accessrequest__headerlink"
                            >
                              View all requests
                            </Link>
                          )}
                      </FlexibleDiv>
                      <p className="accessrequest__bodytext">
                        <span>
                          {request?.thirdParty?.username ||
                            request?.thirdParty?.businessName}
                        </span>{" "}
                        is seeking access to your OneID code. To grant access,
                        click accept request. and to decline, click decline
                        access."
                      </p>
                      <FlexibleDiv
                        justifyContent="flex-start"
                        margin="20px 0 0 0"
                        className="request-action__wrap"
                      >
                        <OIDButton
                          onClick={() =>
                            handleRequestActionModal(request?._id, "accept")
                          }
                          disabled={false}
                        >
                          Accept Request
                        </OIDButton>
                        <span
                          className="decline__request"
                          onClick={() =>
                            handleRequestActionModal(request?._id, "decline")
                          }
                        >
                          Decline Request
                        </span>
                      </FlexibleDiv>
                    </FlexibleDiv>
                  ))
              ) : (
                <FlexibleDiv
                  className="overview__accessrequest__card"
                  flexDir="column"
                  alignItems="flex-end"
                >
                  <FlexibleDiv
                    justifyContent="flex-start"
                    flexWrap="nowrap"
                    margin="10px 0 0 0"
                    className="accessrequest__header"
                  >
                    <h4 className="accessrequest__headertext">
                      OneID Access Request
                    </h4>
                  </FlexibleDiv>
                  <p className="accessrequest__bodytext">
                    You currently do not have any access requests
                  </p>
                </FlexibleDiv>
              )}

              {acessList?.userGetAccesssList?.length ? (
                acessList?.userGetAccesssList
                  ?.slice(0, 1)
                  .map((access: any) => (
                    <FlexibleDiv
                      className="overview__accessrequest__card"
                      flexDir="column"
                      alignItems="flex-end"
                      margin="30px 0 0 0"
                    >
                      {access?.createdAt && (
                        <small className="accessrequest__time">
                          {format(
                            new Date(access?.createdAt),
                            "hh:mm a, dd-MMMM-yy"
                          )}
                        </small>
                      )}
                      <FlexibleDiv
                        justifyContent="space-between"
                        flexWrap="nowrap"
                        margin="10px 0 0 0"
                        className="accessrequest__header"
                      >
                        <h4 className="accessrequest__headertext">
                          OneID Users With Access To Your Account
                        </h4>
                        {acessList?.userGetAccesssList?.length &&
                          acessList?.userGetAccesssList?.length > 1 && (
                            <Link
                              to="/settings/review-access"
                              className="accessrequest__headerlink"
                            >
                              View access list
                            </Link>
                          )}
                      </FlexibleDiv>
                      <p className="accessrequest__bodytext">
                        <span>{access?.username || access?.businessName}</span>{" "}
                        has access to your OneID code. To revoke access, click
                        the button below
                      </p>
                      <FlexibleDiv
                        justifyContent="flex-start"
                        margin="20px 0 0 0"
                        className="request-action__wrap"
                      >
                        <OIDButton
                          onClick={() =>
                            handleRequestActionModal(
                              access?._id,
                              "Revoke Access"
                            )
                          }
                        >
                          Revoke Access
                        </OIDButton>
                      </FlexibleDiv>
                    </FlexibleDiv>
                  ))
              ) : (
                <FlexibleDiv
                  className="overview__accessrequest__card"
                  flexDir="column"
                  alignItems="flex-end"
                  margin="30px 0 0 0"
                >
                  <FlexibleDiv
                    justifyContent="flex-start"
                    flexWrap="nowrap"
                    margin="10px 0 0 0"
                    className="accessrequest__header"
                  >
                    <h4 className="accessrequest__headertext">
                      OneID Users With Access To Your Account
                    </h4>
                  </FlexibleDiv>
                  <p className="accessrequest__bodytext">
                    You have no users with access to your account.
                  </p>
                </FlexibleDiv>
              )}
            </FlexibleSection>

            <FlexibleSection
              className="overview__rightsection"
              width="36%"
              flexDir="column"
              justifyContent="flex-start"
            >
              <FlexibleDiv
                className="profile__card"
                justifyContent="flex-start"
              >
                <FlexibleDiv className="image__wrap" alignItems="flex-end">
                  {profileData?.profileImage ? (
                    <img src={profileData?.profileImage} alt="" />
                  ) : (
                    <UserDemo />
                  )}
                  <label
                    htmlFor="profileImage"
                    className={"profileImage__label"}
                    title={"Change profile picture, max size 70kb"}
                  >
                    <CameraIcon />
                  </label>
                  <input
                    hidden
                    type="file"
                    id={"profileImage"}
                    name={"profileImage"}
                    accept="image/*"
                    onChange={handleGetImageFile}
                  />
                </FlexibleDiv>
                <FlexibleDiv
                  flexDir="column"
                  alignItems="flex-start"
                  width="60%"
                >
                  <FlexibleDiv
                    justifyContent="flex-start"
                    width="120px"
                    className="verifyIcons"
                    margin="0 0 5px 0"
                  >
                    <CheckIcon />
                    {!!me?.profile?.bvn && <CheckIcon pathFill="#C0C0C0" />}
                    {/* <CheckIcon pathFill="#FFD700" /> */}
                  </FlexibleDiv>
                  <h3 className="user__name">
                    {profileData?.fullName && capitalize(profileData.fullName)}
                  </h3>
                  {/* TODO: Find a suitable replacement for this user role as it isn't captured in the system. */}
                  {/* <p className="user__role">Software Engineer (Lead)</p> */}
                  <OIDButton
                    color="#001935"
                    backgroundColor="#fff"
                    height="33px"
                    padding="0 20px"
                    hoverColor="#001935"
                    hoverBg="#eee"
                    margin="10px 0 0 0"
                    radius="8px"
                    onClick={() => navigate("/profile")}
                  >
                    View Profile
                  </OIDButton>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleSection>
          </FlexibleDiv>{" "}
        </DashboardOverviewContainer>
      ) : (
        <NoData
          noDataTitle="Profile Is Not Updated"
          noDataMessage={`Hi ${capitalize(
            me?.username!
          )}, please click the button below to update your profile`}
          noDataButtonRoute="/update-profile/personal-details"
          noDataNavigationData={true}
          noDataButtonText="Update Profile"
        />
      )}

      <RequestActionModal
        requestActionPayload={requestActionPayload}
        setRequestActionPayload={setRequestActionPayload}
        fromPage="/"
      />
      <AccessApprovedModal
        setShowRequestModal={setShowRequestModal}
        showRequestModal={showRequestModal}
        requestId={
          typeof requestedUserDataId.id === "string"
            ? requestedUserDataId.id
            : ""
        }
      />
    </DashboardLayout>
  )
}

export default DashboardOverview
