import React from "react";
import {
  CloseIcon,
  CopyIcon,
} from "../../../../../../assets/images/dashboardLayout/svgExports";
import { ModalContainer } from "../../../../../../components/layout/ModalLayout/modalLayout.styles";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";
import { OIDButton } from "../../../../../../components/lib/Button/button.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  setShowPinModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  setShowScanModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
  setPinMessage: (value: string | ((previousValue: string) => string)) => void;
}

const SetupModal: React.FC<IProps> = ({
  open,
  close,
  setShowPinModal,
  setShowScanModal,
  setPinMessage,
}) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    setShowPinModal(true);
    setPinMessage("Two- Factor");
  };

  const setup = () => {
    handleClose();
    setShowScanModal(true);
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <FlexibleDiv className="key__copy">
          <span className="key__code">CMVD AUT3 NA7Q AOLX</span>{" "}
          <div className="copy">
            <CopyIcon width="20" />
          </div>
        </FlexibleDiv>
        <h3 className="modal__headertext">Setup Manually</h3>
        <p className="modal__subheadertext">
          Tap to copy the key above and then paste it in your authenticator, to
          generate a toke. Once you've successfully added the key above, click
          done.
        </p>

        <FlexibleDiv justifyContent="flex-end" margin="20px 0 0 0">
          <span className="buttonSpan" onClick={setup}>
            Scan QR Code
          </span>{" "}
          <OIDButton margin="0 0 0 25px" onClick={submit}>
            Done{" "}
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default SetupModal;
