import { Config } from "config"
import { useMe, useNativeQueryParams } from "helpers"
import { useMeLazyQuery } from "helpers/hooks/useMe/__types/Me.types"
import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

interface Props {
  children?: React.ReactNode
}

export const Guardian: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const token = localStorage.getItem("1D_AU")
  const auth = useNativeQueryParams().get("auth")
  const refreshToken = useNativeQueryParams().get("rt")
  const isLoggedIn = Boolean(auth) || Boolean(token)
  const me = useMe()
  const [getMe] = useMeLazyQuery()

  React.useEffect(() => {
    if (!auth && !token) {
      window.location.href = `${Config.AUTH_GATEWAY_URL}?d=app&r=${pathname}`
    } else if (!token && auth) {
      localStorage.setItem("1D_AU", auth)
      refreshToken && localStorage.setItem("1D_AUR", refreshToken)
      navigate(pathname)
    } else navigate(pathname)
  }, [auth, token, refreshToken, pathname, navigate])

  // Initiate root-query for getting currentUser if hasn't been cached yet.
  React.useEffect(() => {
    if (!me?._id) {
      getMe()
    }
  }, [me, getMe])

  return isLoggedIn ? (
    <React.Fragment>
      {children}
      <Outlet />
    </React.Fragment>
  ) : null
}
