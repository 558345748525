import styled from "styled-components";
import { FlexibleDiv } from "../../lib/Box/box.styles";

export const SettingsLayoutContainer = styled(FlexibleDiv)`
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`;

export const TabsContainer = styled(FlexibleDiv)`
  padding: 20px 0 0 40px;
  background-color: #fff;
  .tab__text__wrap {
    margin-right: 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    .tab__text {
      color: #7d7d7d;
      padding: 7px 1px;
      position: relative;
      overflow: hidden;
      margin: 0 0 -1px 0;
      font-weight: bold;
      font-size: 14px;
      transition: all 0.3s ease;

      ::before {
        content: "";
        width: 0;
        height: 2px;
        position: absolute;
        bottom: 0;
        background-color: #0b61dd;
        left: 0;
      }
    }

    :hover {
      .tab__text {
        color: #0c0c0c;
      }
    }
  }
  #activetab {
    .tab__text {
      color: #0b61dd;

      ::before {
        width: 100%;
        animation: width 0.3s 1 ease-in-out;
      }
    }

    :hover {
      .tab__text {
        color: #0b61dd;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 20px 0 0 15px;

    .tab__text__wrap {
      margin-right: 30px;
      .tab__text {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 550px) {
    .tab__text__wrap {
      margin-right: 15px;
      .tab__text {
        font-size: 11px;
      }
    }
  }

  @media (max-width: 396px) {
    .tab__text__wrap {
      .tab__text {
        font-size: 9px;
      }
    }
  }

  @keyframes width {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;
