import React from "react";
import { TabsContainer } from "./tabs.styles";
import { FlexibleDiv } from "../Box/box.styles";

interface IProps {
  activeTab: string;
  tablist: any;
  setActiveTab: (value: string | ((previousValue: string) => string)) => void;
  noBorder?: boolean;
}

const Tabs: React.FC<IProps> = ({
  activeTab,
  setActiveTab,
  tablist,
  noBorder,
}) => {
  return (
    <TabsContainer justifyContent="flex-start" noBorder={noBorder}>
      {tablist.map((item: string, idx: number) => (
        <FlexibleDiv
          onClick={() => setActiveTab(item)}
          id={activeTab === item ? "activetab" : ""}
          width="max-content"
          flexWrap="nowrap"
          className="customtab__textwrap"
        >
          <p className="customtab__text">{item}</p>
        </FlexibleDiv>
      ))}
    </TabsContainer>
  );
};

export default Tabs;
