import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const NotificationSettingsContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px;
  justify-content: flex-start;

  .privacy__main__wrap {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px 30px 50px 30px;
    max-width: 1000px;
    align-items: flex-start;
    flex-direction: column;

    .header__text {
      color: #979da5;
      font-size: 16px;
      font-weight: 600;
    }
    .top__header {
      margin-bottom: 20px;
    }
    .body__textwrap {
      margin-bottom: 20px;
      .body__textheader {
        margin: 0;
        color: #272727;
        font-size: 18px;
        cursor: pointer;
      }
      .body__textsubheader {
        color: #7b8087;
        font-size: 16px;
        margin: 10px 0;
        max-width: 500px;
      }
    }
    .switch__wrap {
      max-width: 350px;
      margin: 17px 0;
    }
  }

  @media (max-width: 900px) {
    padding: 40px 15px;

    .privacy__main__wrap {
      padding: 30px 15px;
    }
  }

  @media (max-width: 500px) {
    .privacy__main__wrap {
      .header__text {
        font-size: 15px;
      }
      .body__textwrap {
        margin-bottom: 20px;

        .body__textheader {
          font-size: 15px;
        }
        .body__textsubheader {
          font-size: 13px;
        }
      }
      .switch__wrap {
        max-width: 100%;
      }
    }
  }
`;
