import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {
  showEmail?: boolean;
};

export const HelpContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;

  .popup__wrap {
    margin: 0;
    padding: 10px 16px;
    background-color: #146ff3;
    color: #fff;
    border-radius: 100%;
    z-index: 5;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 16px;
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .help__main__wrap {
    background: #ffffff;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 50px 30px;
    max-width: 800px;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;

    .helpheader__title {
      font-size: 18px;
      margin: 0;
    }

    .content__wrap {
      justify-content: space-between;
      padding: 20px;

      .help__card {
        width: 47%;
        border-radius: 12px;
        background-color: #fafafa;
        overflow: hidden;
        padding: 30px 20px;
        margin-bottom: 40px;
        min-height: 180px;
        cursor: pointer;
        transition: all 0.3s ease;
        align-items: flex-start;
        :hover {
          transform: scale(1.04);
          background-color: #fbfcff;
        }

        .close__card {
          .helpcard__header {
            flex-direction: column;

            img {
              width: 50px;
              height: auto;
            }
            .card__title {
              margin: 10px 0;
              text-align: center;
            }
          }

          .decription {
            text-align: center;
            margin: 0;
            max-width: 300px;
          }
        }
      }
      #active__card {
        width: 100%;
        background-color: #fff;
        order: -1;
        padding: 0;
        .close__card {
          .helpcard__header {
            flex-direction: row;
            justify-content: flex-start;

            img {
              margin-right: 15px;
              width: 30px;
            }
            .card__title {
              margin: 0;
            }
          }

          .decription {
            display: none;
          }
        }

        .slabs__wrap {
          border: 1px solid #e4e4e4;
          border-radius: 12px;
          padding: 15px 20px 40px 20px;
          margin-top: 30px;
          .faq__wrap {
            border-bottom: 1px solid #e4e4e4;
            flex-direction: column;
            align-items: flex-start;

            .faq__textsection {
              justify-content: space-between;
              flex-wrap: nowrap;

              .faq__title {
                font-size: 17px;
                color: #001935;
              }
              svg {
                font-size: 30px;
                color: #42424299;
                margin-left: 20px;
              }
            }

            .faq__subtitle {
              margin: 5px 0 30px 0;
              font-size: 16px;
              max-width: 500px;
              padding-left: 5%;
              line-height: 23px;
            }
          }
        }
        :hover {
          transform: scale(1);
          background-color: #fff;
        }
      }
    }
  }

  .answer__card {
    max-width: 300px;
    min-width: 200px;
    position: fixed;
    background: #ffffff;
    border-radius: 12px;
    align-items: flex-start;
    overflow: hidden;
    padding-bottom: 20px;
    width: 20%;
    right: 5%;

    .email__question {
      background: #d8e8fc;
      text-align: start;
      padding: 20px;
      width: 100%;
      margin: 0;
    }

    .email__header {
      padding: 10px;
      img {
        width: 50px;
        margin-right: 10px;
      }
      .email__title {
        color: #146ff3;
        font-size: 22px;
        margin: 0;
      }
    }
    .email__description {
      padding: 0 20px;
      font-size: 15px;
    }
    @media (max-width: 1330px) {
      right: 20px;
    }
  }

  @media (max-width: 1280px) {
    padding: 40px 15px;

    .help__main__wrap {
      padding: 30px 15px;
    }
  }

  @media (max-width: 1200px) {
    .help__main__wrap {
      .content__wrap {
        justify-content: space-between;
        padding: 20px 0;

        .help__card {
          min-height: 210px;
          .close__card {
            .helpcard__header {
              .card__title {
                font-size: 16px;
                margin: 15px 0;
              }
            }

            .decription {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    position: relative;
    .answer__card {
      max-width: 400px;
      width: 90%;
      bottom: 70px;
      right: 20px;
      transition: all 0.3s ease;
      transform: ${({ showEmail }) => (showEmail ? "0" : "translateX(800px)")};
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    }
    .help__main__wrap {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    .help__main__wrap {
      .content__wrap {
        .help__card {
          min-height: 240px;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .help__main__wrap {
      .content__wrap {
        .help__card {
          min-height: 240px;
          padding: 30px 10px;
          .close__card {
            .helpcard__header {
              .card__title {
                font-size: 14px;
              }
            }

            .decription {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
