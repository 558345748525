import styled from "styled-components";
import { Select } from "@mui/material";

export const SelectContainer = styled(Select)({
  ".MuiInputBase-root, .MuiOutlinedInput-root, .MuiSelect-root": {},
  position: "relative",
  em: {
    fontStyle: "normal",
    opacity: ".4",
  },
  "& fieldset": {
    borderColor: "#F0F4FF !important",
    background: "#fdfdfd !important",
    borderRadius: "8px",
    transition: "all .3s ease-in-out",
    height: "57px",
  },
  "& div": {
    color: "#272727",
    zIndex: "9",
    paddingLeft: "22px",
    textTransform: "capitalize",
    fontSize: "14px",
  },
  "& svg": {
    zIndex: "9",
  },
  "&:hover fieldset": {
    borderColor: "#0B61DD",
  },
  "&.Mui-focused fieldset": {
    borderColor: "#0B61DD !important",
    borderWidth: "1px !important",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
    background: "#F2F2F2",
    "&:hover": {
      borderColor: "transparent",
    },
    "&.Mui-focused": {
      borderColor: "",
    },
  },
});
