export const barChartData = {
  label: [
    "Jul 1, 2021",
    "",
    "Jul 5, 2021",
    "",
    "Jul 7, 2021",
    "",
    "Jul 10, 2021",
  ],
  datas: [
    {
      data: [5, 7, 10, 6, 15, 12, 5, 20],
      color: "#78A431",
    },
  ],
};

export const pieChartData = {
  label: ["Declined", "Pending", "Accepted"],

  datas: [
    {
      data: [7, 5, 15],
      color: "#78A431",
    },
  ],
};
