import { NoData } from "components"

const NotFoundPage = () => {
  return (
    <NoData
      noDataTitle="OOPS! Page Not Found"
      noDataMessage="Hi May, click on the button below to go back to the homepage"
      noDataButtonText="Home"
      noDataButtonRoute="/"
      notFound
    />
  )
}

export default NotFoundPage
