import styled from "styled-components";
import { FlexibleDiv } from "../../../components/lib/Box/box.styles";

type Custom = {};

export const ViewProfileContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 30px 20px;
  justify-content: flex-start;

  .verification__box {
    background: #a5f3c5;
    box-shadow: 0px 8px 16px rgba(147, 147, 147, 0.2);
    border-radius: 12px;
    padding: 20px 15px;
    margin-bottom: 30px;

    .verification__box__text {
      color: #001935;
      font-weight: 600;
      max-width: 350px;
      text-align: center;
      margin: 10px 0 0 0;
    }
    .longer__verification__boxtext {
      max-width: 400px;
    }
  }

  .profile__details__wrap {
    .profile__slab {
      background: #ffffff;
      border-bottom: 1px solid #f5f5f5;
      border-radius: 8px;
      padding: 20px 15px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all 0.3s ease;
      :hover {
        transform: scale(1.02);
      }

      .slab__header {
        .slab__header__text {
          margin: 0;
          font-weight: 600;
          font-size: 16px;
          color: #0c0c0c;

          svg {
            margin: 0 15px -10px 0;
          }
        }

        .arrow__wrap {
          cursor: pointer;
          svg {
            font-size: 30px;
            color: rgba(66, 66, 66, 0.8);
          }
        }
      }

      .slab__body {
        justify-content: flex-start;
        padding: 20px 10px 10px 50px;
        position: relative;

        .single__detail {
          width: 33%;
          align-items: flex-start;
          margin-top: 20px;
          flex-direction: column;

          .data__header {
            width: max-content;
            text-align: start;
            margin: 0;
            font-size: 14px;
            color: #7d7d7d;
            width: 90%;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .data__text {
            width: 90%;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
            font-weight: 600;
            font-size: 16px;
            color: #424242;
            white-space: nowrap;
          }
        }
        .edit__wrap {
          position: absolute;
          right: 10px;
          bottom: 20px;
          svg {
            color: #0b61dd;
            font-size: 25px;
          }
        }
      }

      .longer__width__content {
        .single__detail {
          width: 48%;
          align-items: flex-start;
          margin-top: 20px;
          flex-direction: column;
        }
      }
    }

    #active__slab {
      :hover {
        transform: scale(1);
      }
      .slab__header {
        .slab__header__text {
          color: #0b61dd;
        }

        .arrow__wrap {
          svg {
            color: #0b61dd;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .profile__details__wrap {
      .profile__slab {
        .slab__body {
          padding-bottom: 40px;
          .single__detail {
            width: 48%;
          }

          .edit__wrap {
            bottom: 0;
            svg {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .profile__details__wrap {
      .profile__slab {
        .slab__header {
          .slab__header__text {
            font-size: 16px;

            svg {
              margin: 0 15px -10px 0;
              width: 24px;
            }
          }

          .arrow__wrap {
            svg {
              font-size: 20px;
              margin-bottom: -10px;
            }
          }
        }
        .slab__body {
          padding: 20px 10px 40px 40px;
          .single__detail {
            width: 100%;

            .data__header {
              font-size: 13px;
            }

            .data__text {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
`;
