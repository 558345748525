import React from "react"
import { BackContainer } from "./back.styles"
import { useNavigate } from "react-router-dom"
import { BsArrowLeft as Arrow } from "react-icons/bs"

export const GoBack = () => {
  const navigate = useNavigate()

  return (
    <BackContainer>
      <span onClick={() => navigate(-1)} className="back__text">
        <Arrow />
        Back
      </span>
    </BackContainer>
  )
}
