import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";

import DashboardOverview from "../pages/DashboardOverview/dashboardOverview";
import Notifications from "../pages/NotificationsPage/notifications";
import EmploymentHistory from "../pages/EmploymentHistory/HistoryOverview/employmentHistory";
import AddEmployment from "../pages/EmploymentHistory/AddEmploymentHistory/addEmployment";
import SingleEmployment from "../pages/EmploymentHistory/SingleEmploymentHistory/singleEmployment";
import AddEducation from "../pages/EductionHistory/AddEducationHistory/addEducation";
import EducationHistory from "../pages/EductionHistory/HistoryOverview/educationHistory";
import SingleEducation from "../pages/EductionHistory/SingleEducationHistory/singleEducation";
import TravelHistory from "../pages/TravelHistory/travelHistory";
import MedicalHistory from "../pages/MedicalHistory/HistoryOverview/medicalHistory";
import AddMedical from "../pages/MedicalHistory/AddMedicalHistory/addMedicalHistory";
import SingleMedicalRecord from "../pages/MedicalHistory/SingleMedicalHistory/singleMedicalRecord";
import Appointments from "../pages/Appointments/AllAppointments/allAppointments";
// import SingleAppointment from "../pages/Appointments/SingleAppointment/singleAppointment"
import VerifyUser from "../pages/VerifyUsers/verifyUser";
import BankService from "../pages/BankService/bankService";
import ManageCards from "../pages/ManageCards/manageCards";
import AccountSettings from "../pages/Settings/Account/account";
import PrivacyAndSecurity from "../pages/Settings/Privacy&Security/privacyAndSecurity";
import ReviewThirdParty from "../pages/Settings/Privacy&Security/Sections/AccessFeatures/ReviewThirdParty/reviewThirdParty";
import ReportUser from "../pages/Settings/Privacy&Security/Sections/ReportUser/reportUser";
import Legal from "../pages/Settings/Legal/legal";
import NotificationSettings from "../pages/Settings/Notifications/notificationSettings";
import ChangePassword from "../pages/Settings/Account/Sections/ChangePassword/changePassword";
import PersonalDetailsUpdate from "../pages/Profile/UpdateProfile/UpdatePersonalDetails/updatePersonalDetails";
import NexOfKinUpdate from "../pages/Profile/UpdateProfile/UpdateNextOfKin/updateNextOfKin";
import KYCUpdate from "../pages/Profile/UpdateProfile/UpdateKyc/updateKyc";
import BillingDetailsUpdate from "../pages/Profile/UpdateProfile/UpdateBillingDetails/updateBillings";
import SocialDetailsUpdate from "../pages/Profile/UpdateProfile/UpdateSocialDetails/updateSocials";
import ViewProfile from "../pages/Profile/ViewProfile/viewProfile";
import NotFoundPage from "../pages/404Page/notFoundPage";
import EmergencyContactUpdate from "../pages/Profile/UpdateProfile/EmergencyContact/emergencyContact";
import { Guardian, Progress } from "components";
import Help from "pages/Settings/Help/help";
import VerifyEmail from "pages/VerifyEmail/verifyEmail";
import ViewAccessRequestsPage from "pages/ViewAccessRequests/viewAccessRequests";

export const MainRoutes = () => {
  return (
    <Router>
      <React.Fragment>
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Progress />}>
                <Guardian />
              </React.Suspense>
            }
          >
            <Route index element={<DashboardOverview />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/employment-history" element={<EmploymentHistory />} />
            <Route path="/add-employment" element={<AddEmployment />} />
            <Route path="/employment/:id" element={<SingleEmployment />} />
            <Route path="/travel-history" element={<TravelHistory />} />
            <Route
              path="/educational-background"
              element={<EducationHistory />}
            />
            <Route path="/add-education" element={<AddEducation />} />
            <Route path="/education/:id" element={<SingleEducation />} />
            <Route path="/medical/:id" element={<SingleMedicalRecord />} />
            <Route path="/medical-history" element={<MedicalHistory />} />
            <Route path="/add-medical-history" element={<AddMedical />} />
            <Route path="/appointments" element={<Appointments />} />
            {/* <Route path="/appointment/:id" element={<SingleAppointment />} /> */}
            <Route path="/verify-users" element={<VerifyUser />} />
            <Route
              path="/access-requests"
              element={<ViewAccessRequestsPage />}
            />
            <Route path="/bank-services" element={<BankService />} />
            <Route path="/cards" element={<ManageCards />} />
            <Route path="/settings/account" element={<AccountSettings />} />
            <Route
              path="/settings/privacy-security"
              element={<PrivacyAndSecurity />}
            />

            <Route
              path="/settings/review-access"
              element={<ReviewThirdParty />}
            />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/settings/report-user" element={<ReportUser />} />
            <Route path="/settings/privacy-policy" element={<Legal />} />
            <Route path="/settings/help" element={<Help />} />

            <Route
              path="/settings/notifications"
              element={<NotificationSettings />}
            />
            <Route
              path="/settings/change-password"
              element={<ChangePassword />}
            />
            <Route
              path="/update-profile/personal-details"
              element={<PersonalDetailsUpdate />}
            />
            <Route
              path="/update-profile/next-of-kin"
              element={<NexOfKinUpdate />}
            />
            <Route path="/update-profile/kyc" element={<KYCUpdate />} />
            <Route
              path="/update-profile/billing-details"
              element={<BillingDetailsUpdate />}
            />
            <Route
              path="/update-profile/social-details"
              element={<SocialDetailsUpdate />}
            />
            <Route
              path="/update-profile/emergency-contact"
              element={<EmergencyContactUpdate />}
            />

            <Route path="/profile" element={<ViewProfile />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </React.Fragment>
    </Router>
  );
};
