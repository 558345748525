import styled from "styled-components";

type Custom = {
  notificationsActive?: boolean;
  isNotification?: boolean;
};

export const HeaderContainer = styled.div<Custom>`
  position: fixed;
  width: calc(100vw - 240px);
  height: 60px;
  right: 0;
  top: 0;
  padding: 10px 4% 10px 2%;
  background-color: #ffffff;
  z-index: 3;

  .layoutheader__headertext {
    font-size: 18px;
    margin: 0;
    color: #0c0c0c;
  }
  .menu__iconwrap {
    svg {
      font-size: 20px;
      margin: 0 15px -3px 0;
    }
  }
  .header__rightsection__wrap {
    .notifications__iconwrap {
      font-size: 20px;
      position: relative;
      svg {
        color: ${({ notificationsActive }) =>
          notificationsActive ? "#0B61DD" : "#0c0c0c;"};
        cursor: pointer;
        transition: all 0.3s ease;
        :hover {
          transform: scale(1.1);
        }
      }
      ::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 100%;
        position: absolute;
        background-color: #cf0d19;
        right: 3px;
        visibility: ${({ isNotification }) =>
          isNotification ? "visible" : "hidden"};
      }
    }

    .user__initials__wrap {
      cursor: pointer;

      .user__initials {
        background-color: #ddebff;
        border-radius: 100%;
        transition: all 0.3s ease;

        .headeruser__initials {
          font-size: 13px;
        }
      }
      svg {
        transition: all 0.3s ease;
      }

      :hover {
        .user__initials {
          background-color: #eee;
        }

        svg {
          transform: scale(1.1);
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 100vw;
    padding: 10px 15px;

    .header__rightsection__wrap {
      width: 80px;
      .notifications__iconwrap {
        font-size: 18px;
      }
      .user__initials__wrap {
        .user__initials {
          width: 30px;
          height: 30px;

          .headeruser__initials {
            font-size: 11px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 800px) {
    .menu__iconwrap {
      display: none;
    }
  }
`;
