import React, { useState } from "react"
import { HeaderContainer } from "./header.styles"
import { FlexibleDiv } from "../../../lib/Box/box.styles"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { FiBell as Bell } from "react-icons/fi"
import { BiCaretDown as ArrowDown } from "react-icons/bi"
import { VscMenu as Menu } from "react-icons/vsc"
import { useMe } from "helpers"
import { getInitials } from "utils"
// import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types";

interface IProps {
  isDrawerOpen: boolean
  setIsDrawerOpen: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void
  headerText: string
}

export const Header: React.FC<IProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  headerText,
}) => {
  const location = useLocation()
  const [isNotification] = useState(true)
  const navigate = useNavigate()
  const user = useMe()
  // const { data: profile } = useGetProfileDataQuery();

  // React.useEffect(() => {
  //   window.addEventListener("mousemove", () => {
  //     if (
  //       location.pathname.includes("/update-profile") &&
  //       location.pathname !== "/update-profile/personal-details" &&
  //       !profile
  //     ) {
  //       navigate("/update-profile/personal-details");
  //     }
  //   });

  //   // returned function will be called on component unmount
  //   return () => {
  //     window.removeEventListener("mousemove", () => {
  //       if (
  //         location.pathname.includes("/update-profile") &&
  //         location.pathname !== "/update-profile/personal-details" &&
  //         !profile
  //       ) {
  //         navigate("/update-profile/personal-details");
  //       }
  //     });
  //   };
  // }, [profile]);

  return (
    <HeaderContainer
      notificationsActive={location.pathname === "/notifications"}
      isNotification={isNotification}
    >
      <FlexibleDiv
        justifyContent="space-between"
        height="100%"
        alignItems="flex-end"
        flexWrap="nowrap"
      >
        <FlexibleDiv width="max-content" alignItems="flex-end">
          <div
            className="menu__iconwrap"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <Menu />
          </div>
          <h3 className="layoutheader__headertext">{headerText}</h3>
        </FlexibleDiv>
        <FlexibleDiv
          width="110px"
          flexWrap="nowrap"
          className="header__rightsection__wrap"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Link to="/notifications" className="notifications__iconwrap">
            <Bell />
          </Link>

          <FlexibleDiv
            width="max-content"
            className="user__initials__wrap"
            onClick={() => navigate("/profile")}
          >
            <FlexibleDiv width="35px" height="35px" className="user__initials">
              <span className="headeruser__initials">
                {getInitials({
                  fName: user?.profile?.firstName,
                  lName: user?.profile?.lastName,
                  username: user?.username!,
                })}
              </span>
            </FlexibleDiv>
            <ArrowDown />
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </HeaderContainer>
  )
}
