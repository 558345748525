import styled from "styled-components";
import { FlexibleDiv } from "../Box/box.styles";

export const BackContainer = styled(FlexibleDiv)`
  height: 40px;
  padding: 0 30px;
  justify-content: flex-start;
  background: #ffffff;

  .back__text {
    cursor: pointer;
    color: #272643;
    font-size: 14px;

    svg {
      font-size: 18px;
      margin: 0 5px -4px 0;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 0 15px;
  }
`;
