import { LongArrowRightIcon } from "assets/images/dashboardLayout/svgExports"
import { getErrorMessage } from "common"
import {
  CustomForm,
  DashboardLayout,
  GoBack,
  Progress,
  TOASTER_TYPE,
  ToastMessage,
} from "components"
import { omitTypename } from "helpers"
import { useGetProfileDataQuery } from "pages/DashboardOverview/gql/__types/UserProfile.types"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { UpdateProfileLayoutContainer } from "../../../../components/layout/UpdateProfileLayout/updateProfile.styles"
import { FlexibleDiv } from "../../../../components/lib/Box/box.styles"
import { useUpdateUserProfileMutation } from "../gql/__types/UpdateProfile.types"

const validationSchema = Yup.object().shape({
  fullName: Yup.string().min(6).nullable(),
  email: Yup.string().email().nullable(),
  gender: Yup.string().oneOf(["Male", "Female", "others"]).nullable(),
  relationship: Yup.string()
    .oneOf([
      "Mother",
      "Father",
      "Sister",
      "Brother",
      "Spouse",
      "Cousin",
      "Friend",
      "Guardian",
    ])
    .nullable(),
  phone: Yup.string().min(10).nullable(),
  dob: Yup.date().nullable(),
  address: Yup.string().min(6).nullable(),
})

const NexOfKinUpdate = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [step] = React.useState(location?.state)
  const [mutate, { loading }] = useUpdateUserProfileMutation()
  const { data, refetch, loading: profileLoading } = useGetProfileDataQuery()
  const [hideFormWhileRefetch, sethideFormWhileRefetch] = React.useState(true)

  const profile = data && data.userGetProfile

  const initialValues = {
    fullName: profile?.nextOfKin?.fullName || "",
    address: profile?.nextOfKin?.address || "",
    gender: profile?.nextOfKin?.gender || "",
    dob: profile?.nextOfKin?.dob || null,
    phone: profile?.nextOfKin?.phone || "",
    email: profile?.nextOfKin?.email || "",
    relationship: profile?.nextOfKin?.relationship || "",
  }

  const billingValues = {
    country: profile?.country || "",
    stateOfResidence: profile?.stateOfResidence || "",
    nationality: profile?.nationality || "",
    lga: profile?.lga || "",
    postalCode: profile?.postalCode || "",
    primaryAddress: profile?.primaryAddress || "",
    secondaryAddress: profile?.secondaryAddress || "",
    stateOfOrigin: profile?.stateOfOrigin || "",
  }

  const formDetails = [
    {
      name: "fullName",
      type: "text",
      width: "48%",
      label: "Next Of Kin’s Full Name",
    },
    {
      name: "email",
      type: "text",
      width: "48%",
      label: "Enter Next Of Kin’s Email",
    },
    {
      name: "gender",
      type: "select",
      width: "48%",
      label: "Gender",
      dataSet: ["Male", "Female", "Other"],
    },
    {
      name: "relationship",
      type: "select",
      width: "48%",
      label: "Relationship with Next Of Kin",
      dataSet: [
        "Mother",
        "Father",
        "Sister",
        "Brother",
        "Spouse",
        "Cousin",
        "Friend",
        "Guardian",
      ],
    },
    {
      name: "phone",
      type: "text",
      width: "48%",
      label: "Next Of Kin's Phone Number",
    },
    {
      name: "dob",
      type: "date",
      width: "48%",
      label: "Your Next Of Kin’s Date Of Birth",
    },
    {
      name: "address",
      type: "text",
      width: "48%",
      label: "Enter Next Of Kin’s Address",
    },
  ]

  const submit = async (
    submitAction: string,
    values: any,
    setSubmitting: any
  ) => {
    const payload = {
      ...omitTypename(profile),
      ...billingValues,
      nextOfKin: {
        ...values,
      },
    }

    try {
      await mutate({
        variables: payload,
      })
      sethideFormWhileRefetch(false)
      await refetch()

      if (!submitAction) {
        ToastMessage(
          TOASTER_TYPE.SUCCESS,
          "Next of kin details updated successfully."
        )
      } else {
        if (submitAction === "save_continue") {
          navigate("/update-profile/social-details", { state: true })
        } else {
          navigate("/profile")
        }
      }
      setSubmitting(false)
    } catch (error) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(error))
    }
  }

  return (
    <DashboardLayout headerText="Update Profile">
      <GoBack />
      {loading && <Progress position="relative" />}

      {profileLoading && !!hideFormWhileRefetch ? (
        <Progress position="relative" />
      ) : (
        <UpdateProfileLayoutContainer
          className="updateprofile__main__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          {!!step && (
            <FlexibleDiv className="progress__bar">
              <FlexibleDiv
                className="progress__bar__inner"
                width="60%"
              ></FlexibleDiv>
            </FlexibleDiv>
          )}
          <p className="form__title">Next Of Kin Details</p>
          {!!step && (
            <span className="form__subtitle">
              Hi May, the journey 5 steps begin with just 1... or something like
              that
            </span>
          )}
          <CustomForm
            formDetails={formDetails}
            formFinishButtonText={!!step ? "Save & Exit" : "Update"}
            formContinueButtonText="Save & Continue"
            formColumnButtons={!!step ? true : false}
            submit={submit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
          {!!step && (
            <FlexibleDiv className="form__skip">
              <span
                className="skip__text"
                onClick={() =>
                  navigate("/update-profile/social-details", { state: true })
                }
              >
                Skip
                <LongArrowRightIcon />
              </span>
            </FlexibleDiv>
          )}
        </UpdateProfileLayoutContainer>
      )}
    </DashboardLayout>
  )
}

export default NexOfKinUpdate
