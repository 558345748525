import { DashboardLayout } from "components"
import { FlexibleDiv } from "../../components/lib/Box/box.styles"
import { ManageCardsContainer } from "./manageCards.styles"

const ManageCards = () => {
  return (
    <DashboardLayout headerText="Manage Cards">
      <ManageCardsContainer alignItems="flex-start">
        <FlexibleDiv
          className="manageCards__main__wrap"
          flexDir="column"
          justifyContent="flext-start"
          alignItems="flex-start"
        ></FlexibleDiv>
      </ManageCardsContainer>
    </DashboardLayout>
  )
}

export default ManageCards
