import React from "react"
import { AddEmploymentContainer } from "./addEmployment.styles"
import { FlexibleSection } from "../../../components/lib/Box/box.styles"
import { CustomForm } from "components"
import { DashboardLayout, GoBack } from "components"

const AddEmployment = () => {
  const formDetails = [
    {
      name: "company",
      type: "text",
      width: "100%",
      label: "Name Of Company",
    },
    {
      name: "address",
      type: "text",
      width: "48%",
      label: "Company’s Address",
    },
    {
      name: "website",
      type: "text",
      width: "48%",
      label: "Company’s Website",
    },
    {
      name: "title",
      type: "text",
      width: "48%",
      label: "Job Title",
    },
    {
      name: "salary",
      type: "select",
      width: "48%",
      label: "Salary (Monthly)",
      dataSet: [
        "N500,000 - N750,000",
        "N750,000 - N1,250,000",
        "N5,000,000 - N50,000,000",
      ],
    },
    {
      name: "startDate",
      type: "date",
      width: "48%",
      label: "Start Date",
    },
    {
      name: "endDate",
      type: "date",
      width: "48%",
      label: "End Date",
    },
  ]

  const submit = () => {}

  return (
    <DashboardLayout headerText="Employment History">
      <GoBack />

      <AddEmploymentContainer>
        <FlexibleSection
          className="form__wrap"
          flexDir="column"
          alignItems="flex-start"
        >
          <h4 className="header__text">EMPLOYMENT</h4>
          <span className="subheader__text">
            Please fill in your employment history, starting with your most
            recent job.
          </span>
          <CustomForm
            formDetails={formDetails}
            formFinishButtonText="Save Employment"
            submit={submit}
          />
        </FlexibleSection>
      </AddEmploymentContainer>
    </DashboardLayout>
  )
}

export default AddEmployment
