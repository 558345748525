import React, { useState } from "react";
import { ReportUserContainer } from "./reportUser.styles";
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles";
import { DashboardLayout, GoBack } from "components";
import SuccessModal from "../../../../../components/lib/SuccessModal/successModal";
import { CustomForm } from "components";

const ReportUser = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const formDetails = [
    {
      name: "oneid",
      type: "text",
      width: "100%",
      label: "Enter User OneID",
    },

    {
      name: "reason",
      type: "textarea",
      width: "100%",
      label: "This user seems fraudulent",
    },
  ];

  const submit = () => {
    setOpenSuccessModal(true);
  };

  return (
    <DashboardLayout headerText="Settings">
      <GoBack />

      <ReportUserContainer justifyContent="flex-start">
        <FlexibleDiv
          className="manageaccess__main__wrap"
          flexDir="column"
          justifyContent="flext-start"
          alignItems="flex-start"
        >
          <h4 className="report__header__text">Report OneID User Code</h4>
          <FlexibleDiv maxWidth="650px">
            <CustomForm
              formDetails={formDetails}
              formFinishButtonText={"Report User"}
              submit={submit}
            />
          </FlexibleDiv>
        </FlexibleDiv>
      </ReportUserContainer>

      <SuccessModal
        close={setOpenSuccessModal}
        open={openSuccessModal}
        text="You have successfully filed a report against this user."
      />
    </DashboardLayout>
  );
};

export default ReportUser;
