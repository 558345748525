import React from "react"
import SuccessCheck from "../../../../../assets/images/dashboardLayout/success-check.png"
import { CloseIcon } from "../../../../../assets/images/dashboardLayout/svgExports"
import { ModalContainer } from "../../../../../components/layout/ModalLayout/modalLayout.styles"
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles"

interface IProps {
  open: boolean
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void
}

const KycSuccessModal: React.FC<IProps> = ({ open, close }) => {
  const handleClose = () => {
    close(!open)
  }
  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <img src={SuccessCheck} alt="" className="success__check" />
        <h4 className="modal__header__text">BVN verification completed</h4>
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default KycSuccessModal
