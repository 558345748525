import styled from "styled-components";
import Modal from "@mui/material/Modal";

export const SuccessModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .box__wrap {
    background: #ffffff;
    border-radius: 12px;
    padding: 40px;
    width: 90%;
    max-width: 450px;
    min-height: 350px;
    outline: none;
    position: relative;

    .closeIcon {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }

    .modal__header__text {
      text-transform: capitalize;
      font-size: 15px;
      max-width: 300px;
      text-align: center;
    }
    .success__check {
      width: 30%;
      min-width: 80px;
      max-width: 120px;
    }

    @media (max-width: 402px) {
      padding: 40px 20px;
    }
  }
`;
