import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { NextOfKinFragmentFragmentDoc } from './NextOfKinFragment.types';
import { EmergencyContactFragmentFragmentDoc } from './EmergencyContactFragment.types';
import { SocialMediaFragmentFragmentDoc } from './SocialMediaFragment.types';
export type ProfileFragmentFragment = { __typename?: 'Profile', _id?: string | null, userId?: string | null, dob?: any | null, gender?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, firstName?: string | null, profileImage?: string | null, primaryAddress?: string | null, secondaryAddress?: string | null, secondaryEmail?: string | null, stateOfOrigin?: string | null, stateOfResidence?: string | null, maritalStatus?: string | null, employmentStatus?: string | null, verificationLevel?: number | null, bvn?: string | null, nin?: string | null, lga?: string | null, country?: string | null, nationality?: string | null, phone?: string | null, postalCode?: string | null, kycVerified?: boolean | null, kycTypes?: Array<Types.KycType | null> | null, nextOfKin?: { __typename?: 'NextOfKin', fullName?: string | null, address?: string | null, relationship?: string | null, dob?: any | null, email?: string | null, phone?: string | null, gender?: string | null } | null, emergencyContacts?: Array<{ __typename?: 'EmergencyContact', name?: string | null, relationship?: string | null, email?: string | null, address?: string | null, phone?: string | null } | null> | null, socialMedia?: { __typename?: 'SocialMedia', instagram?: string | null, facebook?: string | null, twitter?: string | null, linkedin?: string | null } | null };

export const ProfileFragmentFragmentDoc = gql`
    fragment profileFragment on Profile {
  _id
  userId
  dob
  gender
  lastName
  middleName
  fullName
  firstName
  profileImage
  primaryAddress
  secondaryAddress
  secondaryEmail
  stateOfOrigin
  stateOfResidence
  maritalStatus
  employmentStatus
  verificationLevel
  bvn
  nin
  lga
  country
  nationality
  phone
  postalCode
  kycVerified
  kycTypes
  nextOfKin {
    ...nextOfKinFragment
  }
  emergencyContacts {
    ...emergencyContactFragment
  }
  socialMedia {
    ...socialMediaFragment
  }
}
    ${NextOfKinFragmentFragmentDoc}
${EmergencyContactFragmentFragmentDoc}
${SocialMediaFragmentFragmentDoc}`;