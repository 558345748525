import * as Types from '../../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePrivacyOptionsMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  isBlocked?: Types.InputMaybe<Types.Scalars['Boolean']>;
  personal?: Types.InputMaybe<Types.Scalars['Boolean']>;
  billing?: Types.InputMaybe<Types.Scalars['Boolean']>;
  nextOfKin?: Types.InputMaybe<Types.Scalars['Boolean']>;
  emergency?: Types.InputMaybe<Types.Scalars['Boolean']>;
  nationality?: Types.InputMaybe<Types.Scalars['Boolean']>;
  travel?: Types.InputMaybe<Types.Scalars['Boolean']>;
  medical?: Types.InputMaybe<Types.Scalars['Boolean']>;
  education?: Types.InputMaybe<Types.Scalars['Boolean']>;
  workExperience?: Types.InputMaybe<Types.Scalars['Boolean']>;
  phoneVerification?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdatePrivacyOptionsMutation = { __typename?: 'Mutation', updatePrivacyOptions: { __typename?: 'PrivacyOptionsResponse', message?: string | null, privacyOptions?: { __typename?: 'PrivacyOptions', travel?: boolean | null, billing?: boolean | null, medical?: boolean | null, personal?: boolean | null, nextOfKin?: boolean | null, isBlocked?: boolean | null, emergency?: boolean | null, education?: boolean | null, nationality?: boolean | null, workExperience?: boolean | null, phoneVerification?: boolean | null } | null } };


export const UpdatePrivacyOptionsDocument = gql`
    mutation updatePrivacyOptions($userId: ID!, $isBlocked: Boolean, $personal: Boolean, $billing: Boolean, $nextOfKin: Boolean, $emergency: Boolean, $nationality: Boolean, $travel: Boolean, $medical: Boolean, $education: Boolean, $workExperience: Boolean, $phoneVerification: Boolean) {
  updatePrivacyOptions(
    data: {userId: $userId, isBlocked: $isBlocked, personal: $personal, billing: $billing, nextOfKin: $nextOfKin, emergency: $emergency, nationality: $nationality, travel: $travel, medical: $medical, education: $education, workExperience: $workExperience, phoneVerification: $phoneVerification}
  ) {
    message
    privacyOptions {
      travel
      billing
      medical
      personal
      nextOfKin
      isBlocked
      emergency
      education
      nationality
      workExperience
      phoneVerification
    }
  }
}
    `;
export type UpdatePrivacyOptionsMutationFn = Apollo.MutationFunction<UpdatePrivacyOptionsMutation, UpdatePrivacyOptionsMutationVariables>;

/**
 * __useUpdatePrivacyOptionsMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacyOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacyOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacyOptionsMutation, { data, loading, error }] = useUpdatePrivacyOptionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isBlocked: // value for 'isBlocked'
 *      personal: // value for 'personal'
 *      billing: // value for 'billing'
 *      nextOfKin: // value for 'nextOfKin'
 *      emergency: // value for 'emergency'
 *      nationality: // value for 'nationality'
 *      travel: // value for 'travel'
 *      medical: // value for 'medical'
 *      education: // value for 'education'
 *      workExperience: // value for 'workExperience'
 *      phoneVerification: // value for 'phoneVerification'
 *   },
 * });
 */
export function useUpdatePrivacyOptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivacyOptionsMutation, UpdatePrivacyOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivacyOptionsMutation, UpdatePrivacyOptionsMutationVariables>(UpdatePrivacyOptionsDocument, options);
      }
export type UpdatePrivacyOptionsMutationHookResult = ReturnType<typeof useUpdatePrivacyOptionsMutation>;
export type UpdatePrivacyOptionsMutationResult = Apollo.MutationResult<UpdatePrivacyOptionsMutation>;
export type UpdatePrivacyOptionsMutationOptions = Apollo.BaseMutationOptions<UpdatePrivacyOptionsMutation, UpdatePrivacyOptionsMutationVariables>;