import styled from "styled-components";
import FormControl from "@mui/material/FormControl";

type Custom = {};

export const PinInputContainer = styled(FormControl)<Custom>`
  width: 100%;
  position: relative;
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 15px 0 !important;
  }

  input {
    background: rgba(252, 252, 253, 0.8) !important;
    border: 1px solid rgba(174, 174, 174, 0.8) !important;
    width: 48px !important;
    height: 48px !important;
    border-radius: 6px;
    font-size: 20px !important;
  }

  @media (max-width: 390px) {
    input {
      width: 40px !important;
      height: 40px !important;
      font-size: 16px !important;
    }
  }
`;
