import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type NextOfKinFragmentFragment = { __typename?: 'NextOfKin', fullName?: string | null, address?: string | null, relationship?: string | null, dob?: any | null, email?: string | null, phone?: string | null, gender?: string | null };

export const NextOfKinFragmentFragmentDoc = gql`
    fragment nextOfKinFragment on NextOfKin {
  fullName
  address
  relationship
  dob
  address
  email
  phone
  gender
}
    `;