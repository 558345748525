import React from "react"
import { ModalContainer } from "./manageAccessModal.styles"
import { FlexibleDiv } from "../../../../../../../components/lib/Box/box.styles"
import { CloseIcon } from "../../../../../../../assets/images/dashboardLayout/svgExports"
import { useMe } from "helpers"
import { useUpdatePrivacyOptionsMutation } from "pages/Settings/Privacy&Security/gql/__types/privacyAndSecurity.types"
import { Switch } from "@mui/material"
import { OIDButton, Progress, TOASTER_TYPE, ToastMessage } from "components"
import { getErrorMessage } from "common"

interface IProps {
  open: boolean
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void
}

const ManageAccessModal: React.FC<IProps> = ({ open, close }) => {
  const [personal, setPersonal] = React.useState(false)
  const [billing, setBilling] = React.useState(false)
  const [nextOfKin, setNextOfKin] = React.useState(false)
  const [emergency, setEmergency] = React.useState(false)
  const [workExperience, setWorkExperience] = React.useState(false)
  const [travel, setTravel] = React.useState(false)
  const [education, setEducation] = React.useState(false)
  const [medical, setMedical] = React.useState(false)

  const me = useMe()
  const [mutate, { loading: isPrivacyOptionsLoading }] =
    useUpdatePrivacyOptionsMutation()

  React.useEffect(() => {
    if (typeof me?.privacyOptions?.personal === "boolean") {
      setPersonal(me?.privacyOptions?.personal)
    }
    if (typeof me?.privacyOptions?.billing === "boolean") {
      setBilling(me?.privacyOptions?.billing)
    }
    if (typeof me?.privacyOptions?.nextOfKin === "boolean") {
      setNextOfKin(me?.privacyOptions?.nextOfKin)
    }
    if (typeof me?.privacyOptions?.emergency === "boolean") {
      setEmergency(me?.privacyOptions?.emergency)
    }
    if (typeof me?.privacyOptions?.workExperience === "boolean") {
      setWorkExperience(me?.privacyOptions?.workExperience)
    }
    if (typeof me?.privacyOptions?.travel === "boolean") {
      setTravel(me?.privacyOptions?.travel)
    }
    if (typeof me?.privacyOptions?.medical === "boolean") {
      setMedical(me?.privacyOptions?.medical)
    }
    if (typeof me?.privacyOptions?.education === "boolean") {
      setEducation(me?.privacyOptions?.education)
    }
  }, [me])

  const submitRequests = async () => {
    try {
      await mutate({
        variables: {
          userId: me?._id ? me?._id : "",
          ...me?.privacyOptions,
          personal: personal,
          medical: medical,
          billing: billing,
          nextOfKin: nextOfKin,
          travel: travel,
          education: education,
          workExperience: workExperience,
          emergency: emergency,
        },
      })
      ToastMessage(TOASTER_TYPE.SUCCESS, "Request updated successfully")
    } catch (e) {
      ToastMessage(TOASTER_TYPE.ERROR, getErrorMessage(e))
    }
  }

  const handleClose = () => {
    close(!open)
  }

  const activities = [
    {
      title: "Personal Details",
      checked: personal,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setPersonal(e.target.checked),
    },
    {
      title: "Medical History",
      checked: medical,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setMedical(e.target.checked),
    },
    {
      title: "Billing Info",
      checked: billing,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setBilling(e.target.checked),
    },
    {
      title: "Next of Kin",
      checked: nextOfKin,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setNextOfKin(e.target.checked),
    },
    {
      title: "Travel",
      checked: travel,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setTravel(e.target.checked),
    },
    {
      title: "Education History",
      checked: education,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setEducation(e.target.checked),
    },
    {
      title: "Work Experience",
      checked: workExperience,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setWorkExperience(e.target.checked),
    },
    {
      title: "Emergency Contacts",
      checked: emergency,
      click: (e: React.ChangeEvent<HTMLInputElement>) =>
        setEmergency(e.target.checked),
    },
  ]

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
        alignItems="flex-start"
      >
        {isPrivacyOptionsLoading && <Progress />}

        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <h3 className="modal__headertext">Manage access to my account</h3>
        <span className="modal__subheadertext">
          Control what data 3rd party can access
        </span>
        <FlexibleDiv justifyContent="space-between" className="activity__wrap">
          {activities.map((item: any, idx) => (
            <FlexibleDiv className="activity" key={idx}>
              <p className="actvity__text"> {item.title}</p>
              <Switch checked={item?.checked} onChange={item.click} />
            </FlexibleDiv>
          ))}
        </FlexibleDiv>

        <FlexibleDiv margin="40px 0 0 0">
          <OIDButton onClick={submitRequests}>Submit Requests</OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  )
}

export default ManageAccessModal
