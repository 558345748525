import styled from "styled-components";

type Custom = {};

export const AddMedicalContainer = styled.div<Custom>`
  width: 100%;
  padding: 40px 30px;

  .form__wrap {
    max-width: 1000px;
    padding: 30px 30px 60px 30px;
    background: #ffffff;
    border: 1px solid #f2f4fc;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    .subheader__text,
    .header__text {
      font-size: 14px;
      margin-bottom: 10px;
      color: #424242;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 40px 15px;

    .form__wrap {
      padding: 20px 15px 60px 15px;
    }
  }
`;
