import { NestedObject } from "./removeProperty"

export function updateNullProperties(
  obj: NestedObject | any[] | null | undefined,
  value: string
): NestedObject | any[] {
  if (obj === null || obj === undefined) {
    return {}
  }

  if (Array.isArray(obj)) {
    for (const element of obj) {
      updateNullProperties(element, value)
    }
  } else {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null) {
          obj[key] = value
        } else if (typeof obj[key] === "object") {
          updateNullProperties(obj[key], value)
        }
      }
    }
  }

  return obj
}
