import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

export const SidebarContainer = styled.div`
  .sidebar__wrap__web {
    padding-left: 10px;
    background-color: #ffffff;
    width: 240px;
    height: 100vh;
    position: fixed;
    left: 0;
    box-shadow: 0px 4px 12px rgba(13, 13, 13, 0.04);
    overflow: hidden;
    top: 0;
    z-index: 4;

    .sidebar__logo {
      height: 60px;
      min-height: 60px;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 10px;
      padding-left: 10px;
    }
    .sidebar__items__wrap {
      height: calc(100vh - 60px);
      overflow-y: auto;

      .sidebar__items {
        margin: 7px 0;

        .sidebar__section__headertext {
          font-size: 14px;
          padding: 0 15px;
          color: #0c0c0c;
          margin: 10px 0;
        }
        .sidebar__link {
          width: 100%;
          font-size: 14px;
          text-decoration: none;
          padding: 13px 15px;
          border-radius: 40px 0px 0px 40px;
          color: #0c0c0c;
          outline: none;
          margin: 2px 0;
          transition: color 0.3s ease, background 0.3s ease;

          :hover {
            background: #f2f2f2;
          }
          svg {
            margin: 0 8px -5px 0;
          }
        }

        #active__path {
          background: #ddebff;
          color: #0b61dd;
          font-weight: bold;
          :hover {
            background: #ddebff;
            cursor: default;
          }
        }
      }

      .settings_section {
        border-top: 1px solid #f2f2f2;
        padding: 10px 0 30px 0;
      }
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
`;

export const MobileDrawer = styled(Drawer)`
  .MuiPaper-root,
  .MuiDrawer-paper {
    width: 240px;

    .sidebar__wrap__mobile {
      padding-left: 10px;

      .sidebar__logo {
        height: 60px;
        min-height: 60px;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 10px;
        padding-left: 10px;
      }
      .sidebar__items__wrap {
        height: calc(100vh - 60px);
        overflow-y: auto;

        .sidebar__items {
          margin: 7px 0;

          .sidebar__section__headertext {
            font-size: 14px;
            padding: 0 15px;
            color: #0c0c0c;
            margin: 10px 0;
          }
          .sidebar__link {
            width: 100%;
            font-size: 14px;
            text-decoration: none;
            padding: 13px 15px;
            border-radius: 40px 0px 0px 40px;
            color: #0c0c0c;
            outline: none;

            :hover {
              background-color: #f2f2f2;
            }
            svg {
              margin: 0 8px -5px 0;
            }
          }

          #active__path {
            background: #ddebff;
            color: #0b61dd;
            font-weight: bold;
            :hover {
              background: #ddebff;
              cursor: default;
            }
          }
        }

        .settings_section {
          border-top: 1px solid #f2f2f2;
          padding: 10px 0 30px 0;
        }
      }
    }
  }
`;
