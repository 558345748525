import styled from "styled-components";
import { FlexibleDiv } from "../../../../../components/lib/Box/box.styles";

type Custom = {};

export const ReportUserContainer = styled(FlexibleDiv)<Custom>`
  width: 100%;
  padding: 40px 30px;
  justify-content: flex-start;

  .manageaccess__main__wrap {
    background: #ffffff;
    border-radius: 18px;
    padding: 40px;
    max-width: 800px;

    .report__header__text {
      margin: 0;
      color: #001935;
      font-size: 18px;
    }
  }

  @media (max-width: 880px) {
    padding: 40px 15px;

    .manageaccess__main__wrap {
      padding: 40px 15px;
    }
  }
`;
