import React from "react";
import {
  CloseIcon,
  InstallIcon,
} from "../../../../../../assets/images/dashboardLayout/svgExports";
import { ModalContainer } from "../../../../../../components/layout/ModalLayout/modalLayout.styles";
import { FlexibleDiv } from "../../../../../../components/lib/Box/box.styles";
import { OIDButton } from "../../../../../../components/lib/Button/button.styles";

interface IProps {
  open: boolean;
  close: (value: boolean | ((previousValue: boolean) => boolean)) => void;
  setShowScanModal: (
    value: boolean | ((previousValue: boolean) => boolean)
  ) => void;
}

const InstallModal: React.FC<IProps> = ({ open, close, setShowScanModal }) => {
  const handleClose = () => {
    close(!open);
  };
  const submit = () => {
    handleClose();
    setShowScanModal(true);
  };

  return (
    <ModalContainer open={open} onClose={handleClose}>
      <FlexibleDiv
        width="max-content"
        flexWrap="nowrap"
        className="box__wrap"
        flexDir="column"
      >
        <div className="closeIcon" onClick={handleClose}>
          <CloseIcon width="24" />
        </div>
        <InstallIcon width="30" />
        <h3 className="modal__headertext">Install and Setup</h3>
        <p className="modal__subheadertext">
          To set up two-factor, you require an authenticator. To proceed,
          download and install "Google Authenticator."
        </p>
        <FlexibleDiv justifyContent="flex-end" margin="20px 0 0 0">
          <span className="buttonSpan">Download </span>{" "}
          <OIDButton margin="0 0 0 25px" onClick={submit}>
            I have it installed
          </OIDButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </ModalContainer>
  );
};

export default InstallModal;
