import styled from "styled-components";
import { FlexibleDiv } from "../Box/box.styles";

type Custom = {
  noBorder?: boolean;
};

export const TabsContainer = styled(FlexibleDiv)<Custom>`
  border-bottom: ${({ noBorder }) => (noBorder ? "none" : "1px solid #f2f2f2")};

  .customtab__textwrap {
    margin-right: 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    .customtab__text {
      color: #7d7d7d;
      padding: 7px 1px;
      position: relative;
      overflow: hidden;
      margin: 0 0 -1px 0;
      ::before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        background-color: #0b61dd;
        transition: all 0.3s ease;
        transform: translateX(-200px);
      }
    }

    :hover {
      .customtab__text {
        color: #0c0c0c;
      }
    }
  }
  #activetab {
    .customtab__text {
      color: #0b61dd;
      font-weight: bold;

      ::before {
        transform: translateX(0);
      }
    }

    :hover {
      .customtab__text {
        color: #0b61dd;
      }
    }
  }

  @media (max-width: 600px) {
    .customtab__textwrap {
      margin-right: 30px;
      .customtab__text {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 397px) {
    .customtab__textwrap {
      margin-right: 20px;
      .customtab__text {
        font-size: 12px;
      }
    }
  }

  @keyframes width {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;
