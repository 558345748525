import RocketIcon from "../../assets/images/help/rocket.svg";
import UserIcon from "../../assets/images/help/user.svg";
import FormIcon from "../../assets/images/help/form.svg";
import LockIcon from "../../assets/images/help/lock.svg";
import EyeIcon from "../../assets/images/help/eye.svg";
import IdIcon from "../../assets/images/help/id-card.svg";
import ProtectionIcon from "../../assets/images/help/protection.svg";

export const helpData = [
  {
    title: "Getting Started on OneID",
    description: "All you need to know about OneID and how the platform works",
    icon: RocketIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },

  {
    title: "My OneID Account",
    description: "Learn more about your OneID Account and the various features",
    icon: UserIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },
  {
    title: "KYC Management",
    description: "KYC Management processes with ease using your OneID Code",
    icon: FormIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },
  {
    title: "Restricting Access",
    description: "You have control over 3rd party access to your information",
    icon: LockIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },
  {
    title: "Privacy Settings",
    description: "Learn how you share your information with just a toggle",
    icon: EyeIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },
  {
    title: "BVN & NIN Related Issues",
    description: "All you need to know about OneID and how the platform works",
    icon: IdIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },
  {
    title: "faqs Protection",
    description: "All you need to know about OneID and how the platform works",
    icon: ProtectionIcon,
    faqs: [
      {
        question: "What is OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Why OneID?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "How does it work?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is OneID a registered company?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Is adding my card details safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question:
          "I am going to be putting all my information out there. Is it safe?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
      {
        question: "Can I manage access to my OneID account?",
        answer:
          "OneID is an application that lets you put all your ID information in one place for a one-click signup or login to third party businesses and services.",
      },
    ],
  },
];
